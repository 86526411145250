import React, { useEffect, useRef, useState } from 'react'
import './ElectronicCard.css'
import ButtonLink from '../ButtonLink/ButtonLink'
import Message from '../Message/Message'
// import CardHook from '../../hooks/CardHook'

export default function ElectronicCard() {
  const eCard = useRef()
  const cardNum = useRef()
  const cardM = useRef()
  const cardY = useRef()
  const cvc = useRef()

  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (window.PaymentSession) {
      console.log(cardNum.current)
      console.log(cardM.current)
      console.log(cardY.current)
      console.log(cvc.current)
      window.PaymentSession.configure({
        fields: {
          // ATTACH HOSTED FIELDS IDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
          card: {
            cardNumber: cardNum.current,
            securityCode: cvc.current,
            expiryMonth: cardM.current,
            expiryYear:cardY.current
          }
        },
        callbacks: {
          initialized: function (err, response) {
            console.log("init....");
            console.log(err, response);
            console.log("/init.....");
            // HANDLE INITIALIZATION RESPONSE
          },
          formSessionUpdate: function (err, response) {
            console.log("update callback.....");
            console.log(err, response);
            console.log("/update callback....");

            // HANDLE RESPONSE FOR UPDATE SESSION
            if (response.statusCode) {
              if (200 === response.statusCode) {
                console.log("Session updated with data: " + response.data.sessionId);
              } else if (201 === response.statusCode) {
                console.log("Session update failed with field errors.");

                if (response.message) {
                  var field = response.message.indexOf('valid')
                  field = response.message.slice(field + 5, response.message.length);
                  console.log(field + " is invalid or missing.");
                }
              } else {
                console.log("Session update failed: " + response);
              }
            }
          }
        }
      }).render(eCard.current);
    } else {
      console.log("Fail to get app/session/script !\n\nPlease check if your appId added in session script tag in head section?")
    }
  }, [])
  const handleButtonClick = () => {
    console.log('clicked!')
  }

  return (
    <>
      <section className="text-center">
        <h2 className="common-heading d-inline-block"> Check Out </h2>
        {/* <h5 className="jumbotron-heading">Check Out</h5> */}
      </section>
      <div  id="antiClickjack" style={{ display: "none !important" }} className="card-form">
        <div className="cardfield cardname-field">
          <label> Card Number: </label>
          <input
            ref={cardNum}
            type="text"
            id="cardNumber"
            value=""
            readOnly
          />
        </div>
        <div className="cardfield cardexpiry-field">
          <label> Expiry Date: </label>
          <div className="expiry-container">
            <input
              ref={cardM}
              type="text"
              value=""
              id="cardMonth"
            />
            <input
              ref={cardY}
              type="text"
              value=""
              id="cardYear"
            />
          </div>
        </div>
        <div className="cardfield cardsecurity-field">
          <label> Security Code: </label>
          <input
            ref={cvc}
            value=""
            type="text"
            id="cardCVC"
            readOnly
          />
        </div>
        <ButtonLink ClassName="learn-more-btn flex">
          <button onClick={handleButtonClick}>Pay <i className="fas fa-arrow-circle-right"></i></button>
        </ButtonLink>
      </div>
      <div style={{ textAlign: 'center' }}>
        {errorMessage && <Message ClassName='message-error'>{errorMessage}</Message>}
      </div>
      <div ref={eCard}></div>
    </>
  )
}
