import React from 'react'
import cancelIcon from '../../../images/general-purpose/cancelIcon.png'

export default function ConfirmModal({ title, body, closeModal, children }) {
  return (
    <div>
      <div className="profile-modal-container" onClick={e => e.stopPropagation()}>
        <div className="cancel-button">
          <div className="cancel-wrapper" onClick={closeModal}>
            <img src={cancelIcon} alt="How We Work" />
          </div>
        </div>
        <div className="delete-confirm-modal">
          <h3>{title}</h3>
          <p>{body}</p>
          {children}
        </div>
      </div>
    </div>
  )
}
