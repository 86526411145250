import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { deleteCourse, listInstructorCourses } from '../../../actions/courseActions';
import { updateUserProfile, verifyTokenValidity } from '../../../actions/userActions';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import CourseStripe from '../../../components/CourseStripe/CourseStripe';
import Message from '../../../components/Message/Message';
import Spinner from '../../../components/Spinner/Spinner';
import { USER_UPDATE_PROFILE_RESET } from '../../../constants/userConstants';
import history from '../../../history';
import Modal from '../../../screen/Modal/Modal';
import ConfirmModal from '../ModalScreens/ConfirmModal';
import EditInfoModal from '../ModalScreens/EditInfoModal';

export default function InstructorPanel() {
  const dispatch = useDispatch()
  const { path: pathroot } = useRouteMatch()
  // let instructorCourses = []
  // let _instructorCourses = []

  const [modalOpen, setModalOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })

  const [courseID, setCourseID] = useState("")

  const deleteTitle = "Delete"
  const deleteBody = "Are you sure you want to delete this course? It will remove it permanently!"
  const addTitle = "Create New Course"
  const addBody = "Pressing confirm will take you to new page where you can create your Course"


  const coursesInstructor = useSelector((state) => state.coursesInstructor)
  const { loading: coursesLoading, error: coursesListError, InstructorCourses } = coursesInstructor

  const courseDelete = useSelector((state) => state.courseDelete)
  const { success: courseDeleteSuccess, error: courseDeleteError } = courseDelete

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const {
    error: errorUpdate,
    success: successUpdate,
  } = userUpdateProfile

  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }
  const openDeleteModal = (id) => {
    setDeleteModal(true)
    setCourseID(id)
  }
  const closeDeleteModal = () => {
    setDeleteModal(false)
  }
  const openAddModal = (id) => {
    setAddModal(true)
  }
  const closeAddModal = () => {
    setAddModal(false)
  }
  const deleteHandler = () => {
    dispatch(deleteCourse(courseID))
    setDeleteModal(false)
  }
  // Basic Information update action and response**************
  useEffect(() => {
    if (!userInfo?._id) {
      setTimeout(() => {
        history.push(`/signin?redirect=${pathroot}`)
      }, 500);
    } else {
      dispatch(verifyTokenValidity())
      if (errorUpdate) {
        setMessage({
          msgType: "error",
          messageBody: errorUpdate
        })
        return
      }
      if (successUpdate) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        setMessage({
          msgType: "success",
          messageBody: "Information Updated successfully"
        })
      }
    }
  }, [userInfo, dispatch, errorUpdate, successUpdate, pathroot])

  const onUpdateConfirm = (_instructorDetails) => {
    dispatch(updateUserProfile(_instructorDetails))
  }
  // *******************************************************
  useEffect(() => {
    dispatch(listInstructorCourses())
    if (courseDeleteError) {
      setMessage({
        msgType: "error",
        messageBody: courseDeleteError
      })
    }
  }, [courseDeleteError, courseDeleteSuccess, dispatch])

  const createCourseHandler = () => {
    history.push('/instructor/course/createnew')
    // dispatch(createCourse())
  }
  return (
    <div className="instructor-panel">
      {coursesLoading && <Spinner />}
      {coursesListError && <Message ClassName="message-error">{coursesListError}</Message>}
      {!coursesListError && !coursesLoading && (
        <>
          <div className="panel-information">
            <div className="title-bar-profile">
              <div className="sction-title">Information</div>
              <div title="Edit your Basic Information" className="user-action" onClick={openModal}><i className="fas fa-edit"></i>EDIT</div>
            </div>
            <div className="info-body">
              <p>Profession</p>
              <h6>{userInfo.instructorDetails.instructorProfession}</h6>
            </div>
            <div className="info-body">
              <p>Description</p>
              <h6>{userInfo.instructorDetails.instructorIntroduction}</h6>
            </div>
            <div className="info-body">
              <p>Recent Class</p>
              <h6>{userInfo.instructorDetails.instructorClassification}</h6>
            </div>
            {message.messageBody && <Message ClassName={message.msgType === "success" ? "message-success" : "message-error"}>{message.messageBody}</Message>}
          </div>
          <div className="courses-control">
            <div className="title-bar-profile">
              <div className="sction-title">Courses</div>
              <div title="Create a new course" className="user-action" onClick={openAddModal}><i className="fas fa-plus"></i>ADD</div>
              {/* <p>Placing card stripes</p> */}
            </div>
            <div className="courses-listing"></div>
            {(InstructorCourses?.length !== 0) && (
              <CourseStripe cartItems={InstructorCourses} type="admin" removeHandler={openDeleteModal} />
            )}
          </div>
        </>
      )}

      {modalOpen && (
        <Modal closeModal={closeModal}>
          <EditInfoModal
            closeModal={closeModal}
            title="Instructor Information"
            userType="instructor"
            details={userInfo.instructorDetails}
            onUpdateConfirm={onUpdateConfirm}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal closeModal={closeDeleteModal}>
          <ConfirmModal
            closeModal={closeDeleteModal}
            title={deleteTitle}
            body={deleteBody}
          >
            <ButtonLink ClassName="learn-more-btn confirm float-left half-width">
              <button onClick={closeDeleteModal}> Cancel </button>
            </ButtonLink>
            <ButtonLink ClassName="learn-more-btn cancel float-right half-width">
              <button onClick={deleteHandler}> Delete </button>
            </ButtonLink>
          </ConfirmModal>
        </Modal>
      )}
      {addModal && (
        <Modal closeModal={closeAddModal}>
          <ConfirmModal
            title={addTitle}
            body={addBody}
            closeModal={closeAddModal}
          >
            <ButtonLink ClassName="learn-more-btn cancel float-left half-width">
              <button onClick={closeAddModal}> Cancel </button>
            </ButtonLink>
            <ButtonLink ClassName="learn-more-btn confirm float-right half-width">
              <button onClick={createCourseHandler}> Confirm </button>
            </ButtonLink>
          </ConfirmModal>
        </Modal>
      )}
    </div>
  )
}
