/*=== Import Instructors Images === */
import img_1 from "../images/instructors/AhmedElsayes1.jpg";
import img_2 from "../images/instructors/AhmedElsayes2.jpg";
import img_3 from "../images/instructors/AhmedElsayes3.jpg";

const instructors = [
  {
    img: img_2,
    name: "Ahmed Elsayes",
    profession: "Founder Of RekGPT Platform",
    linkedinURL: "https://www.linkedin.com/in/ahmed-elsayes-a26b665b/"
  }
]

export default instructors