import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './JobStripe.css'
import ApplicationStripe from '../ApplicationStripe/ApplicationStripe'
import { Toaster, toast } from 'react-hot-toast'
import axios from 'axios'

export default function JobStripe({ job, indx, removeHandler, onActionResponse }) {
  const [requestsBoolean, setRequestsBoolean] = useState(false)
  const [publishStatus, setPublishStatus] = useState(job.publicPublished)

  const handleCheckboxAction = async (event) => {
    const { checked } = event.target
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${job.user}`
      },
    }
    try {
      const { data } = await axios.put(`/api/jobs/mentor/toggle-publish/${job._id}`, { publishState: checked }, config)
      setPublishStatus(checked)
      toast.success(data.message || "success")
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <div className="job-stripe" key={indx}>
      <div className="head">
        <div className="title">{job.title}</div>

        <div className="edit-tools">
          <i title="Remove LearnPath" className="fas fa-trash removal" onClick={() => removeHandler(job._id)}></i>
          <Link to={`/mentor/${job._id}/editjob`} title="Edit Path"><i className="fas fa-edit"></i></Link>
        </div>
      </div>
      <div className="row-tags">
        <p>{job.category}</p>
        <div className="form-check" title='check the box to contol if the job is published on the website or hide it so no one can see it on jobs page'>
          <label htmlFor={job._id.toString()} className="form-check-label label-question styled-help-info">
            Job Published
          </label>
          <input
            className="form-check-input"
            name={job._id.toString()}
            id={job._id.toString()}
            type="checkbox"
            onChange={handleCheckboxAction}
            checked={publishStatus}
          />
        </div>
      </div>

      <div className="job-applications">
        <div className={`requests ${requestsBoolean ? "active" : ""}`} onClick={() => setRequestsBoolean(!requestsBoolean)}>
          <h5>All Applications</h5>
          <i className="fas fa-caret-down"></i>
        </div>
        <div className={`wrapper ${requestsBoolean ? "active" : ""}`}>
          {job.applications.map((_application, _index) => (
            <div key={_application._id} className="application-info">
              <ApplicationStripe
                applicationType="job"
                key={_application._id}
                application={_application}
                index={_index}
                topicID={job._id}
                onActionResponse={onActionResponse}
              />
            </div>
          ))}
        </div>
      </div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            duration: 3000
          },
          error: {
            duration: 3000
          }
        }}
      />
    </div>
  )
}
