import { CART_ACTIVATE_GRANT, CART_ADD_ITEM, CART_APPLY_CERTIFICATE_FEE, CART_DEACTIVATE_GRANT, CART_REMOVE_ITEM, CART_RESET } from '../constants/cartConstants'

export const cartReducer = (state = { cartItems: [] }, action) => {

  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload
      const existItem = state.cartItems.find((x) => x.course === item.course)
      // const fullGrantStatus = ((item.price - item.deductedAmount) === 0)
      if (existItem) {
        return state
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item]
        }
      }

    case CART_ACTIVATE_GRANT:
      const slicedItems = state.cartItems.slice(0, 2).map(_item => {
        return { ..._item, deductedAmount: _item.price }
      })
      return {
        ...state,
        cartItems: slicedItems
      }

    case CART_DEACTIVATE_GRANT:
      const originalItems = state.cartItems.map(_item => {
        return { ..._item, deductedAmount: _item.originalDiscount }
      })
      return {
        ...state,
        cartItems: originalItems
      }

    case CART_APPLY_CERTIFICATE_FEE:
      const { courseID, certificationFee, activeCertifiedTrack } = action.payload
      const certifiedItem = state.cartItems.find((_item) => _item.course === courseID)
      certifiedItem.certificationFee = certificationFee
      certifiedItem.activeCertifiedTrack = activeCertifiedTrack

      const newItems = state.cartItems.map((_item) => {
        if (_item.course === courseID) {
          return certifiedItem
        } else {
          return _item
        }
      })

      return {
        ...state,
        cartItems: newItems
      }

    case CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x.course !== action.payload),
      }
    case CART_RESET:
      return { cartItems: [] }

    default:
      return state
  }
}