import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { verifyTokenValidity } from '../../../actions/userActions';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import Message from '../../../components/Message/Message';
import history from '../../../history';
import ChatMessages from './ChatMessages';
import Conversation from './Conversation';

const msgbody = "Hello this is my first message to develop the messages section of this project"
export default function MessagesPanel() {
  const dispatch = useDispatch()
  const { path: pathroot } = useRouteMatch()
  const [conversations, setConversations] = useState([]);
  // const [currentChat, setCurrentChat] = useState(null);
  const [chatMessages, setChatMessages] = useState([])
  const [currentConversation, setCurrentConversation] = useState({
    conversation: {},
    partnerImg: ""
  })
  const [chatMsg, setChatMsg] = useState("")
  const [userDetails, setUserDetails] = useState({});

  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // Start General function *********************************************
  const getConversations = async (_userInfo) => {
    // console.log("_userInfo._id.toString:>>  ", _userInfo._id.toString())
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${_userInfo.token}`
        },
      }
      const { data } = await axios.get(`/api/conversations/${_userInfo._id.toString()}`, config);
      setConversations(data);
      setUserDetails(_userInfo)
    } catch (err) {
      setMessage({
        msgType: "error",
        messageBody: err.message
      })
    }
  }

  const getMessages = async (currentChat, _userInfo) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${_userInfo.token}`
        },
      }
      const { data } = await axios.get(`/api/messages/${currentChat._id.toString()}`, config);
      setChatMessages(data);
    } catch (err) {
      setMessage({
        msgType: "error",
        messageBody: err.message
      })
    }
  };
  // End General function *********************************************

  const handleChange = (event) => {
    const { value } = event.target
    setChatMsg(value)
  }

  useEffect(() => {
    if (!userInfo?._id) {
      setTimeout(() => {
        history.push(`/signin?redirect=${pathroot}`)
      }, 500);
      
    } else {
      getConversations(userInfo)
      dispatch(verifyTokenValidity())
    }
  }, [userInfo, dispatch, pathroot]);

  const displayChatMessages = (_conv, chatPartnerImage) => {
    getMessages(_conv, userInfo)
    setCurrentConversation({
      conversation: _conv,
      partnerImg: chatPartnerImage
    })

  }

  const handleMsgSend = async (event) => {
    event.preventDefault()
    if (!chatMsg) {
      return
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      },
    }
    const MessageDetails = {
      conversationId: currentConversation.conversation._id,
      sender: userInfo._id,
      text: chatMsg
    }

    try {
      const { data } = await axios.post(`/api/messages`, MessageDetails, config)
      setChatMessages([...chatMessages, data])
      setChatMsg("")
    } catch (err) {
      setMessage({
        msgType: "error",
        messageBody: err.message
      })
    }
  }

  return (
    <div className="messages-panel">
      <div className="row justify-content-center full-width">
        <div className="col-md-5 col-xs-12 height-control right-border">
          {conversations && conversations.map(conv => (
            <Conversation
              key={conv._id}
              conversation={conv}
              myUserInfo={userDetails}
              lastMsg={msgbody}
              chooseThisConversation={displayChatMessages}
            />
          ))}
        </div>
        <div className="col-md-7 col-xs-12 height-control">
          <div className="all-msgs-container">
            {currentConversation?.conversation?._id && (
              <>
                <div className="msg-stripes">
                  <ChatMessages
                    messages={chatMessages}
                    myUserInfo={userDetails}
                    partnerImg={currentConversation.partnerImg}
                  />
                </div>
                <div className="send-msgchat">
                  <textarea
                    type="text"
                    name="body"
                    className="form-control general-input--style"
                    value={chatMsg}
                    onChange={handleChange}
                  />
                  <ButtonLink ClassName="learn-more-btn confirm float-right mt-3">
                    <button onClick={handleMsgSend} >Send</button>
                  </ButtonLink>

                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row justify-content-center full-width">
        {message.messageBody && <Message ClassName={message.msgType === "success" ? "message-success" : "message-error"}>{message.messageBody}</Message>}
      </div>
    </div>

  )
}
