import React, { useEffect, useState } from 'react'
import ButtonLink from '../../../components/ButtonLink/ButtonLink'
import Message from '../../../components/Message/Message'
import useVideoUpload from '../../../hooks/useVideoUpload'
import cancelIcon from '../../../images/general-purpose/cancelIcon.png'
export default function LessonDetailsForm({ closeModal, lessonDetail, onLessonDetailUpdate, freeCourseBoolean }) {

  const [_lessonDetails, setLessonDetails] = useState({
    id: lessonDetail.id,
    title: lessonDetail.title ? lessonDetail.title : "",
    isvideo: lessonDetail ? lessonDetail.isvideo : true,
    preview: lessonDetail ? lessonDetail.preview : false,
    hostingProvider: lessonDetail.hostingProvider ? lessonDetail.hostingProvider : "youtube",
    uploadedVideoID: lessonDetail.uploadedVideoID ? lessonDetail.uploadedVideoID : "",
    uploadedVideoName: lessonDetail.uploadedVideoName ? lessonDetail.uploadedVideoName : "",
    uTubeVideoLink: lessonDetail.uTubeVideoLink ? lessonDetail.uTubeVideoLink : "",
    showtime: lessonDetail.showtime ? lessonDetail.showtime : 0,
    text: lessonDetail.text ? lessonDetail.text : "",
    URL: lessonDetail.URL ? lessonDetail.URL : "",
  })
  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })
  // Custom Hook
  const [vidUploadRes, triggerVidUpload] = useVideoUpload()

  const handleCheck = (event) => {
    const { name, checked } = event.target
    setLessonDetails((prevInput) => {
      return {
        ...prevInput,
        [name]: checked
      }
    })
    // console.log("chexkbox changes state:>>", event.target.checked)
  }
  const handleChange = (event) => {
    const { name, value } = event.target
    setLessonDetails((prevEntries) => {
      return {
        ...prevEntries,
        [name]: value
      }
    })
  }

  // handle video upload to backend
  const onVideoUpload = async (e) => {
    const videoFile = e.target.files[0]
    triggerVidUpload(videoFile)
  }

  useEffect(() => {
    if (vidUploadRes.uploadStatus) {
      setLessonDetails((prevEntries) => {
        return {
          ...prevEntries,
          uploadedVideoID: vidUploadRes.vidId,
          uploadedVideoName: vidUploadRes.fileName
        }
      })
    }
  }, [vidUploadRes])
  useEffect(() => {
    if (freeCourseBoolean) {
      setLessonDetails((prevEntries) => {
        return {
          ...prevEntries,
          hostingProvider: "youtube",
        }
      })
    }
  }, [freeCourseBoolean])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!_lessonDetails.title) {
      setMessage({
        msgType: "error",
        messageBody: "'Lesson Title' is missing"
      })
      return
    }
    if (_lessonDetails.isvideo) {
      if (_lessonDetails.hostingProvider === "youtube") {
        if (!_lessonDetails.uTubeVideoLink.includes("https://www.youtube.com/watch?v=")) {
          setMessage({
            msgType: "error",
            messageBody: "Invalid video link"
          })
          return
        }
      } else {
        if (!_lessonDetails.uploadedVideoID) {
          setMessage({
            msgType: "error",
            messageBody: "No video uploaded for the lesson"
          })
          return
        }
      }
    }
    if (_lessonDetails.isvideo && !_lessonDetails.showtime) {
      setMessage({
        msgType: "error",
        messageBody: "'Length of Video in Minutes' is missing"
      })
      return
    }
    if (!_lessonDetails.isvideo && !_lessonDetails.text) {
      setMessage({
        msgType: "error",
        messageBody: "'Text of Lesson' is missing"
      })
      return
    }
    onLessonDetailUpdate(_lessonDetails)
  }
  // console.log("_lessonDetails: ", _lessonDetails)

  return (
    <div className="lessondetail-form-container" onClick={e => e.stopPropagation()}>
      <div className="cancel-button">
        <div className="cancel-wrapper" onClick={closeModal}>
          <img src={cancelIcon} alt="How We Work" />
        </div>
      </div>
      <h3>Edit Lesson Details</h3>
      <div className="lesson-form">
        <div className="subsection-title mb-3">
          <label> Lesson Title </label>
          <input
            className="form-control course_input"
            name='title'
            value={_lessonDetails.title}
            onChange={handleChange}
          />
        </div>
        <div className="row no-padding justify-content-between align-items-center">
          <div className="col-md-6 col-sm-12">
            <div className="form-check mb-1">
              <input
                className="form-check-input"
                name="preview"
                type="checkbox"
                onChange={handleCheck}
                checked={_lessonDetails.preview}
              />
              <label className="form-check-label label-question">
                Enable free view for this Lesson
              </label>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="form-check mb-1">
              <input
                className="form-check-input"
                name="isvideo"
                type="checkbox"
                onChange={handleCheck}
                checked={_lessonDetails.isvideo}
              />
              <label className="form-check-label label-question">
                It is video
              </label>
            </div>
          </div>
        </div>
        {_lessonDetails.isvideo ? (
          <>
            <div className="row no-padding justify-content-center align-items-center">
              <div className="col-md-4 col-sm-12">
                <label> Video Hosting </label>
                <select name="hostingProvider" className="form-select course_input" onChange={handleChange}>
                  <option value={_lessonDetails.hostingProvider}> {_lessonDetails.hostingProvider === "youtube" ? "Youtube" : "Upload On RekGPT"} </option>
                  <option value="youtube">Youtube</option>
                  {!freeCourseBoolean && <option value="aseeu">Upload On RekGPT</option>}
                </select>
              </div>
              {_lessonDetails.hostingProvider === "youtube" ? (
                <div className="col-md-8 col-sm-12">
                  <label> Link of Youtube video </label>
                  <input
                    className="form-control course_input"
                    name='uTubeVideoLink'
                    placeholder="example: https://www.youtube.com/watch?v=-8GZJiMw_Ys"
                    value={_lessonDetails.uTubeVideoLink}
                    onChange={handleChange}
                  />
                </div>
              ) : !freeCourseBoolean && (
                <div className="col-md-8 col-sm-12">
                  <label> Upload Lesson Video </label>
                  <input
                    id="videofile"
                    type="file"
                    className="form-control course_input no-height"
                    placeholder="Course Introductory video"
                    onChange={onVideoUpload}
                  />
                </div>
              )}
            </div>
            <div className="row no-padding justify-content-center align-items-center">
              <div className="col-12">
                {vidUploadRes.faultMsg && <Message ClassName='message-error mini-msg'>{vidUploadRes.faultMsg}</Message>}
                {vidUploadRes.uploadStatus ? (
                  <Message ClassName='message-success mini-msg'>video is uploaded successfully</Message>
                ) : (
                  vidUploadRes.vidId && <Message ClassName='message-info mini-msg'>Video Uploading: {vidUploadRes.uploadPercent}%</Message>
                )}
                {_lessonDetails.uploadedVideoName && <Message ClassName='message-info mini-msg'>{_lessonDetails.uploadedVideoName}</Message>}
              </div>
            </div>
            <div className="mb-2">
              <label> Length of Video in Minutes </label>
              <input
                className="form-control course_input"
                type="number"
                name='showtime'
                value={_lessonDetails.showtime}
                onChange={handleChange}
              />
            </div>
          </>
        ) : (
          <>
            <div className="mb-3">
              <label> Text of your lesson </label>
              <textarea
                className="form-control course_input"
                name='text'
                value={_lessonDetails.text}
                onChange={handleChange}
                rows={4}
              />
            </div>
            <div className="mb-3">
              <label> Link to specific website </label>
              <input
                className="form-control course_input"
                name='URL'
                value={_lessonDetails.URL}
                onChange={handleChange}
              />
            </div>
          </>
        )}
      </div>
      <ButtonLink ClassName="learn-more-btn full-width">
        <button onClick={handleSubmit}> Confirm </button>
      </ButtonLink>
      {message.messageBody && <Message ClassName={message.msgType === "error" ? "message-error" : "message-success"}>{message.messageBody}</Message>}
    </div>
  )
}
