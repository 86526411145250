import React from 'react'

export default function Modal({closeModal, children}) {

  return (
    <div className="modal-window" onClick={closeModal}>
      {children}
    </div>
  )
}
