const EnglishTranslation = {
  Home: "Home",
  LearningPaths: "Learning Paths",
  Jobs: "Jobs",
  Pricing: "Subscription Plans",
  Plans: "Plans",
  AboutUs: 'About us',
  ArabsTechnopedia: 'Technopedia',
  Entrepreneurs: "Entrepreneurs",
  EntrepreneursOasis: "Entrepreneurs Oasis",
  CoFounder: "Co-Founders Matching",
  Community: "Community",
  Freelancers: "Freelancers",
  DigitalAccountant: "Digital Accountant",
  JoinUs: "Join Us",
  Profile: "Profile",
  MyEnrolledCourses: "My Courses",
  MyLearnPath: "My LearnPath",
  Cart: "Cart",
  Logout: "Logout",
  SignIn: "SignIn",
  Register: "Register",
  Instructor: "Instructor",
  Invest: "Invest",
  Educate: "Educate",
  English: "English",
  Arabic: "Arabic",
  WatchEnglish: "Watch in English",
  WatchArabic: "Watch in Arabic",
  LearnMore: "Learn More",
  Announce: "List Your Vacancies",
  Learning: "Learning",
  ReceiveApplication: "Receive Applications",
  StartUp: "Start Up",
  ContactUs: "Contact Us",
  Optionally: "Optionally",

  // Main Page
  DemoBroadcasting: "Demo Broadcasting",
  GetA: "The Ultimate Solution",
  ClearPath: "AI-Powered Software",
  TowardGoals: "tailored for startups needs",
  HelpCompanies: "RekGPT is your own recruitment software to manage and automate hiring process from A to Z in a smart way and increase your productivity 10x",
  SignupOrUpgrade: "Try it for Free",
  WeHelpYouthEspecially: "We help youth especially immigrants in Europe to land their dream job through mentorship and e-learning programs, or networking to startup business",
  OnePlateformFuture: "one platform for Complete professional Solution",
  Howwework: "How it works",
  ListOpenVacancies: "Write vacancy details and publish it on RekGPT, vacancies will be listed on our jobs section. In your profile, you will find a link that tailored for your company and refers to your open vacancies. Now, you can share this link on your website to start receiving job applications",
  GainNeededExperience: "Gain the needed experience through learning most demanded technologies that are lectured by experts in their fields",
  ReceivingJobApplications: "You will start receiving job applications. Now in your profile, you can see these applications. On you own board, you have a full control to shortlist the suitable candidates and start scheduling the interviews. Our AI technology will help you to find the relevant applications",
  StartUpOrJobApply: "After being ready, apply for the job posted by your mentor or even better use our network space to build a partnership and start up your own business as founder or co-founder",
  TrustedPartner: "Hire in smart way and save time & money",
  OurMissionIsToQualify: "RekGPT is more than a recruitment software that help you to manage hiring process. RekGPT is built mainly for the purpose of helping startups to be super productive when it comes to recruitment. Enjoy using it for free to hire best talents and consider a premium plan when you grow to get astonishing results",
  FillGap: "Filling the gap",
  HelpAvoidWasting: "ًOur ultimate goal is to ensure the success of our partner firms in finiding abundance of qualified candidates. We have a vision for healthy and prosper communities, that is why we learn from our partner companies and design educational programs delivered by experts to qualify next generations for market needs",
  StartWithTechnopedia: "Start Now With Technopedia",
  WeAreDifferentBecause: "We are different because we work in a different way. Our experts investigating the market first, then educating the skills that are highly needed in labour market. Our objective is to equip the youth with latest technologies to enable them competing globally. Start now to get a high quality of education and training. Prove your skills by getting certified with us and build practical applications",
  IfYourDreamIsEntrepreneur: "If your dream is to become an entrepreneur, We understand exactly your needs and obstacles that hinder you from realizing your dream. With us, you will find all tools and guidance to turn your dream into reality",
  DistinguishYourself: "Distinguish Yourself",
  SpeedYourLearning: "Speed-Up Your Learning",
  ClearPathTowardYourGoal: "Get a clear path toward your goal by having a mentor. Build professional network that help you to start up",
  PreciseConciseTraining: "Precise and concise training to meet your target. you do not need to wast any more time by taking unclear tracks",
  TechnologyAdvancingRapidely: "Technology is advancing rapidely. By learning the latest technologies, you are on the track to land the job of your dream",

  // Benefits
  AseeuBenefits: "Why RekGPT should be your choice?",
  Benefit1_title: "Brand your company",
  Benefit1_body: "Managing the recruitment process professionally will help you promote your brand and hunt best talents. You get one link to be used in your website that lead to your jobs. Your applicant will not notice leaving your website.",
  Benefit2_title: "Save time and cut costs",
  Benefit2_body: "Using Recruitment Software allow you to automate and manage your hiring process efficiently. It eliminates your paper work and significantly saves your time and money.",
  Benefit3_title: "Smart system for higher productivity",
  Benefit3_body: "Harnessing power of Artifical Intelligence, our smart system will help you finding the right talent who match your criteria among the crowd. This will have an outstanding impact on your process",
  Benefit4_title: "One access point to reach all",
  Benefit4_body: "To fill a vacancy, you start listing it on job advertising platforms. This is a painful and costy process. What if you can get your job advertised on these platforms automatically once you write it on RekGPT.",
  Benefit5_title: "Software tailored for startups",
  Benefit5_body: "The Free  subscription plan has all main features that ensure best experience with talent acquisition, The software is super easy and simple to use which makes it a perfect choice for startups.",

  // Technopedia
  WhyTechnopediaExperience: "Why technopedia offers a unique experience",
  ContinuousFollowUp: "Continuous follow-up to ensure meeting training goals",
  LearningInNativeLanguage: "Learning in your native language to make sure that you got the most of this experience",
  BuildRealProject: "Build a real project as a prove for your competency",
  ContinuousImprove: "Continuous improve of curriculum to ensure your competency by being updated with the new in your field",
  LearnExperienceSupported: "your learning experience is supported by following well-defined full tracks which designed by professionals",
  MentorshipToEnsure: "Mentorship to ensure that you are on the right track",
  ConnectionToLabor: "Connection to labor market, so you are aware of on-demand skills",
  OngoingCourses: "Ongoing Courses",
  OurInstructors: "Our Instructors",
  ConsiderAScholarship: "Consider A Scholarship",
  MakeAnInvestment: "Make An Investment",
  EducateWithUs: "Educate With Us",
  ASEEUIsFoundedTo: "RekGPT is founded mainly to help youth. We are commited to help motivated youth to work on their dreams. You can now learn for free if the expenses is the only thing stand between you and your dreams",
  ThereAreDevotedInstructors: "There are instructors who devote their full time for providing highest quality courses. That is why there are some paid courses. If you share us the same vision, you can invest with us!",
  ParticipateWithUsEducation: "Teach with us to help thosands of motivated youth in realizing their dreams. Jump to another level of expertise in your field by teaching it to others and highlight your capability in front of many organizations",
  WeDoNotSellIllusions: "We don't sell illusions, we give only a real value",
  YouAreOnlyCertifiedIf: "You are only certified with us if you completed the course successfully and passed the exam by the end of the course. When you be certified with us, your profile will be recorded in our Talents Pool and it will be available for recruiters who are searching for talents with your skill",
  SomeOfCourcesCan: "Some of our course can be enrolled for free. If you enrolled into non-certified track, You will not need to pass any exams or deliver any projects",

  // Joinus
  ReadyToCreateNew: "Ready To Create A New Reality With Us !",
  TeachWithUs: "Teach With Us",
  InvestWithUs: "Invest With Us",
  BeAMentor: "Be A Mentor",
  BenefitsOfTeaching: "Benefits of teaching with us",
  BringChange: "Bring Change",
  BuildUniqueNetwork: "Build A Unique Network",
  EarnSomeMoney: "Earn Some Money",
  MainCoreOfOrganization: "The main core of our organization to make a positive impact in others live. By teaching other people in their native language and through our scholarships program, you will help thousands to realize their dreams",
  JoinExpertsClub: "Joining our experts community is a priceless experience. It will enable you to collaborate with special people in their own fields and you will have a full support from our network if you decided to start your own business someday",
  WhileYouImpactWorld: "While you are working to make a real impact in this world, We will make sure that you generate some money from your work as we know that your work as instructor requires dedication of much time and effort",
  MakeRealImpact: "Make A Real Impact",
  JumbToLevel: "Jump to another level of expertise in your field by teaching it to other people and earn some money",
  NoteSure: "Not sure? Watch this video",
  ToSeeHowMuch: "to see why you should consider teaching on our technopedia",
  AvailableTracks: "Available Tracks",
  ThereAreTwoTracks: "There are two tracks available for teaching on our Technopedia. Select the suitable track based on your motives and your level of experience in online tutoring",
  StarterInstructor: "Starter Instructor",
  ExperiencedInstructor: "Experienced Instructor",
  StillConfused: "Still confused!",
  WhichTrackTake: "Which track should i take?",
  IfProfessionalCreator: "if you are a professional content creator, as an example you have substantial skills with video recording, video editing, graphics etc. If you want to teach a course related to the previously mentioned skills, you should apply through Experienced track",
  IfNoSkillsWithSkills: "If you have no skills with previously mentioned skills, you should apply through starter track",
  IfDoubts: "If any doubts, don't hesitate to",
  BenefitsofInvesting: "Benefits Of Investing With Us",
  WhatModel: "What Is RekGPT Model?",
  WhyConsiderInvest: "Why Consider Investing With Us?",
  WhatManageStyle: "What Is Our Style In Management?",
  WhatFinanceTransparency: "What About Finances Transparency?",
  ClosedLoopModel: "We are following a closed loop business model to ensure the sustainability of our services. This means that the major portion of our profits is pumped back to improve our digital platform and the services that we could offer",
  InvestorFirstMotive: "As investor, your first motive should be on supporting the mission of this initiative rather than maximizing your financial gains; However, you will be able to secure some profits. Only 30 % of profits is available for shareholders, the rest is pumped back in our services",
  DecisionMakingInASEEU: "The decision making in the company is done through voting by ratifying votes of shareholders who possess the biggest portion in the company’s equity. Everything is done and managed securely through the digital platform",
  DigitalizingOurServices: "We are digitalizing our services. This means that even managing the investors affairs is done through digital means. As an investor, you will monitor and follow all our company’s activities, liabilities, your equity, and the equity of all members in the company",
  ShareUsTheVision: "Share Us The Vision For Helping Millions Of Youth",
  IfYouShareUsAndWilling: "If you share us the same values and willing to contribute to a meaningfull work, join us!",
  BenefitsOfMentorship: "Benefits Of mentorship",
  ShortTime: "Short Time and Free",
  NotTimeConsuming: "It is not a time consuming task",
  JustAboutOnePath: "It's just about one learn path and sharing your tech stack",
  PostJobsIfHave: "Use our jobs board for free to post any number of jobs in your place",
  MentorOnAseeuEnable: "Mentorship on RekGPT will enable you to build a unique network which will be very useful if you decided to start-up",
  FindYourTalent: "You will have a free access to our talents pool",
  EarnPoints: "Earn Points",
  BeingActiveMentor: "Being very active mentor:",
  FreeStartUpMembership: "You can get a premium membership for our Entrepreneurs services",
  PremiumSubscription: "You can get a premium subscription for free access to our courses",
  MentorRoles: "Mentor Roles & Responsibilities",
  AtLeastOnePath: "Write at least one learning path that will act as a guideline for youth",
  AddRecommendation: "Add a recommendation or act as a reference for your mentees",
  PostJobsThatOpen: "Post jobs that are open in your workplace or any other other workplaces where your recommendation can be considered",
  ModifyWrittenPath: "Modify your written learning path based on the your mentees feedback if it is needed",
  ReviseMentees: "Revise your mentees CVs and give a feedback if needed",
  YouCanEncourage: "You can encourage your most special mentee to create online course on RekGPT and you act as a supervisor on the content offered on the course. In this case you will share him/her course sales",
  ToSeeHowEasyMentor: " to see how much it easy to mentor with us",
  JoinAsMentor: "Join as Mentor",

  // About us
  AseeuMentors: "RekGPT MENTORS",
  SpicialistsGuiding: "specialists who guiding our youth",
  WhoWeAre: "Who We Are",
  ASEEUStandsFor: "RekGPT stands for “Arab scientists and engineers in Europe”. The company started as an initiative by small group of young men living between Finland and Egypt. Our main idea was to help Youth in MENA region to get a good job opportunities or starting up their business and helping immigrants to speed-up the integration process in their hosting societies.",
  OurMission: "Our Mission",
  OurMissionIsHelp: "Our mission is to help youth to land their dream jobs through proper guidance and education or starting-up their business through building professional network and getting needed guidance to succeed. On the other hand, we would like to help European economies to have access to the skilled workforce which could fill the gap between supply and demand in some sectors such as IT and AI.",
  OurDeam: "Our Dream",
  OurDreamIsToHave: "Our dream is to have soon high number of experts and specialists who are sharing us the same vision and willingness to guid youth to the right direction and helping them to realize their dreams.",
  OurVision: "Our vision",
  WeBelieveThat: "We believe that Youth in MENA region and Europe has a high potential for growth and pushing the economies of countries around Mediterranean. We believe that creating a space for collaboration between youth living in this region can lead to amazing results in terms of prosperity and mutual-understanding.",
  FAQ: "FAQ",
  TheseAreFaq: "These are the most frequent questions that we have got about RekGPT",

  // EntreprenuerOasis
  BoostingSuccessWy: "BOOSTING YOUR WAY FOR SUCCESS",
  HelpingYouTackle: "Helping You To Tackle All Obstacles That Hold You Back and Equipping You With All Tools You Need To Be successful Entrepreneur",
  StartupBusiness: "start-up your business",
  StartBusinessToSuccess: "Starting up a business and take it to success level is not an easy task. To succeed, there are Pillars need to be built",
  WeCanBuildTeam: "We can help you to build your team of talents who can execute the idea. We can help you also with our experience in starting up. Moreover, if we see a high potential in your team and your idea, we can help you in seed funding to create your Minimum Viable product (MVP) and help you at launch phase",
  FoundersIdea: "The idea: You must have the idea of your business clear in your mind",
  FoundersAttitude: "Your attitude: You must have a strong will and solid desire with a lot of patience to continue",
  FoundersPatience: "Experience: You must have the experience and qualified team to turn your idea into reality",
  FounderFunding: "Funding: You must have the money to start-up or know how you will secure funds",
  // explainer sections in EntreprenuerOasis
  CoFounderMatching: "Co-founder matching",
  EntrepreneurshipNotEasy: "Entrepreneurship was never an easy path. It needs a lot of dedication, patience, and persistence. A quick look to all giant techs Google, Facebook, PayPal, Apple, Microsoft, and many more can teach you that it is almost impossible or at least very hard to create a big success alone. our mission is to help you find the success partner",
  EngageOthers: "Engage with others",
  PropablyHaveQuestions: "Most probably, you will have many questions. Engage with other entrepreneurs. Ask them whatever you want to help you find a solution for your business issues. Let’s help each other to build a real community of entrepreneurs.",
  EquityManagement: "Equity and Finances Management",
  startupTools: "StartUp tools",
  DoYouHavePartners: "Do you have partners with you in your business and you don’t know how to calculate the equity of each one? we are offering you an awesome online tool to continuously record your expenses and calculate the equity of each member in the company to goster trust between partners and encourage investors to invest in your start-up.",
  ListHereBestTools: "We list here all the tools that might help you in your startup journey. When you start your journey, You will have many questions about many topics such as what tools that i should use to build my product or service, what tools to help me in bookkeeping, marketing, team management, collecting payments and more",

  // Footer
  EducationalCourses: "Educational Courses",
  FrequentlyAskedQuestions: "Frequently Asked Questions",
  BeMentorOnASEEU: "Be Mentor On RekGPT",
  aseeuAsJbsBoard: "Use RekGPT as your jobs board",
  TeachOnASEEUTechnopedia: "Teach On RekGPT Technopedia",
  JobsInCompanies: "Jobs in our partner companies",
  JobsInAseeu: "Jobs in RekGPT",
  InterestedInvestASEEU: "Interested To Invest In RekGPT?"
}

export default EnglishTranslation