import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAdminGrant, deleteGrant, fetchAllGrants } from '../../actions/grantActions'
import ButtonLink from '../../components/ButtonLink/ButtonLink'
import Message from '../../components/Message/Message'
import Table from '../../components/Table/Table'
import { GRANT_ADMIN_ACTION_RESET } from '../../constants/grantsConstants'

const columns = [
  { accessor: 'code', label: 'Code' },
  { accessor: 'grantorname', label: 'Grantor Name' },
  { accessor: 'grantorid', label: 'Grantor ID' },
  { accessor: 'grantavailability', label: 'Grant Availability', format: value => (value ? '✔️' : '✖️') },
  { accessor: 'membersnum', label: 'Members Num' },
]

export default function GrantsListScreen() {
  const dispatch = useDispatch()

  const { grantsList, grantsListingError } = useSelector((state) => state.allGrants)

  const { deleteSuccess, actionError, createAdminSuccess } = useSelector((state) => state.grantAdminAction)

  const [grantForm, setGrantForm] = useState({
    grantCode: '',
    MaximumMembersForGrant: '',
  })

  const [tableRows, setTableRows] = useState([])
  const [rowsNumPerPage, setRowsNumPerPage] = useState(15)
  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })

  const rowDataFormation = (data) => {
    let grantorname = ""
    let grantorid = ""
    const rearrangedData = data.map(row => {
      row.members.forEach(member => {
        if (member.grantor) {
          grantorname = member.name || ""
          grantorid = member._id
        }
      })
      const tableRow = {
        _id: row._id,
        code: row.grantCode,
        grantavailability: row.grantAvailability,
        membersnum: row.members.length,
        grantorname: grantorname,
        grantorid: grantorid
      }
      return tableRow
    })

    return rearrangedData
  }

  const deleteHandler = (id) => {
    dispatch(deleteGrant(id))
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setGrantForm((prevInputs) => {
      return {
        ...prevInputs,
        [name]: value
      }
    })
  }

  const grantCreateHandler = (event) => {
    event.preventDefault()
    dispatch(createAdminGrant(grantForm))
  }

  useEffect(() => {
    if (actionError) {
      setMessage({
        msgType: "message-error",
        messageBody: actionError
      })
      return
    }
    dispatch(fetchAllGrants())
    if (createAdminSuccess || deleteSuccess) {
      setMessage({
        msgType: "message-success",
        messageBody: "Action Completed successfully"
      })
      setTimeout(() => {
        dispatch({type: GRANT_ADMIN_ACTION_RESET})
        setMessage({
          msgType: "",
          messageBody: ""
        })
      }, 2000);
    }
  }, [dispatch, deleteSuccess, actionError, createAdminSuccess])

  useEffect(() => {
    if (grantsListingError) {
      setMessage({
        msgType: "message-error",
        messageBody: grantsListingError
      })
      return
    }
    if (grantsList) {
      const _tableRows = rowDataFormation(grantsList)
      setTableRows(_tableRows)
    }
  }, [grantsList, grantsListingError])

  return (
    <div className="userdetails-editor">
      <div className="container">
        <h4 className="common-heading"> Create a new Admin Grant </h4>
        <div className="useredit-content mb-5">
          <div className="row justify-content-between align-items-end">
            <div className="col-md-4 col-sm-12">
              <label>Promotional Code</label>
              <input
                className="useredit_input"
                name='grantCode'
                value={grantForm.grantCode}
                placeholder='grant Code'
                onChange={handleChange}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label className="form-label">Maximum users to use code</label>
              <input
                className="useredit_input"
                name='MaximumMembersForGrant'
                value={grantForm.MaximumMembersForGrant}
                placeholder='Maximum Members For Grant'
                onChange={handleChange}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <ButtonLink ClassName="learn-more-btn confirm fit-content">
                <button onClick={grantCreateHandler}>Publish</button>
              </ButtonLink>
            </div>
          </div>
        </div>
        <h4 className="common-heading"> List All Grants </h4>
        <div className="useredit-content">
          <div className="row align-items-center mb-4">
            <div className='col-3'>
              <label> Select Num of Rows Per Page </label>
              <select name="pageRows" className="form-select course_input" onChange={(event) => { setRowsNumPerPage(parseInt(event.target.value)) }}>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          <Table
            columns={columns}
            rows={tableRows}
            rowsPerPage={rowsNumPerPage}
            delteActionEnabled={true}
            editActionEnabled={false}
            deleteHandler={deleteHandler}
            editHandler={() => { }}
          />
        </div>
        {message.messageBody && <Message ClassName={`${message.msgType} fixed-bottom`}>{message.messageBody}</Message>}
      </div>
    </div>
  )
}
