/* === Import Route & Switch For Roting === */
import { Route, Switch } from "react-router-dom";
/* === Import Layouts Components === */
import Header from "./layouts/Header/Header";
import Footer from "./layouts/Footer/Footer";

/* === Import Master Pages Components === */
import MainPage from "./pages/MainPage/MainPage";
// import AboutUs from "./pages/AboutUs/AboutUs";
import Technopedia from "./pages/Technopedia/Technopedia";
import PricingPage from "./pages/Pricing/PricingPage";
import CourseDetails from "./pages/CourseDetails/CourseDetails";
import EnrolledCourses from "./pages/EnrolledCourses/EnrolledCourses";
import Jobs from "./pages/Jobs/Jobs";
import LearningPath from "./pages/LearningPath/LearningPath";
import Cart from "./pages/OrderPages/Cart";
import Order from "./pages/OrderPages/Order";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import UserLearnPath from "./pages/LearningPath/UserLearnPath";

/* === Import Admin Pages Components === */
import UserListScreen from "./pages/AdminPages/UserListScreen";
import UserEditScreen from "./pages/AdminPages/UserEditScreen";
import ListAllCourses from "./pages/AdminPages/ListAllCourses";
import GrantsListScreen from "./pages/AdminPages/GrantsListScreen";

/* === Import Instructor and Mentor pages === */
import InstructorCorses from "./pages/InstructorCorses/InstructorCorses";
import CreateJob from "./pages/MentorJob/CreateJob";
import CreatePath from "./pages/MentorPath/CreatePath";
import UpdatePath from "./pages/MentorPath/UpdatePath";
import UpdateJob from "./pages/MentorJob/UpdateJob";
import CourseCreateScreen from "./pages/AdminPages/CourseCreateScreen";
import CourseEditScreen from "./pages/AdminPages/CourseEditScreen";

/* === Import Entrepreneur Pages Components === */
import Joinus from "./pages/Joinus/Joinus";
import UserProfile from "./pages/UserProfile/UserProfile";
import InstructorRoles from "./pages/Roles/InstructorRoles";
import MentorRoles from "./pages/Roles/MentorRoles";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { listCategories } from "./actions/categoriesAction";
import { fetchGrantInfo } from "./actions/grantActions";
import { verifyTokenValidity } from "./actions/userActions";
import history from "./history";
import CategoriesListScreen from "./pages/AdminPages/CategoriesListScreen";
import CreatePartnershipRequest from "./pages/FounderAdvertiseForm/CreatePartnershipRequest";
import UpdatePartnershipRequest from "./pages/FounderAdvertiseForm/UpdatePartnershipRequest";
import FounderRoles from "./pages/Roles/FounderRoles";

// const MainPage = lazy(() => import('./pages/MainPage/MainPage'));
// const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'));
// const Technopedia = lazy(() => import('./pages/Technopedia/Technopedia'));
// const CourseDetails = lazy(() => import('./pages/CourseDetails/CourseDetails'));
// const EnrolledCourses = lazy(() => import('./pages/EnrolledCourses/EnrolledCourses'));
// const Jobs = lazy(() => import('./pages/Jobs/Jobs'));
// const LearningPath = lazy(() => import('./pages/LearningPath/LearningPath'));
// const Cart = lazy(() => import('./pages/OrderPages/Cart'));
// const Order = lazy(() => import('./pages/OrderPages/Order'));
// const SignIn = lazy(() => import('./pages/SignIn/SignIn'));
// const SignUp = lazy(() => import('./pages/SignUp/SignUp'));
// const UserLearnPath = lazy(() => import('./pages/LearningPath/UserLearnPath'));
// const Joinus = lazy(() => import('./pages/Joinus/Joinus'));
// const UserProfile = lazy(() => import('./pages/UserProfile/UserProfile'));

// const InstructorCorses = lazy(() => import('./pages/InstructorCorses/InstructorCorses'));
// const CreateJob = lazy(() => import('./pages/MentorJob/CreateJob'));
// const CreatePath = lazy(() => import('./pages/MentorPath/CreatePath'));
// const UpdatePath = lazy(() => import('./pages/MentorPath/UpdatePath'));
// const UpdateJob = lazy(() => import('./pages/MentorJob/UpdateJob'));
// const CourseCreateScreen = lazy(() => import('./pages/AdminPages/CourseCreateScreen'));
// const CourseEditScreen = lazy(() => import('./pages/AdminPages/CourseEditScreen'));

// const UserListScreen = lazy(() => import('./pages/AdminPages/UserListScreen'));
// const UserEditScreen = lazy(() => import('./pages/AdminPages/UserEditScreen'));
// const ListAllCourses = lazy(() => import('./pages/AdminPages/ListAllCourses'));
// const GrantsListScreen = lazy(() => import('./pages/AdminPages/GrantsListScreen'));
// const CategoriesListScreen = lazy(() => import('./pages/AdminPages/CategoriesListScreen'));

// const InstructorRoles = lazy(() => import('./pages/Roles/InstructorRoles'));
// const MentorRoles = lazy(() => import('./pages/Roles/MentorRoles'));


const App = () => {
  const dispatch = useDispatch()
  const [loginBoolean, setLoginBoolean] = useState(false)
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // useGaTracker()

  const tokenIntervalCheck = (1000 * 60 * 60 * 5) + 8000
  const tokenVerification = useCallback(
    () => {
      setLoginBoolean(true)
      setInterval(() => {
        dispatch(verifyTokenValidity())
        console.log("token verified !")
      }, tokenIntervalCheck);
    },
    [dispatch, tokenIntervalCheck],
  );

  // console.log("categories: ", categories)
  useEffect(() => {
    if (userInfo?._id) {
      dispatch(fetchGrantInfo())
      tokenVerification()
    }
    if (!userInfo?._id && loginBoolean) {
      setTimeout(() => {
        history.push(`/signin?redirect=${window.location.pathname}`)
      }, 700);
    }
    dispatch(listCategories())
  }, [dispatch, userInfo?._id, tokenVerification, loginBoolean])
  return (
    <>
      <Header />
      {/* Import Master Pages Components */}
      <Switch>
        <Route path="/" exact component={MainPage} />
        {/* <Route path="/aboutus" exact component={AboutUs} /> */}
        <Route path="/technopedia" exact component={Technopedia} />
        <Route path="/pricing" exact component={PricingPage} />
        <Route path="/technopedia/courses/:id" exact component={CourseDetails} />
        <Route path="/mycourses" exact component={EnrolledCourses} />
        <Route path="/mypath/:pathId" exact component={UserLearnPath} />
        <Route path='/cart/:id?' exact component={Cart} />
        <Route path='/order/:id' exact component={Order} />
        <Route path='/profile'>
          <UserProfile />
        </Route>
        <Route path='/instructor/roles' exact component={InstructorRoles} />
        <Route path='/mentor/roles' exact component={MentorRoles} />
        <Route path='/founder/roles' exact component={FounderRoles} />
        <Route path='/instructor/courses' exact component={InstructorCorses} />
        <Route path='/instructor/:id/edit' exact component={CourseEditScreen} />
        <Route path='/instructor/course/createnew' exact component={CourseCreateScreen} />
        <Route path='/founder/partnership-request/create' exact component={CreatePartnershipRequest} />
        <Route path='/founder/:id/editpartnership' exact component={UpdatePartnershipRequest} />
        <Route path='/mentor/path/createnew' exact component={CreatePath} />
        <Route path='/mentor/:id/editpath' exact component={UpdatePath} />
        <Route path='/mentor/job/createnew' exact component={CreateJob} />
        <Route path='/mentor/:id/editjob' exact component={UpdateJob} />
        <Route path='/learningPath'>
          <LearningPath />
        </Route>
        <Route path='/jobs'>
          <Jobs />
        </Route>
        <Route path="/joinus" exact component={Joinus} />
        <Route path="/signin" exact component={SignIn} />
        <Route path="/signup" exact component={SignUp} />

        {/* Pages for Admin access only */}
        <Route path='/admin/userlist' exact component={UserListScreen} />
        <Route path='/admin/courses' exact component={ListAllCourses} />
        <Route path='/admin/user/:id/edit' exact component={UserEditScreen} />
        <Route path='/admin/listallgrants' exact component={GrantsListScreen} />
        <Route path='/admin/listallcategories' exact component={CategoriesListScreen} />
      </Switch>
      <Footer />
    </>
  );
};

export default App;
