import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import coFoundersNum from "../../images/Partnerships-cofoundersMatching/coFoundersNum.svg"
import startupIndustry from "../../images/Partnerships-cofoundersMatching/startupIndustry.svg"
import fundingPattern from "../../images/Partnerships-cofoundersMatching/fundingPattern.svg"
import equityOffer from "../../images/Partnerships-cofoundersMatching/equityOffer.svg"
import "./PartnershipStripe.css"
import CoFounderApplication from '../ApplicationStripe/CoFounderApplication';

export default function PartnershipStripe({ partnership, indx, removeHandler, onActionTrigger }) {
  const [requestsBoolean, setRequestsBoolean] = useState(false)
  return (
    <div className="partnership-stripe" key={indx}>
      <div className="head">
        <div className="title">{partnership.position}</div>
        <div className="edit-tools">
          <i title="Remove Partnership" className="fas fa-trash removal" onClick={() => removeHandler(partnership._id)}></i>
          <Link to={`/founder/${partnership._id}/editpartnership`} title="Edit partnership"><i className="fas fa-edit"></i></Link>
        </div>
      </div>
      <div className="details-wrapper">
        <div className='details-unit'>
          <div className='icon-wrapper'>
            <img className="icon" src={coFoundersNum} alt="Number of coFounders" />
          </div>
          <h6 className='value'>{partnership?.applicantsCounter}</h6>
          <p>Num of Interested Co-Founders</p>
        </div>
        <div className='details-unit'>
          <div className='icon-wrapper'>
            <img className="icon" src={startupIndustry} alt="Industry of Startup" />
          </div>
          <h6 className='value'>{partnership?.businessIndustry || "-"}</h6>
          <p>Industry of Startup</p>
        </div>
        <div className='details-unit'>
          <div className='icon-wrapper'>
            <img className="icon" src={fundingPattern} alt="Fundding Pattern" />
          </div>
          <h6 className='value'>{partnership?.fundingPattern || "-"}</h6>
          <p>Fundding Pattern</p>
        </div>
        <div className='details-unit'>
          <div className='icon-wrapper'>
            <img className="icon" src={equityOffer} alt="Offered Equity" />
          </div>
          <h6 className='value'>{partnership?.minimumEquity}% - {partnership?.maximumEquity}%</h6>
          <p>Offered Equity</p>
        </div>
      </div>

      <div className="partnership-applications">
        <div className={`requests ${requestsBoolean ? "active" : ""}`} onClick={() => setRequestsBoolean(!requestsBoolean)}>
          <h5>All Partnership Requests</h5>
          <i className="fas fa-caret-down"></i>
        </div>
        <div className={`wrapper ${requestsBoolean ? "active" : ""}`}>
          {partnership.applications.map((_application, _index) => (
            <div key={_application._id} className="application-info">
              <CoFounderApplication
                key={_application._id}
                application={_application}
                index={_index}
                topicID={partnership._id}
                onActionTrigger={onActionTrigger}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
