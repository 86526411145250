export const PARTNERSHIP_CREATE_SUCCESS = 'PARTNERSHIP_CREATE_SUCCESS'
export const PARTNERSHIP_CREATE_FAIL = 'PARTNERSHIP_CREATE_FAIL'
export const PARTNERSHIP_CREATE_RESET = 'PARTNERSHIP_CREATE_RESET'

export const PARTNERSHIP_UPDATE_SUCCESS = 'PARTNERSHIP_UPDATE_SUCCESS'
export const PARTNERSHIP_UPDATE_FAIL = 'PARTNERSHIP_UPDATE_FAIL'
export const PARTNERSHIP_UPDATE_RESET = 'PARTNERSHIP_UPDATE_RESET'

export const PARTNERSHIP_DETAILS_LOADING = 'PARTNERSHIP_DETAILS_LOADING'
export const PARTNERSHIP_DETAILS_SUCCESS = 'PARTNERSHIP_DETAILS_SUCCESS'
export const PARTNERSHIP_DETAILS_FAIL = 'PARTNERSHIP_DETAILS_FAIL'
export const PARTNERSHIP_DETAILS_RESET = 'PARTNERSHIP_DETAILS_RESET'

export const PARTNERSHIP_LIST_LOADING = 'PARTNERSHIP_LIST_LOADING'
export const PARTNERSHIP_LIST_SUCCESS = 'PARTNERSHIP_LIST_SUCCESS'
export const PARTNERSHIP_LIST_FAIL = 'PARTNERSHIP_LIST_FAIL'

export const FOUNDER_PARTNERSHIP_LIST_LOADING = 'FOUNDER_PARTNERSHIP_LIST_LOADING'
export const FOUNDER_PARTNERSHIP_LIST_SUCCESS = 'FOUNDER_PARTNERSHIP_LIST_SUCCESS'
export const FOUNDER_PARTNERSHIP_LIST_FAIL = 'FOUNDER_PARTNERSHIP_LIST_FAIL'

export const PARTNERSHIP_DELETE_SUCCESS = 'PARTNERSHIP_DELETE_SUCCESS'
export const PARTNERSHIP_DELETE_FAIL = 'PARTNERSHIP_DELETE_FAIL'
export const PARTNERSHIP_DELETE_RESET = 'PARTNERSHIP_DELETE_RESET'

export const PARTNERSHIP_APPLICANT_APPLY_SUCCESS = 'PARTNERSHIP_APPLICANT_APPLY_SUCCESS'
export const PARTNERSHIP_APPLICANT_APPLY_FAIL = 'PARTNERSHIP_APPLICANT_APPLY_FAIL'
export const PARTNERSHIP_APPLICANT_APPLY_RESET = 'PARTNERSHIP_APPLICANT_APPLY_RESET'

export const PARTNERSHIP_ACTIONON_APPLICANT_SUCCESS = 'PARTNERSHIP_ACTIONON_APPLICANT_SUCCESS'
export const PARTNERSHIP_ACTIONON_APPLICANT_FAIL = 'PARTNERSHIP_ACTIONON_APPLICANT_FAIL'
export const PARTNERSHIP_ACTIONON_APPLICANT_RESET = 'PARTNERSHIP_ACTIONON_APPLICANT_RESET'