export const PATH_CREATE_SUCCESS = 'PATH_CREATE_SUCCESS'
export const PATH_CREATE_FAIL = 'PATH_CREATE_FAIL'
export const PATH_CREATE_RESET = 'PATH_CREATE_RESET'

export const PATH_UPDATE_SUCCESS = 'PATH_UPDATE_SUCCESS'
export const PATH_UPDATE_FAIL = 'PATH_UPDATE_FAIL'
export const PATH_UPDATE_RESET = 'PATH_UPDATE_RESET'

export const PATH_DETAILS_SUCCESS = 'PATH_DETAILS_SUCCESS'
export const PATH_DETAILS_FAIL = 'PATH_DETAILS_FAIL'
export const PATH_DETAILS_RESET = 'PATH_DETAILS_RESET'

export const PATH_LIST_LOADING = 'PATH_LIST_LOADING'
export const PATH_LIST_SUCCESS = 'PATH_LIST_SUCCESS'
export const PATH_LIST_FAIL = 'PATH_LIST_FAIL'

export const MENTOR_PATH_LIST_LOADING = 'MENTOR_PATH_LIST_LOADING'
export const MENTOR_PATH_LIST_SUCCESS = 'MENTOR_PATH_LIST_SUCCESS'
export const MENTOR_PATH_LIST_FAIL = 'MENTOR_PATH_LIST_FAIL'

export const PATH_DELETE_SUCCESS = 'PATH_DELETE_SUCCESS'
export const PATH_DELETE_FAIL = 'PATH_DELETE_FAIL'
export const PATH_DELETE_RESET = 'PATH_DELETE_RESET'

export const PATH_APPLICANT_APPLY_SUCCESS = 'PATH_APPLICANT_APPLY_SUCCESS'
export const PATH_APPLICANT_APPLY_FAIL = 'PATH_APPLICANT_APPLY_FAIL'
export const PATH_APPLICANT_APPLY_RESET = 'PATH_APPLICANT_APPLY_RESET'

export const PATH_ACTIONON_APPLICANT_SUCCESS = 'PATH_ACTIONON_APPLICANT_SUCCESS'
export const PATH_ACTIONON_APPLICANT_FAIL = 'PATH_ACTIONON_APPLICANT_FAIL'
export const PATH_ACTIONON_APPLICANT_RESET = 'PATH_ACTIONON_APPLICANT_RESET'