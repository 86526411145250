import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listCourses } from '../../actions/courseActions';
import CourseCard from '../../components/CourseCard/CourseCard';
import Message from '../../components/Message/Message';
import StartBaner from '../../components/StartBaner/StartBaner';
import welcomeImg from '../../images/welcome.webp'


export default function EnrolledCourses() {
  const dispatch = useDispatch()

  const courseList = useSelector((state) => state.courseList)
  const { loading, courses } = courseList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: { enrolledCourses } } = userLogin

  const myCourses = []
  courses.forEach((course, indx1) => {
    enrolledCourses.forEach((enrolledCourse, indx2) => {
      if (enrolledCourse.course.toString() === course._id.toString()) {
        myCourses.push(course)
      }
    })
  })

  useEffect(() => {
    dispatch(listCourses())
  }, [dispatch])

  return (
    <div>
      <StartBaner imgLink={welcomeImg}>
        <h2>Your Enrolled Courses</h2>
        <p>learn and start working toward your dream</p>
      </StartBaner>
      <div className="course-card">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            {(myCourses.length === 0) ? (
              <Message ClassName='message-warning'>You didn't enroll yet to any course</Message>
            ) : myCourses.map((course, index) => {
              return (
                <CourseCard
                  key={course._id}
                  cours={course}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
