import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import history from '../../history'

export default function PanelSelector({ taps, UrlRoot }) {
  const [selectedPanel, setSelectedPanel] = useState(taps)

  const onSelection = (pathNameVal,indx) => {
    setSelectedPanel((preSelections) => {
      const newPanels = preSelections.map((panel, panelIndx) => {
        if (panelIndx === indx) {
          return { ...panel, selected: "active" }
        } else {
          return { ...panel, selected: "" }
        }
      })
      return newPanels
    })
  }
  return (
    <>
      {selectedPanel.map((panel, indx) => {
        return (
          <Link
            key={indx}
            className={`panel-card nav-link ${panel.selected}`}
            onClick={() => onSelection(panel.pathNameVal, indx)}
            to={`${UrlRoot}/${panel.pathNameVal}`}
          >
            <h5>{panel.panelName}</h5>
          </Link>
        )
      })}
    </>
  )
}
