import React from 'react'
import './StartBaner.css'
export default function StartBaner({imgLink, children}) {
  return (
    <section className="banner-section">
      <img src={imgLink} alt="img" />
      <div className="container z-index-bannertext">
        <div className="row justify-content-center text-center pt-3">
          <div className="col-sm-12 col-md-10 col-lg-9 pt-3 pb-3">
            {/* <h2 className="text-white fw-normal text-uppercase pb-3 font-mobile">{header}</h2>
            <p className="text-white fs-5">{body}</p> */}
            {children}
          </div>
        </div>
      </div>
    </section>
  )
}
