import React, { useMemo, useState } from 'react'
import { filterRows, paginateRows, sortRows } from '../../helpers/TableHelpers'
import ButtonLink from '../ButtonLink/ButtonLink'
import Pagination from '../Pagination/Pagination'


export default function Table({ columns, rows, rowsPerPage, deleteHandler, delteActionEnabled, editHandler, editActionEnabled }) {
  const [activePage, setActivePage] = useState(1)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState({ order: 'asc', orderBy: 'id' })

  const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])
  const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
  const calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage)

  const count = filteredRows.length
  const totalPages = Math.ceil(count / rowsPerPage)

  const handleSearch = (value, accessor) => {
    setActivePage(1)
    if (value) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [accessor]: value,
      }))
    } else {
      setFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters }
        delete updatedFilters[accessor]

        return updatedFilters
      })
    }
  }

  const handleSort = (accessor) => {
    setActivePage(1)
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
  }

  const clearAll = () => {
    setSort({ order: 'asc', orderBy: 'id' })
    setActivePage(1)
    setFilters({})
  }

  const listRowCells = (_columns, singleRow) => {
    const mainRowCells = _columns.map(column => {
      if (column.format) {
        return <td key={column.accessor}>{column.format(singleRow[column.accessor])}</td>
      }
      return <td key={column.accessor}>{singleRow[column.accessor]}</td>
    })

    return (
      <>
        {mainRowCells}
        <td>
          <div className="admin-edit-users">
            {delteActionEnabled && <i title="Remove" onClick={() => deleteHandler(singleRow._id)} className="fas fa-trash"></i>}
            {editActionEnabled && <i title="Edit" onClick={() => editHandler(singleRow._id)} className="edit-symbol fas fa-edit"></i>}
          </div>
        </td>
      </>
    )
  }

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            {columns.map((column) => {
              const sortIcon = () => {
                if (column.accessor === sort.orderBy) {
                  if (sort.order === 'asc') {
                    return '⬆️'
                  }
                  return '⬇️'
                } else {
                  return '️↕️'
                }
              }
              return (
                <th key={column.accessor}>
                  {/* <span>{column.label}</span> */}
                  <ButtonLink ClassName="socialmedia-button pagenumbers">
                    <button onClick={() => handleSort(column.accessor)}>
                      {column.label} <span>{sortIcon()}</span>
                    </button>
                  </ButtonLink>
                  {/* <button onClick={() => handleSort(column.accessor)}>{sortIcon()}</button> */}
                </th>
              )
            })}

            {/* {columns.map(column => {
              return <th scope="col" key={column.accessor}>{column.label}</th>
            })} */}
          </tr>
          <tr>
            {columns.map((column) => {
              return (
                <th>
                  <input
                    className="useredit_input limit-width"
                    key={`${column.accessor}-search`}
                    type="search"
                    placeholder={`Search ${column.label}`}
                    value={filters[column.accessor]}
                    onChange={(event) => handleSearch(event.target.value, column.accessor)}
                  />
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {calculatedRows.map(row => {
            return (
              <tr key={row._id}>
                {listRowCells(columns, row)}
              </tr>
            )
          })}
        </tbody>
      </table>
      {count > 0 ? (
        <Pagination
          activePage={activePage}
          count={count}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          setActivePage={setActivePage}
        />
      ) : (
        <p>No data found</p>
      )}
    </>

  )
}
