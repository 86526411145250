import React from 'react'
import './JobCard.css'
import companyLogo from '../../images/general-purpose/companyLogo.png'


export default function JobCard({ indx, job }) {
  const urlRoot = window.location.origin
  return (
    <div
      key={indx}
      className="card-job-info d-flex position-relative"
      onClick={() => window.open(`${urlRoot}/jobs/job/${job._id}`, "_blank")}
    >
      <img className="img-fluid me-4" src={companyLogo} alt="icon img" />
      <div className="text-start">
        <h5>{job.title}</h5>
        <span>{`${job.companyName}, ${job.jobType}`}</span>
        <div className="row-tags mt-3">
          <p>{job.category}</p>
        </div>
      </div>
    </div>
  )
}
