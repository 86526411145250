import React, { useCallback, useEffect, useState } from 'react'
import './AdminPages.css'
import { deframeCourseSections } from '../../hooks/GeneralFacilitateFuncs'
import { useDispatch, useSelector } from 'react-redux'
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { createCourse } from '../../actions/courseActions'
import Message from '../../components/Message/Message'
import ButtonLink from '../../components/ButtonLink/ButtonLink'
import CourseSections from './CourseEditAssets/CourseSections'
import { DragDropContext } from 'react-beautiful-dnd'
import Modal from '../../screen/Modal/Modal'
import LessonDetailsForm from './CourseEditAssets/LessonDetailsForm'
import { COURSE_CREATE_RESET } from '../../constants/courseConstants'
import history from '../../history'
import useVideoUpload from '../../hooks/useVideoUpload';

export default function CourseCreateScreen() {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const courseCreate = useSelector((state) => state.courseCreate)
  const { createSuccess, createError } = courseCreate

  const categoriesList = useSelector((state) => state.categoriesList)
  const { categories } = categoriesList
  const courseCategories = categories.filter(category => category.categoryType === 'general')

  const [otherCatgryBoolean, setOtherCatgryBoolean] = useState(false)
  const [newCatgry, setNewCatgry] = useState("")

  // Hooks
  const [allSectionData, setAllSectionData] = useState({ lessons: {}, sections: {}, sectionOrder: [] })
  const [lessonInfoModal, setLessonInfoModal] = useState({
    lessonID: "",
    modalState: false
  })
  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })
  // Custom Hook
  const [vidUploadRes, triggerVidUpload] = useVideoUpload()

  const [outComesArray, setOutComesArray] = useState([])
  const [skillsArray, setSkillsArray] = useState([])
  const [relatedJobsArray, setRelatedJobsArray] = useState([])

  const [singleOutCome, setSingleOutCome] = useState("")
  const [singleSkill, setSingleSkill] = useState("")
  const [singleRelatedJob, setSingleRelatedJob] = useState("")

  const [categoriesArray, setCategoriesArray] = useState([])

  const [formEntries, setFormEntries] = useState({
    name: "",
    body: "",
    explainerVideo: { vidId: "", vidName: "" },
    youTubeExplainerVideo: "",
    price: 0,
    discount: 0,
    certificationFee: 0,
    courseLanguage: "",
    courseLevel: "",
    isCertified: true,
    permanentlyFree: false
  })
  const [overallPrice, setOverallPrice] = useState(0)
  const [videoHostProvider, setVideoHostProvider] = useState("youtube")

  // handle video upload to backend
  const onVideoUpload = async (e) => {
    const videoFile = e.target.files[0]
    triggerVidUpload(videoFile)
  }
  // console.log("vidUploadRes: ", vidUploadRes)
  useEffect(() => {
    if (vidUploadRes.uploadStatus) {
      setFormEntries((prevEntries) => {
        return {
          ...prevEntries,
          explainerVideo: { vidId: vidUploadRes.vidId, vidName: vidUploadRes.fileName }
        }
      })
    }
  }, [vidUploadRes])

  // removeCategoryField to delete input entry from categories
  const removeCategoryField = (indx) => {
    setCategoriesArray((prevSections) => {
      return prevSections.filter((CategoryUnit, id) => { return id !== indx })
    })
  }

  // build course categories array
  const handleCategorySelection = (event) => {
    const { value } = event.target
    if (value === "Other") {
      setOtherCatgryBoolean(true)
    } else {
      setOtherCatgryBoolean(false)
      setCategoriesArray((prevCategories) => {
        return [...prevCategories, value]
      })
    }
  }
  const handleExtraCatgryChange = (event) => {
    const { value } = event.target
    setNewCatgry(value)
  }
  const handleExtraCatgrySelection = (event) => {
    if (event.key === 'Enter') {
      setCategoriesArray((prevCategories) => {
        return [...prevCategories, newCatgry]
      })
      setNewCatgry("")
    }
  }

  const handleOverAllPrice = useCallback(
    () => {
      const discountedFee = parseFloat(formEntries.price) - parseFloat(formEntries.discount)
      const totalFee = discountedFee + parseFloat(formEntries.certificationFee)
      setOverallPrice(totalFee)
    },
    [formEntries.price, formEntries.certificationFee, formEntries.discount],
  );

  const handleOutComesRecord = (event) => {
    if (event.key === 'Enter') {
      setOutComesArray(preOutComes => {
        return [...preOutComes, singleOutCome]
      })
      setSingleOutCome("")
    }
  }
  const removeOutCome = (indx) => {
    setOutComesArray((preOutComes) => {
      return preOutComes.filter((OutCome, id) => { return id !== indx })
    })
  }

  const handleSkillsRecord = (event) => {
    if (event.key === 'Enter') {
      setSkillsArray(preSkills => {
        return [...preSkills, singleSkill]
      })
      setSingleSkill("")
    }
  }
  const removeSkill = (indx) => {
    setSkillsArray((preSkills) => {
      return preSkills.filter((skill, id) => { return id !== indx })
    })
  }
  const handleRelatedJobsRecord = (event) => {
    if (event.key === 'Enter') {
      setRelatedJobsArray(preRelatedJobs => {
        return [...preRelatedJobs, singleRelatedJob]
      })
      setSingleRelatedJob("")
    }
  }
  const removeRelatedJob = (indx) => {
    setRelatedJobsArray((preRelatedJobs) => {
      return preRelatedJobs.filter((relatedJob, id) => { return id !== indx })
    })
  }

  const handlevideoHostProvider = (event) => {
    setVideoHostProvider(event.target.value)
  }
  const handleVideoIdRemoval = () => {
    setFormEntries(prevEntries => {
      return {
        ...prevEntries,
        explainerVideo: { vidId: "", vidName: "" }
      }
    })
  }

  // Start of course section logics, hooks and updates assignment****************
  const handleCheck = (event) => {
    const { checked } = event.target
    setFormEntries((prevEntry) => {
      return {
        ...prevEntry,
        isCertified: checked
      }
    })
  }
  const handleFreeCourseCheck = (event) => {
    const { checked } = event.target
    setFormEntries((prevEntry) => {
      return {
        ...prevEntry,
        price: 30,
        discount: 30,
        certificationFee: 0,
        permanentlyFree: checked
      }
    })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormEntries((prevEntry) => {
      return {
        ...prevEntry,
        [name]: value
      }
    })
  }

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result
    if (!destination) {
      return
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // This gives a section which have Props of title and lessons
    const start = allSectionData.sections[source.droppableId];
    const finish = allSectionData.sections[destination.droppableId];

    // if we are moving in the same section***********************
    if (start === finish) {
      const newLessonIDs = Array.from(start.lessons);
      const [removed] = newLessonIDs.splice(source.index, 1);
      newLessonIDs.splice(destination.index, 0, removed);
      setAllSectionData((_allsectioData) => {
        const newSection = {
          ...start,
          lessons: newLessonIDs
        }
        return {
          ..._allsectioData,
          sections: {
            ..._allsectioData.sections,
            [newSection.id]: newSection
          }
        }
      })

      return;
    }

    // Moving from one section to another****************************
    const startLessonIDs = Array.from(start.lessons);
    startLessonIDs.splice(source.index, 1);
    const newStart = {
      ...start,
      lessons: startLessonIDs,
    };

    const finishLessonIDs = Array.from(finish.lessons);
    finishLessonIDs.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      lessons: finishLessonIDs,
    };

    setAllSectionData((allSections) => {
      return {
        ...allSections,
        sections: {
          ...allSections.sections,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish
        }
      }
    })

  }

  const onSectionTitleChange =
    (newTitle, sectionid) => {
      const targetedSection = allSectionData.sections[sectionid]
      const newSection = { ...targetedSection, title: newTitle }
      setAllSectionData(_allsectionData => {
        return {
          ..._allsectionData,
          sections: {
            ..._allsectionData.sections,
            [newSection.id]: newSection
          }
        }
      })
    }

  const onAddingNewSection = () => {
    // const sectionIDEnum = allSectionData.sectionOrder.length + 1
    const lastSectionID = Object.keys(allSectionData.sections)[Object.keys(allSectionData.sections).length - 1]
    const sectionIDEnum = lastSectionID ? parseInt(lastSectionID.split("-")[1]) + 10 : 1
    // console.log("sectionIDEnum: ", sectionIDEnum)
    // pushing new section-id to section order
    const newSectionOrder = Array.from(allSectionData.sectionOrder)
    newSectionOrder.push(`section-${sectionIDEnum}`)
    // pushing new section with title placeholder to sections
    const newSectionsObject = {
      ...allSectionData.sections,
      [`section-${sectionIDEnum}`]: {
        id: `section-${sectionIDEnum}`,
        title: `New Section | Section${sectionIDEnum}`,
        lessons: []
      }
    }
    // Hooking new changes
    setAllSectionData((_allsectioData) => {
      return {
        ..._allsectioData,
        sectionOrder: newSectionOrder,
        sections: newSectionsObject
      }
    })
  }

  const onAddingNewLesson = (section_id) => {
    const targetedSection = allSectionData.sections[section_id]
    const newLessonIDs = Array.from(targetedSection.lessons)

    const lastLessonId = Object.keys(allSectionData.lessons)[Object.keys(allSectionData.lessons).length - 1]
    const lessonIDEnum = lastLessonId ? parseInt(lastLessonId.split("-")[1]) + 10 : 1
    // console.log("lessonIDEnum: ", lessonIDEnum)
    newLessonIDs.push(`lesson-${lessonIDEnum}`)

    const newLessonsObject = {
      ...allSectionData.lessons,
      [`lesson-${lessonIDEnum}`]: {
        id: `lesson-${lessonIDEnum}`,
        title: `Title of New Lesson`,
        video: "",
        videoServer: "",
        preview: false,
        text: "",
        isvideo: true,
        URL: "",
        showtime: "",
      }
    }

    setAllSectionData((_allsectioData) => {
      const newSection = {
        ...targetedSection,
        lessons: newLessonIDs
      }
      return {
        ..._allsectioData,
        lessons: newLessonsObject,
        sections: {
          ..._allsectioData.sections,
          [newSection.id]: newSection
        }
      }
    })
  }

  const onLessonRemoval = (sectionid, lessonid) => {
    // removing leaason from lessons object
    delete allSectionData.lessons[lessonid]
    const newSectionLessons = allSectionData.lessons
    // removing lessonId from lessonIDs array in the tageted section
    const targetedSection = allSectionData.sections[sectionid]
    const newLessonIDs = Array.from(targetedSection.lessons)
    const lessonindx = newLessonIDs.indexOf(lessonid)
    newLessonIDs.splice(lessonindx, 1);

    // Hooking new changes
    setAllSectionData((_allsectioData) => {
      const newSection = {
        ...targetedSection,
        lessons: newLessonIDs
      }
      return {
        ..._allsectioData,
        lessons: newSectionLessons,
        sections: {
          ..._allsectioData.sections,
          [newSection.id]: newSection
        }
      }
    })

  }

  // Open and close of Lesson information Modal
  const closeModal = () => {
    setLessonInfoModal({
      modalState: false
    })
  }
  const onDisplayLessonDetails = (_lessonID) => {
    setLessonInfoModal({
      lessonID: _lessonID,
      modalState: true
    })
  }
  const onLessonDetailUpdate = (newLessonDetail) => {
    // Hooking new changes
    // console.log("newLessonDetail: ", newLessonDetail)
    setAllSectionData((_allsectioData) => {
      return {
        ..._allsectioData,
        lessons: {
          ..._allsectioData.lessons,
          [newLessonDetail.id]: newLessonDetail
        }
      }
    })
    setLessonInfoModal({
      modalState: false
    })
  }
  // End of Lesson sections operations**************************************
  // console.log("allSectionData: ", allSectionData)
  const [editorState, setEditorState] = useState({ editorState: "" })
  //change local state of editor
  const handleEditorChange = (editorState) => {
    setEditorState({ editorState });
  }

  useEffect(() => {
    if (!userInfo?.isApprovedInstructor && !userInfo?.isInstructor) {
      history.push('/')
    }
    if (createError) {
      setMessage({
        msgType: "error",
        messageBody: createError
      })
      return
    }
    if (createSuccess) {
      setMessage({
        msgType: "success",
        messageBody: "Your Course is created successfully, you will be redirected to your board.."
      })
      setTimeout(() => {
        history.push("/profile/instructorboard")
        dispatch({ type: COURSE_CREATE_RESET })
      }, 4000);
    }
  }, [createSuccess, createError, dispatch, userInfo])

  // CONFIRM CHANGES BY SUBMITTING THE CREATED FORM******************************
  const confirmHandler = (event) => {
    const examInstructions = editorState.editorState ? JSON.stringify(convertToRaw(editorState.editorState.getCurrentContent())) : ""
    event.preventDefault()
    if (!formEntries.name) {
      setMessage({
        msgType: "error",
        messageBody: "Course Name is missing"
      })
      return
    }
    if (!formEntries.body) {
      setMessage({
        msgType: "error",
        messageBody: "Course Description is missing"
      })
      return
    }
    if (!(formEntries.explainerVideo.vidId || formEntries.youTubeExplainerVideo)) {
      setMessage({
        msgType: "error",
        messageBody: "You didn't upload your explainer video or Enter the Link to your video on YouTube"
      })
      return
    }
    if (!formEntries.price) {
      setMessage({
        msgType: "error",
        messageBody: "Course Price is missing"
      })
      return
    }
    if (!formEntries.courseLanguage) {
      setMessage({
        msgType: "error",
        messageBody: "Course Language is missing"
      })
      return
    }
    if (!formEntries.courseLevel) {
      setMessage({
        msgType: "error",
        messageBody: "Course Level is missing"
      })
      return
    }
    // outComesArray
    if (outComesArray.length === 0) {
      setMessage({
        msgType: "error",
        messageBody: "Course Outcomes are missing"
      })
      return
    }
    if (categoriesArray.length === 0) {
      setMessage({
        msgType: "error",
        messageBody: "Course Categories are missing"
      })
      return
    }
    if (formEntries.isCertified && !editorState.editorState) {
      setMessage({
        msgType: "error",
        messageBody: "Exam instructions are missing. If the course lead to certification, there must be an exam instuctions"
      })
      return
    }
    const complexForm = {
      courseOutcomes: outComesArray,
      keySkills: skillsArray,
      relatedJobs: relatedJobsArray,
      courseCategory: categoriesArray,
      courseSections: deframeCourseSections(allSectionData),
    }
    const finalForm = { ...complexForm, ...formEntries, examInstructions, publicPublished: true }
    dispatch(createCourse(finalForm))
  }
  const savePathHandler = (event) => {
    const examInstructions = editorState.editorState ? JSON.stringify(convertToRaw(editorState.editorState.getCurrentContent())) : ""
    event.preventDefault()
    if (!formEntries.name) {
      setMessage({
        msgType: "error",
        messageBody: "Course Name is missing"
      })
      return
    }
    if (categoriesArray.length === 0) {
      setMessage({
        msgType: "error",
        messageBody: "You must mention at least one category for your course"
      })
      return
    }
    const complexForm = {
      courseOutcomes: outComesArray,
      keySkills: skillsArray,
      relatedJobs: relatedJobsArray,
      courseCategory: categoriesArray,
      courseSections: deframeCourseSections(allSectionData),
    }
    const finalForm = { ...complexForm, ...formEntries, examInstructions, publicPublished: false }
    // console.log("finalForm: ", finalForm)
    dispatch(createCourse(finalForm))
  }
  // cancel updates canceleHandler
  const canceleHandler = (event) => {
    event.preventDefault()
    history.push('/profile/instructorboard')
  }

  return (
    <div className="course-editor">
      <div className='container'>
        <h4 className="common-heading">Create your Course</h4>
        <div className="course-content">
          <div className="course-form">
            <h4> Main Information of the Course </h4>
            <div className="row justify-content-center align-items-center">
              <div className="col-12">
                <label> Course Name <span>({formEntries.name.length}/54)</span></label>
                <input
                  type="text"
                  name="name"
                  className="form-control course_input"
                  value={formEntries.name}
                  maxLength="54"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-12">
                <label> Course Description <span>({formEntries.body.length}/160)</span></label>
                <textarea
                  type="text"
                  name="body"
                  className="form-control course_input"
                  value={formEntries.body}
                  maxLength="160"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row justify-content-center align-items-center">
              <div className="col-md-4 col-sm-12">
                <label> Select Video Hosting Provider </label>
                <select name="explainerVideoHosting" className="form-select course_input" onChange={handlevideoHostProvider}>
                  <option value="youtube">Youtube</option>
                  <option value="aseeu">Upload On RekGPT</option>
                </select>
              </div>
              <div className="col-md-8 col-sm-12">
                {videoHostProvider === "youtube" ? (
                  <>
                    <label> Link to Video On Youtube </label>
                    <input
                      type="text"
                      name="youTubeExplainerVideo"
                      className="form-control course_input"
                      value={formEntries.youTubeExplainerVideo}
                      onChange={handleChange}
                    />
                  </>
                ) : (
                  <>
                    <label> Upload Course Explainer Video </label>
                    <input
                      id="videofile"
                      type="file"
                      className="form-control course_input no-height"
                      placeholder="Course Introductory video"
                      onChange={onVideoUpload}
                    />
                    {vidUploadRes.faultMsg && <Message ClassName='message-error mini-msg'>{vidUploadRes.faultMsg}</Message>}
                    {vidUploadRes.uploadStatus ? (
                      <Message ClassName='message-success mini-msg'>video is uploaded successfully</Message>
                    ) : (
                      vidUploadRes.vidId && <Message ClassName='message-info mini-msg'>Video Uploading: {vidUploadRes.uploadPercent}%</Message>
                    )}
                    {formEntries.explainerVideo.vidName && (
                      <Message ClassName='message-info mini-msg'>
                        {formEntries.explainerVideo.vidName} <span className='reset-button' onClick={handleVideoIdRemoval}> Remove Video </span>
                      </Message>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="row justify-content-center align-items-center">
              <div className="col-md-6 col-sm-12">
                <label> Course Level </label>
                <select name="courseLevel" className="form-select course_input" onChange={handleChange}>
                  <option defaultValue=""> Select A Level </option>
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced">Advanced</option>
                </select>
              </div>
              <div className="col-md-6 col-sm-12">
                <label> Course Language </label>
                <select name="courseLanguage" className="form-select course_input" onChange={handleChange}>
                  <option value=""> Select Language </option>
                  <option value="Arabic">Arabic</option>
                  <option value="English">English</option>
                </select>
              </div>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    name="permanentlyFree"
                    type="checkbox"
                    onChange={handleFreeCourseCheck}
                    checked={formEntries.permanentlyFree}
                  />
                  <label className="form-check-label label-question styled-help-info">
                    The course will be for free permanently
                    <i className="far fa-question-circle">
                      <span>
                        Selecting this option means that your course will be for free forever and all your videos will be uploaded on Youtube and you will use these video links to build your course on technopedia
                      </span>
                    </i>
                  </label>
                </div>
              </div>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-md-6 col-sm-12">
                <label> Cource Price <span>(In US dollars)</span> </label>
                <div className="input-group">
                  <input
                    type="number"
                    name="price"
                    min="0"
                    className={`form-control course_input ${formEntries.permanentlyFree ? "disabled-element" : ""}`}
                    value={formEntries.price}
                    onChange={handleChange}
                    onBlur={handleOverAllPrice}
                    disabled={formEntries.permanentlyFree ? "disabled" : ""}
                  />
                  <span className={`input-group-text ${formEntries.permanentlyFree ? "disabled-element" : ""}`} id="basic-addon2"> $ </span>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <label> Cource Discount <span>(In US dollars)</span> </label>
                <div className="input-group">
                  <input
                    type="number"
                    name="discount"
                    min="0"
                    className={`form-control course_input ${formEntries.permanentlyFree ? "disabled-element" : ""}`}
                    value={formEntries.discount}
                    onChange={handleChange}
                    onBlur={handleOverAllPrice}
                    disabled={formEntries.permanentlyFree ? "disabled" : ""}
                  />
                  <span className={`input-group-text ${formEntries.permanentlyFree ? "disabled-element" : ""}`} id="basic-addon2"> $ </span>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    name="isCertified"
                    type="checkbox"
                    onChange={handleCheck}
                    checked={formEntries.isCertified}
                  />
                  <label className="form-check-label label-question styled-help-info">
                    It is a certified course
                    <i className="far fa-question-circle">
                      <span>
                        You must write an exam if your course includes granting certifications to students who completed the course
                      </span>
                    </i>
                  </label>
                </div>
              </div>
              {formEntries.isCertified && (
                <div className="col-md-3 col-sm-12">
                  <label className='styled-help-info'> Certification Fee
                    <i className="far fa-question-circle">
                      <span>
                        If You want to charge an extra fee for students who enroll into certified track, add it here. If no additional fee for certification you can put 0 or leave it empty
                      </span>
                    </i>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      min="0"
                      name="certificationFee"
                      className="form-control course_input"
                      value={formEntries.certificationFee}
                      onChange={handleChange}
                      onBlur={handleOverAllPrice}
                    />
                    <span className={`input-group-text`}> $ </span>
                  </div>
                </div>
              )}
              <div className="col-md-3 col-sm-12">
                <Message ClassName="message-info smaller-font mt-5">
                  Final Price: {overallPrice}
                </Message>
              </div>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-md-6 col-sm-12">
                <label> Add Industries of the course </label>
                <select className="form-select course_input" onChange={handleCategorySelection} aria-label="Example select with button addon">
                  <option key="courseDefault" value="">Choose the course categories...</option>
                  {courseCategories.map(category => (
                    <option value={category.categoryName}>{category.categoryName}</option>
                  ))}
                  <option key="other1" value="Other"> Other </option>
                </select>
              </div>
              <div className="col-md-6 col-sm-12">
                {otherCatgryBoolean && (
                  <>
                    <label className="highlight-txt"> Specify Category Name <span>(Press Enter Key after writing one Category to record it)</span> </label>
                    <input
                      className="form-control course_input"
                      type="text"
                      name="newCatgry"
                      value={newCatgry}
                      onChange={handleExtraCatgryChange}
                      onKeyDown={handleExtraCatgrySelection}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-12">
                <div className="row-tags sentences" >
                  {categoriesArray.map((fieldEntry, indx) => {
                    return (
                      <p key={indx}>{fieldEntry}
                        <i className="fas fa-minus-circle" onClick={() => removeCategoryField(indx)}>
                        </i>
                      </p>
                    )
                  })}
                </div>
              </div>
            </div>

            <hr />

            <h4> Increase the impact of your course </h4>
            <div className="row justify-content-between align-items-center">
              <div className="col-md-6 col-sm-12">
                <label>Key Skills</label>
                <input
                  onChange={(e) => setSingleSkill(e.target.value)}
                  className="form-control course_input"
                  type="text"
                  name="singleSkill"
                  value={singleSkill}
                  onKeyDown={handleSkillsRecord}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <label>Related Jobs</label>
                <input
                  onChange={(e) => setSingleRelatedJob(e.target.value)}
                  className="form-control course_input"
                  type="text"
                  name="singleRelatedJob"
                  value={singleRelatedJob}
                  onKeyDown={handleRelatedJobsRecord}
                />
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-md-6 col-sm-12">
                <div className="row-tags sentences">
                  {skillsArray.map((_skill, indx) => {
                    return (
                      <p key={indx}>{_skill} <i className="fas fa-minus-circle" onClick={() => removeSkill(indx)}></i></p>
                    )
                  })}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="row-tags sentences">
                  {relatedJobsArray.map((_relatedJob, indx) => {
                    return (
                      <p key={indx}>{_relatedJob} <i className="fas fa-minus-circle" onClick={() => removeRelatedJob(indx)}></i></p>
                    )
                  })}
                </div>
              </div>
            </div>

            <hr />

            <h4> Build your Course Curriculum </h4>
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <label>OutComes <span>(Press Enter Key after writing one OutCome, so you can record another one)</span></label>
                <input
                  onChange={(e) => setSingleOutCome(e.target.value)}
                  className="form-control course_input"
                  type="text"
                  name="singleOutCome"
                  value={singleOutCome}
                  onKeyDown={handleOutComesRecord}
                />
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <div className="row-tags sentences">
                  {outComesArray.map((_outCome, indx) => {
                    return (
                      <p key={indx}>{_outCome} <i className="fas fa-minus-circle" onClick={() => removeOutCome(indx)}></i></p>
                    )
                  })}
                </div>
              </div>
            </div>

            <div className="row justify-content-center align-items-center">
              <div className="col-12">
                {/* <input className="form-control" defaultValue="99.13" /> */}
                <div className="editing-section-header">
                  <div className="section-title"><h4> Start add the content of the course </h4></div>
                  <button className="section-button" onClick={onAddingNewSection}> Add new section </button>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                  {allSectionData.sections && allSectionData.sectionOrder.map((sectionId, indx) => {
                    const singleSection = allSectionData.sections[sectionId]
                    const singleSectionLessons = singleSection.lessons.map(
                      lessonid => allSectionData.lessons[lessonid]
                    )
                    return (
                      <CourseSections
                        key={singleSection.id}
                        singleSection={singleSection}
                        sectionLessons={singleSectionLessons}
                        onAddingNewLesson={onAddingNewLesson}
                        onLessonRemoval={onLessonRemoval}
                        onSectionTitleChange={onSectionTitleChange}
                        onDisplayLessonDetails={onDisplayLessonDetails}
                      />
                    )
                  })}
                </DragDropContext>
              </div>
            </div>
            {lessonInfoModal.modalState && (
              <Modal>
                <LessonDetailsForm
                  lessonDetail={allSectionData.lessons[lessonInfoModal.lessonID]}
                  closeModal={closeModal}
                  onLessonDetailUpdate={onLessonDetailUpdate}
                  freeCourseBoolean={formEntries.permanentlyFree}
                />
              </Modal>
            )}
            <div className="row justify-content-between align-items-center">
              <div className="col-12 exam-body">
                <label>Exam Instructions <span>(This is mandatory field if your course is certified)</span> </label>
                <Editor
                  editorState={editorState.editorState}
                  onEditorStateChange={handleEditorChange}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                />
              </div>
            </div>
            <div className="row justify-content-between align-items-center actions-section">
              <div className="col-md-6 col-sm-12">
                <ButtonLink ClassName="learn-more-btn cancel float-left">
                  <button onClick={canceleHandler}>CANCEL</button>
                </ButtonLink>
              </div>
              <div className="col-md-3 col-sm-12">
                <ButtonLink ClassName="learn-more-link">
                  <button onClick={savePathHandler}>Save And Continue Later</button>
                </ButtonLink>
              </div>
              <div className="col-md-3 col-sm-12">
                <ButtonLink ClassName="learn-more-btn confirm float-right">
                  <button onClick={confirmHandler}>PUBLISH</button>
                </ButtonLink>
              </div>
            </div>
          </div>
          {message.messageBody && <Message ClassName={message.msgType === "error" ? "message-error" : "message-success"}>{message.messageBody}</Message>}
        </div>
      </div>
    </div>
  )
}
