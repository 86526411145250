import { JOB_ACTIONON_APPLICANT_FAIL, JOB_ACTIONON_APPLICANT_RESET, JOB_ACTIONON_APPLICANT_SUCCESS, JOB_APPLICANT_APPLY_FAIL, JOB_APPLICANT_APPLY_RESET, JOB_APPLICANT_APPLY_SUCCESS, JOB_CREATE_FAIL, JOB_CREATE_RESET, JOB_CREATE_SUCCESS, JOB_DELETE_FAIL, JOB_DELETE_SUCCESS, JOB_DETAILS_FAIL, JOB_DETAILS_RESET, JOB_DETAILS_SUCCESS, JOB_LIST_FAIL, JOB_LIST_LOADING, JOB_LIST_SUCCESS, JOB_UPDATE_FAIL, JOB_UPDATE_RESET, JOB_UPDATE_SUCCESS, MENTOR_JOB_LIST_FAIL, MENTOR_JOB_LIST_LOADING, MENTOR_JOB_LIST_SUCCESS } from "../constants/jobConstants"

export const jobListReducer = (state = { jobs: [] }, action) => {
  switch (action.type) {
    case JOB_LIST_LOADING:
      return {loading: true, ...state }
    case JOB_LIST_SUCCESS:
      return {loading: false, error: null, jobs: action.payload }
    case JOB_LIST_FAIL:
      return {loading: false, error: action.payload }
    default:
      return state
  }
}

export const mentorJobListReducer = (state = { mentorJobs: [] }, action) => {
  switch (action.type) {
    case MENTOR_JOB_LIST_LOADING:
      return {loading: true, ...state }
    case MENTOR_JOB_LIST_SUCCESS:
      return {loading: false, error: null, mentorJobs: action.payload }
    case MENTOR_JOB_LIST_FAIL:
      return {loading: false,  error: action.payload }
    default:
      return state
  }
}

export const jobDetailsReducer = (state = { jobDetails: {} }, action) => {
  switch (action.type) {
    case JOB_DETAILS_SUCCESS:
      return { jobdetails: action.payload }
    case JOB_DETAILS_FAIL:
      return { detailserror: action.payload }
    case JOB_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const jobUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_UPDATE_SUCCESS:
      return { updateSuccess: true }
    case JOB_UPDATE_FAIL:
      return { updateError: action.payload }
    case JOB_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const jobCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_CREATE_SUCCESS:
      return { createSuccess: true }
    case JOB_CREATE_FAIL:
      return { createError: action.payload }
    case JOB_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const jobDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_DELETE_SUCCESS:
      return { deleteSuccess: true }
    case JOB_DELETE_FAIL:
      return { deleteError: action.payload }
    default:
      return state
  }
}

export const jobMentorsActionReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_ACTIONON_APPLICANT_SUCCESS:
      return { successMessage: action.payload }
    case JOB_ACTIONON_APPLICANT_FAIL:
      return { error: action.payload }
    case JOB_ACTIONON_APPLICANT_RESET:
      return {}
    default:
      return state
  }
}

export const jobApplyReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_APPLICANT_APPLY_SUCCESS:
      return { applySuccess: true }
    case JOB_APPLICANT_APPLY_FAIL:
      return { applyError: action.payload }
    case JOB_APPLICANT_APPLY_RESET:
      return {}
    default:
      return state
  }
}