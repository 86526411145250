import React, { useEffect, useState } from 'react'
import './ModalScreens.css'
import cancelIcon from '../../../images/general-purpose/cancelIcon.png'
import ButtonLink from '../../../components/ButtonLink/ButtonLink'
import MultiSelector from '../../../components/MultiSelector/MultiSelector'
import Message from '../../../components/Message/Message'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserProfile } from '../../../actions/userActions'
import { verifyEmail } from '../../../hooks/GeneralFacilitateFuncs'

const _options = [
  {
    label: "Starter Instructor",
    value: "Starter"
  },
  {
    label: "Experienced Instructor",
    value: "Expert"
  }
]

export default function NewApplyModal({ closeModal, applicationType }) {
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const {
    error: errorUpdate,
    success: successUpdate,
  } = userUpdateProfile

  // const instructorRolesURI = `${window.location.origin}/instructor/roles`
  // const mentorRolesURI = `${window.location.origin}/mentor/roles`
  // const founderRolesURI = `${window.location.origin}/founder/roles`

  const [applicationForm, setApplicationForm] = useState({
    profession: "",
    email: userInfo.email ? userInfo.email : "",
    linkedinURL: userInfo.linkedinURL ? userInfo.linkedinURL : "",
    introduction: "",
  })

  const [skillsArray, setSkillsArray] = useState([])
  const [singleSkill, setSingleSkill] = useState("")

  const [instructorClassification, setInstructorClassification] = useState('')
  // const [agreed, setAgreed] = useState(false)
  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })
  // All Hooking functions*****************************************************
  const handleChange = (event) => {
    const { name, value } = event.target
    setApplicationForm((prevEntries) => {
      return {
        ...prevEntries,
        [name]: value
      }
    })
  }
  const onSelection = (value, indx) => {
    setInstructorClassification(value)
  }

  // const handleCheck = (event) => {
  //   const { checked } = event.target
  //   setAgreed(checked)
  // }

  const handleSkillsRecord = (event) => {
    if (event.key === 'Enter') {
      setSkillsArray(preSkills => {
        return [...preSkills, singleSkill]
      })
      setSingleSkill("")
    }
  }
  const removeSkill = (indx) => {
    setSkillsArray((preSkills) => {
      return preSkills.filter((skill, id) => { return id !== indx })
    })
  }

  // useEffect Hook *******************************************************
  useEffect(() => {
    if (errorUpdate) {
      setMessage({
        msgType: "error",
        messageBody: errorUpdate
      })
      return
    }
    if (successUpdate) {
      closeModal()
    }
  }, [errorUpdate, successUpdate, closeModal])

  // submition function ************************************************
  const handleApplication = (event) => {
    event.preventDefault()
    if (!applicationForm.profession) {
      setMessage({
        msgType: "error",
        messageBody: "You must enter your profession"
      })
      return
    }
    if (!applicationForm.linkedinURL) {
      setMessage({
        msgType: "error",
        messageBody: "You must enter the link to your profile on Linkedin"
      })
      return
    }
    if (!applicationForm.email || !verifyEmail(applicationForm.email)) {
      setMessage({
        msgType: "error",
        messageBody: "You must enter a valid email address"
      })
      return
    }
    if (!applicationForm.introduction) {
      setMessage({
        msgType: "error",
        messageBody: "You must enter a short description about your experience"
      })
      return
    }
    if ((applicationType === "instructor") && !instructorClassification) {
      setMessage({
        msgType: "error",
        messageBody: "You must specify which track you want to join (Starter or Experienced)"
      })
      return
    }
    if (applicationType === "instructor") {
      const instructorDetails = {
        instructorProfession: applicationForm.profession,
        instructorClassification: instructorClassification,
        instructorIntroduction: applicationForm.introduction,
      }
      const submitForm = { isInstructor: true, email: applicationForm.email, linkedinURL: applicationForm.linkedinURL, instructorDetails: instructorDetails }
      dispatch(updateUserProfile(submitForm))
    } else if (applicationType === "mentor") {
      const mentorDetails = {
        mentorProfession: applicationForm.profession,
        mentorIntroduction: applicationForm.introduction,
      }
      const submitForm = { isMentor: true, email: applicationForm.email, linkedinURL: applicationForm.linkedinURL, mentorDetails: mentorDetails }
      dispatch(updateUserProfile(submitForm))
    } else if (applicationType === "founder") {
      const founderDetails = {
        founderProfession: applicationForm.profession,
        founderIntroduction: applicationForm.introduction,
        founderkeySkills: skillsArray
      }
      const submitForm = { isFounder: true, email: applicationForm.email, linkedinURL: applicationForm.linkedinURL, founderDetails: founderDetails }
      // console.log("submitted form: ", submitForm)
      dispatch(updateUserProfile(submitForm))
    }
  }

  return (
    <div className="profile-modal-container" onClick={e => e.stopPropagation()}>
      <div className="cancel-button">
        <div className="cancel-wrapper" onClick={closeModal}>
          <img src={cancelIcon} alt="How We Work" />
        </div>
      </div>
      <div className="edit-userinfo-form">
        {/* <h3>{title}</h3> */}
        <form onSubmit={handleApplication}>

          <div className="row no-padding justify-content-between align-items-center">
            <div className="col-md-6 col-sm-12">
              <label>Profession </label>
              <input
                className="profile-input"
                type="text"
                name="profession"
                value={applicationForm.profession}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <label>Email </label>
              <input
                className="profile-input"
                type="email"
                name="email"
                value={applicationForm.email}
                onChange={handleChange}
              />
            </div>
          </div>

          <label>LinkedIn </label>
          <input
            className="profile-input"
            type="text"
            name="linkedinURL"
            value={applicationForm.linkedinURL}
            onChange={handleChange}
          />
          <label> Introduce yourself in short words </label>
          <textarea
            className="profile-input"
            type="text"
            name="introduction"
            value={applicationForm.introduction}
            onChange={handleChange}
          />
          {applicationType === "instructor" && (
            <MultiSelector options={_options} label="" onSelection={onSelection} />
          )}
          {applicationType === "founder" && (
            <div className="row justify-content-between align-items-end">
              <div className="col-md-5 col-sm-12">
                <label>Key Skills <span>(Optional {singleSkill.length}/30)</span> </label>
                <input
                  onChange={(e) => setSingleSkill(e.target.value)}
                  className="profile-input"
                  type="text"
                  name="singleSkill"
                  value={singleSkill}
                  maxLength="30"
                  onKeyDown={handleSkillsRecord}
                />
              </div>
              <div className="col-md-7 col-sm-12">
                <div className="row-tags wrap">
                  {skillsArray.map((_skill, indx) => {
                    return (
                      <p key={indx}>{_skill} <i className="fas fa-minus-circle tag-removal-icon" onClick={() => removeSkill(indx)}></i></p>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
          {/* <div className="form-check mt-2">
            <input
              className="form-check-input"
              name="isvideo"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              onChange={handleCheck}
            />
            <label className="form-check-label label-question">
              I've read and agree with
              {applicationType === "instructor" && <span onClick={() => window.open(instructorRolesURI, "_blank")}> Instructor Roles & Responsibilities</span>}
              {applicationType === "mentor" && <span onClick={() => window.open(mentorRolesURI, "_blank")}> Recruiter Roles & Responsibilities</span>}
              {applicationType === "founder" && <span onClick={() => window.open(founderRolesURI, "_blank")}> Founder Roles & Responsibilities</span>}
            </label>
          </div> */}
          <ButtonLink ClassName={`learn-more-btn full-width`}>
            <button> Apply </button>
          </ButtonLink>
        </form>
        {message.messageBody && <Message ClassName="message-error">{message.messageBody}</Message>}
      </div>
    </div>
  )
}
