/*===  Css Custom File === */
import "./StarterInstructor.css";
import React, { useEffect, useState } from 'react';
import Accordion from "../../../components/Accordion/Accordion";
import { item1English, item1Arabic, item2English, item2Arabic } from "./AccordionItems";
import { useSelector } from "react-redux";


const StarterInstructor = () => {
  const [translatedItems1, setTranslatedItems1] = useState(item1English)
  const [translatedItems2, setTranslatedItems2] = useState(item2English)
  const language = useSelector((state) => state.language);
  const { translation, translationType } = language;

  useEffect(() => {

    if (translationType === "EN") {
      setTranslatedItems1(item1English)
      setTranslatedItems2(item2English)
    } else {
      setTranslatedItems1(item1Arabic)
      setTranslatedItems2(item2Arabic)
    }

  }, [translationType])
  console.log("translatedItems1: ", translatedItems1)
  return (
    <div className="starter-instructor">
      <div className="container-lg">
        <div className="tracks-descripe">
          <h2 className="text-center"> {translation.AvailableTracks} </h2>
          <p>{translation.ThereAreTwoTracks}</p>
        </div>
        <h4 className="text-capitalize">1- {translation.StarterInstructor}</h4>
        {/* Starter Instructor Accordion */}
        <Accordion items={translatedItems1} />
        <h4 className="text-capitalize">2- {translation.ExperiencedInstructor}</h4>
        {/* Experienced instructor Accordion */}
        <Accordion items={translatedItems2} />
      </div>
    </div>
  );
};

export default StarterInstructor;
