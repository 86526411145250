import React from 'react';
import UnderConstructPage from '../../layouts/UnderConstructPage/UnderConstructPage';

export default function FounderRoles() {
  return (
    <>
      <UnderConstructPage />
    </>
  )
}
