import axios from "axios"
import { CATEGORIES_ADMIN_LIST_FAIL, CATEGORIES_ADMIN_LIST_SUCCESS, CATEGORIES_LIST_FAIL, CATEGORIES_LIST_SUCCESS, CATEGORIES_UPDATE_STATUS_FAIL, CATEGORIES_UPDATE_STATUS_SUCCESS } from "../constants/categoriesConstants"

// public access to all Job, all users can see the list
export const listCategories = () => async (dispatch) => {
  try {
    const { data } = await axios.get('/api/categories')
    dispatch({
      type: CATEGORIES_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CATEGORIES_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Admin Fetch all categories with all status
export const listAdminCategories = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/categories/adminFetch', config)
    dispatch({
      type: CATEGORIES_ADMIN_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CATEGORIES_ADMIN_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Admin is able to update the category status
export const updateCategory = (id, categoryNewStatus) => async (dispatch, getState) => {
  // categoryNewStatus = {categoryName: String, activeStatus: Boolean}
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(`/api/categories/adminaction/${id}`, categoryNewStatus, config)

    dispatch({ type: CATEGORIES_UPDATE_STATUS_SUCCESS })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: CATEGORIES_UPDATE_STATUS_FAIL,
      payload: message,
    })
  }
}


