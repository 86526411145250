import React, { useEffect, useState } from 'react'
import { convertMinutes } from '../../hooks/GeneralFacilitateFuncs'

export default function CourseSideBarInfo({ coursedetails }) {
  const [extractedInfo, setExtractedInfo] = useState({
    priceAfterDiscount: 0,
    lectureNumber: 0,
    courseLength: ""
  })

  useEffect(() => {
    if (coursedetails?._id) {
      const numOfLectures = coursedetails.courseSections.reduce((acc, section) => acc + section.lessons.length, 0)
      const newPrice = coursedetails.price - coursedetails.discount
      const courseInMinutes = coursedetails.courseSections.reduce((acc, section) => acc + section.lessons.reduce((acc2, lesson) => acc2 + lesson.showtime, 0), 0)
      const totalCourseLength = convertMinutes(courseInMinutes)
      setExtractedInfo({
        priceAfterDiscount: newPrice,
        lectureNumber: numOfLectures,
        courseLength: `${totalCourseLength.timeHours}h : ${totalCourseLength.timeMinutes}m`
      })
    }
  }, [coursedetails])
  return (
    <>
      <ul className="price clearfix">
        {/* this can be used with fractional prices */}
        {/* <sup>99</sup> */}
        <li className="float-left"><strong className="s-color"><del>${(coursedetails?.price)?.toFixed(2)}</del>${(extractedInfo.priceAfterDiscount)?.toFixed(2)} <sup>Limited Offer</sup></strong></li>
        <li className="float-right"><a href="#courseWatchingPanel" className="tran3s"><i className="flaticon-like"></i> </a></li>
      </ul>
      {/* {coursedetails.isCertified && (
            <p>Extra <strong className="s-color"> ${(coursedetails?.certificationFee)?.toFixed(2)} </strong> if you choosed Certified Track</p>
          )} */}
      <ul className="info-list row">
        <li className="col-6"><b>Lectures :</b> {extractedInfo.lectureNumber} </li>
        <li className="col-6"><b>Skill :</b> {coursedetails.courseLevel}</li>
        <li className="col-6"><b>Language :</b> {coursedetails.courseLanguage}</li>
        <li className="col-6"><b>Duration :</b> {extractedInfo.courseLength}</li>
      </ul>
      <div className="extr-inf">
        {/* <ul className="clearfix student-image">
              {[...Array(4).keys()].map(num => (<li key={num}><img src={userLogo} alt="" /></li>))}
            </ul>
            <p><b>{coursedetails.enrolledStudents}</b> Students enrolled</p> */}
        <i className="fas fa-user-graduate"></i>
        <div className="students">
          <p className="shadowed"><b>Students</b></p>
          <p className="shadowed">{coursedetails.enrolledStudents}</p>
        </div>
      </div>
      <div className="extr-inf">
        <i className="fas fa-award"></i>
        {/* <p className="shadowed"><b>Certification</b></p> */}
        {coursedetails.isCertified ? (
          <i title="Certification is available for this course" className='fas fa-check' style={{ color: 'green', fontSize: '1.5rem', margin: 'auto 20px auto 0' }}></i>
        ) : (
          <i title="Certification is not available for this course" className='fas fa-times' style={{ color: 'red', fontSize: '1.5rem', margin: 'auto 20px auto 0' }}></i>
        )}
      </div>
    </>
  )
}
