import React from 'react'
import { useDocTitle } from "../../hooks/ChangePageTitle";

import StartBaner from '../../components/StartBaner/StartBaner'
import SignScreen from '../../screen/SignScreen/SignScreen'
import hero from '../../images/hero.webp'
export default function SignUp() {
  useDocTitle("RekGPT | Sign Up");

  return (
    <div className="signup-page">
      <StartBaner imgLink={hero}>
        <h2 className="text-white fw-normal pb-3 font-mobile">Signup
          <span className="text-uppercase different-header-color"> and </span>work on your
          <span className="text-uppercase different-header-color"> dream </span>
        </h2>
      </StartBaner>
      <SignScreen
        ClassName="signup-screen"
        signLabel="Sign Up"
        signType="signup"
      />
    </div>
  )
}
