import React from 'react'
// import userLogo from '../../../images/userLogo.svg'

export default function ChatMessages({ messages, myUserInfo, partnerImg }) {
  return (
    <>
      {messages.map(msg => (
        <div key={msg._id} className={`msg-body-container ${myUserInfo._id===msg.sender ? "myMsg" : ""}`}>
          <div className="img-container">
          {partnerImg ? (
            <img
              className="me-4"
              src={`data:image/${partnerImg.contentType};base64,${partnerImg}`}
              alt="icon img"
            />
          ) : (
            <img className="me-4" src="/profileLogo_new.png" alt="userLogo" />
          )}
          </div>
          <div className="msg-txt">{msg.text}</div>
        </div>
      ))}
    </>
  )
}
