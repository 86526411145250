import React, { useState, useEffect } from "react";
import './CourseDetails.css'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { Element, Link as ScrollLink } from 'react-scroll'

// import userLogo from '../../images/userLogo.svg'
import BoardMembers from "../../SampleData/members";
// import jobs from "../../SampleData/jobs"
import { listCourseDetails, createCourseReview } from '../../actions/courseActions'
import { COURSE_CREATE_REVIEW_RESET, COURSE_DETAILS_RESET } from "../../constants/courseConstants";

import { listJobs } from "../../actions/jobActions";
import Modal from "../../screen/Modal/Modal";
import StudentsRatingBars from "../../components/StudentsRatingBars/StudentsRatingBars";
import ButtonLink from "../../components/ButtonLink/ButtonLink";
import JobCard from "../../components/JobCard/JobCard";
import { convertMinutes } from "../../hooks/GeneralFacilitateFuncs";
import CourseRelatedCategories from "./CourseRelatedCategories";
import CourseHeaderInfo from "./CourseHeaderInfo";
import ExamScreen from "./ExamScreen";
import CourseSideBarInfo from "./CourseSideBarInfo";
import Spinner from "../../components/Spinner/Spinner";
import Message from "../../components/Message/Message";
import FeedbackForm from "./FeedbackForm";



const setPageTitle = (title) => {
  const prevTitle = document.title;
  document.title = title;
  return () => document.title = prevTitle;
}

export default function CourseDetails({ match }) {
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const courseDetails = useSelector((state) => state.courseDetails)
  const { loading, error, coursedetails } = courseDetails

  const courseReviewCreate = useSelector((state) => state.courseReviewCreate)
  const { successReview, errorReview } = courseReviewCreate

  const jobList = useSelector((state) => state.jobList)
  const { jobs } = jobList

  const relatedJobs = jobs.filter(job => coursedetails.courseCategory.includes(job.category))

  // end of selectors section*************************************
  const uTubevideoURIStart = "https://www.youtube-nocookie.com/embed/"
  const uTubevideoURIEnd = "?&rel=0;&autoplay=1;&modestbranding=1"
  const iframeVideoStart = "https://iframe.videodelivery.net/"

  const [takeExamBoolean, setTakeExamBoolean] = useState(false)
  const [displayExamBoolean, setDisplayExamBoolean] = useState(false)
  const [instructorAccess, setInstructorAccess] = useState(false)
  const [studentAccess, setStudentAccess] = useState(false)
  const [studentExamAcess, setStudentExamAcess] = useState(false)
  const [certificateInfo, setCertificateInfo] = useState({
    instructorId: "",
    courseId: "",
    completionStatus: false,
    certificateID: ""
  })
  const [newText, setNewText] = useState("")
  const [newTextUrl, setNewTextUrl] = useState("")
  const [newVid, setNewVid] = useState("")
  const [introVideo, setIntroVideo] = useState('')
  const [lectureNumber, setLectureNumber] = useState(0)
  const [courseLength, setCourseLength] = useState({ timeHours: 0, timeMinutes: 0 })

  const [instructorImage, setInstructorImage] = useState('')
  const [instructorImageType, setInstructorImageType] = useState('')
  // setStudentExamAcess
  // const [relatedJobs, setRelatedJobs] = useState([])
  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })
  // const [reviewMessage, setReviewMessage] = useState('')
  const [rating, setRating] = useState(null)
  const [comment, setComment] = useState("")
  const [askFeedback, setAskFeedback] = useState(false);

  useEffect(() => {
    if (!loading && studentAccess) {
      setTimeout(() => {
        setAskFeedback(true);
      }, 3000);
    }
  }, [studentAccess, loading]);
  useEffect(() => {
    dispatch(listJobs())
    dispatch(listCourseDetails(match.params.id))
    return () => {
      dispatch({ type: COURSE_CREATE_REVIEW_RESET })
      dispatch({ type: COURSE_DETAILS_RESET })
    }
  }, [dispatch, match])
  useEffect(() => {
    if (errorReview) {
      setMessage({
        msgType: "message-error",
        messageBody: errorReview
      })
      return
    }
    if (successReview) {
      setMessage({
        msgType: "message-success",
        messageBody: "Your rating has been added successfuly. Thanks for your feedback!"
      })
    }
  }, [successReview, errorReview])


  const estimateSectionTime = (lesns) => {
    const lessonLength = lesns.reduce((acc, lesson) => acc + lesson.showtime, 0)
    const lessonsTime = convertMinutes(lessonLength)
    return lessonsTime
  }

  useEffect(() => {
    if (coursedetails?.explainerVideo?.vidId || coursedetails?.youTubeExplainerVideo || coursedetails?.reviews) {
      const numOfLectures = coursedetails.courseSections.reduce((acc, section) => acc + section.lessons.length, 0)
      const courseInMinutes = coursedetails.courseSections.reduce((acc, section) => acc + section.lessons.reduce((acc2, lesson) => acc2 + lesson.showtime, 0), 0)
      const totalCourseLength = convertMinutes(courseInMinutes)
      setLectureNumber(numOfLectures)
      setCourseLength(totalCourseLength)

      if (coursedetails?.explainerVideo?.vidId && coursedetails?.explainerVideo?.vidName) {
        const videoLink = `${iframeVideoStart}${coursedetails.explainerVideo.vidId}${uTubevideoURIEnd}`
        setIntroVideo(videoLink)
      } else {
        const utubeVideoSerial = coursedetails?.youTubeExplainerVideo?.split("=")[1]
        const videoLink = `${uTubevideoURIStart}${utubeVideoSerial}${uTubevideoURIEnd}`
        setIntroVideo(videoLink)
      }
    }

    if (coursedetails?.user?.userImage?.img?.data) {
      setInstructorImage(Buffer.from(coursedetails.user.userImage.img.data).toString('base64'))
      setInstructorImageType(coursedetails.user.userImage.img.contentType)
      setPageTitle(coursedetails.name)
      if (userInfo) {
        if (coursedetails.user._id === userInfo?._id) {
          setInstructorAccess(true)
        }
        userInfo?.enrolledCourses?.forEach((item) => {
          if (item.course === match.params.id) {
            setStudentAccess(true)
            if (item?.certifiedTrack?.EnrollStatus) {
              setStudentExamAcess(true)
              setCertificateInfo({
                instructorId: coursedetails.user._id,
                courseId: match.params.id,
                completionStatus: item.certifiedTrack.completionStatus,
                certificateID: item.certifiedTrack.certificateID || ""
              })
            }
          }
        })
      }
    }

  }, [match, coursedetails, loading, userInfo, jobs, introVideo])

  // const wholeVidURI = `${videoURI}${newVid}?&rel=0;&autoplay=1;&modestbranding=1`
  // console.log("introVideo: ", introVideo)

  const handleExamCheck = (event) => {
    const { checked } = event.target
    setTakeExamBoolean(checked)
  }

  const handleExamDisplay = (event) => {
    event.preventDefault()
    //  update completionStatus in the backend send (courseId)
    setDisplayExamBoolean(true)
  }

  const closeExamModal = () => {
    setDisplayExamBoolean(false)
  }
  const closeFeedbackModal = () => {
    setAskFeedback(false)
  }


  const handleLinkClick = (lessondetails) => {
    const utubeVideoSerial = lessondetails?.uTubeVideoLink?.split("=")[1]
    let videoLink = ""
    if (lessondetails.isvideo) {
      if (lessondetails?.hostingProvider === "youtube") {
        videoLink = `${uTubevideoURIStart}${utubeVideoSerial}${uTubevideoURIEnd}`
      } else {
        videoLink = `${iframeVideoStart}${lessondetails.uploadedVideoID}${uTubevideoURIEnd}`
      }
      setNewVid(videoLink)
      setNewText("")
    } else {
      setNewText(lessondetails.text)
      setNewTextUrl(lessondetails.URL)
      setNewVid("")
    }
  }

  const reviewHandler = (e) => {
    e.preventDefault()
    if (!comment || comment.length < 10) {
      setMessage({
        msgType: "message-error",
        messageBody: "Very short feedback or you didn't write any. feedback must be more than 10 characters."
      })
      return
    }
    if (!rating) {
      setMessage({
        msgType: "message-error",
        messageBody: "you didn't rate the course"
      })
      return
    }
    dispatch(
      createCourseReview(match.params.id, {
        rating,
        comment,
      })
    )
  }

  return (
    <div className="wrapper-course-details">
      {loading && <Spinner />}
      {error && <Message ClassName="message-error">{error}</Message>}
      {!error && !loading && (
        <div>
          <div className="Head-course-details-parallax">
            <div className="course-category-heighlight">
              <h1>{coursedetails.courseCategory[0]}</h1>
            </div>
          </div>
          <div className="course-details">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-sm-12 mb-2">
                  <div className="details-wrapper">
                    <div className="course-title">
                      <h2>{coursedetails.name}</h2>
                      {/* this will come later from database */}
                      {/* <span>Posted on 20 Dec, 2020</span> */}
                    </div>
                    <CourseHeaderInfo
                      coursedetails={coursedetails}
                      lectureNumber={lectureNumber}
                      courseLength={courseLength}
                    />
                    {/* Course image is placed Here */}
                    <Element id="courseWatchingPanel">
                      {!newVid ? (
                        newText ? (
                          <div className="lesson-instructions">
                            <p>{newText}</p>
                            <a href={newTextUrl} target="_blank" rel="noopener noreferrer">{newTextUrl}</a>
                          </div>
                          // <img style={{ margin: "auto" }} src={coursedetails.imgLink} alt="courseImage" />
                        ) : (
                          <iframe
                            src={introVideo}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            title="Explainer Video"
                            frameBorder="0"
                            allowFullScreen></iframe>
                        )
                      ) : (
                        <iframe
                          src={newVid}
                          title="tutorial"
                          frameBorder="0"
                          allowFullScreen
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                      )}
                    </Element>

                    {/* <img src={OpenCV} alt="trialImage"/> */}
                    <p className="p1">{coursedetails.body}</p>
                    <div className="learn-list">
                      <h3>What Will I Learn?</h3>
                      <ul className="row">
                        {coursedetails.courseOutcomes.map((item, index) => {
                          return (
                            <li key={index} className="col-sm-6 col-xs-12"><span>{item}</span></li>
                          )
                        })}
                      </ul>
                    </div>

                    <div className="curriculum-panel">
                      <div className="head">
                        <h3>Curriculum</h3>
                        <ul>
                          <li><span>{coursedetails.courseSections.length}</span> Sections</li>
                          {/* this will replaced later based on algorithm to calculate course time length */}
                          <li><span>{`${courseLength.timeHours}hours : ${courseLength.timeMinutes}minutes`}</span></li>
                        </ul>
                      </div>
                      <div className="panel-group theme-accordion" id="accordionTwo">
                        {coursedetails.courseSections.map((course, indx) => {
                          return (
                            <div key={indx} className="panel">
                              <div className="panel-heading">
                                <h5 className="panel-title">
                                  <a data-bs-toggle="collapse" href={"#collapse1" + indx} aria-controls={"#collapse1" + indx}>
                                    <h6 className="float-left">
                                      <span>Section {indx + 1}</span>
                                      {course.title}
                                    </h6>
                                    <p className="float-right">{estimateSectionTime(course.lessons).timeHours} h : {estimateSectionTime(course.lessons).timeMinutes} minutes</p>
                                  </a>
                                </h5>
                              </div>
                              <div id={"collapse1" + indx} className="panel-collapse collapse in" data-bs-parent="#accordionTwo">
                                <div className="panel-body">
                                  <ul>
                                    {course.lessons.map((courseLesson, lessonIndx) => {
                                      return (
                                        <li key={lessonIndx} className="lesson">
                                          <span>
                                            <ScrollLink
                                              className={(instructorAccess || studentAccess) ? "lesson-link-active" : "lesson-link-inactive"}
                                              to={(instructorAccess || studentAccess) ? "courseWatchingPanel" : ""}
                                              smooth={true}
                                              duration={500}
                                              offset={-200}
                                              onClick={(instructorAccess || studentAccess) ? () => { handleLinkClick(courseLesson) } : null}
                                            >
                                              <i className={courseLesson.isvideo ? "fas fa-play-circle" : "far fa-file-alt"}></i>{courseLesson.title}
                                            </ScrollLink>
                                            {courseLesson.preview && !studentAccess && !instructorAccess && (
                                              <ScrollLink
                                                className={"lesson-link-preview"}
                                                to="courseWatchingPanel"
                                                smooth={true}
                                                duration={500}
                                                offset={-200}
                                                onClick={() => { handleLinkClick(courseLesson) }}
                                              >
                                                <i className="fas fa-play-circle"><span>Play</span></i>
                                              </ScrollLink>
                                            )}

                                            {/* <a href="#courseWatchingPanel" className="tran3s" onClick={() => { handleLinkClick(courseLesson) }}>
                                            <i className={courseLesson.isvideo ? "fas fa-play-circle" : "far fa-file-alt"}></i>{courseLesson.title}
                                          </a> */}
                                          </span>
                                          <span className="length">{courseLesson.isvideo ? `${courseLesson.showtime} minutes` : null} </span>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>

                    <StudentsRatingBars coursedetails={coursedetails} />

                    {(studentAccess || instructorAccess) && (
                      <div className="submit-review-form mt-5">
                        <h3>Submit a Review</h3>
                        <p>Rate the course</p>
                        <ul style={{ display: 'contents' }}>
                          {[...Array(5).keys()].map(indx => (
                            <li key={indx} onClick={() => setRating(indx + 1)}> <i className="fas fa-star"></i> </li>
                          ))}
                        </ul>
                        {rating ? (
                          <p style={{ padding: '15px 0px', fontWeight: 'bold' }}> Your Rating: <span style={{ marginLeft: '10px', color: 'blueviolet' }}> {rating} </span></p>
                        ) : (
                          <p style={{ color: '#9a9a9a', padding: '15px 0px', fontWeight: 'bold' }}> Rate this course when you complete it </p>
                        )}
                        <form onSubmit={reviewHandler}>
                          <label className="general-input--style-label">Your Message</label>
                          <textarea
                            className="form-control general-input--style"
                            type="text"
                            value={comment}
                            placeholder="Write Commnent..."
                            onChange={(e) => setComment(e.target.value)}
                          />
                          <ButtonLink ClassName="learn-more-btn upper-case mt-3">
                            <input type="submit" value="Submit Review" />
                          </ButtonLink>
                        </form>
                        {message.messageBody && <Message ClassName={message.msgType}>{message.messageBody}</Message>}
                      </div>
                    )}
                  </div>
                </div>


                <div className="col-md-4 col-sm-12">
                  <div className="course-sidebar">
                    <div className="sidebar-course-information">

                      <CourseSideBarInfo coursedetails={coursedetails} />

                      {instructorAccess ? (
                        <ButtonLink ClassName="learn-more-btn full-width Xmar">
                          <Link
                            to={`/instructor/${match.params.id}/edit`}
                            title="CourseDetails"> Edit Your course
                          </Link>
                        </ButtonLink>
                      ) : !studentAccess ? (
                        // activate enrollement when starting business, remove message and uncomment the enrollement link
                        // <Message ClassName='message-info'>Enrollement is not available now. it will open at 20/11/2021</Message>
                        <ButtonLink ClassName="learn-more-btn full-width Xmar">
                          <Link
                            to={`/cart/${match.params.id}`}
                            title="CourseDetails"> Enroll
                          </Link>
                        </ButtonLink>
                      ) : null}
                      {(instructorAccess || studentExamAcess) && coursedetails?.examInstructions && <>
                        <div className="form-check take-exam mt-3">
                          <input
                            id="confirm-exam"
                            className="form-check-input"
                            name="confirm-exam"
                            type="checkbox"
                            onChange={handleExamCheck}
                          />
                          <label className="form-check-label label-question" htmlFor="confirm-exam">
                            I Completed the course
                          </label>
                        </div>
                        <ButtonLink ClassName={`learn-more-btn ${takeExamBoolean ? "" : "disabled"} full-width`}>
                          <button onClick={takeExamBoolean ? handleExamDisplay : (e) => e.preventDefault()}> Show Exam </button>
                        </ButtonLink>
                      </>}
                      {/* <a href="#courseWatchingPanel" className="tran3s s-bg-color take-course hvr-trim">Enroll</a> */}
                    </div>
                    <div className="sidebar-instructor-info">
                      <h4>Instructor Info</h4>
                      <div className="wrapper">
                        <div className="name">
                          {/* <img src={coursedetails.instructorImg} alt="" /> */}
                          <img src={`data:${instructorImageType};base64,${instructorImage}`} alt="" />
                          <h6>{coursedetails.user.name || BoardMembers[0].Name}</h6>
                          <span>{coursedetails.user.instructorDetails.instructorProfession || BoardMembers[0].job}</span>
                          <ul>
                            <li>
                              <a href={coursedetails.user.linkedinURL || BoardMembers[0].linkedinLink} rel="noreferrer" target="_blank" className="tran3s">
                                <i className="fab fa-linkedin-in" aria-hidden="true">
                                </i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <p>{coursedetails.user.instructorDetails.instructorIntroduction || BoardMembers[0].shortDescription}</p>
                        {/* <a href="#courseWatchingPanel" className="tran3s p-bg-color follow hvr-trim">Know more</a> */}
                      </div>
                    </div>
                    <CourseRelatedCategories />
                  </div>
                </div>
              </div>

            </div>
            {/* ***************** Matching jobs ************************************** */}
            <div className="job-course-matchs">
              <h3>Related Jobs</h3>
              <div className="jobcards-container">
                {relatedJobs.map((jobCard, indx) => {
                  return (
                    <JobCard key={indx} indx={indx} job={jobCard} />
                  )
                })}
              </div>
            </div>
          </div>
          {displayExamBoolean && (
            <Modal closeModal={closeExamModal}>
              <ExamScreen
                examInstructions={coursedetails?.examInstructions}
                closeModal={closeExamModal}
                certificateInfo={certificateInfo}
              />
            </Modal>
          )}
        </div>
      )}
      {askFeedback && studentAccess && (
        <Modal closeModal={closeFeedbackModal}>
          <FeedbackForm
            closeModal={closeFeedbackModal}
            courseId={match.params.id}
          />
        </Modal>
      )}

    </div>
  )
}