import {
    ORDER_CREATE_REQUEST,
    ORDER_CREATE_SUCCESS,
    ORDER_CREATE_FAIL,
    ORDER_CREATE_RESET,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,
    ORDER_DETAILS_RESET,
    ORDER_PAY_REQUEST,
    ORDER_PAY_SUCCESS,
    ORDER_PAY_FAIL,
    ORDER_PAY_RESET,
    ORDER_LIST_ORDERS_REQUEST,
    ORDER_LIST_ORDERS_SUCCESS,
    ORDER_LIST_ORDERS_FAIL,
    ORDER_LIST_ORDERS_RESET,
    ORDER_DELETE_FAIL,
    ORDER_DELETE_SUCCESS
  } from '../constants/orderConstants'
  
  export const orderCreateReducer = (state = { success: false }, action) => {
    switch (action.type) {
      case ORDER_CREATE_REQUEST:
        return {
          success: false,
          loading: true,
        }
      case ORDER_CREATE_SUCCESS:
        return {
          loading: false,
          success: true,
          order: action.payload,
        }
      case ORDER_CREATE_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      case ORDER_CREATE_RESET:
        return {}
      default:
        return state
    }
  }

  export const orderDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case ORDER_DELETE_SUCCESS:
        return { deleteSuccess: true }
      case ORDER_DELETE_FAIL:
        return { error: action.payload }
      default:
        return state
    }
  }

  export const orderDetailsReducer = (state = { loading: true, orderdetail: { orderItems: [], user: {} } }, action) => {
    switch (action.type) {
      case ORDER_DETAILS_REQUEST:
        return {
          ...state,
          loading: true,
        }
      case ORDER_DETAILS_SUCCESS:
        return {
          loading: false,
          orderdetail: action.payload,
        }
      case ORDER_DETAILS_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      case ORDER_DETAILS_RESET:
        return { loading: true, orderdetail: { orderItems: [], user: {} } }
      default:
        return state
    }
  }

  export const orderPayReducer = (state = {}, action) => {
    switch (action.type) {
      case ORDER_PAY_REQUEST:
        return {
          payLoading: true,
        }
      case ORDER_PAY_SUCCESS:
        return {
          payLoading: false,
          paySuccess: true,
        }
      case ORDER_PAY_FAIL:
        return {
          payLoading: false,
          error: action.payload,
        }
        case ORDER_PAY_RESET:
          return {}
      default:
        return state
    }
  }

  // LIST_ORDERS

  export const listMyOrdersReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
      case ORDER_LIST_ORDERS_REQUEST:
        return {
          loading: true,
        }
      case ORDER_LIST_ORDERS_SUCCESS:
        return {
          loading: false,
          orders: action.payload,
        }
      case ORDER_LIST_ORDERS_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      default:
        return state
    }
  }