import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listAdminCategories, updateCategory } from '../../actions/categoriesAction'
import Message from '../../components/Message/Message'
import Table from '../../components/Table/Table'
import Modal from '../../screen/Modal/Modal'
import CaregoryUpdateForm from './CaregoryUpdateForm/CaregoryUpdateForm'

const columns = [
  { accessor: 'categoryType', label: 'Category Type' },
  { accessor: 'categoryName', label: 'Category Name' },
  { accessor: 'activeStatus', label: 'Grant Availability', format: value => (value ? '✔️' : '✖️') }
]

export default function CategoriesListScreen({ history }) {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const adminCategoriesList = useSelector((state) => state.adminCategoriesList)
  const { error: categoryListError, categories } = adminCategoriesList
  const updateCategoryStatus = useSelector((state) => state.updateCategoryStatus)
  const { updateError, updateSuccess } = updateCategoryStatus

  const [tableRows, setTableRows] = useState([])
  const [rowsNumPerPage, setRowsNumPerPage] = useState(15)
  const [categoryModal, setCategoryModal] = useState({
    categoryDetails: {},
    modalState: false
  })
  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })

  const rowDataFormation = (data) => {
    const reArrangedData = data.map((row, indx) => {
      const tableRow = {
        _id: row._id,
        rownum: indx,
        categoryType: row.categoryType,
        categoryName: row.categoryName,
        activeStatus: row.activeStatus
      }
      return tableRow
    })
    return reArrangedData
  }
  const extractCategoryInfo = (_categoryId) => {
    const selectedcategory = tableRows.find(singleCategory => singleCategory._id.toString() === _categoryId.toString())
    setCategoryModal({
      categoryDetails: selectedcategory,
      modalState: true
    })
  }
  // close of Lesson information Modal
  const closeModal = () => {
    setCategoryModal({
      categoryDetails: {},
      modalState: false
    })
  }
  const onUpdateConfirm = (id, info) => {
    dispatch(updateCategory(id, info))
    setTimeout(() => {
      setCategoryModal({
        categoryDetails: {},
        modalState: false
      })
    }, 500);
  }

  useEffect(() => {
    if (updateError) {
      setMessage({
        msgType: "message-error",
        messageBody: updateError
      })
      return
    }
    if (userInfo?.isAdmin) {
      dispatch(listAdminCategories())
    } else {
      history.push('/signin')
    }
  }, [dispatch, history, userInfo, updateSuccess, updateError])

  useEffect(() => {
    if (categoryListError) {
      setMessage({
        msgType: "message-error",
        messageBody: categoryListError
      })
      return
    }
    if (categories) {
      const _tableRows = rowDataFormation(categories)
      setTableRows(_tableRows)
    }
  }, [categories, categoryListError])


  return (
    <div className="userdetails-editor">
      <div className="container">
        <h4 className="common-heading"> List All Grants </h4>
        <div className="useredit-content">
          <div className="row align-items-center mb-4">
            <div className='col-3'>
              <label> Select Num of Rows Per Page </label>
              <select name="pageRows" className="form-select course_input" onChange={(event) => { setRowsNumPerPage(parseInt(event.target.value)) }}>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          <Table
            columns={columns}
            rows={tableRows}
            rowsPerPage={rowsNumPerPage}
            delteActionEnabled={false}
            editActionEnabled={true}
            deleteHandler={() => { }}
            editHandler={(categoryId) => extractCategoryInfo(categoryId)}
          />
        </div>
        {message.messageBody && <Message ClassName={message.msgType}>{message.messageBody}</Message>}
        {categoryModal.modalState && (
          <Modal closeModal={closeModal}>
            <CaregoryUpdateForm
              categoryDetails={categoryModal.categoryDetails}
              closeModal={closeModal}
              onUpdateConfirm={onUpdateConfirm}
            />
          </Modal>
        )}
      </div>
    </div>
  )
}
