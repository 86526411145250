import React from 'react'
import { useDocTitle } from "../../hooks/ChangePageTitle";

import StartBaner from '../../components/StartBaner/StartBaner'
import SignScreen from '../../screen/SignScreen/SignScreen'
import hero from '../../images/hero.webp'

export default function SignIn() {
  useDocTitle("RekGPT | Sign In");

  return (
    <div className="signin-page">
      <StartBaner imgLink={hero}>
        <h2 className="text-white fw-normal pb-3 font-mobile"> SIGN IN </h2>
      </StartBaner>
      <SignScreen
        ClassName="signin-screen"
        signLabel="Sign In"
        signType="signin"
      />
    </div>
  )
}
