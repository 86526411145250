import React, { useState } from 'react'
import './Pricing.css'
import { Toaster, toast } from 'react-hot-toast'
import axios from 'axios'
import Modal from '../../screen/Modal/Modal'
import JoinListForm from '../../screen/PricingSections/JoinListForm'
import Hiring from '../../screen/PricingSections/Hiring'
import HiringPlus from '../../screen/PricingSections/HiringPlus'
import SuccessPartner from '../../screen/PricingSections/SuccessPartner'
import Premium from '../../screen/PricingSections/Premium'
import verifyFields from './verifyFields'
import { useDocTitle } from '../../hooks/ChangePageTitle'
// import cancelIcon from '../../images/general-purpose/cancelIcon.png'

export default function PricingPage() {
  /* === Page Title === */
  useDocTitle("RekGPT | Subscription Plans");
  const [modalOpen, setModalOpen] = useState({
    state: false,
    planIndx: null
  })

  const openModal = (interestedPlanIndx) => {
    setModalOpen({
      state: true,
      planIndx: interestedPlanIndx
    })
  }
  const closeModal = () => {
    const newVal = {
      ...modalOpen,
      state: false
    }
    setModalOpen(newVal)
  }


  const handleSubscriptionAction = async (registerForm, _planIndx) => {
    const errorDetails = verifyFields(registerForm)
    if (errorDetails) {
      toast.error(errorDetails)
      return
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    try {
      await axios.post(
        `/api/users/companyinterest`,
        { ...registerForm, interestedPlan: _planIndx },
        config
      )
      closeModal()
      setTimeout(() => {
        toast.success("Thanks a lot for your interest!, we will contact you very soon")
      }, 200);
    } catch (error) {
      toast.error('You have already subscribed with this email to other plan, contact us if you wish to change your current plan')
    }
  }

  return (
    <div className="pricing-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-sm-12"
          >
            {/* <!-- section-title start --> */}
            <div className="section_head">
              <h2 className="title">
                Check our amazing plans, choose the best one for you.
              </h2>
              <p>
                Different payment plans that suits your recruitment needs
              </p>
              <div className='shortnotice'>
                <b>Notice: These Pricing plans is only for customers who will join us in this early stage of launching our product, you don't want to miss the opportunity!</b>
              </div>

            </div>
            {/* <!-- section-title end --> */}
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-lg-6 col-md-12 mb-5 mt-3">
            {/* <!-- card start --> */}
            <Hiring openModal={openModal} />
            {/* <!-- card end --> */}
          </div>
          <div className="col-lg-6 col-md-12 mb-5 mt-3">
            {/* <!-- card start --> */}
            <HiringPlus openModal={openModal} />
            {/* <!-- card end --> */}
          </div>
          {/* <div className="col-lg-4 col-md-12 mb-5 mt-3">
            <SuccessPartner openModal={openModal} />
          </div> */}
          {/* <div className="col-lg-4 col-md-12 mb-5 mt-3">
            <Premium openModal={openModal} />
          </div> */}
        </div>
        {modalOpen.state && (
          <Modal closeModal={closeModal}>
            <JoinListForm
              closeModal={closeModal}
              handleSubscriptionAction={handleSubscriptionAction}
              planIndx={modalOpen.planIndx}
            />
          </Modal>
        )}
      </div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            duration: 5000
          },
          error: {
            duration: 8000
          }
        }}
      />
    </div>
  )
}
