import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FacebookShareButton, LinkedinShareButton } from "react-share"
import { createGrant, fetchGrantInfo } from '../../../actions/grantActions'
import ButtonLink from '../../../components/ButtonLink/ButtonLink'
import Message from '../../../components/Message/Message'
import history from '../../../history'
import './PromotionPanel.css'
import { MinimumMembersForGrant } from '../../../SampleData/standardVals'
import { verifyTokenValidity } from '../../../actions/userActions'
import { useRouteMatch } from 'react-router'
export default function PromotionPanel() {
  const dispatch = useDispatch()
  const { path: pathroot } = useRouteMatch()
  // const [congratMessage, setCongratMessage] = useState(false)
  const RootURL = window.location.origin
  // console.log("RootURL:  ", RootURL)
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const grantInformation = useSelector((state) => state.grantInformation)
  const { grantInfo, createSuccess } = grantInformation

  const _singlemember = grantInfo?.members?.find(singlemember => singlemember.member.toString() === userInfo._id.toString())
  const grantExpiryStatus = _singlemember ? _singlemember.expiredGrant : true

  useEffect(() => {
    if (!userInfo._id) {
      setTimeout(() => {
        history.push(`/signin?redirect=${pathroot}`)
      }, 500);
    } else {
      if (!grantInfo || createSuccess)
        dispatch(fetchGrantInfo())
      dispatch(verifyTokenValidity())
    }
  }, [dispatch, grantInfo, createSuccess, userInfo, pathroot])


  const handleGrantApplication = async (event) => {
    event.preventDefault()
    if (userInfo?._id) {
      dispatch(createGrant())
      // setCongratMessage(true)
    }
  }

  return (
    <div className="promotion-panel">
      <div className="container">
        <div className="row">
          <div className='col-lg-8 col-md-12 user-promotion'>
            <ul>
              <li>Share about RekGPT with your network on the social media. When anyone uses your link for registration, all of you will get a grant that covers a free enrolment into maximum of two paid courses on Technopedia.</li>
              <li>Alternatively, instead of social media, you can share your grant code with your friends and tell them to enter this code during registration and still all of you will get the grant.</li>
              <li>When {MinimumMembersForGrant} new users (including you) use this code, your scholarship will be active</li>
              <li>As more people register with your link, as more grants you will get in the future. As example, 10 people register with your link and start learning, you will get another grant code to use in the future for free enrolment into paid courses.</li>
              <li><strong>Never scam by creating multiple accounts to get the grant, We will know and you will be blocked from using our platform forever</strong></li>
            </ul>
            {(!grantInfo?._id) ? (
              <div>
                <ButtonLink ClassName="learn-more-btn">
                  <button onClick={handleGrantApplication}>
                    Apply for Grant Code
                  </button>
                </ButtonLink>
              </div>
            ) : null}

            {(grantInfo?.grantCode && !grantInfo?.grantAvailability) && (
              <div className="congratulation-statement">
                <Message ClassName="message-success">
                  <ul>
                    <li> Congratulations!!, Now you are assigned a grant Code</li>
                    <li> Once {MinimumMembersForGrant} new users register with your code, your scholarship will be active</li>
                  </ul>
                </Message>
              </div>

            )}
            {/* warning message should be displayed here */}
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="socialmedia-sharing">
              <div className="grant-code-wrapper">
                <div className="grant-code"> Your Grant code: <span> {grantInfo?.grantCode ? grantInfo?.grantCode : null} </span></div>
                <div className="grant-code"> Grant status: <span> {!grantInfo?.grantAvailability ? (
                  <Message ClassName="message-warning smaller-font">Inactive</Message>
                ) : (
                  <Message ClassName="message-success smaller-font">You have Scholarship!</Message>
                )}
                  {(grantInfo?.grantAvailability && grantExpiryStatus) && <Message ClassName="message-info smaller-font">You used your scholarship</Message>} </span></div>
                {(grantInfo?.grantCode && !grantInfo?.grantAvailability) ? (
                  <div className="grant-code"> <Message ClassName="message-info txt-left"> When {MinimumMembersForGrant} new users register with this code, scholarship will be active</Message> </div>
                ) : null}
              </div>

              <div className="socialmedia-sharing-wrapper">
                <p>Share on social media to reach more audience: </p>
                <p className="highlight-txt">Anyone use your sharing link, will get the grant code automatically in registeration</p>
                <div className="socialmedia-sharing-box">
                  {/* i should use this when i deploy website ${location.origin} http://localhost:3000/ */}
                  <FacebookShareButton
                    url={`${RootURL}/?grant=${grantInfo?.grantCode}`}
                    quote={'Seize the chance and get a scholarship that include a free enrolement to three paid courses'}
                  >
                    <i className="fab fa-facebook-f"></i>
                  </FacebookShareButton>
                  <LinkedinShareButton
                    url={`${RootURL}/?grant=${grantInfo?.grantCode}`}
                    title={'Scholarship for online courses'}
                    summary={'Seize the chance and get a scholarship that include a free enrolement to three paid courses'}
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </LinkedinShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
