import React, { useEffect, useState } from 'react';
import './Accordion.css'

export default function Accordion({ items }) {
  const [activatedItems, setActivatedItems] = useState(items);

  const onTitleClick = (index) => {
    setActivatedItems((prevItems) => {
      const newItems = prevItems.map((cell, cellIndx) => {
        if (cellIndx === index) {
          return {...cell, activated: !cell.activated}
        } else {
          return {...cell, activated: cell.activated}
        }
      })
      return newItems
    });
  };

  useEffect(() => {
    setActivatedItems(items)
  }, [items])


  return (
    <div className="accordion--container">
      {activatedItems.map((item, indx) => {
        return (
          <React.Fragment key={item.title}>
            <div className={`title ${item.activated ? "active" : ""}`} onClick={() => onTitleClick(indx)}>
              <p className="text">{item.title}</p>
              <i className="fas fa-caret-down"></i>
            </div>
            <div className={`content ${item.activated ? "active" : ""}`}>
              <ul className="list-unstyled position-relative">
                {item.content.map((unit, conindx) => {
                  return (
                    <li key={conindx}> {unit} </li>
                  )
                })}
              </ul>
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
};

