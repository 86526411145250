// import learnPaths from "../../../SampleData/learnPathsData";
import PathScreen from "./PathScreen";
import PathSelector from "./PathSelector";
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { listPaths } from "../../../actions/pathActions";
import { Route, Switch, useRouteMatch } from "react-router";
import Spinner from "../../../components/Spinner/Spinner";
import Message from "../../../components/Message/Message";

export default function PathDetails({ filteredPath }) {
  const dispatch = useDispatch();
  const { path: pathroot, url: urlroot } = useRouteMatch()

  const pathList = useSelector((state) => state.pathList)
  const { loading, error, paths } = pathList
  const [pathsList, setPathsList] = useState(paths)
  // const [learnPath, setLearnPath] = useState({})

  useEffect(() => {
    let displayedPaths = []
    if (!filteredPath.pathName && !filteredPath.category) {
      // setLearnPath(paths[0])
      setPathsList(paths)
    } else {
      console.log("filteredPath: ", filteredPath)
      console.log("paths:  ", paths)
      if (!filteredPath.pathName) {
        displayedPaths = paths.filter(_path => _path.category === filteredPath.category)
      } else if (!filteredPath.category) {
        displayedPaths = paths.filter(_path => _path.pathName === filteredPath.pathName)
      } else {
        const firstGroup = paths.filter(_path => _path.category === filteredPath.category)
        displayedPaths = firstGroup.filter(_path => _path.pathName === filteredPath.pathName)
      }
      // const displayedPaths = paths.filter(_path => (_path.pathName === filteredPath.pathName || _path.category === filteredPath.category))
      setPathsList(displayedPaths)
    }
  }, [paths, filteredPath])


  useEffect(() => {
    dispatch(listPaths())
  }, [dispatch])

  return (
    <div className="details-learnPaths">
      {loading && <Spinner />}
      {error && <Message ClassName="message-error">{error}</Message>}
      {!loading && !error && (
        <>
          <div className="container-lg">
            {/* Start Details Jobs */}
            <div className="d-md-flex justify-content-center">
              <div className="nav flex-column nav-pills me-5 quarter-1-4">
                <PathSelector
                  paths={pathsList}
                  UrlRoot={urlroot}
                />
              </div>
              <div className="tab-content quarter-3-4">
                <Switch>
                  {/* <Route exact path={pathroot}>
                <PathScreen selectedPath={learnPath} />
              </Route> */}
                  <Route path={`${pathroot}/path/:pathId`}>
                    <PathScreen />
                  </Route>
                </Switch>
                {/* {learnPath && <PathScreen selectedPath={learnPath} />} */}
              </div>
            </div>
            {/* End Details Jobs */}
          </div>
        </>
      )}
    </div>
  );
};
