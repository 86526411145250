import axios from "axios"
import { GRANT_ACTION_FAIL, GRANT_ADMIN_ACTION_FAIL, GRANT_ADMIN_CREATE_SUCCESS, GRANT_ADMIN_FETCH_ALL_FAIL, GRANT_ADMIN_FETCH_ALL_SUCCESS, GRANT_CREATE_SUCCESS, GRANT_DELETE_SUCCESS, GRANT_FETCH_STATE_SUCCESS } from "../constants/grantsConstants"

// User create a grantCode
export const createGrant = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.post(`/api/grants/create`, {userName: userInfo.name}, config)
    dispatch({
      type: GRANT_CREATE_SUCCESS,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: GRANT_ACTION_FAIL,
      payload: message,
    })
  }
}

// User create a grantCode
export const createAdminGrant = (info) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.post(`/api/grants/adminaction/create`, {userName: userInfo.name, ...info}, config)
    dispatch({
      type: GRANT_ADMIN_CREATE_SUCCESS,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: GRANT_ADMIN_ACTION_FAIL,
      payload: message,
    })
  }
}

// check if the user is part of grant group or not
export const fetchGrantInfo = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/grants/checkstate/code`, config)
    dispatch({
      type: GRANT_FETCH_STATE_SUCCESS,
      payload: data,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: GRANT_ACTION_FAIL,
      payload: message,
    })
  }
}

// Admin fetch all grants
export const fetchAllGrants = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/grants/admin/fetchall`, config)
    dispatch({
      type: GRANT_ADMIN_FETCH_ALL_SUCCESS,
      payload: data,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: GRANT_ADMIN_FETCH_ALL_FAIL,
      payload: message,
    })
  }
}

// -- Admin access --
export const deleteGrant = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/grants/adminaction/${id}`, config)

    dispatch({ type: GRANT_DELETE_SUCCESS })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: GRANT_ADMIN_ACTION_FAIL,
      payload: message,
    })
  }
}
