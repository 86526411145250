import React from 'react'

export default function MentorRoles() {
  return (
    <div className="instructor-roles">
      <div className="container">
        <h4 className="common-heading"> Merits of Mentorship and your role as a Mentor </h4>
        <div className="roles-container">
          {/* Starter Instructor */}
          <h6>Advantages of joining as RekGPT as a Mentor</h6>
          <ul className="list-unstyled position-relative">
            <li>It is not time consuming process because it is just about Revising selected mentees CVs and recommend whoever followed your LearnPath</li>
            <li>Mentorship on RekGPT will enable you to build a unique network which will be very useful if you decided to start-up your business someday</li>
            <li>You can get a free start-up membership for our business services which has a great value that will include costs exemptions</li>
            <li>You can get a premium subscription for free access to all online courses on our Technopedia if you are a very active mentor who work hardly with us to succeed this experience</li>
          </ul>

          <h6>Your roles as a Mentor on RekGPT</h6>
          <ul className="list-unstyled position-relative">
            <li>Write at least one learning path that will act as a guideline for youth.</li>
            <li>Add a recommendation or act as a reference for mentees who finished courses through our platform or any accredited program.</li>
            <li>Post jobs that are open in your workplace or any other other workplaces where your recommendation can be considered.</li>
            <li>Modify your written learning path based on the your mentees feedback if it is needed.</li>
            <li>Revise your mentees CVs and give a feedback if needed.</li>
            <li>It will be great if you can join us on our internal meetings to update RekGPT Roadmap continously to improve our services</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
