import {
  COURSE_LIST_FAIL,
  COURSE_LIST_SUCCESS,
  COURSE_LIST_REQUEST,
  COURSE_DETAILS_FAIL,
  COURSE_DETAILS_SUCCESS,
  COURSE_DETAILS_REQUEST,
  COURSE_DETAILS_RESET,
  COURSE_DELETE_SUCCESS,
  COURSE_DELETE_FAIL,
  COURSE_CREATE_RESET,
  COURSE_CREATE_FAIL,
  COURSE_CREATE_SUCCESS,
  COURSE_UPDATE_SUCCESS,
  COURSE_UPDATE_FAIL,
  COURSE_UPDATE_RESET,
  COURSE_CREATE_REVIEW_SUCCESS,
  COURSE_CREATE_REVIEW_FAIL,
  COURSE_CREATE_REVIEW_RESET,
  INSTRUCTOR_COURSE_LIST_SUCCESS,
  INSTRUCTOR_COURSE_LIST_FAIL,
  INSTRUCTOR_COURSE_LIST_REQUEST
} from '../constants/courseConstants'

export const courseListReducer = (state = { courses: [] }, action) => {
  switch (action.type) {
    case COURSE_LIST_REQUEST:
      return { loading: true, ...state }
    case COURSE_LIST_SUCCESS:
      return { loading: false, courses: action.payload }
    case COURSE_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const coursesInstructorReducer = (state = { InstructorCourses: [] }, action) => {
  switch (action.type) {
    case INSTRUCTOR_COURSE_LIST_REQUEST:
      return { loading: true, ...state }
    case INSTRUCTOR_COURSE_LIST_SUCCESS:
      return { loading: false, InstructorCourses: action.payload }
    case INSTRUCTOR_COURSE_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const courseDetailsReducer = (state = {
  loading: true,
  coursedetails: {
    courseImage: {}, instructorImage: {},
    user: { instructorDetails: {} },
    courseOutcomes: [],
    courseCategory: [],
    courseSubCategories: [[]],
    courseSections: [{ lessons: [] }],
    rating: 5,
    reviews: []
  }
}, action) => {
  switch (action.type) {
    case COURSE_DETAILS_REQUEST:
      return { loading: true, ...state }
    case COURSE_DETAILS_SUCCESS:
      return { loading: false, coursedetails: action.payload }
    case COURSE_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case COURSE_DETAILS_RESET:
      return {
        coursedetails: {
          courseImage: {}, instructorImage: {},
          user: { instructorDetails: {} },
          courseOutcomes: [],
          courseCategory: [],
          courseSubCategories: [[]],
          courseSections: [{ lessons: [] }],
          rating: 5,
          reviews: []
        }
      }
    default:
      return state
  }
}

export const courseDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_DELETE_SUCCESS:
      return { success: true }
    case COURSE_DELETE_FAIL:
      return { error: action.payload }
    default:
      return state
  }
}

export const courseCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_CREATE_SUCCESS:
      return { createSuccess: true }
    case COURSE_CREATE_FAIL:
      return { createError: action.payload }
    case COURSE_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const courseUpdateReducer = (state = { updatedCourse: {} }, action) => {
  switch (action.type) {
    case COURSE_UPDATE_SUCCESS:
      return { updateSuccess: true, updatedCourse: action.payload }
    case COURSE_UPDATE_FAIL:
      return { updateError: action.payload }
    case COURSE_UPDATE_RESET:
      return { updatedCourse: {} }
    default:
      return state
  }
}

export const courseReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_CREATE_REVIEW_SUCCESS:
      return { successReview: true }
    case COURSE_CREATE_REVIEW_FAIL:
      return { errorReview: action.payload }
    case COURSE_CREATE_REVIEW_RESET:
      return {}
    default:
      return state
  }
}
