import React, { useState } from 'react'

export default function CourseSelector({categories, onSelection}) {

  const [newCategories, setNewCategories] = useState(categories)

  const onCategorySelect = (category) => {
    setNewCategories((prevCategories) => {
      const newCatgrs = prevCategories.map((catgry) => {
        if (catgry.categoryName === category.categoryName) {
          return {...catgry, selected: "active"}
        } else {
          return {...catgry, selected: ""}
        }
      })
      return newCatgrs
    })
    onSelection(category)
  }
  return (
    <>
      {newCategories.map((category, indx) => {
        return (
          <button
            key= {category._id}
            className={category.selected}
            onClick={() => onCategorySelect(category)}>{category.categoryName}</button>
        )
      })}
    </>
  )
}
