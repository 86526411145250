import React, { useEffect, useState } from 'react'
import './AdminPages.css'
import { useDispatch, useSelector } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';
import { deleteUser, listUsers } from '../../actions/userActions'

import Message from '../../components/Message/Message'
import Table from '../../components/Table/Table'
import ButtonLink from '../../components/ButtonLink/ButtonLink'
import axios from 'axios'

const userColumns = [
  { accessor: 'rownum', label: '#' },
  { accessor: 'username', label: 'Name' },
  { accessor: 'email', label: 'Email' },
  { accessor: 'isEnrolledInCourse', label: 'CourseEnroll', format: value => (value ? '✔️' : '✖️') },
]

const authorizedUserColumns = [
  { accessor: 'username', label: 'Name' },
  { accessor: 'email', label: 'Email' },
  { accessor: 'isMentor', label: 'Mentor', format: value => (value ? '✔️' : '✖️') },
  { accessor: 'isApprovedMentor', label: 'A.Mentor', format: value => (value ? '✔️' : '✖️') },
  { accessor: 'isInstructor', label: 'Instructor', format: value => (value ? '✔️' : '✖️') },
  { accessor: 'isApprovedInstructor', label: 'A.Instructor', format: value => (value ? '✔️' : '✖️') },
  { accessor: 'isFounder', label: 'Founder', format: value => (value ? '✔️' : '✖️') },
  { accessor: 'isApprovedFounder', label: 'A.Founder', format: value => (value ? '✔️' : '✖️') },
  { accessor: 'isAdmin', label: 'Admin', format: value => (value ? '✔️' : '✖️') },
]

const UserListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userList = useSelector((state) => state.userList)
  const { loading, error: userListError, users: usersList } = userList

  const [tableRows, setTableRows] = useState([])
  const [rowsNumPerPage, setRowsNumPerPage] = useState(15)

  const [partnerTableRows, setPartnerTableRows] = useState([])
  const [rowsNumPerPage2, setRowsNumPerPage2] = useState(15)

  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })

  const rowDataFormation = (data) => {
    const filteredData = data.filter(row => !row.isMentor && !row.isInstructor && !row.isFounder && !row.isAdmin)
    const reArrangedData = filteredData.map((row, indx) => {
      const isEnrolledInCourse = row?.enrolledCourses ? row.enrolledCourses.length !== 0 : false
      const tableRow = {
        _id: row._id,
        rownum: indx,
        username: row.name,
        email: row.email,
        isEnrolledInCourse: isEnrolledInCourse,
      }
      return tableRow
    })
    return reArrangedData
  }
  const rowDataFormation2 = (data) => {
    const filteredData = data.filter(row => row.isMentor || row.isInstructor || row.isFounder || row.isAdmin)
    const reArrangedData = filteredData.map((row, indx) => {
      const tableRow = {
        _id: row._id,
        username: row.name,
        email: row.email,
        isMentor: row.isMentor,
        isApprovedMentor: row.isApprovedMentor,
        isInstructor: row.isInstructor,
        isApprovedInstructor: row.isApprovedInstructor,
        isFounder: row.isFounder,
        isApprovedFounder: row.isApprovedFounder,
        isAdmin: row.isAdmin
      }
      return tableRow
    })
    return reArrangedData
  }

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDelete = useSelector((state) => state.userDelete)
  const { success: successDelete, error: deleteError } = userDelete

  useEffect(() => {
    if (deleteError) {
      setMessage({
        msgType: "message-error",
        messageBody: deleteError
      })
      return
    }
    if (userInfo?.isAdmin) {
      dispatch(listUsers())
    } else {
      history.push('/signin')
    }
  }, [dispatch, history, userInfo, successDelete, deleteError])

  useEffect(() => {
    if (userListError) {
      setMessage({
        msgType: "message-error",
        messageBody: userListError
      })
      return
    }
    if (usersList) {
      const _tableRows = rowDataFormation(usersList)
      const _tableRows2 = rowDataFormation2(usersList)
      setTableRows(_tableRows)
      setPartnerTableRows(_tableRows2)
    }
  }, [usersList, userListError])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteUser(id))
    }
  }

  const migrationHandler = async (courseId = "", listId = "") => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      },
    }
    try {
      if (courseId && listId) {
        const { data } = await axios.post(`/api/users/migrate/${courseId}`, { listId, payload: "migrate contacts" }, config)
        toast.success(data.message || "success")
      } else {
        const { data } = await axios.post(`/api/users/migrate-contacts`, { payload: "migrate contacts" }, config)
        toast.success(data.message || "success")
      }
    } catch (error) {
      toast.error("calling api failed")
    }
  }

  return (
    <>
      <div className="userdetails-editor">
        <div className="container">
          <h4 className="common-heading"> List All Users </h4>
          <div className="useredit-content">
            <div className="row align-items-center mb-4">
              <div className='col-3'>
                <label> Select Num of Rows Per Page </label>
                <select name="pageRows" className="form-select course_input" onChange={(event) => { setRowsNumPerPage(parseInt(event.target.value)) }}>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <Table
              columns={userColumns}
              rows={tableRows}
              rowsPerPage={rowsNumPerPage}
              delteActionEnabled={true}
              editActionEnabled={true}
              deleteHandler={deleteHandler}
              editHandler={(userId) => { history.push(`/admin/user/${userId}/edit`) }}
            />
          </div>
          <h4 className="common-heading mt-5"> List Authorized users </h4>
          <div className="useredit-content">
            <div className="row align-items-center mb-4">
              <div className='col-3'>
                <label> Select Num of Rows Per Page </label>
                <select name="pageRows" className="form-select course_input" onChange={(event) => { setRowsNumPerPage2(parseInt(event.target.value)) }}>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <Table
              columns={authorizedUserColumns}
              rows={partnerTableRows}
              rowsPerPage={rowsNumPerPage2}
              delteActionEnabled={true}
              editActionEnabled={true}
              deleteHandler={deleteHandler}
              editHandler={(userId) => { history.push(`/admin/user/${userId}/edit`) }}
            />
          </div>
          <div className='buttons_wrapper'>
            <ButtonLink ClassName="learn-more-btn confirm fit-content">
              <button style={{ margin: 'auto' }} onClick={() => migrationHandler()}>Migrate all students</button>
            </ButtonLink>
            <ButtonLink ClassName="learn-more-btn confirm fit-content">
              <button style={{ margin: 'auto' }} onClick={() => migrationHandler("61cb6f19945f650016f30b38", "18")}>Migrate React Course</button>
            </ButtonLink>
            <ButtonLink ClassName="learn-more-btn confirm fit-content">
              <button style={{ margin: 'auto' }} onClick={() => migrationHandler("616fbd7f421d524c349d2fa4", "19")}>Migrate MERN Course</button>
            </ButtonLink>
          </div>
        </div>
        {message.messageBody && <Message ClassName={message.msgType}>{message.messageBody}</Message>}
        <Toaster
          position="bottom-center"
          reverseOrder={false}
          toastOptions={{
            success: {
              duration: 5000
            },
            error: {
              duration: 5000
            }
          }}
        />
      </div>
    </>
  )
}

export default UserListScreen
