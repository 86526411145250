export const JOB_CREATE_SUCCESS = 'JOB_CREATE_SUCCESS'
export const JOB_CREATE_FAIL = 'JOB_CREATE_FAIL'
export const JOB_CREATE_RESET = 'JOB_CREATE_RESET'

export const JOB_UPDATE_SUCCESS = 'JOB_UPDATE_SUCCESS'
export const JOB_UPDATE_FAIL = 'JOB_UPDATE_FAIL'
export const JOB_UPDATE_RESET = 'JOB_UPDATE_RESET'

export const JOB_DETAILS_SUCCESS = 'JOB_DETAILS_SUCCESS'
export const JOB_DETAILS_FAIL = 'JOB_DETAILS_FAIL'
export const JOB_DETAILS_RESET = 'JOB_DETAILS_RESET'

export const JOB_LIST_LOADING = 'JOB_LIST_LOADING'
export const JOB_LIST_SUCCESS = 'JOB_LIST_SUCCESS'
export const JOB_LIST_FAIL = 'JOB_LIST_FAIL'

export const MENTOR_JOB_LIST_LOADING = 'MENTOR_JOB_LIST_LOADING'
export const MENTOR_JOB_LIST_SUCCESS = 'MENTOR_JOB_LIST_SUCCESS'
export const MENTOR_JOB_LIST_FAIL = 'MENTOR_JOB_LIST_FAIL'

export const JOB_DELETE_SUCCESS = 'JOB_DELETE_SUCCESS'
export const JOB_DELETE_FAIL = 'JOB_DELETE_FAIL'
export const JOB_DELETE_RESET = 'JOB_DELETE_RESET'

export const JOB_APPLICANT_APPLY_SUCCESS = 'JOB_APPLICANT_APPLY_SUCCESS'
export const JOB_APPLICANT_APPLY_FAIL = 'JOB_APPLICANT_APPLY_FAIL'
export const JOB_APPLICANT_APPLY_RESET = 'JOB_APPLICANT_APPLY_RESET'

export const JOB_ACTIONON_APPLICANT_SUCCESS = 'JOB_ACTIONON_APPLICANT_SUCCESS'
export const JOB_ACTIONON_APPLICANT_FAIL = 'JOB_ACTIONON_APPLICANT_FAIL'
export const JOB_ACTIONON_APPLICANT_RESET = 'JOB_ACTIONON_APPLICANT_RESET'