import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { applyToJob } from '../../../actions/jobActions'
import ButtonLink from '../../../components/ButtonLink/ButtonLink'
import Message from '../../../components/Message/Message'
import { JOB_APPLICANT_APPLY_RESET } from '../../../constants/jobConstants'

import cancelIcon from '../../../images/general-purpose/cancelIcon.png'
import axios from 'axios'
export default function JobForm({ closeModal, title, userInfo, jobID }) {
  const dispatch = useDispatch()
  const [registerForm, setRegisterForm] = useState({
    userFullName: userInfo.name,
    linkedinURL: userInfo.linkedinURL ? userInfo.linkedinURL : "",
    email: userInfo.email ? userInfo.email : "",
    phone: userInfo.phone ? userInfo.phone : "",
    motivation: "",
    pdfURL: "",
    pdfName: "",
    approveStatus: false
  })
  const [countryOfResidence, setCountryOfResidence] = useState(userInfo.countryOfResidence ? userInfo.countryOfResidence : "")
  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })
  const options = useMemo(() => countryList().getData(), [])

  const jobApply = useSelector((state) => state.jobApply)
  const { applySuccess, applyError } = jobApply


  const handleChange = (event) => {
    const { name, value } = event.target
    setRegisterForm((prevEntries) => {
      return {
        ...prevEntries,
        [name]: value
      }
    })
  }

  useEffect(() => {
    if (applyError) {
      setMessage({
        msgType: "error",
        messageBody: applyError
      })
      setTimeout(() => {
        dispatch({ type: JOB_APPLICANT_APPLY_RESET })
        setMessage({
          msgType: "",
          messageBody: ""
        })
        closeModal()
      }, 4000);
      return
    }
    if (applySuccess) {
      setMessage({
        msgType: "success",
        messageBody: "Your application sent successfully"
      })
      setTimeout(() => {
        dispatch({ type: JOB_APPLICANT_APPLY_RESET })
        setMessage({
          msgType: "",
          messageBody: ""
        })
        closeModal()
      }, 4000);
    }
  }, [applySuccess, applyError, closeModal, dispatch])

  const handleCountryChange = (info) => {
    setCountryOfResidence(info)
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${userInfo.token}`,
    },
  }

  const uploadPhotoToDB = async (_file) => {
    const formData = new FormData()
    formData.append('file', _file)
    try {
      const { data } = await axios.put(`/api/uploadfiles/uploadcv`, formData, config)
      setRegisterForm((prevEntries) => {
        return {
          ...prevEntries,
          pdfURL: data.pdfURL,
        }
      })
    } catch (error) {
      console.log("error: ", error)
      setMessage({
        msgType: "error",
        messageBody: error.response.data.message
      })
      // console.error(error)
    }
  }
  const onPhotoUpload = async (e) => {
    if (!userInfo.token) return

    setRegisterForm((prevEntries) => {
      return {
        ...prevEntries,
        pdfName: e.target.files[0].name
      }
    })
    await uploadPhotoToDB(e.target.files[0])
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!registerForm.userFullName) {
      setMessage({
        msgType: "error",
        messageBody: " Your name is missing"
      })
      return
    }
    if (!registerForm.linkedinURL) {
      setMessage({
        msgType: "error",
        messageBody: "Link to your Linkedin profile is missing"
      })
      return
    }
    if (!registerForm.email) {
      setMessage({
        msgType: "error",
        messageBody: "Your email address is missing"
      })
      return
    }
    if (!registerForm.phone) {
      setMessage({
        msgType: "error",
        messageBody: "Your phone number is missing"
      })
      return
    }
    if (!registerForm.motivation) {
      setMessage({
        msgType: "error",
        messageBody: "Your motivation statement is missing"
      })
      return
    }
    const submitForm = { ...registerForm, countryOfResidence }
    dispatch(applyToJob(submitForm, jobID))
  }

  return (
    <div className='job-form-container' onClick={e => e.stopPropagation()}>
      <div className="cancel-button">
        <div className="cancel-wrapper" onClick={closeModal}>
          <img src={cancelIcon} alt="How We Work" />
        </div>
      </div>
      <h3>{title}</h3>
      <div className="job-form">
        <div className="inputs-wrapper">
          <div className="half-width-cell">
            <label> Full Name </label>
            <input
              className="half-input job-input"
              type="text"
              name="userFullName"
              value={registerForm.userFullName}
              onChange={handleChange}
            />
          </div>
          <div className="half-width-cell">
            <label> Country of Residence </label>
            <Select
              className="profile-input no-padding half-input"
              options={options}
              name="countryOfResidence"
              value={countryOfResidence}
              onChange={handleCountryChange}
            />
          </div>
        </div>
        <label>Your portfolio (Linkedin or Behance or Dribbble)</label>
        <input
          className="job-input"
          type="text"
          name="linkedinURL"
          value={registerForm.linkedinURL}
          onChange={handleChange}
        />
        <div className="inputs-wrapper">
          <div className="half-width-cell">
            <label> Email Address </label>
            <input
              className="half-input job-input"
              type="email"
              name="email"
              value={registerForm.email}
              onChange={handleChange}
            />
          </div>
          <div className="half-width-cell">
            <label> Phone Number </label>
            <input
              className="half-input job-input"
              type="phone"
              name="phone"
              value={registerForm.phone}
              onChange={handleChange}
            />
          </div>
        </div>
        <label> Motivation  <span>({registerForm.motivation.length}/400)</span></label>
        <textarea
          className="text-input job-input"
          type="text"
          name="motivation"
          value={registerForm.motivation}
          maxLength="400"
          onChange={handleChange}
        />
        <div className="uploadfile">
          <label title="Image must be 'jpg' or 'jpeg' or 'png' and size must be less than 1 megabit" htmlFor="upload-photo">
            Upload Resume
          </label>
          <input
            id="upload-photo"
            type="file"
            className="form-control course_input no-height"
            placeholder="Upload Resume"
            onChange={onPhotoUpload}
          />

          {registerForm.pdfURL && <div className='pdfinfo'>
            <img
              className="pdficon"
              src="/pdficon.svg"
              alt="userLogo"
              id='drop_zone'
            />
            <b className='pdfname'>{registerForm.pdfName}</b>
          </div>}
        </div>
      </div>
      <ButtonLink ClassName="learn-more-btn full-width">
        <button onClick={handleSubmit}> Apply </button>
      </ButtonLink>
      {message.messageBody && <Message ClassName={message.msgType === "error" ? "message-error" : "message-success"}>{message.messageBody}</Message>}
    </div>
  )
}
