// reframing course sections to enable dragging and dropping******************
const createLessonsKeysArray = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map(k => (`lesson-${k + 1 + offset}`));
const createSectionsKeysArray = count =>
  Array.from({ length: count }, (v, k) => k).map(k => (`section-${k + 1}`));

export const reframeCourseSections = (CorsSections, callback) => {
  // create sections object
  let sections = {}
  let lessonsCounter = 0
  CorsSections.map((_section, indx) => {
    sections = {
      ...sections,
      [`section-${indx + 1}`]: {
        id: `section-${indx + 1}`,
        title: _section.title,
        lessons: createLessonsKeysArray(_section.lessons.length, lessonsCounter)
      }
    }
    lessonsCounter = lessonsCounter + _section.lessons.length
  })

  // create section order
  const sectionOrder = createSectionsKeysArray(CorsSections.length)
  // create object for all lessons in the course
  let lessons = {}
  let count = 0
  CorsSections.map((_section, indx) => {
    _section.lessons.map((_lesson, indx2) => {
      count++
      lessons = {
        ...lessons,
        [`lesson-${count}`]: {
          id: `lesson-${count}`,
          title: _lesson.title,
          hostingProvider: _lesson.hostingProvider ? _lesson.hostingProvider : "",
          uploadedVideoID: _lesson.uploadedVideoID ? _lesson.uploadedVideoID : "",
          uploadedVideoName: _lesson.uploadedVideoName ? _lesson.uploadedVideoName : "",
          uTubeVideoLink: _lesson.uTubeVideoLink ? _lesson.uTubeVideoLink : "",
          preview: _lesson.preview,
          text: _lesson.text,
          isvideo: _lesson.isvideo,
          URL: _lesson.URL,
          showtime: _lesson.showtime,
        }
      }
    })

  })
  callback({ lessons, sections, sectionOrder })
  // setAllSectionData({ lessons, sections, sectionOrder })
}

// Deframe Section to fits with schema in database
export const deframeCourseSections = (_sectionData) => {
  const originalData = _sectionData.sectionOrder.map((sectionID) => {
    // checking if section has lessons
    const originalSection = _sectionData.sections[sectionID]
    delete originalSection.id
    if (originalSection.lessons.length !== 0) {
      const sectionLessons = originalSection.lessons.map(lessonID => {
        const originalSectionLesson = _sectionData.lessons[lessonID]
        delete originalSectionLesson.id
        return originalSectionLesson
      })

      const _originSection = {
        ...originalSection,
        lessons: sectionLessons
      }
      return _originSection
    } else {
      return null
    }
  })
  const filteredData = originalData.filter(el => el !== null)

  return filteredData
}
// End of Refram and Deframe section******************

// function that takes minutes and convert to hours format
export const convertMinutes = (minuts) => {
  if (minuts >= 60) {
    const wholeTime = ((minuts / 60).toFixed(2)).toString()
    const hour = parseInt(wholeTime.split(".")[0])
    const minute = (parseInt(wholeTime.split(".")[1]))
    const minutes = (minute * 60 / 100).toFixed(0)
    return {
      timeHours: hour,
      timeMinutes: minutes
    }
  } else {
    return {
      timeHours: 0,
      timeMinutes: minuts
    }
  }
}

// email verifier
export const verifyEmail = (email) => {
  const lastAtPos = email.lastIndexOf('@');
  const lastDotPos = email.lastIndexOf('.');
  const verificationResult = (lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)
  // it must return a true value to say that email is valid
  return verificationResult
}