import React, { useEffect } from 'react'
import './ApplicationStripe.css'
import { useDispatch, useSelector } from 'react-redux';
import { actionOnMentorship } from '../../actions/pathActions';
// import userLogo from '../../images/userLogo.svg'
import linkedinIcon from '../../images/general-purpose/linkedinIcon.png'
import { actionOnJobApplication } from '../../actions/jobActions';

export default function ApplicationStripe({ application, index, topicID, applicationType, onActionResponse }) {
  const dispatch = useDispatch();

  const pathMentorsAction = useSelector((state) => state.pathMentorsAction)
  const { successMessage: pathMsg, error: errPathMsg } = pathMentorsAction
  const jobMentorsAction = useSelector((state) => state.jobMentorsAction)
  const { successMessage: jobMsg, error: errJobMsg } = jobMentorsAction

  useEffect(() => {
    if (errPathMsg || errJobMsg) {
      onActionResponse({
        msgType: "Error",
        messageBody: errPathMsg || errJobMsg,
        value: true
      })
      return
    }
    if (pathMsg || jobMsg) {
      onActionResponse({
        msgType: "Success",
        messageBody: pathMsg || jobMsg,
        value: true
      })
    }
  }, [dispatch, errPathMsg, errJobMsg, pathMsg, jobMsg, onActionResponse])

  const handleApproval = (applicantID, key, action) => {
    if (applicationType === "path") {
      dispatch(actionOnMentorship({ applicantID, key, action }, topicID))
    } else if (applicationType === "job") {
      dispatch(actionOnJobApplication({ applicantID, key, action }, topicID))
    }
  }
  return (
    <>
      <div className="application_stripe-header d-flex position-relative">
        <a
          href={application.linkedinURL}
          rel="noreferrer"
          target="_blank"
          title="check user linkedin profile"
          className="img-container me-4"
        >
          <img className="profile" src="/profileLogo_new.png" alt="userlogo" />
          <img className="linkedin" src={linkedinIcon} alt="icon img" />
        </a>
        <div className="text-start">
          <h6>{`${application.userFullName}`}</h6>
          <p>{application.email}</p>
          <p>{application?.phone}</p>
          <p>{application?.countryOfResidence?.label}</p>
        </div>
      </div>
      <div className="application_stripe-body">
        {application.motivation}
      </div>
      {application.pdfURL && (
        <a
          href={application.pdfURL}
          rel="noreferrer"
          target="_blank"
          title="check user linkedin profile"
          className="pdf-container me-4"
        >
          <img className="pdffile" src="/pdficon.svg" alt="Applicant CV" />
          <p className='pdfname'>{application.pdfName}</p>
        </a>
      )}
      {!application.approveStatus ? (
        <>
          <i
            onClick={() => handleApproval(application.applicant, index, "approve")}
            title={applicationType === "path" ? "Approve applicant for this track" : "Shortlist this applicant"}
            className="approve fas fa-check-circle"
          ></i>
          <i
            onClick={() => handleApproval(application.applicant, index, "refuse")}
            title="Delete this application"
            className="disapprove fas fa-minus-circle"
          ></i>
        </>
      ) : (
        <div className="state">
          {applicationType === "path" ? "Approved" : "Shortlisted"}
          <i
            title={applicationType === "path" ? "Disapprove Mentee" : "Remove from shortlist"}
            className="fas fa-minus-circle"
            onClick={() => handleApproval(application.applicant, index, "disapprove")}
          ></i>
        </div>
      )}
    </>
  )
}
