/*===  Import Link & NavLink === */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { changeLanguage } from "../../actions/languageAction";
import { logout } from "../../actions/userActions";
import logo from '../../images/rekgpt_logo.png'
import lang from '../../images/general-purpose/world.png'
import academy from '../../images/general-purpose/academy.png'
/*===  Css Custom File === */
import "./Header.css";

const Header = () => {
  const language = useSelector((state) => state.language);
  const { translation } = language;

  const dispatch = useDispatch();

  const [languageLabel, setLanguageLabel] = useState("En");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };
  const selectLanguage = (languageKey) => {
    // console.log('selected language is: ', languageKey)
    dispatch(changeLanguage(languageKey));
    switch (languageKey) {
      case "AR":
        return setLanguageLabel("Ar");
      case "EN":
        return setLanguageLabel("En");
      default:
        return setLanguageLabel("En");
    }
  };
  return (
    <nav className="navbar navbar-expand-md fixed-top">
      <div className="container-lg">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarBtn"
          aria-controls="navbarBtn"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars text-white"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarBtn">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink className="nav-link me-3 mb-2" to="/" exact>
                {translation.Home}
              </NavLink>
            </li>

            {/* 
            <li className="nav-item">
              <NavLink className="nav-link me-3 mb-2" to="/technopedia">
                {translation.ArabsTechnopedia}
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink className="nav-link me-3 mb-2" to="/learningPath">
                {translation.LearningPaths}
              </NavLink>
            </li> */}

            {/* Entrepreneur Dropdown */}

            {/* <li className="nav-item">
              <NavLink className="nav-link me-3 mb-2" to="/jobs">
                {translation.Jobs}
              </NavLink>
            </li> */}

            <li className="nav-item">
              <NavLink className="nav-link me-3 mb-2" to="/pricing">
                {translation.Plans}
              </NavLink>
            </li>

            {/* <li className="nav-item">
              <NavLink className="nav-link me-3 mb-2" to="/joinus">
                {translation.JoinUs}
              </NavLink>
            </li> */}

            {/* <li className="nav-item dropdown mb-2">
              <NavLink
                className="nav-link me-3 dropdown-toggle"
                to="/joinus"
                id="JoinusDropDownMenu"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {translation.JoinUs}
              </NavLink>
              <ul
                className="dropdown-menu mt-1"
                aria-labelledby="JoinusDropDownMenu"
              >
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="/joinus"
                    exact
                  >
                    {translation.JoinUs}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to="/aboutus">
                    {translation.AboutUs}
                  </NavLink>
                </li>
              </ul>
            </li> */}

            {/* <li className="nav-item">
              <NavLink className="nav-link me-3 mb-2" to="/aboutus">
                {translation.AboutUs}
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink className="nav-link me-3 mb-2" to="/joinus">
                {translation.JoinUs}
              </NavLink>
            </li> */}

            {/* Entrepreneur Dropdown */}
            <li className="nav-item dropdown mb-2">
              <NavLink
                className="nav-link me-3"
                to="/about"
                id="navbarDropdownMenuLink2"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span style={{ marginRight: "3px" }}>{languageLabel}</span> <img src={lang} alt="language" width={20} height={20} />
              </NavLink>
              <ul
                className="dropdown-menu mt-1"
                aria-labelledby="navbarDropdownMenuLink2"
              >
                <li onClick={() => selectLanguage("EN")}>
                  <div className="dropdown-item">English</div>
                </li>
                <li onClick={() => selectLanguage("AR")}>
                  <div className="dropdown-item">Arabic</div>
                </li>
              </ul>
            </li>
            {/* Language Dropdown */}

            <li className="nav-item dropdown mb-2">
              <NavLink
                className="nav-link me-3 dropdown-toggle"
                to="/technopedia"
                id="TechnopediaDropDownMenue"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={academy} alt="language" width={25} height={25} />
              </NavLink>
              <ul
                className="dropdown-menu mt-1"
                aria-labelledby="TechnopediaDropDownMenue"
              >
                <div className="tap_info"><p> Qualifying Academy for job Seekrs </p></div>

                <li>
                  <NavLink
                    className="dropdown-item"
                    to="/technopedia"
                    exact
                  >
                    {translation.ArabsTechnopedia}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to="/learningPath">
                    {translation.LearningPaths}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to="/joinus">
                    {translation.JoinUs}
                  </NavLink>
                </li>
              </ul>
            </li>

            {/* routes available for registered users */}
            {userInfo?._id && (
              <li className="nav-item dropdown mb-2">
                <NavLink
                  className="nav-link me-3 dropdown-toggle"
                  to="/profile"
                  id="navbarDropdownMenuLink3"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {userInfo.name}
                </NavLink>
                <ul
                  className="dropdown-menu mt-1"
                  aria-labelledby="navbarDropdownMenuLink3"
                >
                  <li>
                    <NavLink to="/profile" className="dropdown-item">
                      {translation.Profile}
                    </NavLink>
                  </li>
                  {userInfo?.enrolledPath &&
                    <li>
                      <NavLink to={`/mypath/${userInfo?.enrolledPath.toString()}`} className="dropdown-item">
                        {translation.MyLearnPath}
                      </NavLink>
                    </li>
                  }
                  <li>
                    <NavLink to="/mycourses" className="dropdown-item">
                      {translation.MyEnrolledCourses}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/cart" className="dropdown-item">
                      {translation.Cart}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/signin"
                      className="dropdown-item"
                      onClick={logoutHandler}
                    >
                      {translation.Logout}
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}
            {userInfo?._id && userInfo.isAdmin && (
              <li className="nav-item dropdown">
                <NavLink
                  to="#"
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  title="Services"
                >
                  Admin
                </NavLink>
                <ul
                  className="dropdown-menu mt-1"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <NavLink to="/admin/userlist" className="dropdown-item">
                      All Users
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/listallgrants" className="dropdown-item">
                      All Grants
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/listallcategories" className="dropdown-item">
                      All Categories
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}
          </ul>
          {/* Sign in Button */}
          {!userInfo?._id && (
            <Link to="/signin" className="custom-nav-btn">
              {translation.SignIn}
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
