import { FOUNDER_PARTNERSHIP_LIST_FAIL, FOUNDER_PARTNERSHIP_LIST_LOADING, FOUNDER_PARTNERSHIP_LIST_SUCCESS, PARTNERSHIP_ACTIONON_APPLICANT_FAIL, PARTNERSHIP_ACTIONON_APPLICANT_RESET, PARTNERSHIP_ACTIONON_APPLICANT_SUCCESS, PARTNERSHIP_APPLICANT_APPLY_FAIL, PARTNERSHIP_APPLICANT_APPLY_RESET, PARTNERSHIP_APPLICANT_APPLY_SUCCESS, PARTNERSHIP_CREATE_FAIL, PARTNERSHIP_CREATE_RESET, PARTNERSHIP_CREATE_SUCCESS, PARTNERSHIP_DELETE_FAIL, PARTNERSHIP_DELETE_RESET, PARTNERSHIP_DELETE_SUCCESS, PARTNERSHIP_DETAILS_FAIL, PARTNERSHIP_DETAILS_LOADING, PARTNERSHIP_DETAILS_RESET, PARTNERSHIP_DETAILS_SUCCESS, PARTNERSHIP_LIST_FAIL, PARTNERSHIP_LIST_LOADING, PARTNERSHIP_LIST_SUCCESS, PARTNERSHIP_UPDATE_FAIL, PARTNERSHIP_UPDATE_RESET, PARTNERSHIP_UPDATE_SUCCESS } from "../constants/partnershipConstants"

export const partnershipListReducer = (state = { partnerships: [] }, action) => {
  switch (action.type) {
    case PARTNERSHIP_LIST_LOADING:
      return {loading: true, ...state }
    case PARTNERSHIP_LIST_SUCCESS:
      return {loading: false, error: null, partnerships: action.payload }
    case PARTNERSHIP_LIST_FAIL:
      return {loading: false, error: action.payload }
    default:
      return state
  }
}

export const founderPartnershipListReducer = (state = { founderPartnerships: [] }, action) => {
  switch (action.type) {
    case FOUNDER_PARTNERSHIP_LIST_LOADING:
      return {loading: true, ...state }
    case FOUNDER_PARTNERSHIP_LIST_SUCCESS:
      return {loading: false, error: null, founderPartnerships: action.payload }
    case FOUNDER_PARTNERSHIP_LIST_FAIL:
      return {loading: false,  error: action.payload }
    default:
      return state
  }
}

export const partnershipDetailsReducer = (state = { partnershipDetails: {} }, action) => {
  switch (action.type) {
    case PARTNERSHIP_DETAILS_LOADING:
      return {loading: true }
    case PARTNERSHIP_DETAILS_SUCCESS:
      return { partnershipdetails: action.payload }
    case PARTNERSHIP_DETAILS_FAIL:
      return { detailserror: action.payload }
    case PARTNERSHIP_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const partnershipUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNERSHIP_UPDATE_SUCCESS:
      return { updateSuccess: true }
    case PARTNERSHIP_UPDATE_FAIL:
      return { updateError: action.payload }
    case PARTNERSHIP_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const partnershipCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNERSHIP_CREATE_SUCCESS:
      return { createSuccess: true }
    case PARTNERSHIP_CREATE_FAIL:
      return { createError: action.payload }
    case PARTNERSHIP_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const partnershipDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNERSHIP_DELETE_SUCCESS:
      return { deleteSuccess: true }
    case PARTNERSHIP_DELETE_FAIL:
      return { deleteError: action.payload }
    case PARTNERSHIP_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const partnershipFoundersActionReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNERSHIP_ACTIONON_APPLICANT_SUCCESS:
      return { successMessage: action.payload, errorMessage: null }
    case PARTNERSHIP_ACTIONON_APPLICANT_FAIL:
      return { errorMessage: action.payload, successMessage:null }
    case PARTNERSHIP_ACTIONON_APPLICANT_RESET:
      return {successMessage: null, errorMessage: null}
    default:
      return state
  }
}

export const partnershipApplyReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNERSHIP_APPLICANT_APPLY_SUCCESS:
      return { applySuccess: true }
    case PARTNERSHIP_APPLICANT_APPLY_FAIL:
      return { applyError: action.payload }
    case PARTNERSHIP_APPLICANT_APPLY_RESET:
      return {}
    default:
      return state
  }
}