/*===  Import Tecno Steps Images === */
import img_1 from "../../../images/icons/techno1.png";
import img_2 from "../../../images/icons/techno2.png";
import img_3 from "../../../images/icons/techno3.png";

import { Link } from "react-router-dom";
import ButtonLink from "../../../components/ButtonLink/ButtonLink";
import { useSelector } from "react-redux";

const TechnoSteps = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin
  const language = useSelector((state) => state.language);
  const { translation } = language;

  return (
    <div className="how-works text-center" style={{ padding: "100px 0" }}>
      <div className="container-lg">
        <div className="row justify-content-evenly align-items-align-items-end">
          {/* Distinguish yourself */}
          <div className="col-md-4 col-lg-3">
            <div className="card py-4 mb-3">
              <img
                src={img_1}
                className="card-img-top m-auto"
                alt="How We Work"
              />
              <div className="card-body">
                <h5 className="card-title text-capitalize py-3">
                  {translation.ConsiderAScholarship}
                </h5>
                <p className="card-text">
                  {translation.ASEEUIsFoundedTo}
                </p>
                <ButtonLink ClassName="learn-more-link">
                  <Link to={userInfo?._id ? "/profile/scholarship" : "/signin?redirect=profile/scholarship"}>
                    {translation.LearnMore} <i className="fas fa-arrow-right ms-1"></i>
                  </Link>
                </ButtonLink>
              </div>
            </div>
          </div>
          {/* speed-up your learning */}
          <div className="col-md-4 col-lg-3">
            <div className="card py-4 mb-3">
              <img
                src={img_2}
                className="card-img-top m-auto"
                alt="How We Work"
              />
              <div className="card-body">
                <h5 className="card-title text-capitalize py-3">
                  {translation.MakeAnInvestment}
                </h5>
                <p className="card-text">
                  {translation.ThereAreDevotedInstructors}
                </p>
                <ButtonLink ClassName="learn-more-link">
                  <Link to="/joinus">
                    {translation.Invest} <i className="fas fa-arrow-right ms-1"></i>
                  </Link>
                </ButtonLink>
              </div>
            </div>
          </div>
          {/* Start your own business */}
          <div className="col-md-4 col-lg-3">
            <div className="card py-4 mb-3">
              <img
                src={img_3}
                className="card-img-top m-auto"
                alt="How We Work"
              />
              <div className="card-body">
                <h5 className="card-title text-capitalize py-3">
                  {translation.EducateWithUs}
                </h5>
                <p className="card-text">
                  {translation.ParticipateWithUsEducation}
                </p>
                <ButtonLink ClassName="learn-more-link">
                  <Link to={userInfo?._id ? "/profile/instructorboard" : "/signin?redirect=profile/instructorboard"}>
                    {translation.Educate} <i className="fas fa-arrow-right ms-1"></i>
                  </Link>
                </ButtonLink>
              </div>
            </div>
          </div>
          {/* End Cards */}
        </div>
      </div>
    </div>
  );
};

export default TechnoSteps;
