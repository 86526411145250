import React, { useEffect, useState } from 'react'
import draftToHtml from 'draftjs-to-html';
import cancelIcon from '../../images/general-purpose/cancelIcon.png'
import Message from '../../components/Message/Message';
import { useSelector } from 'react-redux';
import axios from 'axios';

export default function ExamScreen({ closeModal, examInstructions, certificateInfo }) {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`
    },
  }

  useEffect(() => {
    if (certificateInfo.completionStatus && certificateInfo.certificateID) {
      const msgTagElement = <p>Your anwer will be evaluated soon to get your certificate. Your Certificate id: <strong>{certificateInfo.certificateID}</strong></p>
      setMessage({
        msgType: "message-success mini-msg",
        messageBody: msgTagElement
      })
    } else {
      setMessage({
        msgType: "",
        messageBody: ""
      })
    }
  }, [certificateInfo])

  const orderTheCerticate = async () => {
    const { courseId, instructorId } = certificateInfo
    try {
      const { data: _cetificateInfo } = await axios.post(`/api/certificates/ordercertificate`, { courseId, instructorId }, config);
      const msgTagElement = <p>Your anwer will be evaluated soon to get your certificate. Your Certificate id: <strong>{_cetificateInfo.certificateID}</strong></p>
      setMessage({
        msgType: "message-success mini-msg",
        messageBody: msgTagElement
      })
    } catch (err) {
      setMessage({
        msgType: "message-error mini-msg",
        messageBody: "Instructor will evaluate your answer soon. please wait patiently"
      })
    }
  }

  const handleExamcompletion = (event) => {
    const { checked } = event.target
    if (checked) {
      orderTheCerticate()
    }
    console.log("exam completed: ", checked)
  }

  return (
    <div className='course-exam-modal' onClick={e => e.stopPropagation()}>
      <div className="general-cancel-button-modal">
        <div className="cancel-wrapper" onClick={closeModal}>
          <img src={cancelIcon} alt="How We Work" />
        </div>
      </div>
      <h3>Course Exam</h3>
      <div className="exam-wrapper">
        <div className="exam-info" dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(examInstructions)) }}>
        </div>
      </div>
      {(message?.msgType) ? (
        <Message ClassName={message.msgType}>{message.messageBody}</Message>
      ) : (
        <div className="form-check take-exam mt-3">
          <input
            id="completed-exam"
            className="form-check-input"
            name="completed-exam"
            type="checkbox"
            onChange={handleExamcompletion}
            checked={false}
          />
          <label className="form-check-label label-question">
            I Completed the course <span>(This box can be checked only once. Mark it only when you are ready to send the answer to Instructor)</span>
          </label>
        </div>
      )}
    </div>
  )
}
