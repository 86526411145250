import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { deleteJob, listMentorJobs } from '../../../actions/jobActions';
import { deletePath, listMentorPaths } from '../../../actions/pathActions';
import { updateUserProfile } from '../../../actions/userActions';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import JobStripe from '../../../components/JobStripe/JobStripe';
import LearnPathStripe from '../../../components/LearnPathStripe/LearnPathStripe';
import Message from '../../../components/Message/Message';
import Spinner from '../../../components/Spinner/Spinner';
import { JOB_ACTIONON_APPLICANT_RESET } from '../../../constants/jobConstants';
import { PATH_ACTIONON_APPLICANT_RESET } from '../../../constants/pathConstants';
import { USER_UPDATE_PROFILE_RESET } from '../../../constants/userConstants';
import history from '../../../history';
import Modal from '../../../screen/Modal/Modal';
import ConfirmModal from '../ModalScreens/ConfirmModal';
import EditInfoModal from '../ModalScreens/EditInfoModal';

export default function MentorPanel() {
  const dispatch = useDispatch()
  const { path: pathroot } = useRouteMatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState({
    itemKey: "",
    value: false
  })
  const [addModal, setAddModal] = useState({
    itemKey: "",
    value: false
  })
  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })
  // msgModal
  const [msgModal, setMsgModal] = useState({
    msgType: "",
    messageBody: "",
    value: false
  })

  const [itemID, setItemID] = useState("")
  const deleteTitle = "Delete"
  const deletePathBody = "Are you sure you want to delete this Learning Path? It will remove it permanently!"
  const deleteJobBody = "Are you sure you want to delete this Job? It will remove it permanently!"
  const addPathBody = "Pressing confirm will take you to new page where you can create your Learning Path"
  const addJobBody = "Pressing confirm will take you to new page where you can report an open vacancy"

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const {
    error: errorUpdate,
    success: successUpdate,
  } = userUpdateProfile

  const mentorPathList = useSelector((state) => state.mentorPathList)
  const { loading: PathsLoading, mentorPaths, error: pathlistError } = mentorPathList

  const mentorJobList = useSelector((state) => state.mentorJobList)
  const { loading: JobsLoading, mentorJobs, error: joblistError } = mentorJobList

  const pathDelete = useSelector((state) => state.pathDelete)
  const { deleteSuccess: pathDeleteSuccess, deleteError: pathDeleteError } = pathDelete

  const jobDelete = useSelector((state) => state.jobDelete)
  const { deleteSuccess: jobDeleteSuccess, deleteError: jobDeleteError } = jobDelete


  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }
  const openAddModal = (_key) => {
    setAddModal({
      itemKey: _key,
      value: true
    })
  }
  const closeAddModal = () => {
    setAddModal({
      value: false
    })
  }
  const openDeleteModal = (id, _key) => {
    setDeleteModal({
      itemKey: _key,
      value: true
    })
    setItemID(id)
  }
  const closeDeleteModal = () => {
    setDeleteModal({
      value: false
    })
  }

  const createItemHandler = (_key) => {
    if (_key === "path") {
      history.push('/mentor/path/createnew')
    } else if (_key === "job") {
      history.push('/mentor/job/createnew')
    }
  }

  const deleteHandler = (_key) => {
    if (_key === "path") {
      dispatch(deletePath(itemID))
    } else if (_key === "job") {
      dispatch(deleteJob(itemID))
    }
    setDeleteModal(false)
  }

  // const onActionResponse = (msg) => {
  //   setMsgModal(msg)
  // }
  const onActionResponse = useCallback(
    (msg) => {
      setMsgModal(msg);
    },
    [],
  );
  // closeMsgModal
  const closeMsgModal = () => {
    setMsgModal({
      value: false
    })
    dispatch({ type: JOB_ACTIONON_APPLICANT_RESET })
    dispatch({ type: PATH_ACTIONON_APPLICANT_RESET })
  }

  // Basic Information update action and response**************
  useEffect(() => {
    if (!userInfo?._id) {
      setTimeout(() => {
        history.push(`/signin?redirect=${pathroot}`)
      }, 500);
    } else {
      if (errorUpdate) {
        setMessage({
          msgType: "error",
          messageBody: errorUpdate
        })
        return
      }
      if (successUpdate) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        setMessage({
          msgType: "success",
          messageBody: "Information Updated successfully"
        })
      }
    }
  }, [userInfo, dispatch, errorUpdate, successUpdate, pathroot])

  // fetch Learning paths once Page mounts
  useEffect(() => {
    dispatch(listMentorPaths())
    dispatch(listMentorJobs())
    if (pathDeleteError || jobDeleteError) {
      setMessage({
        msgType: "error",
        messageBody: pathDeleteError || jobDeleteError
      })
    }
  }, [dispatch, pathDeleteSuccess, pathDeleteError, jobDeleteSuccess, jobDeleteError, msgModal])

  const onUpdateConfirm = (_mentorDetails) => {
    dispatch(updateUserProfile(_mentorDetails))
  }
  return (
    <div className="mentor-panel">
      {(PathsLoading || JobsLoading) && <Spinner />}
      {(pathlistError || joblistError) && <Message ClassName="message-error">{pathlistError || joblistError}</Message>}
      {!PathsLoading && !JobsLoading && !pathlistError && !joblistError && (
        <>
          <div className="panel-information">
            <div className="title-bar-profile">
              <div className="sction-title">Information</div>
              <div title="Edit your Basic Information" className="user-action" onClick={openModal}><i className="fas fa-edit"></i>EDIT</div>
            </div>
            <div className="info-body">
              <p>Profession</p>
              <h6>{userInfo.mentorDetails.mentorProfession}</h6>
            </div>
            <div className="info-body">
              <p>About Me in short words</p>
              <h6>{userInfo.mentorDetails.mentorIntroduction}</h6>
            </div>
            {message.messageBody && <Message ClassName={message.msgType === "success" ? "message-success" : "message-error"}>{message.messageBody}</Message>}
          </div>
          {/* LearnPaths************** */}
          <div className="paths-control">
            <div className="title-bar-profile">
              <div className="sction-title">Learning Paths</div>
              <div
                title="Create a new Learning Path"
                className="user-action"
                onClick={() => openAddModal("path")}
              >
                <i className="fas fa-plus"></i>
                ADD
              </div>
            </div>
            <div className="learnpath-stripes-container">
              {(mentorPaths.length !== 0) && (
                mentorPaths.map((_path, _indx) => (
                  <LearnPathStripe
                    key={_indx}
                    indx={_indx}
                    path={_path}
                    removeHandler={(id) => openDeleteModal(id, "path")}
                    onActionResponse={onActionResponse}
                  />
                ))
              )}
            </div>
          </div>
          {/* Vacancies**************** */}
          <div className="jobs-control">
            <div className="title-bar-profile">
              <div className="sction-title">Vacancies</div>
              <div
                title="Create a new Job"
                className="user-action"
                onClick={() => openAddModal("job")}
              >
                <i className="fas fa-plus"></i>
                ADD
              </div>
            </div>
            <div className="job-stripes-container">
              {(mentorJobs.length !== 0) && (
                mentorJobs.map((_job, _indx) => (
                  <JobStripe
                    key={_indx}
                    indx={_indx}
                    job={_job}
                    removeHandler={(id) => openDeleteModal(id, "job")}
                    onActionResponse={onActionResponse}
                  />
                ))
              )}
            </div>
          </div>
        </>
      )}
      {modalOpen && (
        <Modal closeModal={closeModal}>
          <EditInfoModal
            closeModal={closeModal}
            title="About Job Advertiser"
            userType="mentor"
            details={userInfo.mentorDetails}
            onUpdateConfirm={onUpdateConfirm}
          />
        </Modal>
      )}
      {addModal.value && (
        <Modal closeModal={closeAddModal}>
          <ConfirmModal
            title={addModal.itemKey === "path" ? "Add Learning Path" : "Add Vacancy"}
            body={addModal.itemKey === "path" ? addPathBody : addJobBody}
            closeModal={closeAddModal}
          >
            <ButtonLink ClassName="learn-more-btn cancel float-left half-width">
              <button onClick={closeAddModal}> Cancel </button>
            </ButtonLink>
            <ButtonLink ClassName="learn-more-btn confirm float-right half-width">
              <button onClick={() => createItemHandler(addModal.itemKey)}> Confirm </button>
            </ButtonLink>
          </ConfirmModal>
        </Modal>
      )}
      {deleteModal.value && (
        <Modal closeModal={() => closeDeleteModal()}>
          <ConfirmModal
            closeModal={() => closeDeleteModal()}
            title={deleteTitle}
            body={deleteModal.itemKey === "path" ? deletePathBody : deleteJobBody}
          >
            <ButtonLink ClassName="learn-more-btn confirm float-left half-width">
              <button onClick={() => closeDeleteModal()}> Cancel </button>
            </ButtonLink>
            <ButtonLink ClassName="learn-more-btn cancel float-right half-width">
              <button onClick={() => deleteHandler(deleteModal.itemKey)}> Delete </button>
            </ButtonLink>
          </ConfirmModal>
        </Modal>
      )}
      {msgModal.value && (
        <Modal closeModal={() => closeMsgModal()}>
          <ConfirmModal
            closeModal={() => closeMsgModal()}
            title={msgModal.msgType}
            body={msgModal.messageBody}
          >
          </ConfirmModal>
        </Modal>
      )}
    </div>
  )
}
