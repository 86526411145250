import React from 'react'
import ButtonLink from '../ButtonLink/ButtonLink'
import './Pagination.css'

export default function Pagination({ activePage, count, rowsPerPage, totalPages, setActivePage }) {
  const beginning = activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1
  const end = activePage === totalPages ? count : beginning + rowsPerPage - 1

  return (
    <>
      <div className="pagination">
        <ButtonLink ClassName="socialmedia-button pagenumbers">
          <button
            className={`page-item ${activePage === 1 ? "disabled-element" : ""}`}
            disabled={activePage === 1}
            onClick={() => setActivePage(1)}
          >
            ⏮️ First
          </button>
        </ButtonLink>

        <ButtonLink ClassName="socialmedia-button pagenumbers">
          <button
            className={`page-item ${activePage === 1 ? "disabled-element" : ""}`}
            disabled={activePage === 1}
            onClick={() => setActivePage(activePage - 1)}
          >
            ⬅️ Previous
          </button>
        </ButtonLink>

        <ButtonLink ClassName="socialmedia-button pagenumbers">
          <button
            className={`page-item ${activePage === totalPages ? "disabled-element" : ""}`}
            disabled={activePage === totalPages}
            onClick={() => setActivePage(activePage + 1)}
          >
            Next ➡️
          </button>
        </ButtonLink>

        <ButtonLink ClassName="socialmedia-button pagenumbers">
          <button
            className={`page-item ${activePage === totalPages ? "disabled-element" : ""}`}
            disabled={activePage === totalPages}
            onClick={() => setActivePage(totalPages)}
          >
            Last ⏭️
          </button>
        </ButtonLink>

      </div>
      <p>
        Page {activePage} of {totalPages}
      </p>
      <p>
        Rows: {beginning === end ? end : `${beginning} - ${end}`} of {count}
      </p>
    </>
  )
}
