/* === Import Custom Hook To Change Page Title === */
import React from 'react'
import { useDocTitle } from "../../hooks/ChangePageTitle";
import "./Jobs.css"
/* === Import Jobs Sections === */
import DetailsJobs from "../../screen/JobsSections/DetailsJobs/DetailsJobs";

export default function Jobs() {
  /* === Page Title === */
  useDocTitle("RekGPT | Jobs")

  return (
    <>
      <div className="welcome-section">
        <h2 className="h1 text-center text-white text-capitalize">
          Open jobs available at our <span>partner firms</span>
        </h2>
      </div>
      <DetailsJobs filteredValues={{}} />
    </>
  );
};
