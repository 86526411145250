import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { ListPathDetails, updateLearnPath } from '../../actions/pathActions'
import ButtonLink from '../../components/ButtonLink/ButtonLink'
import Message from '../../components/Message/Message'
import { PATH_DETAILS_RESET, PATH_UPDATE_RESET } from '../../constants/pathConstants'
import history from '../../history'
import './MentorPath.css'

export default function UpdatePath({ match }) {
  const pathId = match.params.id
  // console.log("pathId: ", pathId)
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const pathDetails = useSelector((state) => state.pathDetails)
  const { pathdetails, detailserror } = pathDetails
  const pathUpdate = useSelector((state) => state.pathUpdate)
  const { updateSuccess, updateError } = pathUpdate

  const categoriesList = useSelector((state) => state.categoriesList)
  const { categories } = categoriesList
  const pathCategories = categories.filter(category => category.categoryType === 'general')
  const pathTitles = categories.filter(category => category.categoryType === 'special')

  // Form values start *******************************************
  const [pathForm, setPathForm] = useState({
    pathName: "",
    keySkill: "",
    jobDemand: "",
    startEasy: "",
    language: "",
    category: "",
    publicPublished: false
  })
  const [skillsArray, setSkillsArray] = useState([])
  // Form values end *******************************************

  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })

  // const editorContent = EditorState.createEmpty();

  const [editorState, setEditorState] = useState({ editorState: "" });

  //change local state of editor
  const handleEditorChange = (editorState) => {
    setEditorState({ editorState });
  }


  useEffect(() => {
    if (detailserror) {
      setMessage({
        msgType: "error",
        messageBody: detailserror
      })
      return
    }
    if (!pathdetails?._id) {
      dispatch(ListPathDetails(pathId))
    } else {
      setPathForm({
        pathName: pathdetails.pathName,
        jobDemand: pathdetails.jobDemand,
        startEasy: pathdetails.startEasy,
        language: pathdetails.language || "",
        category: pathdetails.category || "",
        publicPublished: pathdetails.publicPublished || false
      })
      setSkillsArray(pathdetails.keySkills)
      setEditorState({ editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(pathdetails.article))) })
    }
  }, [dispatch, pathId, pathdetails, detailserror])

  useEffect(() => {
    if (!userInfo?.isApprovedMentor && !userInfo?.isMentor) {
      history.push('/')
    }
    if (updateError) {
      setMessage({
        msgType: "error",
        messageBody: updateError
      })
      return
    }
    if (updateSuccess) {
      setMessage({
        msgType: "success",
        messageBody: "Your Learn path is updated successfully, you will be redirected to your board.."
      })
      setTimeout(() => {
        history.push("/profile/companyboard")
        dispatch({ type: PATH_UPDATE_RESET })
        dispatch({ type: PATH_DETAILS_RESET })
      }, 5000);
    }
  }, [updateSuccess, updateError, dispatch, userInfo])

  const handleChange = (event) => {
    const { name, value } = event.target
    setPathForm((prevEntries) => {
      return {
        ...prevEntries,
        [name]: value
      }
    })
  }

  const handleSkillRecord = (event) => {
    if (event.key === 'Enter') {
      setSkillsArray(prevSkills => {
        return [...prevSkills, pathForm.keySkill]
      })
      setPathForm((prevEntries) => {
        return {
          ...prevEntries,
          keySkill: ""
        }
      })
    }
  }
  const removeSkill = (indx) => {
    // console.log(categories)
    setSkillsArray((prevSections) => {
      return prevSections.filter((skill, id) => { return id !== indx })
    })
  }

  const cancelUpdate = (e) => {
    e.preventDefault()
    history.push("/profile/companyboard")
    dispatch({ type: PATH_DETAILS_RESET })
  }

  const updatePathHandler = (keyword) => {
    const article = JSON.stringify(convertToRaw(editorState.editorState.getCurrentContent()))
    if (!pathForm.pathName) {
      setMessage({
        msgType: "error",
        messageBody: "Learning Path name is missing"
      })
      return
    }
    if (!article) {
      setMessage({
        msgType: "error",
        messageBody: "Article of this learn path is missing"
      })
      return
    }
    if (skillsArray.length === 0) {
      setMessage({
        msgType: "error",
        messageBody: "Key skills of this learn path are missing"
      })
      return
    }
    delete pathForm.keySkill
    let _pathForm = {}
    if (keyword === "publish") {
      _pathForm = {
        ...pathForm,
        article,
        keySkills: skillsArray,
        publicPublished: true,
        _id: pathId
      }
    } else {
      _pathForm = {
        ...pathForm,
        article,
        keySkills: skillsArray,
        _id: pathId
      }
    }

    dispatch(updateLearnPath(_pathForm))
    // console.log("pathForm: ", _pathForm)
  }

  return (
    <div className="learnpaths-editor">
      <div className="container">
        <h4 className="common-heading"> Update A Learning Path</h4>
        <div className="path-content">
          {/* <h3>Add Learning Path</h3> */}
          <div className="path-form">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-6 col-sm-12">
                <label> Learning Path Name </label>
                {/* <input
                  className="path_input"
                  type="text"
                  name="pathName"
                  value={pathForm.pathName}
                  onChange={handleChange}
                /> */}
                <select name="pathName" className="form-select job_input" onChange={handleChange}>
                  <option key={'original'} value={pathForm.pathName}>{pathForm.pathName} </option>
                  {pathTitles.map(title => (
                    <option key={title.categoryName} value={title.categoryName}>{title.categoryName}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-3 col-sm-12">
                <label>Article Language</label>
                <select name="language" className="form-select path_input" onChange={handleChange}>
                  <option value={pathForm.language}> {pathForm.language} </option>
                  <option value="English">English</option>
                  <option value="Arabic">Arabic</option>
                </select>
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-md-4 col-sm-12">
                <label>Job Category</label>
                <select name="category" className="form-select job_input" onChange={handleChange}>
                  <option key="orginal2" value={pathForm?.category}> {pathForm?.category} </option>
                  {pathCategories.map(category => (
                    <option key={category.categoryName} value={category.categoryName}>{category.categoryName}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-md-4 col-sm-12">
                <label>Enter Key Skills </label>
                <input
                  className="path_input"
                  type="text"
                  name="keySkill"
                  value={pathForm.keySkill}
                  onChange={handleChange}
                  onKeyDown={handleSkillRecord}
                />
              </div>
              <div className="col-md-7 col-sm-12">
                <div className="row-tags skills">
                  {skillsArray.map((skill, indx) => {
                    return (
                      <p key={indx} className="mt-4">{skill} <i className="fas fa-minus-circle" onClick={() => removeSkill(indx)}></i></p>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-12 article-body">
                <h3>Learn Path Article </h3>
                <Editor
                  editorState={editorState.editorState}
                  onEditorStateChange={handleEditorChange}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                />
              </div>
            </div>
            <div className="row justify-content-center align-items-center noborder">
              <div className="col-md-4 col-sm-12">
                <label className="styled-help-info">Probability of catching job in this track <span>(optional)</span>
                  <i className="far fa-question-circle">
                    <span>Note!: This is just an estimation based on mentor expertise in the field. So, this estimation don't give an absolute guarantee that mentee will get a job in the field </span>
                  </i>
                </label>
                <div className="input-group">
                  <input
                    className="form-control path_input nowidth"
                    type="text"
                    name="jobDemand"
                    value={pathForm.jobDemand}
                    onChange={handleChange}
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              <div className="col-md-8 col-sm-12">
                <label className="styled-help-info">Specify level of Easiness to start up a business in the field <span>(optional)</span>
                  <i className="far fa-question-circle">
                    <span>Note!: This is just an estimation based on mentor expertise in the field. This estimation depends on some factors such as amount of fund needed to startup, sponsorship availability and features of the business in this field </span>
                  </i>
                </label>
                <div className="input-group">
                  <input
                    className="form-control path_input nowidth"
                    type="text"
                    name="startEasy"
                    value={pathForm.startEasy}
                    onChange={handleChange}
                  />
                  <span className="input-group-text">%</span>
                </div>

              </div>
            </div>
            <div className="row justify-content-between align-items-center actions-section noborder">
              <div className="col-md-7 col-sm-12">
                <ButtonLink ClassName="learn-more-btn mb-3 cancel">
                  <button onClick={cancelUpdate}>CANCEL</button>
                </ButtonLink>
              </div>
              <div className="col-md-2 col-sm-12">
                <ButtonLink ClassName="learn-more-btn mb-3 confirm">
                  <button onClick={() => updatePathHandler("update")}>UPDATE</button>
                </ButtonLink>
              </div>
              {!pathForm.publicPublished && (
                <div className="col-md-2 col-sm-12">
                  <ButtonLink ClassName="learn-more-btn mb-3 confirm">
                    <button onClick={() => updatePathHandler("publish")}>PUBLISH</button>
                  </ButtonLink>
                </div>
              )}
            </div>
          </div>
          {message.messageBody && <Message ClassName={message.msgType === "error" ? "message-error" : "message-success"}>{message.messageBody}</Message>}
        </div>
      </div>
    </div>
  )
}
