/* === Import Custom Hook To Change Page Title === */
import React, { useState } from 'react'
import { useDocTitle } from "../../hooks/ChangePageTitle";
// import FilterPaths from "../../screen/LearnPathSections/FilterPaths/FilterPaths";
import PathDetails from "../../screen/LearnPathSections/PathDetails/PathDetails";
import pathModel from '../../images/learnPathModel.png'
import './LearningPath.css'
import "./PathDetails.css";
import { useSelector } from 'react-redux';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

export default function LearningPath() {
  /* === Page Title === */
  useDocTitle("RekGPT | Learning Path")
  const categoriesList = useSelector((state) => state.categoriesList)
  const { categories } = categoriesList
  const pathCategories = categories.filter(category => category.categoryType === 'general')
  const pathTitles = categories.filter(category => category.categoryType === 'special')

  const [form, setForm] = useState({
    pathName: "",
    category: ""
  })
  const [_filteredPath, setFilteredPath] = useState({})


  const handleChange = (event) => {
    const { name, value } = event.target
    setForm((prevEntry) => {
      return {
        ...prevEntry,
        [name]: value
      }
    })
  }
  const handleFiltering = (event) => {
    event.preventDefault()
    setFilteredPath(form)
  }
  const handleShowingAll = (event) => {
    event.preventDefault()
    setFilteredPath({})
  }

  return (
    <>
      <div className="welcome-section">
        <h2 className="h1 text-center text-white text-capitalize">
          move beyond the limitation of <span>E-Learning</span>
        </h2>
      </div>
      {/* <FilterPaths /> */}
      <div className="container-lg">
        <div className="links-section search-section">
          {/* Start Form */}
          <form className="row gy-2">
            {/* <div className="col-lg-4">
              <div className="input-group">
                <div className="input-group-text">
                  <i className="fas fa-search"></i>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
              </div>
            </div> */}
            <div className="col-lg-3">
              <labe className="general-input--style-label">Filter By LearningPath Name</labe>
              <select name="pathName" className="form-select" onChange={handleChange}>
                <option key="name" value="">All</option>
                {pathTitles.map(title => (
                  <option key={title.categoryName} value={title.categoryName}>{title.categoryName}</option>
                ))}
              </select>
            </div>
            <div className="col-lg-3">
              <label className="general-input--style-label">Filter By Industry</label>
              <select name="category" className="form-select" onChange={handleChange}>
                <option key="catgry" value="">All</option>
                {pathCategories.map(category => (
                  <option key={category.categoryName} value={category.categoryName}>{category.categoryName}</option>
                ))}
              </select>
            </div>
            <div className="col-lg-3">
              <ButtonLink ClassName="learn-more-link">
                <button onClick={handleShowingAll}>Show All</button>
              </ButtonLink>
            </div>
            <div className="col-lg-3">
              <ButtonLink ClassName="learn-more-btn confirm">
                <button onClick={handleFiltering}>Filter Results</button>
              </ButtonLink>
            </div>
          </form>
          {/* Start Form */}
        </div>
      </div>
      <PathDetails filteredPath={_filteredPath} />
      <div className="learnpath-model">
        <h3>how it works between mentor & mentee</h3>
        <div className="d-flex">
          <img src={pathModel} alt="learn_path_model" />
        </div>
      </div>
    </>
  );
};

