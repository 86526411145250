import React from 'react'
import axios from "axios";
import { useEffect, useState } from "react";
// import userLogo from '../../../images/userLogo.svg'

export default function Conversation({ conversation, myUserInfo, chooseThisConversation }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const friendId = conversation.members.find((m) => m !== myUserInfo._id);

    const getUser = async () => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${myUserInfo.token}`
          },
        }
        const { data } = await axios(`/api/users/chatpartner?userId=${friendId}`, config);
        setUser(data);
      } catch (err) {
        console.log(err.message);
      }
    };
    if (friendId && myUserInfo.token)
    getUser();
  }, [myUserInfo, conversation]);



  const userImage = user?.userImage?.img?.data ? Buffer.from(user?.userImage?.img?.data).toString('base64') : ""


  return (
    <>
      {user && (
        <div key={conversation._id} className={`msg-brief-container`} onClick={() => chooseThisConversation(conversation, userImage)}>
          {userImage ? (
            <img
              className="img-fluid me-3"
              src={`data:image/${userImage.contentType};base64,${userImage}`}
              alt="icon img"
            />
          ) : (
            <img className="img-fluid me-4" src="/profileLogo_new.png" alt="userLogo" />
          )}
          <div>
            <h6>{user.name}</h6>
            <span>{conversation.conversationScope}</span>
          </div>
        </div>
      )}

    </>
  );
}
