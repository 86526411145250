import React, { useEffect, useMemo, useState } from 'react'
import './AdminPages.css'
import { useDispatch, useSelector } from 'react-redux'
import history from '../../history'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { getUserDetails, updateUser } from '../../actions/userActions'
import Message from '../../components/Message/Message'
import { USER_DETAILS_RESET, USER_UPDATE_RESET } from '../../constants/userConstants'
import ButtonLink from '../../components/ButtonLink/ButtonLink'

import Select from 'react-select'
import countryList from 'react-select-country-list'

export default function UserEditScreen({ match }) {

  const userId = match.params.id
  const dispatch = useDispatch()

  const [phoneNumber, setphoneNumber] = useState('')
  const [countryOfResidence, setCountryOfResidence] = useState('')
  const [authUsersDetails, setAuthUsersDetails] = useState({
    instructorDetails: null,
    mentorDetails: null,
    founderDetails: null
  })
  const options = useMemo(() => countryList().getData(), [])
  const [updatedForm, setUpdatedForm] = useState({
    name: '',
    email: '',
    linkedinURL: "",
    address: '',
    isAdmin: false,
    isInstructor: false,
    isApprovedInstructor: false,
    isMentor: false,
    isApprovedMentor: false,
    isFounder: false,
    isApprovedFounder: false
  })
  const [enrolledCoursesArray, setEnrolledCoursesArray] = useState([])
  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error: userDetailsError, user } = userDetails

  const userUpdate = useSelector((state) => state.userUpdate)
  const {
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate

  useEffect(() => {
    if (userDetailsError) {
      setMessage({
        msgType: "message-error",
        messageBody: userDetailsError
      })
      return
    }
    if (!user?.name || user?._id !== userId) {
      dispatch(getUserDetails(userId))
    } else {
      setphoneNumber(user?.phone || "")
      setCountryOfResidence((user?.countryOfResidence || {}))
      setEnrolledCoursesArray(user?.enrolledCourses || [])
      setAuthUsersDetails({
        instructorDetails: user?.instructorDetails || null,
        mentorDetails: user?.mentorDetails || null,
        founderDetails: user?.founderDetails || null
      })
      setUpdatedForm({
        name: user?.name,
        email: user?.email || "",
        linkedinURL: user?.linkedinURL || "",
        address: user?.address || "",
        isAdmin: user?.isAdmin || false,
        isInstructor: user?.isInstructor || false,
        isApprovedInstructor: user?.isApprovedInstructor || false,
        isMentor: user?.isMentor || false,
        isApprovedMentor: user?.isApprovedMentor || false,
        isFounder: user?.isFounder || false,
        isApprovedFounder: user?.isApprovedFounder || false
      })
    }
  }, [dispatch, userId, userDetailsError, user])

  useEffect(() => {
    if (!userInfo?.isAdmin) {
      history.push('/')
    }
    if (errorUpdate) {
      setMessage({
        msgType: "message-error",
        messageBody: errorUpdate
      })
      return
    }
    if (successUpdate) {
      setMessage({
        msgType: "message-success",
        messageBody: "userInfo is updated, you will be redirected to your board.."
      })
      setTimeout(() => {
        history.push('/admin/userlist')
        dispatch({ type: USER_UPDATE_RESET })
        dispatch({ type: USER_DETAILS_RESET })
      }, 5000);

    }
  }, [dispatch, userInfo, errorUpdate, successUpdate])

  const handleChange = (event) => {
    const { name, value } = event.target
    setUpdatedForm((prevInputs) => {
      return {
        ...prevInputs,
        [name]: value
      }
    })
  }
  const handleCountryChange = (info) => {
    setCountryOfResidence(info)
  }
  const handleCheck = (event) => {
    const { name, checked } = event.target
    setUpdatedForm((prevInputs) => {
      return {
        ...prevInputs,
        [name]: checked
      }
    })
  }


  // const handleGrantCheck = (event) => {
  //   const { name, checked } = event.target
  //   setGrantForm((prevInputs) => {
  //     return {
  //       ...prevInputs,
  //       [name]: checked
  //     }
  //   })
  // }
  const handleCourseFormChange = (event) => {
    const { id, name, value } = event.target
    setEnrolledCoursesArray(preArray => {
      const newArray = preArray.map(item => {
        if (item.course === id) {
          return { ...item, [name]: value }
        } else {
          return item
        }
      })
      return newArray
    })
  }
  const handleCourseCertificateCheck = (event) => {
    const { id, name, checked } = event.target
    setEnrolledCoursesArray(preArray => {
      const newArray = preArray.map(item => {
        if (item.course === id) {
          return { ...item, certifiedTrack: { ...item.certifiedTrack, [name]: checked } }
        } else {
          return item
        }
      })
      return newArray
    })
  }

  const listEnrolledCourses = () => {
    const enrollmentDetailsForm = enrolledCoursesArray.map(course => {
      // console.log("Course info", course)
      const _courseId = course.course.toString()
      return (
        <div key={_courseId} className='enrolled-courses'>
          <div className="row justify-content-between align-items-center">
            <div className="col-md-4 col-sm-12">
              <label>Course Name</label>
              <input
                id={_courseId}
                className="useredit_input"
                name='name'
                value={course.name}
                placeholder='name'
                onChange={handleCourseFormChange}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <label>Course Id</label>
              <input
                id={_courseId}
                className="useredit_input"
                name='course'
                value={course.course}
                placeholder='course'
                onChange={handleCourseFormChange}
              />
            </div>
          </div>

          <div className="row justify-content-between align-items-center">
            <div className="col-md-4 col-sm-12">
              <div>Certification ID: <span>{course.certifiedTrack.certificateID}</span></div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={_courseId}
                  name='EnrollStatus'
                  onChange={handleCourseCertificateCheck}
                  checked={course.certifiedTrack.EnrollStatus}
                />
                <label className="form-check-label label-question">
                  Change Enrollement Status
                </label>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id={_courseId}
                  name='completionStatus'
                  onChange={handleCourseCertificateCheck}
                  checked={course.certifiedTrack.completionStatus}
                />
                <label className="form-check-label label-question">
                  Change Course Completion Status
                </label>
              </div>
            </div>
          </div>

        </div>
      )
    })

    return (
      <>
        <h4> Edit details of enrolled courses </h4>
        {enrollmentDetailsForm}
      </>
    )
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(updateUser({
      _id: userId,
      phone: phoneNumber,
      countryOfResidence,
      ...updatedForm,
      enrolledCourses: enrolledCoursesArray
    }))
  }
  const cancelHandler = (e) => {
    e.preventDefault()
    dispatch({ type: USER_DETAILS_RESET })
    history.push('/admin/userlist')
  }

  return (
    <>
      {loading ? (
        <p>loading...............</p>
      ) : (
        <>
          <div className="userdetails-editor">
            <div className="container">
              <h4 className="common-heading"> User Details </h4>
              <div className="useredit-content">
                {/* <h3>Add Learning Path</h3> */}
                <div className="useredit-form">
                  <div className="row justify-content-between align-items-center border-bottom">
                    <div className="col-md-4 col-sm-12">
                      <label htmlFor="username">User Name</label>
                      <input
                        id="username"
                        className="useredit_input"
                        name='name'
                        value={updatedForm.name}
                        placeholder='name'
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <label htmlFor="exampleFormControlTextarea1" className="form-label">User Email</label>
                      <input
                        id="exampleFormControlTextarea1"
                        className="useredit_input"
                        name='email'
                        value={updatedForm.email}
                        placeholder='email'
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <label htmlFor="exampleFormControlTextarea1" className="form-label">User Phone Number</label>
                      <PhoneInput
                        international
                        defaultCountry="EG"
                        className="form-control flex"
                        id="exampleFormControlTextarea1"
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        onChange={setphoneNumber}
                      />
                    </div>
                  </div>

                  <label>Linkedin: </label>
                  <p>{updatedForm.linkedinURL}</p>

                  <div className="row justify-content-between align-items-center border-bottom">
                    <div className="col-md-6 col-sm-12">
                      {/* <label htmlFor="username">Country Of Residence</label>
                      <input
                        id="username"
                        className="useredit_input"
                        name='countryOfResidence'
                        value={updatedForm.countryOfResidence}
                        placeholder='countryOfResidence'
                        onChange={handleChange}
                      /> */}
                      <label> Country of Residence </label>
                      <Select
                        className="half-input profile-input no-padding"
                        options={options}
                        name="countryOfResidence"
                        value={countryOfResidence}
                        onChange={handleCountryChange}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <label htmlFor="exampleFormControlTextarea1" className="form-label">Address</label>
                      <input
                        id="exampleFormControlTextarea1"
                        className="useredit_input"
                        name='address'
                        value={updatedForm.address}
                        placeholder='address'
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <h4> Control Admin  Status </h4>
                  <div className="row justify-content-between align-items-center border-bottom">
                    <div className="col-12">
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="isadmin"
                          name='isAdmin'
                          onChange={handleCheck}
                          checked={updatedForm.isAdmin}
                        />
                        <label className="form-check-label label-question" htmlFor="isadmin">
                          User is admin
                        </label>
                      </div>
                    </div>
                  </div>

                  <h4> Editor for Instructor Information </h4>
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="isinstructor"
                          name='isInstructor'
                          onChange={handleCheck}
                          checked={updatedForm.isInstructor}
                        />
                        <label className="form-check-label label-question" htmlFor="isinstructor">
                          user is <span style={{ fontWeight: 'bolder' }}> instructor </span>
                        </label>
                      </div>

                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="isapprovedinstructor"
                          name='isApprovedInstructor'
                          onChange={handleCheck}
                          checked={updatedForm.isApprovedInstructor}
                        />
                        <label className="form-check-label label-question" htmlFor="isapprovedinstructor">
                          Approve user as an <span style={{ fontWeight: 'bolder' }}> instructor </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row border-bottom">
                    <div className="col-12 enrolled-courses">
                      <h5> {authUsersDetails?.instructorDetails?.instructorProfession} </h5>
                      <h6> {authUsersDetails?.instructorDetails?.instructorClassification} </h6>
                      <p> {authUsersDetails?.instructorDetails?.instructorIntroduction} </p>
                    </div>
                  </div>

                  <h4> Editor for Mentor Information </h4>
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="ismentor"
                          name='isMentor'
                          onChange={handleCheck}
                          checked={updatedForm.isMentor}
                        />
                        <label className="form-check-label label-question" htmlFor="ismentor">
                          user is Mentor
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="isapprovedmentor"
                          name='isApprovedMentor'
                          onChange={handleCheck}
                          checked={updatedForm.isApprovedMentor}
                        />
                        <label className="form-check-label label-question" htmlFor="isapprovedmentor">
                          Approve user as an Mentor
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row border-bottom">
                    <div className="col-12 enrolled-courses">
                      <h5> {authUsersDetails?.mentorDetails?.mentorProfession} </h5>
                      <p> {authUsersDetails?.mentorDetails?.mentorIntroduction} </p>
                    </div>
                  </div>


                  <h4> Editor for Founder Information </h4>
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="isfounder"
                          name='isFounder'
                          onChange={handleCheck}
                          checked={updatedForm.isFounder}
                        />
                        <label className="form-check-label label-question" htmlFor="isfounder">
                          user is Founder
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="isapprovedfounder"
                          name='isApprovedFounder'
                          onChange={handleCheck}
                          checked={updatedForm.isApprovedFounder}
                        />
                        <label className="form-check-label label-question" htmlFor="isapprovedfounder">
                          Approve user as an Founder
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row border-bottom">
                    <div className="col-12 enrolled-courses">
                      <h5> {authUsersDetails?.founderDetails?.founderProfession} </h5>
                      <p> {authUsersDetails?.founderDetails?.founderIntroduction} </p>
                      <div className="row-tags" >
                        {authUsersDetails?.founderDetails?.founderkeySkills.map((fieldEntry, indx) => {
                          return (
                            <p key={indx}>{fieldEntry}</p>
                          )
                        })}
                      </div>
                    </div>
                  </div>


                  {listEnrolledCourses()}

                  <div className="row justify-content-between align-items-center actions-section noborder">
                    <div className="col-md-7 col-sm-12">
                      <ButtonLink ClassName="learn-more-btn cancel">
                        <button onClick={cancelHandler}>Cancel</button>
                      </ButtonLink>
                    </div>

                    <div className="col-md-2 col-sm-12">
                      <ButtonLink ClassName="learn-more-btn confirm">
                        <button onClick={submitHandler}>Publish</button>
                      </ButtonLink>
                    </div>
                  </div>
                </div>
                {message.messageBody && <Message ClassName={message.msgType}>{message.messageBody}</Message>}
              </div>
            </div>
          </div>
        </>)}
    </>
  )
}
