import { MENTOR_PATH_LIST_FAIL, MENTOR_PATH_LIST_LOADING, MENTOR_PATH_LIST_SUCCESS, PATH_ACTIONON_APPLICANT_FAIL, PATH_ACTIONON_APPLICANT_RESET, PATH_ACTIONON_APPLICANT_SUCCESS, PATH_APPLICANT_APPLY_FAIL, PATH_APPLICANT_APPLY_RESET, PATH_APPLICANT_APPLY_SUCCESS, PATH_CREATE_FAIL, PATH_CREATE_RESET, PATH_CREATE_SUCCESS, PATH_DELETE_FAIL, PATH_DELETE_SUCCESS, PATH_DETAILS_FAIL, PATH_DETAILS_RESET, PATH_DETAILS_SUCCESS, PATH_LIST_FAIL, PATH_LIST_LOADING, PATH_LIST_SUCCESS, PATH_UPDATE_FAIL, PATH_UPDATE_RESET, PATH_UPDATE_SUCCESS } from "../constants/pathConstants"

export const pathListReducer = (state = { paths: [] }, action) => {
  switch (action.type) {
    case PATH_LIST_LOADING:
      return {loading: true, ...state }
    case PATH_LIST_SUCCESS:
      return {loading: false, error: null, paths: action.payload }
    case PATH_LIST_FAIL:
      return {loading: false, error: action.payload }
    default:
      return state
  }
}

export const mentorPathListReducer = (state = { mentorPaths: [] }, action) => {
  switch (action.type) {
    case MENTOR_PATH_LIST_LOADING:
      return {loading: true, ...state }
    case MENTOR_PATH_LIST_SUCCESS:
      return {loading: false, error: null, mentorPaths: action.payload }
    case MENTOR_PATH_LIST_FAIL:
      return {loading: false, error: action.payload }
    default:
      return state
  }
}

export const pathDetailsReducer = (state = { pathDetails: { article: {} } }, action) => {
  switch (action.type) {
    case PATH_DETAILS_SUCCESS:
      return { pathdetails: action.payload }
    case PATH_DETAILS_FAIL:
      return { detailserror: action.payload }
    case PATH_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const pathUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PATH_UPDATE_SUCCESS:
      return { updateSuccess: true }
    case PATH_UPDATE_FAIL:
      return { updateError: action.payload }
    case PATH_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const pathCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PATH_CREATE_SUCCESS:
      return { createSuccess: true }
    case PATH_CREATE_FAIL:
      return { createError: action.payload }
    case PATH_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const pathDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PATH_DELETE_SUCCESS:
      return { deleteSuccess: true }
    case PATH_DELETE_FAIL:
      return { deleteError: action.payload }
    default:
      return state
  }
}

export const pathMentorsActionReducer = (state = {}, action) => {
  switch (action.type) {
    case PATH_ACTIONON_APPLICANT_SUCCESS:
      return { successMessage: action.payload }
    case PATH_ACTIONON_APPLICANT_FAIL:
      return { error: action.payload }
    case PATH_ACTIONON_APPLICANT_RESET:
      return {}
    default:
      return state
  }
}

export const pathApplyReducer = (state = {}, action) => {
  switch (action.type) {
    case PATH_APPLICANT_APPLY_SUCCESS:
      return { applySuccess: true }
    case PATH_APPLICANT_APPLY_FAIL:
      return { applyError: action.payload }
    case PATH_APPLICANT_APPLY_RESET:
      return {}
    default:
      return state
  }
}