import React from 'react'
import './MemberCard.css'
import linkedinIcon from '../../images/general-purpose/linkedin_memberImg.png'
export default function MemberCard({ name, img, profession, linkedinURL, ClassName }) {
  return (
    <div className={`${ClassName} member-card mb-3`} onClick={() => window.open(linkedinURL, "_blank")}>
      <div className="imgs-wrapper">
        <img src={img} className="memberImg" alt="instructors" />
        <img src={linkedinIcon} className="linkedinicon" alt="linkedin" />
      </div>
      <div className="card-body">
        <h5 className="card-title text-capitalize">{name}</h5>
        <p className="card-text">
          {profession}
        </p>
      </div>
    </div>
  )
}
