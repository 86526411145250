import axios from "axios"
import { JOB_ACTIONON_APPLICANT_FAIL, JOB_ACTIONON_APPLICANT_SUCCESS, JOB_APPLICANT_APPLY_FAIL, JOB_APPLICANT_APPLY_SUCCESS, JOB_CREATE_FAIL, JOB_CREATE_SUCCESS, JOB_DELETE_FAIL, JOB_DELETE_SUCCESS, JOB_DETAILS_FAIL, JOB_DETAILS_SUCCESS, JOB_LIST_FAIL, JOB_LIST_LOADING, JOB_LIST_SUCCESS, JOB_UPDATE_FAIL, JOB_UPDATE_SUCCESS, MENTOR_JOB_LIST_FAIL, MENTOR_JOB_LIST_LOADING, MENTOR_JOB_LIST_SUCCESS } from "../constants/jobConstants"
import { logout } from "./userActions"

// public access to all Job, all users can see the list
export const listJobs = () => async (dispatch) => {
  try {
    dispatch({ type: JOB_LIST_LOADING })
    const { data } = await axios.get('/api/jobs')
    dispatch({
      type: JOB_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: JOB_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const ListJobDetails = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/api/jobs/${id}`)
    dispatch({
      type: JOB_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: JOB_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Mentor fetch his written learning jobs
export const listMentorJobs = () => async (dispatch, getState) => {
  try {
    dispatch({ type: MENTOR_JOB_LIST_LOADING })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get('/api/jobs/mentor/getjobs', config)
    dispatch({
      type: MENTOR_JOB_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MENTOR_JOB_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Mentor and Admin create  Job ****
export const createJob = (jobForm) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
      categoriesList: { categories }
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    // update categories in database
    const alreadyExistedGeneral = categories.filter( category => category.categoryName===jobForm.category)
    const alreadyExistedSpecial = categories.filter( category => category.categoryName===jobForm.title)
    // console.log("alreadyExistedGeneral: ", alreadyExistedGeneral)
    // console.log("alreadyExistedSpecial: ", alreadyExistedSpecial)
    if (alreadyExistedGeneral.length === 0) {
      const newCategory = {
        categoryType: "general",
        categoryName: jobForm.category,
      }
      await axios.post(`/api/categories/useraction`, newCategory, config)
    }

    if (alreadyExistedSpecial.length === 0) {
      const newCategory = {
        categoryType: "special",
        categoryName: jobForm.title,
      }
      await axios.post(`/api/categories/useraction`, newCategory, config)
    }

    await axios.post(`/api/jobs/mentor/createjob`, jobForm, config)
    dispatch({
      type: JOB_CREATE_SUCCESS,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: JOB_CREATE_FAIL,
      payload: message,
    })
  }
}

// Mentor and Admin update  Job ****
export const updateJob = (jobForm) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.put(`/api/jobs/mentor/${jobForm._id}`, jobForm, config)
    dispatch({
      type: JOB_UPDATE_SUCCESS,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: JOB_UPDATE_FAIL,
      payload: message,
    })
  }
}
// Mentor delete his written job
export const deleteJob = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/jobs/mentor/${id}`, config)

    dispatch({
      type: JOB_DELETE_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: JOB_DELETE_FAIL,
      payload: message,
    })
  }
}
// Mentor shortlist or refuses job applications
export const actionOnJobApplication = (actionForm, jobID) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(`/api/jobs/approveapplicant/${jobID}`, actionForm, config)
    dispatch({
      type: JOB_ACTIONON_APPLICANT_SUCCESS,
      payload: data.message,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: JOB_ACTIONON_APPLICANT_FAIL,
      payload: message,
    })
  }
}
// user apply to job
export const applyToJob = (application, jobID) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.post(`/api/jobs/${jobID}`, application, config)
    dispatch({
      type: JOB_APPLICANT_APPLY_SUCCESS,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: JOB_APPLICANT_APPLY_FAIL,
      payload: message,
    })
  }
}