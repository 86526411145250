import React from 'react'
import underProcessing from '../../images/general-purpose/underProcessing.png'
import './UnderConstructPage.css'
export default function UnderConstructPage() {
  return (
    <div className="page-under-construction">
      <div className="construction-container">
        <img src={underProcessing} alt="under-construction" />
        <h4>Page is under construction. It will be available soon</h4>
      </div>
    </div>
  )
}
