import React, { useState } from 'react'
import ButtonLink from '../../../components/ButtonLink/ButtonLink'
import cancelIcon from '../../../images/general-purpose/cancelIcon.png'

export default function CaregoryUpdateForm({ categoryDetails, closeModal, onUpdateConfirm }) {
  const [categoryInfo, setCategoryInfo] = useState({
    categoryName: categoryDetails.categoryName || "",
    activeStatus: categoryDetails.activeStatus || false
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setCategoryInfo((prevEntries) => {
      return {
        ...prevEntries,
        [name]: value
      }
    })
  }
  const handleCheck = (event) => {
    const { name, checked } = event.target
    setCategoryInfo((prevInput) => {
      return {
        ...prevInput,
        [name]: checked
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onUpdateConfirm(categoryDetails._id, categoryInfo)
  }

  return (
    <div className="lessondetail-form-container" onClick={e => e.stopPropagation()}>
      <div className="cancel-button">
        <div className="cancel-wrapper" onClick={closeModal}>
          <img src={cancelIcon} alt="How We Work" />
        </div>
      </div>
      <h3>Edit Lesson Details</h3>
      <div className="lesson-form">
        <div className="row no-padding justify-content-between align-items-end">
          <div className="col-md-6 col-sm-12">
            <div className="form-check mb-1">
              <label className="form-check-label label-question">
                Name Of Category
              </label>
              <input
                className="form-control course_input"
                type="text"
                name='categoryName'
                value={categoryInfo.categoryName}
                onChange={handleChange}
              />

            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="form-check mb-1">
              <input
                className="form-check-input"
                name="activeStatus"
                type="checkbox"
                onChange={handleCheck}
                checked={categoryInfo.activeStatus}
              />
              <label className="form-check-label label-question">
                Change Category Status
              </label>
            </div>
          </div>
        </div>
      </div>
      <ButtonLink ClassName="learn-more-btn full-width">
        <button onClick={handleSubmit}> Confirm </button>
      </ButtonLink>
    </div>
  )
}
