import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { courseListReducer, courseDetailsReducer, courseDeleteReducer, courseCreateReducer, courseUpdateReducer, courseReviewCreateReducer, coursesInstructorReducer } from './reducers/courseReducer'
import { userLoginReducer, userRegisterReducer, userDetailsReducer, userUpdateProfileReducer, userListReducer, userDeleteReducer, userUpdateReducer } from './reducers/userReducers'
import { cartReducer } from './reducers/cartReducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import { orderCreateReducer, orderDeleteReducer, orderDetailsReducer, orderPayReducer, listMyOrdersReducer } from './reducers/orderReducers'
import { languageReducer } from './reducers/languageReducer'
import { mentorPathListReducer, pathApplyReducer, pathCreateReducer, pathDeleteReducer, pathDetailsReducer, pathListReducer, pathMentorsActionReducer, pathUpdateReducer } from './reducers/pathReducers'
import { jobApplyReducer, jobCreateReducer, jobDeleteReducer, jobDetailsReducer, jobListReducer, jobMentorsActionReducer, jobUpdateReducer, mentorJobListReducer } from './reducers/jobReducers'
import { adminCategoriesListReducer, categoriesListReducer, updateCategoryStatusReducer } from './reducers/categoriesReducer'
import { allGrantsReducer, grantAdminActionReducer, grantInformationReducer } from './reducers/grantReducers'
import { founderPartnershipListReducer, partnershipApplyReducer, partnershipCreateReducer, partnershipDeleteReducer, partnershipDetailsReducer, partnershipFoundersActionReducer, partnershipListReducer, partnershipUpdateReducer } from './reducers/partnershipReducers'

const reducer = combineReducers({
  language: languageReducer,
  courseList: courseListReducer,
  coursesInstructor: coursesInstructorReducer,
  courseDetails: courseDetailsReducer,
  courseCreate: courseCreateReducer,
  courseDelete: courseDeleteReducer,
  courseUpdate: courseUpdateReducer,
  courseReviewCreate: courseReviewCreateReducer,
  cart: cartReducer,
  // users
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  // orders
  orderCreate: orderCreateReducer,
  orderDelete: orderDeleteReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  listMyOrders: listMyOrdersReducer,
  // paths
  pathList: pathListReducer,
  mentorPathList: mentorPathListReducer,
  pathCreate: pathCreateReducer,
  pathDelete: pathDeleteReducer,
  pathDetails: pathDetailsReducer,
  pathUpdate: pathUpdateReducer,
  pathApply: pathApplyReducer,
  pathMentorsAction: pathMentorsActionReducer,
  // jobs
  jobList: jobListReducer,
  mentorJobList: mentorJobListReducer,
  jobDetails: jobDetailsReducer,
  jobUpdate: jobUpdateReducer,
  jobCreate: jobCreateReducer,
  jobDelete: jobDeleteReducer,
  jobApply: jobApplyReducer,
  jobMentorsAction: jobMentorsActionReducer,
  // partnerships
  partnershipList: partnershipListReducer,
  founderPartnershipList: founderPartnershipListReducer,
  partnershipDetails: partnershipDetailsReducer,
  partnershipUpdate: partnershipUpdateReducer,
  partnershipCreate: partnershipCreateReducer,
  partnershipDelete: partnershipDeleteReducer,
  partnershipApply: partnershipApplyReducer,
  partnershipFoundersAction: partnershipFoundersActionReducer,
  // categories
  categoriesList: categoriesListReducer,
  adminCategoriesList: adminCategoriesListReducer,
  updateCategoryStatus: updateCategoryStatusReducer,
  // grants
  grantInformation: grantInformationReducer,
  allGrants: allGrantsReducer,
  grantAdminAction: grantAdminActionReducer
})

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

// const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
//   ? JSON.parse(localStorage.getItem('shippingAddress'))
//   : {}


const initialState = {
  cart: { cartItems: cartItemsFromStorage },
  userLogin: { userInfo: userInfoFromStorage }
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store