/*===  Import Link === */
import { Link } from "react-router-dom";

/*===  Css Custom File === */
import "./Footer.css";

/* == Import Logo And Image Icons Footer == */
import LogoFooter from "../../images/rekgpt_slogan_footer.svg";
import facebook from "../../images/footer/facebook.png";
import linkedid from "../../images/footer/linkedin.png";
import youtube from "../../images/footer/youtube.png";
import { useSelector } from "react-redux";

const Footer = () => {
  const language = useSelector((state) => state.language);
  const { translation } = language;

  return (
    <div id="contactForm" className="footer text-white">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 col-sm-12 mb-4">
            <img
              className="logo-footer m-auto"
              src={LogoFooter}
              alt="Logo Footer"
            />
          </div>
          <div className="col-md-4 col-sm-12 mb-4">
            <ul className="list-unstyled">
              <li>
                <Link to="/learningPath">{translation.LearningPaths}</Link>
              </li>
              <li>
                <Link to="/technopedia">{translation.EducationalCourses}</Link>
              </li>
              <li>
                <Link to="/jobs">{translation.JobsInCompanies}</Link>
              </li>
              <li>
                <Link to="/joinus">{translation.TeachOnASEEUTechnopedia}</Link>
              </li>
            </ul>
          </div>
          {/* <div className="col-md-4 col-sm-12 mb-4">
            <ul className="list-unstyled">
              <li>
                <Link to="/profile/companyboard">{translation.aseeuAsJbsBoard}</Link>
              </li>
              <li>
                <Link to="/jobs">{translation.JobsInAseeu}</Link>
              </li>
            </ul>
          </div> */}
          <div className="col-md-4 col-sm-12 mb-4">
            <ul className="list-unstyled">
              <li className="h6">{translation.ContactUs}</li>
              <li>
                <i className="fas fa-map-marker-alt me-3"></i>
                <span to="/">Helsinki, Finland</span>
              </li>
              {/* <li>
                <i className="fas fa-phone-alt me-3"></i>
                <span to="/">+358414970371</span>
              </li> */}
              <li>
                <i className="fas fa-envelope me-3"></i>
                <span to="/">info@rekgpt.com</span>
              </li>
              {/* <div className="social">
                <div className="ul d-flex">
                  <li className="me-3">
                    <a href="https://www.facebook.com/ArabsOfEurope/" rel="noreferrer" target="_blank">
                      <img src={facebook} alt="facebook" />
                    </a>
                  </li>
                  <li className="me-3">
                    <a href="https://www.linkedin.com/company/aseeu" rel="noreferrer" target="_blank">
                      <img src={linkedid} alt="linkedid" />
                    </a>
                  </li>
                  <li className="me-3">
                    <a href="https://www.youtube.com/channel/UC8pXo5xhUJROXuKiJ9l1j5w" rel="noreferrer" target="_blank">
                      <img src={youtube} alt="youtube" />
                    </a>
                  </li>
                </div>
              </div> */}
            </ul>
          </div>
        </div>
        <div className="copy-right text-center py-1">
          {new Date().getFullYear()} RekGPT | All Rights Reserved
          {/* <p>RekGPT is registered as a limited liability company in United Kingdom</p> */}
          {/* <p className="mb-1">RekGPT is personal initiative to help immigrants community</p>
          <p className="mt-1 mb-1">Made with love by @Ahmed Elsayes</p> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
