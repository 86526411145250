const item1English = [
  {
    title: 'Advantages of joining as Starter Instructor',
    content: ['You will prepare the education material, while, we will will help you in video editing, polishing your course and adding all necessary effects that ensure the production of high quality material. Once your lectures are ready, you will upload it by yourself to the website through your instructor portal on RekGPT',
      'We will help you to reach thousands or hundreds of thousands of students as we will make the paid advertisements on behalf of you. This means that we will endure 100% of marketing-related costs to help you reach your audience',
      'We will make sure that you generate profits by applying fee on your course through our platform or by getting support from some governmental organizations which will pay for your course. In general, we will allocate 50% of profits for you',
      'As an instructor on our Technopedia, you will have the merit of being a member in fast growing community and we will prioritize supporting you through our Entrepreneurship platform if you decided one day to start your own business',
      'In sum, when it comes to sales on your courses, RekGPT will take 50% of revenue, while, you will take 50% of revenue'
    ],
    activated: false
  },
  {
    title: 'Recommendations and Notices for this track',
    content: [
      'This track is recommended for beginner content-maker. You commit to follow our regulations and instructions to produce high-quality online course',
      'RekGPT has all rights to decide on the pricing policies of courses according to our organization mission and goals'
    ],
    activated: false
  }
];

const item1Arabic = [
  {
    title: 'مزايا الانضمام كمدرب مبتدئ',
    content: ['سوف تقوم بإعداد المواد التعليمية ، بينما سنساعدك في تحرير الفيديو ، وتجهيز الدورة التدريبية الخاصة بك بشكل احترافي. بمجرد أن تصبح محاضراتك جاهزة ، ستقوم بتحميلها بنفسك على الموقع من خلال بوابة المعلم الخاصة',
      'سوف نساعدك في وصول كورساتك الى آلاف من الطلاب حيث سوف نقوم بعمل الإعلانات المدفوعة نيابة عنك. هذا يعني أننا سنتحمل 100٪ من التكاليف المتعلقة بالتسويق لمساعدتك في الوصول إلى جمهورك',
      'سوف نتأكد من أنك تحقق أرباحًا من خلال تطبيق رسوم على دورتك التدريبية من خلال منصتنا أو عن طريق الحصول على الدعم من بعض المنظمات الحكومية التي ستدفع مقابل الدورة التدريبية الخاصة بك. بشكل عام ، سوف تحصل على نسبة 50% من كل عملية شراء تتم على الكورس الخاص بك',
      'بصفتك معلمًا على منصتنا تكنوبيديا ، سوف تتمتع بميزة كونك عضوًا في مجتمع سريع النمو وسنمنح الأولوية لدعمك من خلال منصة ريادة الأعمال الخاصة بنا إذا قررت يومًا ما أن تبدأ عملك الخاص',
      'كما ذكرنا سابقا سوف تحصل على نسبة 50% من الأرباح على كورساتك بينما سوف تحصل ريكجبت على النصف الأخر الذي سوف يكون في صالحك لأننا نسعى الى تطوير المنصه باستمرار عبر هذه الايرادات '
    ],
    activated: false
  },
  {
    title: 'توصيات و ملاحظات لهذا المسار',
    content: [
      'يوصى بهذا المسار لصناع المحتوى المبتدئيين. يجب تلتزم باتباع لوائحنا وتعليماتنا لإنتاج دورة تدريبية عالية الجودة عبر الإنترنت سوف نقوم بالاتفاق معك على كل شيء في أول مقابله لك مع منسق المدريين على ريكجبت',
      'تمتلك ريكجبت حريةاتخاذ القرار بشأن سياسات تسعير الدورات وفقًا لأهدافنا المرحليه و سياستنا التسويقيه'
    ],
    activated: false
  }
];

const item2English = [
  {
    title: 'Advantages of joining as Experienced Instructor',
    content: [
      'You will prepare the educational material. You will handle everything to ensure the production of high-quality material such as (video editingو montage and marketing of your course). Then, you will upload the course on the platform through your instructor portal',
      'You will enjoy the merit of deciding your course price as you want (it is all up to you). However, you should accept that we will deduct 25% percent of every payment that done by an enrolled student into your course. These charges will be used to advertise your course and grant scholarships to students in need for it which will be a good marketing for your services',
      'If your focus is on maximizing profits or you have experience with content creation, this track is the recommended one for you',
      'Publishing your course through our platform will enable you to reach more audience',
      'As an instructor on our Technopedia, you will have the merit of being a member in fast growing community and we will prioritize supporting you through our Entrepreneurship platform if you decided one day to start your own business',
      'In sum, when it comes to sales on your courses, RekGPT will take 25% of revenue, while, you will take 75% of revenue'
    ],
    activated: false
  },
  {
    title: 'Recommendations and Notices for this track',
    content: [
      'This track is recommended for professional content-maker. You are committed to create all your course materials, edit and include explainer video to make it looking professional',
      'You should accept that the first month of releasing the platform to public, we will grant some scholarships that include free enrollement into your course. This will be part of our marketing campaign to promote your course. We will agre with you beforehand on number of grants which we can offer. We will not do any free enrollements into your courses before your permission'
    ],
    activated: false
  }
];

const item2Arabic = [
  {
    title: 'مزايا الانضمام كمدرب خبير',
    content: [
      'في هذا المسار سوف تقوم بإعداد المواد التعليمية.ذلك سوف يتضمن تحرير الفيديو والمونتاج لخروجه بشكل احترافي وتسويق لكورساتك. سوف تقوم برفع كورساتك الأونلاين على المنصة من خلال بوابة المعلم الخاصة بك',
      'سوف تستمتع بميزة تحديد سعر دورتك كما تريد (الأمر كله متروك لك). ومع ذلك ، يجب أن تقبل أننا سوف نحصل على نسبة 25% من كل عملية شراء تتم على الكورسات  الدراسية الخاصه بك. سيتم استخدام هذه الرسوم للإعلان عن الدورة التدريبية الخاصة بك ومنح المنح الدراسية للطلاب المحتاجين لها والتي سوف تكون تسويقًا جيدًا لخدماتك',
      'إذا كان تركيزك على تعظيم الأرباح أو لديك خبرة في إنشاء المحتوى الأونلاين ، فإن هذا المسار هو المسار الموصى به لك',
      ' نشر دورتك من خلال منصتنا سوف يمكنك من الوصول إلى المزيد من الجمهور',
      'بصفتك معلمًا على منصتنا تكنوبيديا ، سوف تتمتع بميزة كونك عضوًا في مجتمع سريع النمو وسنمنح الأولوية لدعمك من خلال منصة ريادة الأعمال الخاصة بنا إذا قررت يومًا ما أن تبدأ عملك الخاص',
      'كما ذكرنا سابقا سوف تحصل على نسبة 75% من الايرادات على كورساتك بينما سوف تحصل ريكجبت على 25% فقط. نسبة ريكجبت الذي سوف تضخ ثانية في صالحك لأننا نسعى الى تطوير المنصه باستمرار عبر هذه الايرادات و التسويق للمنصه'
    ],
    activated: false
  },
  {
    title: 'توصيات و ملاحظات لهذا المسار',
    content: [
      'يوصى بهذا المسار لصانع المحتوى المحترف. أنت ملتزم بإنشاء جميع مواد الدورة التدريبية الخاصة بك ، وتعديل الفيديوهات و عمل المونتاج ان تطلب الأمر ذلك لخروج الماده التعليميه بشكل احترافي و جوده عاليه',
      'يجب أن تقبل أنه في الشهر الأول من إطلاق المنصة للجمهور ، سنمنح بعض المنح الدراسية التي تشمل التسجيل المجاني في الدورة التدريبية الخاصة بك. سيكون هذا جزءًا من حملتنا التسويقية للترويج لدوراتك التدريبية و للمنصه. كل شيء سوف يكون بالاتفاق معك في البدايه على عدد المنح المتاحه. لن نقوم بعمل أي شيء قبل الرجوع اليك و موافقتك'
    ],
    activated: false
  }
];

export { item1English, item1Arabic, item2English, item2Arabic }