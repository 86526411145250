import axios from 'axios'
import { CART_ADD_ITEM, CART_REMOVE_ITEM,
         CART_SAVE_SHIPPING_ADDRESS,
         CART_SAVE_PAYMENT_METHOD, 
         CART_ACTIVATE_GRANT,
         CART_APPLY_CERTIFICATE_FEE,
         CART_DEACTIVATE_GRANT} from '../constants/cartConstants'

export const addToCart = (id) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/courses/${id}`)
  // const imgdata = Buffer.from(data.courseImage.img.data).toString('base64')
  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      originalDiscount: data.discount,
      deductedAmount: data.discount,
      course: data._id,
      name: data.name,
      courseImage: data.courseImage,
      price: data.price,
      courseCategory: data.courseCategory[0],
      rating: data.rating,
      isCertified: data.isCertified,
      certificationFee: 0,
      activeCertifiedTrack: false
    },
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const toggleGrantStatusInCart = (grantStatus) => (dispatch, getState) => {
  if (grantStatus==="activate") {
    dispatch({
      type: CART_ACTIVATE_GRANT,
    })
  } else {
    dispatch({
      type: CART_DEACTIVATE_GRANT,
    })
  }
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const applyCertificationFees = (courseID, trackStatus) => async (dispatch, getState) => {
  if (trackStatus === "certify") {
    const { data } = await axios.get(`/api/courses/${courseID}`)
    dispatch({
      type: CART_APPLY_CERTIFICATE_FEE,
      payload: {courseID, certificationFee: data.certificationFee, activeCertifiedTrack: true}
    })
  } else {
    dispatch({
      type: CART_APPLY_CERTIFICATE_FEE,
      payload: {courseID, certificationFee: 0, activeCertifiedTrack: false}
    })
  }

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

//saveShippingAddress
export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  })

  localStorage.setItem('shippingAddress', JSON.stringify(data))
}


//savePaymentMethod
// export const savePaymentMethod = (data) => (dispatch) => {
//   dispatch({
//     type: CART_SAVE_PAYMENT_METHOD,
//     payload: data,
//   })

//   localStorage.setItem('paymentMethod', JSON.stringify(data))
// }