import { GRANT_ACTION_FAIL, GRANT_ADMIN_ACTION_FAIL, GRANT_ADMIN_ACTION_RESET, GRANT_ADMIN_CREATE_SUCCESS, GRANT_ADMIN_FETCH_ALL_FAIL, GRANT_ADMIN_FETCH_ALL_SUCCESS, GRANT_CREATE_SUCCESS, GRANT_DELETE_SUCCESS, GRANT_FETCH_STATE_SUCCESS, GRANT_UPDATE_SUCCESS } from "../constants/grantsConstants"

export const grantInformationReducer = (state = { grantInfo: {members: []} }, action) => {
  switch (action.type) {
    case GRANT_CREATE_SUCCESS:
      return { createSuccess: true, error: null }
    case GRANT_UPDATE_SUCCESS:
      return { grantInfo: action.payload, error: null }
    case GRANT_FETCH_STATE_SUCCESS:
      return { grantInfo: action.payload, error: null }
    case GRANT_ACTION_FAIL:
      return { error: action.payload }
    default:
      return state
  }
}

export const allGrantsReducer = (state = { grantsList: [] }, action) => {
  switch (action.type) {
    case GRANT_ADMIN_FETCH_ALL_SUCCESS:
      return { grantsList: action.payload }
    case GRANT_ADMIN_FETCH_ALL_FAIL:
      return { grantsListingError: action.payload }
    default:
      return state
  }
}

export const grantAdminActionReducer = (state = {}, action) => {
  switch (action.type) {
    case GRANT_DELETE_SUCCESS:
      return { deleteSuccess: true, actionError: null }
    case GRANT_ADMIN_CREATE_SUCCESS:
      return { createAdminSuccess: true, actionError: null }
    case GRANT_ADMIN_ACTION_FAIL:
      return { actionError: action.payload }
    case GRANT_ADMIN_ACTION_RESET:
      return {}
    default:
      return state
  }
}