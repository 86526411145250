import React from 'react'
import Rating from '../../components/Rating'

export default function CourseHeaderInfo({coursedetails, lectureNumber, courseLength}) {
  return (
    <div className="course-info row">
      <div className="col-sm-4 col-xs-6">
        <div>
          <i className="far fa-clock"></i>
          <p>Duration</p>
          {/* this will replaced later based on algorithm calculation */}
          <b>{lectureNumber} lesson || {`>${courseLength.timeHours}`} Hours</b>
        </div>
      </div>
      <div className="col-sm-4 col-xs-6">
        <div>
          <i className="fas fa-bookmark"></i>
          <p>Main Category</p>
          <b>{coursedetails.courseCategory[0]}</b>
        </div>
      </div>
      <div className="col-sm-4 col-xs-6">
        <div>
          <i className="fas fa-star"></i>
          <p>Rating: {coursedetails.rating.toFixed(1)}/5 {`(${coursedetails.numReviews})`} </p>
          <Rating classname='rating-coursedetails-header' value={coursedetails.rating.toFixed(1)} />
        </div>
      </div>
    </div>
  )
}
