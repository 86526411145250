import React, { useEffect, useState } from 'react'
import './ApplicationStripe.css'
import { useDispatch, useSelector } from 'react-redux';
import linkedinIcon from '../../images/general-purpose/linkedinIcon.png'
import { PARTNERSHIP_ACTIONON_APPLICANT_RESET } from '../../constants/partnershipConstants';
import Message from '../Message/Message';
import { actionOnPartnershipApplication } from '../../actions/partnershipActions';

export default function CoFounderApplication({ application, index, topicID, onActionTrigger }) {
  const dispatch = useDispatch();

  const partnershipFoundersAction = useSelector((state) => state.partnershipFoundersAction)
  const { successMessage, errorMessage } = partnershipFoundersAction

  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })

  useEffect(() => {
    if (successMessage) {
      setMessage({
        msgType: "message-success",
        messageBody: successMessage
      })
      onActionTrigger()
      const timeId = setTimeout(() => {
        dispatch({ type: PARTNERSHIP_ACTIONON_APPLICANT_RESET })
        clearTimeout(timeId)
      }, 3000);
    }
    if (errorMessage) {
      setMessage({
        msgType: "message-error",
        messageBody: errorMessage
      })
      const timeId = setTimeout(() => {
        dispatch({ type: PARTNERSHIP_ACTIONON_APPLICANT_RESET })
        clearTimeout(timeId)
      }, 3000);
    }
    if (!errorMessage && !successMessage) {
      setMessage({
        msgType: "",
        messageBody: ""
      })
    }
  }, [dispatch, errorMessage, successMessage])

  const handleApproval = (applicantID, key, action) => {
    dispatch(actionOnPartnershipApplication({ applicantID, key, action }, topicID))
  }
  return (
    <div className='application-wrap'>
      <div className="application_stripe-header d-flex position-relative">
        <a
          href={application.linkedinURL}
          rel="noreferrer"
          target="_blank"
          title="check user linkedin profile"
          className="img-container me-4"
        >
          <img className="profile" src="/profileLogo_new.png" alt="userlogo" />
          <img className="linkedin" src={linkedinIcon} alt="icon img" />
        </a>
        <div className="text-start">
          <h6>{`${application.userFullName}`}</h6>
          <p>{application.email}</p>
          <p>{application?.countryOfResidence?.label}</p>
        </div>
      </div>
      <div className="row-tags wrap mb-4">
        {application?.keySkills.map((_skill, indx) => {
          return (
            <p className='tag' key={indx}>{_skill.skillName} <i className="fas fa-star"></i> <span>{_skill.skillRate}</span> </p>
          )
        })}
      </div>
      <div className="application_stripe-body">
        {application.motivation}
      </div>
      {!application.approveStatus ? (
        <>
          <i
            onClick={() => handleApproval(application.applicant, index, "approve")}
            title="Shortlist as possible co-founder"
            className="approve fas fa-check-circle"
          ></i>
          <i
            onClick={() => handleApproval(application.applicant, index, "refuse")}
            title="Delete this application"
            className="disapprove fas fa-minus-circle"
          ></i>
        </>
      ) : (
        <div className="state">
          Shortlisted
          <i
            title="Remove from shortlist"
            className="fas fa-minus-circle"
            onClick={() => handleApproval(application.applicant, index, "disapprove")}
          ></i>
        </div>
      )}
      {message.messageBody && <Message ClassName={`${message.msgType} fixed-bottom`}>{message.messageBody}</Message>}
    </div>
  )
}
