import React from 'react'
import './InstructorRoles.css'
export default function InstructorRoles() {
  return (
    <div className="instructor-roles">
      <div className="container">
        <h4 className="common-heading"> Roles and Responsibilities of Instructors </h4>
        <div className="roles-container">
          {/* Starter Instructor */}
          <h4> Starter Instructor</h4>
          <h6>Advantages of joining as Starter Instructor</h6>
          <ul className="list-unstyled position-relative">
            <li>You will prepare the education material, while, we will will help you in video editing and montage, polishing your course, adding all necessary effects that ensure the production of high quality material. Once your lectures are ready, you will upload it by yourself to the website through your instructor portal on RekGPT</li>
            <li>We will help you to reach thousands or hundreds of thousands of students as we will make the paid advertisements on behalf of you. This means that we will endure 100% of marketing-related costs to help you reach your audience</li>
            <li>We will make sure that you generate profits by applying fee on your course through our platform or by getting support from some governmental organizations which will pay for your course. In general, we will allocate 50% of profits for you</li>
            <li>As an instructor on our Technopedia, you will have the merit of being a member in fast growing community and we will prioritize supporting you through our Entrepreneurship platform if you decided one day to start your own business</li>
          </ul>

          <h6>Recommendations and Notices for this track</h6>
          <ul className="list-unstyled position-relative">
            <li>This track is recommended for beginner content-maker. You commit to follow our regulations and instructions to produce high-quality online course</li>
            <li>RekGPT has all rights to decide on the pricing policies of courses according to our organization mission and goals</li>
          </ul>

          {/* Experienced Instructor */}
          <h4> Experienced Instructor</h4>
          <h6>Advantages of joining as Experienced Instructor</h6>
          <ul className="list-unstyled position-relative">
            <li>You will prepare the educational material. You will handle everything to ensure the production of high-quality material such as (video editing and montage, marketing of your course). Then, you will upload the course on the platform through your instructor portal</li>
            <li>You will enjoy the merit of deciding your pricing policy as you want (it is all up to you). However, you should accept that we will charge 25% percent of every payment that done by an enrolled student into your course. These charges will be used to advertise your course and grant scholarships to students in need for it which will be a good marketing for your services</li>
            <li>If your focus is on maximizing profits or you have experience with content creation, this track is the recommended one for you</li>
            <li>Publishing your course through our platform will enable you to reach more audience</li>
            <li>As an instructor on our Technopedia, you will have the merit of being a member in fast growing community and we will prioritize supporting you through our Entrepreneurship platform if you decided one day to start your own business</li>
          </ul>

          <h6>Recommendations and Notices for this track</h6>
          <ul className="list-unstyled position-relative">
            <li>This track is recommended for professional content-maker. You are committed to create all your course materials, edit and include explainer video to make it looking professional</li>
            <li>You should accept that the first month of releasing the platform to public, we will grant some scholarships that include free enrollement into your course as this will be part of our marketing campaign to promote your course</li>
          </ul>
          <h4>Genral Roles for all Instructors</h4>
          <ul className="list-unstyled position-relative">
            <li>It is important to know that you can only delete your course if no one yet purchased your course. Once Someone purchase your course and you get paid for it, you will not be able to delete the course from your instructor portal</li>
            <li>If you insist to delete the course, you should contact us on info@rekgpt.com and return any money you have received for the course + (10% of total money you have received as a transaction fees for sending money back to original accounts). Then, we will send money back to students </li>
            <li>If it is free course, you can delete your course anytime you want</li>
            <li>If you have a paid course, you should accept that the first month of releasing the platform, we will grant some scholarships that include free enrollement into your course as this will be part of our marketing campaign to promote your course</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
