/* Import All screens For Home Page */
import ClearPath from "../../screen/HomeSections/ClearPath/ClearPath";
import HeroImg from "../../screen/HomeSections/HeroImg/HeroImg";
import HowWorks from "../../screen/HomeSections/HowWorks/HowWorks";
import OurMission from "../../screen/HomeSections/OurMission/OurMission";
// import Planning from "../../screen/HomeSections/Planning/Planning";
// import StartTechnopedia from "../../screen/HomeSections/StartTechnopedia/StartTechnopedia";
// import StartUp from "../../screen/HomeSections/StartUp/StartUp";
// import WebSteps from "../../screen/HomeSections/WebSteps/WebSteps";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Modal from "../../screen/Modal/Modal";

/* === Import Custom Hook To Change Page Title === */
import { useDocTitle } from "../../hooks/ChangePageTitle";
// import SignScreen from "../../screen/SignScreen/SignScreen";
// import PromotionScreen from "../../screen/PromotionScreen/PromotionScreen";
import { linkedinLogin } from "../../actions/userActions";
// import CongratulateScreen from "../../screen/PromotionScreen/CongratulateScreen";
// import OAuthScreen from "../../screen/SignScreen/OAuthScreen";

const MainPage = ({ location }) => {
  /* === Page Title === */
  useDocTitle("RekGPT | Best software to manage your hiring process in smart way");
  const dispatch = useDispatch();

  // const [signUpInvite, setSignUpInvite] = useState(false);
  // comes from server side later
  // const userId = true

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin

  // const grantInformation = useSelector((state) => state.grantInformation)
  // const { grantInfo } = grantInformation
  // const [extractedCode, setExtractedCode] = useState("")

  // useEffect(() => {
  //   // ******* This can be used if smooth scroll needed********
  //   // window.scroll({
  //   //   top: 0, 
  //   //   left: 0, 
  //   //   behavior: 'smooth' 
  //   //  })
  //   window.scrollTo(0, 0)
  //   setTimeout(() => {
  //     setSignUpInvite(true);
  //   }, 3000);
  // }, []);

  useEffect(() => {
    const redirectCode = location?.search
      ? location.search.split("code=")[1]
      : null;
    const authorizCode = redirectCode ? redirectCode.split("&state=")[0] : null;
    const stateCode = redirectCode ? redirectCode.split("&state=")[1] : null;
    // this will be changed later to fetch current location of user
    const redirectUrl = window?.location?.origin;
    const authorizationInfo = { authorizCode, stateCode, redirectUrl };
    // console.log(authorizationInfo);
    if (authorizCode) {
      const grantCode = localStorage.getItem('grantCode') ? JSON.parse(localStorage.getItem('grantCode')) : ""
      dispatch(linkedinLogin({ ...authorizationInfo, grantCode: grantCode }));
      // const { data } = axios.post('api/linkedin/verifyuser', authorizationInfo, config)
    }

    // URL: /?code=314KHeQ89

    // const codeURL = location.search ? location.search.split('grant=')[1] : null
    // let _extractedCode = ""
    // if (codeURL) {
    //   if (codeURL.includes("&")) {
    //     _extractedCode = codeURL.split('&')[0]
    //   } else {
    //     _extractedCode = codeURL
    //   }
    // }

    // const _extractedCode = codeURL ? codeURL.split('&')[0] : ""
    // console.log(_extractedCode)
    // if (_extractedCode) {
    //   localStorage.setItem('grantCode', JSON.stringify(_extractedCode))
    //   setExtractedCode(_extractedCode)
    //   // setCongratulateMsg("Congratulation!! You have now a grant code that can be used to enroll for any 2 paid courses for free")
    // }

  }, [location, dispatch]);

  // const closeModal = () => {
  //   setSignUpInvite(false);
  // };
  // console.log("signUpInvite:", signUpInvite);
  return (
    <>
      <HeroImg />
      {/* <Planning /> */}
      <HowWorks />
      <OurMission />
      <ClearPath />
      {/* <StartTechnopedia /> */}
      {/* <StartUp />
      <WebSteps /> */}
      {/* {signUpInvite && (
        !userInfo && (
          <Modal closeModal={closeModal}>
            <OAuthScreen
              closeModal={closeModal}
              ClassName="signup-popup-screen"
            />
          </Modal>
        )
      )} */}
    </>
  );
};

export default MainPage;
