import React, { useState } from 'react'
import ButtonLink from '../../components/ButtonLink/ButtonLink'
import cancelIcon from '../../images/general-purpose/cancelIcon.png'

export default function JoinListForm({ closeModal, handleSubscriptionAction, planIndx }) {
  const [registerForm, setRegisterForm] = useState({
    name: "",
    email: "",
    companyName: "",
    website: ""
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setRegisterForm((prevEntries) => {
      return {
        ...prevEntries,
        [name]: value
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    handleSubscriptionAction(registerForm, planIndx)
  }

  return (
    <div className='form_container' onClick={e => e.stopPropagation()}>
      <div className="cancel-button">
        <div className="cancel-wrapper" onClick={closeModal}>
          <img src={cancelIcon} alt="How We Work" />
        </div>
      </div>
      <p>Join our <span className="highlight-txt"> list of early users </span>. These prices for early customers and they will continue to enjoy our services in these prices <span className="highlight-txt"> forever</span>. you don't want to miss that!</p>
      {/* <iframe
      title="SendinBlue Form"
      width="100%"
      height="500"
      src="https://5d4ea543.sibforms.com/serve/MUIEAJCRXdMYMKI9L3CKV3m8yseWv4O3b67PRrtMWlmGLUGB0Ift8qhhwlMLXV3UWVDwRJIN1DYt87SGzcKV8QEjx20X-INQYWGX-lcWJr8tRSgfGOkqa3qd0Vn7_JKP2Mf2sKPaCbPBG41TopE9CViG32PvfekkJbIana8Dd1EsCy6A--QKl_020coP9KDvSlyCPPjws-ZINlwV"
      frameBorder="0"
      scrolling="auto"
      allowFullScreen
    ></iframe> */}
      <div className='form_entries'>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <label className='general-input--style-label'> Your Name </label>
            <input
              className="form-control general-input--style mb-3"
              type="text"
              name="name"
              placeholder='Your Name'
              value={registerForm.name}
              onChange={handleChange}
            />
          </div>
          <div className='col-md-6 col-sm-12'>
            <label className='general-input--style-label'> Your Email </label>
            <input
              className="form-control general-input--style mb-3"
              type="text"
              name="email"
              placeholder='Your Email'
              value={registerForm.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <label className='general-input--style-label'> Company Name </label>
            <input
              className="form-control general-input--style mb-3"
              type="text"
              name="companyName"
              placeholder='Company Name'
              value={registerForm.companyName}
              onChange={handleChange}
            />
          </div>
          <div className='col-md-6 col-sm-12'>
            <label className='general-input--style-label'> Company website <span>(Optional)</span> </label>
            <input
              className="form-control general-input--style mb-3"
              type="text"
              name="website"
              placeholder='Company website'
              value={registerForm.website}
              onChange={handleChange}
            />
          </div>
        </div>


        <ButtonLink ClassName="learn-more-btn confirm float-right mt-3">
          <button onClick={handleSubmit} >Join</button>
        </ButtonLink>
      </div>
    </div>
  )
}
