import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux'
import store from './store'
import "./index.css";
import "./animate.min.css"
// import App from "./App";

/* === Import Bootstrap === */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Router } from "react-router-dom";
import history from "./history";
// import PlaceHolderMessage from "./components/PlaceHolderMessage";
import App from "./App";

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      {/* <PlaceHolderMessage>
        <h4> RekGPT will come under a new brand. The platform is under update to give you the best service. We will be here again soon</h4>
      </PlaceHolderMessage> */}
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
