import React from 'react'
import { useState } from 'react'
import './style.css'

export default function Rating({ value, classname, onRating, ratingInfo }) {

  const [rateValue, setRateValue] = useState(value)
  const onRateSelection = (newValue) => {
    setRateValue(newValue)
    onRating(newValue, ratingInfo)
  }

  return (
    <div className={classname}>
      {rateValue >= 4.5 ? (
        <ul>
          <li onClick={() => onRateSelection(1)} className='fas fa-star'></li>
          <li onClick={() => onRateSelection(2)} className='fas fa-star'></li>
          <li onClick={() => onRateSelection(3)} className='fas fa-star'></li>
          <li onClick={() => onRateSelection(4)} className='fas fa-star'></li>
          <li onClick={() => onRateSelection(5)} className='fas fa-star'></li>
        </ul>
      ) : rateValue >= 3.5 ? (
        <ul>
          <li onClick={() => onRateSelection(1)} className='fas fa-star'></li>
          <li onClick={() => onRateSelection(2)} className='fas fa-star'></li>
          <li onClick={() => onRateSelection(3)} className='fas fa-star'></li>
          <li onClick={() => onRateSelection(4)} className='fas fa-star'></li>
          <li onClick={() => onRateSelection(5)} className='fas fa-star' style={{ color: '#00000033' }}></li>
        </ul>
      ) : rateValue >= 2.5 ? (
        <ul>
          <li onClick={() => onRateSelection(1)} className='fas fa-star'></li>
          <li onClick={() => onRateSelection(2)} className='fas fa-star'></li>
          <li onClick={() => onRateSelection(3)} className='fas fa-star'></li>
          <li onClick={() => onRateSelection(4)} className='fas fa-star' style={{ color: '#00000033' }}></li>
          <li onClick={() => onRateSelection(5)} className='fas fa-star' style={{ color: '#00000033' }}></li>
        </ul>
      ) : rateValue >= 1.5 ? (
        <ul>
          <li onClick={() => onRateSelection(1)} className='fas fa-star'></li>
          <li onClick={() => onRateSelection(2)} className='fas fa-star'></li>
          <li onClick={() => onRateSelection(3)} className='fas fa-star' style={{ color: '#00000033' }}></li>
          <li onClick={() => onRateSelection(4)} className='fas fa-star' style={{ color: '#00000033' }}></li>
          <li onClick={() => onRateSelection(5)} className='fas fa-star' style={{ color: '#00000033' }}></li>
        </ul>
      ) : rateValue >= 0.5 ? (
        <ul>
          <li onClick={() => onRateSelection(1)} className='fas fa-star'></li>
          <li onClick={() => onRateSelection(2)} className='fas fa-star' style={{ color: '#00000033' }}></li>
          <li onClick={() => onRateSelection(3)} className='fas fa-star' style={{ color: '#00000033' }}></li>
          <li onClick={() => onRateSelection(4)} className='fas fa-star' style={{ color: '#00000033' }}></li>
          <li onClick={() => onRateSelection(5)} className='fas fa-star' style={{ color: '#00000033' }}></li>
        </ul>
      ) : (
        <ul>
          <li onClick={() => onRateSelection(1)} className='fas fa-star' style={{ color: '#00000033' }}></li>
          <li onClick={() => onRateSelection(2)} className='fas fa-star' style={{ color: '#00000033' }}></li>
          <li onClick={() => onRateSelection(3)} className='fas fa-star' style={{ color: '#00000033' }}></li>
          <li onClick={() => onRateSelection(4)} className='fas fa-star' style={{ color: '#00000033' }}></li>
          <li onClick={() => onRateSelection(5)} className='fas fa-star' style={{ color: '#00000033' }}></li>
        </ul>
      )}
    </div>
  )
}
