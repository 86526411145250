import React from 'react'
import ButtonLink from '../../components/ButtonLink/ButtonLink'

export default function HiringPlus({ openModal }) {
  return (
    <div className="card_container">
      <div className='recommend'>Perfect for staffing agencies</div>
      {/* <!-- card-header start --> */}
      <div className="card_header">
        <div className="pricing_title">
          <h5 className="ultra-strong">
            Premium
          </h5>
          <h6 className="light">
            Your Own Recruitment Board and More
          </h6>
        </div>
        <h2 className="price main-color">
          {/* <span className='temp'>$70</span> */}
          $30
          <span className="text-color period">
            /month
          </span>
          <span className='separator'>OR</span>
          $150
          <span className="text-color period">
            /year
          </span>
        </h2>
      </div>
      {/* <!-- card-header end --> */}
      {/* <!-- card-body start --> */}
      <div className="card_body general-list-style">
        <ul className="list-unstyled position-relative">
          <li className='firstcolor'><b>Includes everything in Hiring Board <br></br> <span className='xxlargefont'>+</span></b></li>
          <li className='styled-help-info'>
            <b>Free access to our Talents Pool</b>
            <i className="far fa-question-circle">
              <span>
                We have thosands of job seekrs, we carefully selected the best and added them to our talents pool. You can access to this pool and headhunt best talent according your business requirements
              </span>
            </i>
          </li>
          <li className='styled-help-info'>
            <b>Multi-channel stream to public job platforms</b>
            <i className="far fa-question-circle">
              <span>
                By just publishing your job on RekGPT, it will be automatically published on public platforms such as 'LinkedIn', 'GlassDoor', 'Indeed' and other popular platforms. This feature combined with our AI algorithm will ensure that you will have access to huge pool of talents and hunt the best among them
              </span>
            </i>
          </li>
          <li className='styled-help-info'>
            <b>Questionnaire builder for pre-evaluation</b>
            <i className="far fa-question-circle">
              <span>
                build a job application form and share it with your job applicants, so you can pre-evaluate candidates before interviewing them. Questions and answers of this Questionnaire will increase the accuracy of your AI-assistant to hunt best talents
              </span>
            </i>
          </li>
        </ul>
        <ButtonLink ClassName="learn-more-btn styled-help-info">
          <button title='interested to know how these premium features will be game-changer for you, watch a demo with us' onClick={() => { openModal(2) }}>Interested in Demo</button>
          <i>?
            <span>
              interested to know how these premium features will be game-changer for you, watch a demo with us
            </span>
          </i>
        </ButtonLink>
      </div>
      {/* <!-- card-body end --> */}
      {/* <!-- card-footer end --> */}
      <div className="card-footer bg-transparent border-0 px-0 py-0">
        <p className='trial'>First month for free!, no credit card info required!</p>
        <ButtonLink ClassName="learn-more-btn full-width">
          <button onClick={() => { openModal(2) }}>Subscribe</button>
        </ButtonLink>
      </div>
      {/* <!-- card-footer end --> */}
    </div>
  )
}
