import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./FounderAdvertiseForm.css"
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Message from '../../components/Message/Message';
import history from '../../history';
import { createPartnership } from '../../actions/partnershipActions';
import { useEffect } from 'react';
import { PARTNERSHIP_CREATE_RESET } from '../../constants/partnershipConstants';

export default function CreatePartnershipRequest() {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const partnershipCreate = useSelector((state) => state.partnershipCreate)
  const { createSuccess, createError } = partnershipCreate

  const [partnershipForm, setPartnershipForm] = useState({
    position: "",
    minWorkHoursWeek: 0,
    deadLine: "",
    fundingPattern: "",
    minimumEquity: 0,
    maximumEquity: 0,
    positionDescription: "",

    companyName: "",
    copmpanyRegisteredLocation: "",
    currentPhase: "",
    businessIndustry: "",
    explainerVideo: "",
    businessPitch: "",
  })

  const [requirementsArray, setRequirementsArray] = useState([])
  const [singleRequirement, setSingleRequirement] = useState("")

  const [keySkillsArray, setKeySkillsArray] = useState([])
  const [singleKeySkill, setSingleKeySkill] = useState("")

  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setPartnershipForm((prevEntries) => {
      return {
        ...prevEntries,
        [name]: value
      }
    })
  }

  const handleRequirementsRecord = (event) => {
    if (event.key === 'Enter') {
      // console.log('enter key pressed')
      setRequirementsArray(preRequirements => {
        return [...preRequirements, singleRequirement]
      })
      setSingleRequirement("")
    }
  }
  const removeRequirement = (indx) => {
    setRequirementsArray((preRequirements) => {
      return preRequirements.filter((requirement, id) => { return id !== indx })
    })
  }

  const handleSkillsRecord = (event) => {
    if (event.key === 'Enter') {
      // console.log('enter key pressed')
      setKeySkillsArray(preSkills => {
        return [...preSkills, singleKeySkill]
      })
      setSingleKeySkill("")
    }
  }
  const removeKeySkill = (indx) => {
    setKeySkillsArray((preSkills) => {
      return preSkills.filter((skill, id) => { return id !== indx })
    })
  }

  useEffect(() => {
    if (!userInfo?.isApprovedFounder && !userInfo?.isFounder) {
      history.push('/')
    }
    if (createError) {
      setMessage({
        msgType: "message-error",
        messageBody: createError
      })
      return
    }
    if (createSuccess) {
      setMessage({
        msgType: "message-success",
        messageBody: "Your Partnership request is published successfully, you will be redirected to your board.."
      })
      setTimeout(() => {
        history.push("/profile/founderboard")
        dispatch({ type: PARTNERSHIP_CREATE_RESET })
      }, 5000);
    }
  }, [createSuccess, createError, dispatch, userInfo])

  const createPartnershipHandler = (e) => {
    e.preventDefault()
    if (!partnershipForm.position) {
      setMessage({
        msgType: "message-error",
        messageBody: "Co-Founder Profession is missing"
      })
      return
    }
    if (!partnershipForm.minimumEquity || !partnershipForm.maximumEquity) {
      setMessage({
        msgType: "message-error",
        messageBody: "Either Maximum or Minimum Equity is missing"
      })
      return
    }
    if (!partnershipForm.minWorkHoursWeek) {
      setMessage({
        msgType: "message-error",
        messageBody: "Minimum Number of Work Hours per Week is missing"
      })
      return
    }
    if (!partnershipForm.fundingPattern) {
      setMessage({
        msgType: "message-error",
        messageBody: "Funding Pattern of your startup is missing"
      })
      return
    }
    if (!partnershipForm.positionDescription) {
      setMessage({
        msgType: "message-error",
        messageBody: "Description of Co-Founder Position is missing"
      })
      return
    }
    if (!partnershipForm.companyName) {
      setMessage({
        msgType: "message-error",
        messageBody: "Company Name is missing"
      })
      return
    }
    if (!partnershipForm.currentPhase) {
      setMessage({
        msgType: "message-error",
        messageBody: "Current Phase of your startup is missing"
      })
      return
    }
    if (!partnershipForm.businessIndustry) {
      setMessage({
        msgType: "message-error",
        messageBody: "Industry of your startup is missing"
      })
      return
    }
    if (keySkillsArray.length === 0) {
      setMessage({
        msgType: "message-error",
        messageBody: "keySkills of wanted co-founder are missing"
      })
      return
    }
    if (!partnershipForm.businessPitch) {
      setMessage({
        msgType: "message-error",
        messageBody: "Description of your startup business is missing"
      })
      return
    }
    const _partnershipForm = {
      ...partnershipForm,
      positionKeySkills: keySkillsArray,
      positionRequirements: requirementsArray,
      publicPublished: true
    }
    // console.log("_partnershipForm: ", _partnershipForm)
    dispatch(createPartnership(_partnershipForm))
  }
  const savePathHandler = (e) => {
    e.preventDefault()
    if (!partnershipForm.position) {
      setMessage({
        msgType: "message-error",
        messageBody: "Co-Founder Profession must be mentioned before saving"
      })
      return
    }
    if (!partnershipForm.companyName) {
      setMessage({
        msgType: "message-error",
        messageBody: "Company Name must be mentioned before saving"
      })
      return
    }
    const _partnershipForm = {
      ...partnershipForm,
      positionKeySkills: keySkillsArray,
      positionRequirements: requirementsArray,
      publicPublished: false
    }
    // console.log("_partnershipForm: ", _partnershipForm)
    dispatch(createPartnership(_partnershipForm))
  }


  return (
    <div className="partnerships-editor">
      <div className="container">
        <h4 className="common-heading"> Create A Partnership request </h4>
        <div className="partnership-content">
          <div className="partnership-form">
            <h4>Co-Founder Details</h4>
            <div className="row justify-content-between align-items-center">
              <div className="col-md-4 col-sm-12">
                <label className="highlight-txt"> Co-Founder Profession* </label>
                <input
                  className="partnership_input"
                  type="text"
                  name="position"
                  value={partnershipForm.position}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label> Minimum work hours per week </label>
                <input
                  className="partnership_input"
                  type="number"
                  name="minWorkHoursWeek"
                  value={partnershipForm.minWorkHoursWeek}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Deadline to apply <span>(Optional)</span> </label>
                <input
                  className="partnership_input"
                  type="text"
                  name="deadLine"
                  placeholder="dd/mm/yyyy"
                  value={partnershipForm.deadLine}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row justify-content-between align-items-center">
              <div className="col-md-6 col-sm-12">
                <label>Funding Pattern</label>
                <select name="fundingPattern" className="form-select partnership_input" onChange={handleChange}>
                  <option defaultValue=""> - </option>
                  <option value="Share by Hard Work"> Share by Hard Work </option>
                  <option value="Share in Expenses">Share in Expenses</option>
                  <option value="Share in expenses and work load">Share in expenses and work load</option>
                </select>
              </div>
              <div className="col-md-3 col-sm-6">
                <label> minimum Equity (%)</label>
                <input
                  className="partnership_input"
                  type="number"
                  max="100"
                  min="0"
                  name="minimumEquity"
                  value={partnershipForm.minimumEquity}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <label> maximum Equity (%)</label>
                <input
                  className="partnership_input"
                  type="number"
                  max="100"
                  min="0"
                  name="maximumEquity"
                  value={partnershipForm.maximumEquity}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <label>position Description</label>
                <textarea
                  className="partnership_input"
                  type="text"
                  name="positionDescription"
                  value={partnershipForm.positionDescription}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row justify-content-between align-items-center">
              <div className="col-md-6 col-sm-12">
                <label>Requirements <span>(Optional Field, Press Enter Key after writing each requirement)</span></label>
                <input
                  onChange={(e) => setSingleRequirement(e.target.value)}
                  className="partnership_input"
                  type="text"
                  name="singleRequirement"
                  value={singleRequirement}
                  onKeyDown={handleRequirementsRecord}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <label>Key Skills <span>(({singleKeySkill.length}/30), Press Enter Key after writing each skill)</span></label>
                <input
                  onChange={(e) => setSingleKeySkill(e.target.value)}
                  className="partnership_input"
                  type="text"
                  maxLength="30"
                  name="singleKeySkill"
                  value={singleKeySkill}
                  onKeyDown={handleSkillsRecord}
                />
              </div>
            </div>

            <div className="row justify-content-between align-items-center">
              <div className="col-md-6 col-sm-12">
                <div className="row-tags sentences">
                  {requirementsArray.map((_requirement, indx) => {
                    return (
                      <p key={indx}>{_requirement} <i className="fas fa-minus-circle" onClick={() => removeRequirement(indx)}></i></p>
                    )
                  })}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="row-tags sentences">
                  {keySkillsArray.map((_skill, indx) => {
                    return (
                      <p key={indx+_skill}>{_skill} <i className="fas fa-minus-circle" onClick={() => removeKeySkill(indx)}></i></p>
                    )
                  })}
                </div>
              </div>
            </div>

            <hr />

            <h4 className="mt-4">Your Start-up Details</h4>
            <div className="row justify-content-between align-items-center">
              <div className="col-md-3 col-sm-12">
                <label className="highlight-txt">Company Name*</label>
                <input
                  className="partnership_input"
                  type="text"
                  name="companyName"
                  value={partnershipForm.companyName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-9 col-sm-12">
                <label> Registered Location of Your Start-up <span>(Optional)</span></label>
                <input
                  className="partnership_input"
                  type="text"
                  name="copmpanyRegisteredLocation"
                  placeholder="Example: 'London, UK'"
                  value={partnershipForm.copmpanyRegisteredLocation}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-md-6 col-sm-12">
                <label>Current Phase</label>
                <select name="currentPhase" className="form-select partnership_input" onChange={handleChange}>
                  <option defaultValue=""> - </option>
                  <option value="Idea"> Idea </option>
                  <option value="Prototype">Prototype</option>
                  <option value="Launch">Launch</option>
                  <option value="Users">Users</option>
                </select>
              </div>
              <div className="col-md-6 col-sm-12">
                <label>Your Start-up Industry</label>
                <input
                  className="partnership_input"
                  type="text"
                  name="businessIndustry"
                  value={partnershipForm.businessIndustry}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row justify-content-between align-items-center">
              <div className="col-md-8 col-sm-12">
                <label> Explainer video of your startup <span>(optional but highly recommended)</span></label>
                <input
                  className="partnership_input"
                  type="text"
                  name="explainerVideo"
                  value={partnershipForm.explainerVideo}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <label> Quick description about your start-up </label>
                <textarea
                  placeholder="Short information about company business or may be a link to their website"
                  className="partnership_input"
                  type="text"
                  name="businessPitch"
                  value={partnershipForm.businessPitch}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row justify-content-between align-items-center actions-section">
              <div className="col-md-7 col-sm-12">
                <ButtonLink ClassName="learn-more-btn cancel">
                  <button onClick={() => history.push("/profile/founderboard")}>Cancel</button>
                </ButtonLink>
              </div>
              <div className="col-md-3 col-sm-12">
                <ButtonLink ClassName="learn-more-link">
                  <button onClick={savePathHandler}>Save And Continue Later</button>
                </ButtonLink>
              </div>
              <div className="col-md-2 col-sm-12">
                <ButtonLink ClassName="learn-more-btn confirm">
                  <button onClick={createPartnershipHandler}>Publish</button>
                </ButtonLink>
              </div>
            </div>
          </div>
          {message.messageBody && <Message ClassName={`${message.msgType} fixed-bottom`}>{message.messageBody}</Message>}
        </div>
      </div>
    </div>
  )
}
