import { CATEGORIES_ADMIN_LIST_FAIL, CATEGORIES_ADMIN_LIST_SUCCESS, CATEGORIES_LIST_FAIL, CATEGORIES_LIST_SUCCESS, CATEGORIES_UPDATE_STATUS_FAIL, CATEGORIES_UPDATE_STATUS_RESET, CATEGORIES_UPDATE_STATUS_SUCCESS } from "../constants/categoriesConstants"

export const categoriesListReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case CATEGORIES_LIST_SUCCESS:
      return { categories: action.payload }
    case CATEGORIES_LIST_FAIL:
      return { error: action.payload }
    default:
      return state
  }
}

export const adminCategoriesListReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case CATEGORIES_ADMIN_LIST_SUCCESS:
      return { categories: action.payload }
    case CATEGORIES_ADMIN_LIST_FAIL:
      return { error: action.payload }
    default:
      return state
  }
}

export const updateCategoryStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORIES_UPDATE_STATUS_SUCCESS:
      return { updateSuccess: true }
    case CATEGORIES_UPDATE_STATUS_FAIL:
      return { updateError: action.payload }
    case CATEGORIES_UPDATE_STATUS_RESET:
      return {}
    default:
      return state
  }
}