import React from 'react'

export default function CourseRelatedCategories({ coursedetails }) {
  return (
    <>
      <div className="sidebar-categories">
        <h4>Industries</h4>
        <div className="row-tags skills wrap">
          {coursedetails?.courseCategory.map((cat, indx) => {
            return (
              <p key={indx}>{cat}</p>
            )
          })}
        </div>
      </div>
      {coursedetails?.keySkills && <div className="sidebar-categories">
        <h4>Key Skills</h4>
        <div className="row-tags skills wrap">
          {coursedetails?.keySkills.map((cat, indx) => {
            return (
              <p key={indx}>{cat}</p>
            )
          })}
        </div>
      </div>}
      {coursedetails?.relatedJobs && <div className="sidebar-categories">
        <h4>Related Positions</h4>
        <div className="row-tags skills wrap">
          {coursedetails?.relatedJobs.map((cat, indx) => {
            return (
              <p key={indx}>{cat}</p>
            )
          })}
        </div>
      </div>}
    </>
  )
}
