import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { deletePartnership, listFounderPartnerships } from '../../../actions/partnershipActions'
import { updateUserProfile } from '../../../actions/userActions'
import ButtonLink from '../../../components/ButtonLink/ButtonLink'
import Message from '../../../components/Message/Message'
import PartnershipStripe from '../../../components/PartnershipStripe/PartnershipStripe'
import Spinner from '../../../components/Spinner/Spinner'
import { PARTNERSHIP_CREATE_RESET, PARTNERSHIP_DELETE_RESET } from '../../../constants/partnershipConstants'
import { USER_UPDATE_PROFILE_RESET } from '../../../constants/userConstants'
import history from '../../../history'
import Modal from '../../../screen/Modal/Modal'
import ConfirmModal from '../ModalScreens/ConfirmModal'
import EditInfoModal from '../ModalScreens/EditInfoModal'

export default function FounderPanel() {
  const dispatch = useDispatch()
  const { path: pathroot } = useRouteMatch()
  const { userInfo } = useSelector((state) => state.userLogin)
  const { error: errorUpdate, success: successUpdate } = useSelector((state) => state.userUpdateProfile)
  const { founderPartnerships, loading: loadingList, error: errorList } = useSelector((state) => state.founderPartnershipList)
  const { deleteSuccess, deleteError } = useSelector((state) => state.partnershipDelete)

  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })
  const [itemID, setItemID] = useState("")
  const [modalOpen, setModalOpen] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }
  const openAddModal = () => {
    setAddModal(true)
  }
  const closeAddModal = () => {
    setAddModal(false)
  }
  const openDeleteModal = (id, _key) => {
    setDeleteModal(true)
    setItemID(id)
  }
  const closeDeleteModal = () => {
    setDeleteModal(false)
  }
  const deleteHandler = () => {
    dispatch(deletePartnership(itemID))
    setDeleteModal(false)
  }
  const onActionTrigger = () => {
    dispatch(listFounderPartnerships())
  }
  // console.log("founderPartnerships: ", founderPartnerships)

  // fetch Partnership announcements once page mounts
  useEffect(() => {
    dispatch(listFounderPartnerships())
    // reserved space for deletehandlers
    if (deleteSuccess) {
      setMessage({
        msgType: "message-success",
        messageBody: "Partnership vaccancy is deleted successfully"
      })
      setTimeout(() => {
        dispatch({ type: PARTNERSHIP_DELETE_RESET })
      }, 3000);
    }
    if (deleteError) {
      setMessage({
        msgType: "message-error",
        messageBody: "Couln't delete partnership vaccancy, Try again!"
      })
      setTimeout(() => {
        dispatch({ type: PARTNERSHIP_DELETE_RESET })
      }, 3000);
    }
    if (!deleteError && !deleteSuccess) {
      setMessage({
        msgType: "",
        messageBody: ""
      })
    }

  }, [dispatch, deleteSuccess, deleteError])

  useEffect(() => {
    if (!userInfo?._id) {
      setTimeout(() => {
        history.push(`/signin?redirect=${pathroot}`)
      }, 500);
    } else {
      if (errorUpdate) {
        setMessage({
          msgType: "message-error",
          messageBody: errorUpdate
        })
        return
      }
      if (successUpdate) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        setMessage({
          msgType: "message-success",
          messageBody: "Information Updated successfully"
        })
      }
    }
  }, [userInfo, dispatch, errorUpdate, successUpdate, pathroot])

  const onUpdateConfirm = (_founderDetails) => {
    dispatch(updateUserProfile(_founderDetails))
  }

  return (
    <div className="mentor-panel">
      {loadingList && <Spinner />}
      {errorList && <Message ClassName="message-error">{errorList}</Message>}
      {!loadingList && !errorList && (
        <>
          <div className="panel-information">
            <div className="title-bar-profile">
              <div className="sction-title">Information</div>
              <div title="Edit your Basic Information" className="user-action" onClick={openModal}><i className="fas fa-edit"></i>EDIT</div>
            </div>
            <div className="info-body">
              <p>Profession</p>
              <h6>{userInfo?.founderDetails?.founderProfession}</h6>
            </div>
            <div className="info-body">
              <p>Description</p>
              <h6>{userInfo?.founderDetails?.founderIntroduction}</h6>
            </div>
          </div>
          <div className="paths-control">
            <div className="title-bar-profile">
              <div className="sction-title">Partnership Requests</div>
              <div
                title="Create a new Learning Path"
                className="user-action"
                onClick={openAddModal}
              >
                <i className="fas fa-plus"></i>
                ADD
              </div>
            </div>
          </div>
          <div className="partnership-stripes-container">
            {(founderPartnerships.length !== 0) && (
              founderPartnerships.map((_partnership, _indx) => (
                <PartnershipStripe
                  key={_indx}
                  indx={_indx}
                  partnership={_partnership}
                  removeHandler={(id) => openDeleteModal(id)}
                  onActionTrigger={onActionTrigger}
                />
              ))
            )}
          </div>
        </>
      )}
      {message.messageBody && <Message ClassName={`${message.msgType} fixed-bottom`}>{message.messageBody}</Message>}
      {modalOpen && (
        <Modal closeModal={closeModal}>
          <EditInfoModal
            closeModal={closeModal}
            title="Founder Information"
            userType="founder"
            details={userInfo?.founderDetails}
            onUpdateConfirm={onUpdateConfirm}
          />
        </Modal>
      )}
      {addModal && (
        <Modal closeModal={closeAddModal}>
          <ConfirmModal
            title="Create a Request for Partnership"
            body="Pressing confirm will take you to new page where you can create an advertise to find a co-founder in your start-up or"
            closeModal={closeAddModal}
          >
            <ButtonLink ClassName="learn-more-btn cancel float-left half-width">
              <button onClick={closeAddModal}> Cancel </button>
            </ButtonLink>
            <ButtonLink ClassName="learn-more-btn confirm float-right half-width">
              <button onClick={() => history.push('/founder/partnership-request/create')}> Confirm </button>
            </ButtonLink>
          </ConfirmModal>
        </Modal>
      )}
      {deleteModal && (
        <Modal closeModal={() => closeDeleteModal()}>
          <ConfirmModal
            closeModal={() => closeDeleteModal()}
            title="Delete"
            body="Are you sure you want to delete this Partnership request? It will remove it permanently!"
          >
            <ButtonLink ClassName="learn-more-btn confirm float-left half-width">
              <button onClick={() => closeDeleteModal()}> Cancel </button>
            </ButtonLink>
            <ButtonLink ClassName="learn-more-btn cancel float-right half-width">
              <button onClick={() => deleteHandler()}> Delete </button>
            </ButtonLink>
          </ConfirmModal>
        </Modal>
      )}
    </div>
  )
}
