import axios from 'axios';
import draftToHtml from 'draftjs-to-html';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router'
import { ListPathDetails } from '../../actions/pathActions';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Canvas from '../../components/Canvas/Canvas';
import Message from '../../components/Message/Message';
import history from '../../history';
// import userLogo from '../../images/userLogo.svg'

export default function UserLearnPath() {
  const { pathId } = useParams()
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const pathDetails = useSelector((state) => state.pathDetails)
  const { pathdetails, detailserror } = pathDetails

  const [_selectedPath, setSelectedPath] = useState({})
  // msgToMentor
  const [msgToMentor, setMsgToMentor] = useState("")
  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })

  // General function for csending message to backend************
  const establishChatChannel = async (_userInfo, receiverId, conversationScope, msgBody) => {
    if (!_userInfo || !receiverId || !conversationScope || !msgBody) {
      setMessage({
        msgType: "error",
        messageBody: "Couldn't send your message, Please try again within seconds"
      })
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${_userInfo.token}`
      },
    }

    try {
      const conversationFetails = {
        senderId: _userInfo._id,
        receiverId: receiverId,
        conversationScope: conversationScope
      }
      const { data: conversationInfo } = await axios.post(`/api/conversations`, conversationFetails, config);

      const MessageDetails = {
        conversationId: conversationInfo._id,
        sender: _userInfo._id,
        text: msgBody
      }
      await axios.post(`/api/messages`, MessageDetails, config)
      
      setMessage({
        msgType: "success",
        messageBody: "Your Message Is Sent Successfully"
      })

    } catch (err) {
      setMessage({
        msgType: "error",
        messageBody: err.message
      })
    }
  };

  const handleChange = (event) => {
    const { value } = event.target
    setMsgToMentor(value)
  }

  const handleMsgSend = (event) => {
    event.preventDefault()
    if(!msgToMentor) {
      setMessage({
        msgType: "error",
        messageBody: "You Are Sending Empty Message!"
      })
      return
    }
    establishChatChannel(userInfo, _selectedPath.user._id, `LearnPath | ${_selectedPath?.pathName}`, msgToMentor)
  }

  useEffect(() => {
    if (!userInfo?._id) {
      history.push('/signin')
      return
    }
    if (userInfo?.enrolledPath?.toString() !== pathId) {
      history.push('/')
      return
    }
    if (pathId) {
      if (detailserror) {
        setMessage({
          msgType: "error",
          messageBody: detailserror
        })
        return
      }
      if (!pathdetails) {
        dispatch(ListPathDetails(pathId))
      } else {
        setSelectedPath(pathdetails)
      }
    }
  }, [detailserror, pathdetails, dispatch, pathId, userInfo?._id, userInfo?.enrolledPath])

  const draw = (ctx) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.lineWidth = 3;
    // full circle
    ctx.beginPath();
    ctx.strokeStyle = "#e0e0e0";
    ctx.arc(100, 75, 50, 0, 2 * Math.PI);
    ctx.stroke();
    // indicator
    ctx.lineWidth = 4;
    ctx.beginPath();
    ctx.strokeStyle = "#7ef0ac";
    ctx.arc(100, 75, 50, 0, (_selectedPath?.jobDemand / 100) * 2 * Math.PI);
    ctx.stroke();
    // indicator
    ctx.beginPath();
    ctx.strokeStyle = "#604cac";
    ctx.arc(100, 75, 35, 0, (_selectedPath?.startEasy / 100) * 2 * Math.PI);
    ctx.stroke();
    return [_selectedPath?.startEasy, _selectedPath?.jobDemand]
  }

  return (
    <div className="details-learnPaths">
      <h3 className="text-capitalize common-heading">{_selectedPath?.pathName}</h3>
      <div className="container-lg">
        {_selectedPath?.article ? (
          <div className="tab-content">
            {/* Start Details Jobs */}
            <div className="body-details d-flex position-relative">
              <div>
                <p className="shadowed">Key skills of the track:</p>
                {_selectedPath?.keySkills?.length !== 0 && (
                  <div className="row-tags mt-3">
                    {_selectedPath?.keySkills?.map((cat, indx) => {
                      return (
                        <p key={indx}>{cat}</p>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="more-info mt-3">
              <div className="article" dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(_selectedPath?.article)) }}>
              </div>
              {(_selectedPath?.jobDemand || _selectedPath?.startEasy) && (
                <div className="row align-items-center statistics-container">
                  <div className="col-md-3 col-sm-12">
                    <div className="statistics-indicator">
                      <Canvas draw={draw} />
                    </div>
                  </div>
                  <div className="col-md-7 col-sm-12">
                    <div className="statistics-info">
                      <p className="job">Level of track-demand in job market</p>
                      <span>{_selectedPath?.jobDemand} %</span>
                    </div>
                    <div className="statistics-info">
                      <p className="startB">Easiness to starting up a business</p>
                      <span>{_selectedPath?.startEasy} %</span>
                    </div>
                  </div>
                </div>
              )}
              <div className="row path-instruct-wrap">
                <div className="btn-paths-info d-flex position-relative">
                  <img className="img-fluid me-4" src="/profileLogo_new.png" alt="userLogo" />
                  <div className="text-start">
                    <h5>{_selectedPath?.user?.name}</h5>
                    <span>{_selectedPath?.user?.mentorDetails?.mentorProfession}</span>
                  </div>
                </div>
                <p className="mt-3">{_selectedPath?.user?.mentorDetails?.mentorIntroduction}</p>
              </div>
            </div>
            {/* End Details Jobs */}
          </div>
        ) : null}
        <h3 className="text-capitalize common-heading mt-5">Write to Your Mentor</h3>
        <div>
          <div className="tab-content">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-10 col-xs-12">
                <textarea
                  type="text"
                  name="body"
                  className="form-control general-input--style"
                  value={msgToMentor}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row justify-content-center align-items-center mt-4">
              <div className="col-md-10 col-xs-12">
                <ButtonLink ClassName="learn-more-btn confirm float-right">
                  <button onClick={handleMsgSend} >Send</button>
                </ButtonLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      {message.messageBody && <Message ClassName={message.msgType === "success" ? "message-success" : "message-error"}>{message.messageBody}</Message>}
    </div>
  )
}
