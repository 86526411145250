import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { createLearnPath } from '../../actions/pathActions'
import ButtonLink from '../../components/ButtonLink/ButtonLink'
import Message from '../../components/Message/Message'
import { PATH_CREATE_RESET } from '../../constants/pathConstants'
import history from '../../history'
import './MentorPath.css'
export default function CreatePath() {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const pathCreate = useSelector((state) => state.pathCreate)
  const { createSuccess, createError } = pathCreate

  const categoriesList = useSelector((state) => state.categoriesList)
  const { categories } = categoriesList
  const pathCategories = categories.filter(category => category.categoryType === 'general')
  const pathTitles = categories.filter(category => category.categoryType === 'special')

  const [pathForm, setPathForm] = useState({
    keySkill: "",
    jobDemand: "",
    startEasy: "",
    language: ""
  })
  const [skillsArray, setSkillsArray] = useState([])

  const [_pathName, setPathName] = useState("")
  const [otherPathBoolean, setOtherPathBoolean] = useState(false)

  const [catgryName, setCatgryName] = useState("")
  const [otherCatgryBoolean, setOtherCatgryBoolean] = useState(false)

  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })

  const editorContent = EditorState.createEmpty();
  const [editorState, setEditorState] = useState({ editorState: editorContent });
  //change local state of editor
  const handleEditorChange = (editorState) => {
    setEditorState({ editorState });
  }

  useEffect(() => {
    if (!userInfo?.isApprovedMentor && !userInfo?.isMentor) {
      history.push('/')
    }
    if (createError) {
      setMessage({
        msgType: "error",
        messageBody: createError
      })
      return
    }
    if (createSuccess) {
      setMessage({
        msgType: "success",
        messageBody: "Your Learn path is Created successfully, you will be redirected to your board.."
      })
      setTimeout(() => {
        history.push("/profile/companyboard")
        dispatch({ type: PATH_CREATE_RESET })
      }, 5000);
    }
  }, [createSuccess, createError, dispatch, userInfo])

  const handlePathNameChange = (event) => {
    const { value } = event.target
    if (value === "Other") {
      setOtherPathBoolean(true)
      setPathName("")
    } else {
      setOtherPathBoolean(false)
      setPathName(value)
    }
  }
  // handleCatgryNameChange
  const handleCatgryNameChange = (event) => {
    const { value } = event.target
    if (value === "Other") {
      setOtherCatgryBoolean(true)
      setCatgryName("")
    } else {
      setOtherCatgryBoolean(false)
      setCatgryName(value)
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setPathForm((prevEntries) => {
      return {
        ...prevEntries,
        [name]: value
      }
    })
    if (name === "pathName") {
      setPathName(value)
    }
    if (name === "category") {
      setCatgryName(value)
    }
  }

  const handleSkillRecord = (event) => {
    if (event.key === 'Enter') {
      // console.log('enter key pressed')
      setSkillsArray(prevSkills => {
        return [...prevSkills, pathForm.keySkill]
      })
      setPathForm((prevEntries) => {
        return {
          ...prevEntries,
          keySkill: ""
        }
      })
    }
  }
  const removeSkill = (indx) => {
    // console.log(categories)
    setSkillsArray((prevSections) => {
      return prevSections.filter((skill, id) => { return id !== indx })
    })
  }

  const createPathHandler = (e) => {
    e.preventDefault()
    const article = JSON.stringify(convertToRaw(editorState.editorState.getCurrentContent()))
    if (!_pathName) {
      setMessage({
        msgType: "error",
        messageBody: "Learning Path name is missing"
      })
      return
    }
    if (!article) {
      setMessage({
        msgType: "error",
        messageBody: "Article of this learn path is missing"
      })
      return
    }
    if (skillsArray.length === 0) {
      setMessage({
        msgType: "error",
        messageBody: "Key skills of this learn path are missing"
      })
      return
    }
    delete pathForm.keySkill
    const _pathForm = {
      ...pathForm,
      publicPublished: true,
      pathName: _pathName,
      category: catgryName,
      article,
      keySkills: skillsArray
    }

    dispatch(createLearnPath(_pathForm))
    // console.log("pathForm: ", _pathForm)
  }
  const savePathHandler = (e) => {
    e.preventDefault()
    if (!_pathName) {
      setMessage({
        msgType: "error",
        messageBody: "Learning Path name is missing"
      })
      return
    }
    if (!catgryName) {
      setMessage({
        msgType: "error",
        messageBody: "Learning Path name is missing"
      })
      return
    }
    const article = JSON.stringify(convertToRaw(editorState.editorState.getCurrentContent()))
    delete pathForm.keySkill
    const _pathForm = {
      ...pathForm,
      publicPublished: false,
      pathName: _pathName,
      category: catgryName,
      article,
      keySkills: skillsArray
    }
    dispatch(createLearnPath(_pathForm))
  }

  return (
    <div className="learnpaths-editor">
      <div className="container">
        <h4 className="common-heading"> Create A Learning Path</h4>
        <div className="path-content">
          {/* <h3>Add Learning Path</h3> */}
          <div className="path-form">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-5 col-sm-12">
                <label>Learning Path</label>
                <select name="pathName" className="form-select job_input" onChange={handlePathNameChange}>
                  <option key="name1" value=""> Select learnPath Name </option>
                  {pathTitles.map(title => (
                    <option key={title.categoryName} value={title.categoryName}>{title.categoryName}</option>
                  ))}
                  <option key="other" value="Other"> Other </option>
                </select>
              </div>
              <div className="col-md-3 col-sm-12">
                <label>Article Language</label>
                <select name="language" className="form-select path_input" onChange={handleChange}>
                  <option value=""> Select Language </option>
                  <option value="English">English</option>
                  <option value="Arabic">Arabic</option>
                </select>
              </div>
              <div className="col-md-7 col-sm-12">
                {otherPathBoolean && (
                  <>
                    <label className="highlight-txt"> Please, specify LearningPath Name </label>
                    <input
                      className="path_input"
                      type="text"
                      name="pathName"
                      value={_pathName}
                      onChange={handleChange}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-md-4 col-sm-12">
                <label>Job Category</label>
                <select name="category" className="form-select job_input" onChange={handleCatgryNameChange}>
                  <option key="name2" value=""> Select Path Category </option>
                  {pathCategories.map(category => (
                    <option key={category.categoryName} value={category.categoryName}>{category.categoryName}</option>
                  ))}
                  <option key="other2" value="Other"> Other </option>
                </select>
              </div>
              <div className="col-md-4 col-sm-12">
                {otherCatgryBoolean && (
                  <>
                    <label className="highlight-txt"> Please, specify LearningPath Category </label>
                    <input
                      className="path_input"
                      type="text"
                      name="category"
                      value={catgryName}
                      onChange={handleChange}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-md-4 col-sm-12">
                <label>Enter Key Skills </label>
                <input
                  className="path_input"
                  type="text"
                  name="keySkill"
                  value={pathForm.keySkill}
                  onChange={handleChange}
                  onKeyDown={handleSkillRecord}
                />
              </div>
              <div className="col-md-7 col-sm-12">
                <div className="row-tags skills">
                  {skillsArray.map((skill, indx) => {
                    return (
                      <p key={indx} className="mt-4">{skill} <i className="fas fa-minus-circle" onClick={() => removeSkill(indx)}></i></p>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-12 article-body">
                <h3>Learn Path Article </h3>
                <Editor
                  editorState={editorState.editorState}
                  onEditorStateChange={handleEditorChange}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                />
              </div>
            </div>
            <div className="row justify-content-center align-items-center noborder">
              <div className="col-md-4 col-sm-12">
                <label className="styled-help-info">Probability of catching job in this track <span>(optional)</span>
                  <i className="far fa-question-circle">
                    <span>Note!: This is just an estimation based on mentor expertise in the field. So, this estimation don't give an absolute guarantee that mentee will get a job in the field </span>
                  </i>
                </label>
                <div className="input-group">
                  <input
                    className="form-control path_input nowidth"
                    type="text"
                    name="jobDemand"
                    value={pathForm.jobDemand}
                    onChange={handleChange}
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              <div className="col-md-8 col-sm-12">
                <label className="styled-help-info">Specify level of Easiness to start up a business in the field <span>(optional)</span>
                  <i className="far fa-question-circle">
                    <span>Note!: This is just an estimation based on mentor expertise in the field. This estimation depends on some factors such as amount of fund needed to startup, sponsorship availability and features of the business in this field </span>
                  </i>
                </label>
                <div className="input-group">
                  <input
                    className="form-control path_input nowidth"
                    type="text"
                    name="startEasy"
                    value={pathForm.startEasy}
                    onChange={handleChange}
                  />
                  <span className="input-group-text">%</span>
                </div>

              </div>
            </div>
            <div className="row justify-content-between align-items-center actions-section noborder">
              <div className="col-md-7 col-sm-12">
                <ButtonLink ClassName="learn-more-btn cancel">
                  <button onClick={() => history.push("/profile/companyboard")}>Cancel</button>
                </ButtonLink>
              </div>
              <div className="col-md-3 col-sm-12">
                <ButtonLink ClassName="learn-more-link">
                  <button onClick={savePathHandler}>Save And Continue Later</button>
                </ButtonLink>
              </div>
              <div className="col-md-2 col-sm-12">
                <ButtonLink ClassName="learn-more-btn confirm">
                  <button onClick={createPathHandler}>Publish</button>
                </ButtonLink>
              </div>
            </div>
          </div>
          {message.messageBody && <Message ClassName={message.msgType === "error" ? "message-error" : "message-success"}>{message.messageBody}</Message>}
        </div>
      </div>
    </div>
  )
}
