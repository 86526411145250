import React, { useState } from 'react'
import { Droppable, Draggable } from "react-beautiful-dnd";

export default React.memo(function CourseSections({
  singleSection,
  sectionLessons,
  onAddingNewLesson,
  onLessonRemoval,
  onSectionTitleChange,
  onDisplayLessonDetails
}) {
  const [updatedTitle, setUpdatedTitle] = useState(singleSection.title)

  const handleChange = (event) => {
    setUpdatedTitle(event.target.value)
  }

  const updatedTitleHandler = () => {
    onSectionTitleChange(updatedTitle, singleSection.id)
  }

  return (
    <div key={singleSection.id} className="course-lessons-wrapper">
      <div className="input-group mb-3">
        <input
          key={singleSection.id}
          type="text"
          className="form-control course_input nowidth"
          value={updatedTitle}
          onChange={handleChange}
          onBlur={updatedTitleHandler}
        />
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={() => onAddingNewLesson(singleSection.id)}> Add subSection
        </button>
      </div>
      <Droppable key={singleSection.id} droppableId={singleSection.id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={snapshot.isDraggingOver ? "droppingElem active" : "droppingElem"}
          >
            {sectionLessons.map((singleLesson, index) => (
              <Draggable key={singleLesson.id} draggableId={singleLesson.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className={snapshot.isDragging ? "draggingElem active" : "draggingElem"}
                    {...provided.draggableProps}
                    style={{ ...provided.draggableProps.style }}
                  >
                    <div className="dragging-title">
                      <i {...provided.dragHandleProps} className="fas fa-arrows-alt moveIcon"></i>
                      <span
                        title="Edit Lesson"
                        onClick={() => onDisplayLessonDetails(singleLesson.id)}>{singleLesson.title}</span>
                    </div>
                    <div className="delete-icon">
                      <i
                        className="fas fa-trash removeIcon"
                        title="Remove Lesson"
                        onClick={() => onLessonRemoval(singleSection.id, singleLesson.id)}></i>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
})
