/*===  Css Custom File === */
import "./ClearPath.css";
import ButtonLink from "../../../components/ButtonLink/ButtonLink";
import { Link } from "react-router-dom";

/*===  Import Clear Path Images === */
import img_1 from "../../../images/clear-path/filling_gap.svg";
import { useSelector } from "react-redux";

const ClearPath = () => {
  const language = useSelector((state) => state.language);
  const { translation } = language;

  return (
    <div className="clear-path">
      <div className="container-lg">
        <div className="row justify-content-between align-items-center mb-5">
          <div className="col-md-5">
            <img className="img-fluid pt-5 mb-5" src={img_1} alt="bac-img" />
          </div>
          <div className="col-md-5">
            <div className="clear-info ms-6">
              <h2 className="text-capitalize py-4">
                {translation.FillGap}
              </h2>
              <p>
                {translation.HelpAvoidWasting}
              </p>
              <ButtonLink ClassName="learn-more-link">
                <Link to={"/technopedia"} className="firstcolor strong">
                  {translation.LearnMore} <i className="fas fa-arrow-right ms-1"></i>
                </Link>
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClearPath;
