import React, { useEffect } from 'react'
import './UserProfile.css'
import PanelSelector from './PanelSelector'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import MainProfile from './MainProfile/MainProfile'
import MentorPanel from './MentorPanel/MentorPanel'
import InstructorPanel from './InstructorPanel/InstructorPanel'
// import FounderPanel from './FounderPanel/FounderPanel'
import MessagesPanel from './MessagesPanel/MessagesPanel'
import PromotionPanel from './PromotionPanel/PromotionPanel'
import { useDispatch, useSelector } from 'react-redux'
import InstructorInvite from './InstructorPanel/InstructorInvite'
import ApplicationProcess from './ApplicationProcess'
import MentorInvite from './MentorPanel/MentorInvite'
import { verifyTokenValidity } from '../../actions/userActions'
import history from '../../history'
import FounderInvite from './FounderPanel/FounderInvite'
import FounderPanel from './FounderPanel/FounderPanel'

const panelTaps = [
  {
    panelName: "My Profile",
    selected: "",
    pathNameVal: "mainboard"
  },
  {
    panelName: "Company Board",
    selected: "",
    pathNameVal: "companyboard"
  },
  {
    panelName: "Instructor Board",
    selected: "",
    pathNameVal: "instructorboard"
  },
  // {
  //   panelName: "Founder Board",
  //   selected: "",
  //   pathNameVal: "founderboard"
  // },
  {
    panelName: "Messages",
    selected: "",
    pathNameVal: "messagesboard"
  },
  {
    panelName: "Student Scholarship",
    selected: "",
    pathNameVal: "scholarship"
  },
]

const applicationProcess = "Your Application Is Under Processing"
// const commingSoon = "Section Will Be Available Soon"
// const boardComesSoon = "Your request has been approved. Your board will be available soon to enable you publishing partnership requests"

export default function UserProfile() {
  const dispatch = useDispatch()
  const { path: pathroot, url: urlroot } = useRouteMatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // let customizedPanelTaps = panelTaps
  // if (userInfo.isApprovedMentor || userInfo.isApprovedInstructor || userInfo.isAdmin) {
  //   customizedPanelTaps = panelTaps
  // } else {
  //   customizedPanelTaps.splice(4, 1)
  // }
  useEffect(() => {
    if (!userInfo?._id) {
      history.push('/signin')
    }
    dispatch(verifyTokenValidity())
  }, [dispatch, userInfo])

  return (
    <div className="profile-container">
      <div className="container-lg">
        {/* Start Details Jobs */}
        <div className="columns-wrapper">
          <div className="nav flex-column nav-pills me-5 quarter-1-4">
            <PanelSelector taps={panelTaps} UrlRoot={urlroot} />
          </div>
          <div className="panel-content quarter-3-4">
            <Switch>
              <Route exact path={pathroot}>
                <PromotionPanel />
              </Route>
              <Route path={`${pathroot}/mainboard`}>
                <MainProfile />
              </Route>
              <Route path={`${pathroot}/companyboard`}>
                {userInfo?.isApprovedMentor ? <MentorPanel /> : userInfo?.isMentor ? <ApplicationProcess sectionBody={applicationProcess} /> : <MentorInvite />}
              </Route>
              <Route path={`${pathroot}/instructorboard`}>
                {userInfo?.isApprovedInstructor ? <InstructorPanel /> : userInfo?.isInstructor ? <ApplicationProcess sectionBody={applicationProcess} /> : <InstructorInvite />}
              </Route>
              <Route path={`${pathroot}/founderboard`}>
                {userInfo?.isApprovedFounder ? <FounderPanel /> : userInfo?.isFounder ? <ApplicationProcess sectionBody={applicationProcess} /> : <FounderInvite />}
                {/* <ApplicationProcess sectionBody={commingSoon} /> */}
              </Route>
              <Route path={`${pathroot}/messagesboard`}>
                <MessagesPanel />
              </Route>
              <Route path={`${pathroot}/scholarship`}>
                <PromotionPanel />
              </Route>
            </Switch>
          </div>
        </div>
        {/* End Details Jobs */}
      </div>
    </div>
  )
}
