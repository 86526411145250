import React, { useEffect } from 'react'
import './AdminPages.css'
import { useDispatch, useSelector } from 'react-redux'
import { listCourses, deleteCourse } from '../../actions/courseActions'

import welcome from '../../images/welcome.webp'
import CourseStripe from '../../components/CourseStripe/CourseStripe'
import Message from '../../components/Message/Message'
import StartBaner from '../../components/StartBaner/StartBaner'

export default function ListAllCourses({ history }) {
  // this part to add iems to the cart and to display the selected items
  const dispatch = useDispatch();
  let allCourses = []
  const courseList = useSelector((state) => state.courseList)
  const { loading, courses, error } = courseList

  if (courses.length !== 0) {
    allCourses = courses.map(item => {
      const course = {
        imgType: item.courseImage.img.contentType,
        imgLink: Buffer.from(item.courseImage.img.data).toString('base64'),
        name: item.name,
        price: item.price,
        rating: item.rating,
        courseCategory: item.courseCategory,
        _id: item._id
      }
      return course
    })
  }


  const courseDelete = useSelector((state) => state.courseDelete)
  const { success } = courseDelete

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteCourse(id))
    }
  }

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history.push('/signin')
    }
    if (courses.length === 0 || success)
      dispatch(listCourses())
  }, [dispatch, courses, userInfo, history, success])


  return (
    <div>
      <StartBaner imgLink={welcome}>
        <h4> Admin access to all courses on RekGPT </h4>
      </StartBaner>
      {loading ? (
        <p>loading.........</p>
      ) : error ? (
        <Message ClassName='message-error'>{error}</Message>
      ) : (
        <section className="list-courses--wrapper">
          <div className='container'>
            <div className="row justify-content-center align-items-center">
              <div className="col-9">
                <div className="text-center">
                  <h2 className="common-heading d-inline-block">Listing all Courses</h2>
                </div>
                <CourseStripe cartItems={allCourses} type="admin" removeHandler={deleteHandler} />
              </div>
            </div>
          </div>
        </section>
      )}

    </div>
  )
}
