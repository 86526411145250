import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { applyToLearnPath } from '../../../actions/pathActions'
import ButtonLink from '../../../components/ButtonLink/ButtonLink'
import Message from '../../../components/Message/Message'
import { PATH_APPLICANT_APPLY_RESET } from '../../../constants/pathConstants'

import cancelIcon from '../../../images/general-purpose/cancelIcon.png'
export default function PathForm({ closeModal, title, userInfo, pathID }) {
  const dispatch = useDispatch()
  const [registerForm, setRegisterForm] = useState({
    userFullName: userInfo.name,
    linkedinURL: userInfo.linkedinURL ? userInfo.linkedinURL : "",
    email: userInfo.email ? userInfo.email : "",
    phone: userInfo.phone ? userInfo.phone : "",
    motivation: "",
    approveStatus: false
  })
  const [countryOfResidence, setCountryOfResidence] = useState(userInfo.countryOfResidence ? userInfo.countryOfResidence : "")
  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })
  const options = useMemo(() => countryList().getData(), [])

  const pathApply = useSelector((state) => state.pathApply)
  const { applySuccess, applyError } = pathApply

  const handleChange = (event) => {
    const { name, value } = event.target
    setRegisterForm((prevEntries) => {
      return {
        ...prevEntries,
        [name]: value
      }
    })
  }

  useEffect(() => {
    if (applyError) {
      setMessage({
        msgType: "error",
        messageBody: applyError
      })
      setTimeout(() => {
        dispatch({ type: PATH_APPLICANT_APPLY_RESET })
        setMessage({
          msgType: "",
          messageBody: ""
        })
        closeModal()
      }, 4000);
      return
    }
    if (applySuccess) {
      setMessage({
        msgType: "success",
        messageBody: "Your application sent successfully"
      })
      setTimeout(() => {
        dispatch({ type: PATH_APPLICANT_APPLY_RESET })
        setMessage({
          msgType: "",
          messageBody: ""
        })
        closeModal()
      }, 4000);
    }
  }, [applySuccess, applyError, closeModal, dispatch])

  const handleCountryChange = (info) => {
    setCountryOfResidence(info)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!registerForm.userFullName) {
      setMessage({
        msgType: "error",
        messageBody: " Your name is missing"
      })
      return
    }
    if (!registerForm.linkedinURL) {
      setMessage({
        msgType: "error",
        messageBody: "Link to your Linkedin profile is missing"
      })
      return
    }
    if (!registerForm.email) {
      setMessage({
        msgType: "error",
        messageBody: "Your email address is missing"
      })
      return
    }
    if (!registerForm.motivation) {
      setMessage({
        msgType: "error",
        messageBody: "Your motivation statement is missing"
      })
      return
    }
    const submitForm = { ...registerForm, countryOfResidence }
    dispatch(applyToLearnPath(submitForm, pathID))
  }

  return (
    <div className='path-form-container' onClick={e => e.stopPropagation()}>
      <div className="cancel-button">
        <div className="cancel-wrapper" onClick={closeModal}>
          <img src={cancelIcon} alt="How We Work" />
        </div>
      </div>
      <h3>{title}</h3>
      <p>Show the mentor that you interested in this track, So he/she can help you preparing your CV, improve your portfolio  and jump to <span className="highlight-txt"> higher level </span> in your field </p>
      <div className="path-form">
        <div className="inputs-wrapper">
          <div className="half-width-cell">
            <label> Full Name </label>
            <input
              className="half-input path-input"
              type="text"
              name="userFullName"
              value={registerForm.userFullName}
              onChange={handleChange}
            />
          </div>
          <div className="half-width-cell">
            <label> Linkedin Profile </label>
            <input
              className="half-input path-input"
              type="text"
              name="linkedinURL"
              value={registerForm.linkedinURL}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="inputs-wrapper">
          <div className="half-width-cell">
            <label> Email Address </label>
            <input
              className="half-input path-input"
              type="email"
              name="email"
              value={registerForm.email}
              onChange={handleChange}
            />
          </div>
          <div className="half-width-cell">
            <label> Phone Number  <span>(Optional)</span></label>
            <input
              className="half-input path-input"
              type="phone"
              name="phone"
              value={registerForm.phone}
              onChange={handleChange}
            />
          </div>
        </div>
        <label> Country of Residence </label>
        <Select
          className="profile-input no-padding"
          options={options}
          name="countryOfResidence"
          value={countryOfResidence}
          onChange={handleCountryChange}
        />
        <label> Motivation <span>({registerForm.motivation.length}/400)</span></label>
        <textarea
          className="text-input path-input"
          type="text"
          name="motivation"
          value={registerForm.motivation}
          maxLength="400"
          onChange={handleChange}
          rows="5"
        />
      </div>
      <ButtonLink ClassName="learn-more-btn full-width">
        <button onClick={handleSubmit}> Apply </button>
      </ButtonLink>
      {message.messageBody && <Message ClassName={message.msgType === "error" ? "message-error" : "message-success"}>{message.messageBody}</Message>}
    </div>
  )
}
