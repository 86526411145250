import React from 'react'

export default function Spinner({extraClassName}) {
  return (
    <div className={`modal-window ${extraClassName}`}>
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  )
}

Spinner.defaultProps = {
  extraClassName: ""
}
