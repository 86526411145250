import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createJob } from '../../actions/jobActions'
import ButtonLink from '../../components/ButtonLink/ButtonLink'
import Message from '../../components/Message/Message'
import { JOB_CREATE_RESET } from '../../constants/jobConstants'
import history from '../../history'
import './MentorJob.css'
export default function CreateJob() {
  const dispatch = useDispatch()
  // const requirementref = useRef()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const jobCreate = useSelector((state) => state.jobCreate)
  const { createSuccess, createError } = jobCreate

  const categoriesList = useSelector((state) => state.categoriesList)
  const { categories } = categoriesList
  const jobCategories = categories.filter(category => category.categoryType === 'general')
  // const jobTitles = categories.filter(category => category.categoryType === 'special')

  // const [_jobTitle, setJobTitle] = useState("")
  // const [otherJobTitleBoolean, setOtherJobTitleBoolean] = useState(false)

  const [_jobCatgry, setJobCatgry] = useState("")
  const [otherJobCatgryBoolean, setOtherJobCatgryBoolean] = useState(false)


  const [jobForm, setJobForm] = useState({
    deadLine: "",
    status: "open",
    title: "",
    jobType: "",
    experienceLevel: "",
    jobDescription: "",
    companyName: "",
    jobLocation: "",
    aboutCompany: "",
    companyHeadQuarter: ""
  })
  const [requirementsArray, setRequirementsArray] = useState([])
  const [singleRequirement, setSingleRequirement] = useState("")

  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })

  useEffect(() => {
    if (!userInfo?.isApprovedMentor && !userInfo?.isMentor) {
      history.push('/')
    }
    if (createError) {
      setMessage({
        msgType: "message-error",
        messageBody: createError
      })
      return
    }
    if (createSuccess) {
      setMessage({
        msgType: "message-success",
        messageBody: "Your  job is published successfully, you will be redirected to your board.."
      })
      setTimeout(() => {
        history.push("/profile/companyboard")
        dispatch({ type: JOB_CREATE_RESET })
      }, 5000);
    }
  }, [createSuccess, createError, dispatch, userInfo])

  const handleChange = (event) => {
    const { name, value } = event.target
    setJobForm((prevEntries) => {
      return {
        ...prevEntries,
        [name]: value
      }
    })
    if (name === "category") {
      setJobCatgry(value)
    }
  }

  // const handleJobTitleChange = (event) => {
  //   const { value } = event.target
  //   if (value === "Other") {
  //     setOtherJobTitleBoolean(true)
  //     setJobTitle("")
  //   } else {
  //     setOtherJobTitleBoolean(false)
  //     setJobTitle(value)
  //   }
  // }

  const handlejobCatgryChange = (event) => {
    const { value } = event.target
    if (value === "Other") {
      setOtherJobCatgryBoolean(true)
      setJobCatgry("")
    } else {
      setOtherJobCatgryBoolean(false)
      setJobCatgry(value)
    }
  }

  const handleRequirementsRecord = (event) => {
    if (event.key === 'Enter') {
      // console.log('enter key pressed')
      setRequirementsArray(preRequirements => {
        return [...preRequirements, singleRequirement]
      })
      setSingleRequirement("")
    }
  }
  const removeRequirement = (indx) => {
    setRequirementsArray((preRequirements) => {
      return preRequirements.filter((requirement, id) => { return id !== indx })
    })
  }

  const cretaeJobHandler = (e) => {
    e.preventDefault()
    if (!jobForm.title) {
      setMessage({
        msgType: "message-error",
        messageBody: " Job title is missing"
      })
      return
    }
    if (!_jobCatgry) {
      setMessage({
        msgType: "message-error",
        messageBody: "Job category is missing"
      })
      return
    }
    if (!jobForm.experienceLevel) {
      setMessage({
        msgType: "message-error",
        messageBody: "Level of Experience is missing"
      })
      return
    }
    if (!jobForm.jobType) {
      setMessage({
        msgType: "message-error",
        messageBody: "Job type is missing"
      })
      return
    }
    if (!jobForm.jobDescription) {
      setMessage({
        msgType: "message-error",
        messageBody: "Job description is missing"
      })
      return
    }
    if (requirementsArray.length === 0) {
      setMessage({
        msgType: "message-error",
        messageBody: "Requirements of the job are missing"
      })
      return
    }
    if (!jobForm.companyName) {
      setMessage({
        msgType: "message-error",
        messageBody: "Company name is missing"
      })
      return
    }
    if (!jobForm.jobLocation) {
      setMessage({
        msgType: "message-error",
        messageBody: "Job location is missing"
      })
      return
    }
    const _jobForm = {
      ...jobForm,
      jobRequirements: requirementsArray,
      category: _jobCatgry
    }
    // console.log("jobForm: ", _jobForm)
    dispatch(createJob(_jobForm))
  }

  return (
    <div className="jobs-editor">
      <div className="container">
        <h4 className="common-heading"> Create A Job </h4>
        <div className="job-content">
          <div className="job-form">
            <h4>Vacancy Details</h4>
            <div className="row justify-content-between align-items-center">
              {/* <div className="col-md-4 col-sm-12">
                <label>Job Title</label>
                <select name="title" className="form-select job_input" onChange={handleJobTitleChange}>
                  <option key="titleJob" value=""> Select Job Title </option>
                  {jobTitles.map(title => (
                    <option value={title.categoryName}>{title.categoryName}</option>
                  ))}
                  <option key="other1" value="Other"> Other </option>
                </select>
              </div> */}
              <div className="col-md-5 col-sm-12">
                <label className="highlight-txt"> Please, specify Job Title </label>
                <input
                  className="job_input"
                  type="text"
                  name="title"
                  value={jobForm.title}
                  onChange={handleChange}
                />

              </div>
              <div className="col-md-5 col-sm-12">
                <label>Level Of Experience</label>
                <select name="experienceLevel" className="form-select job_input" onChange={handleChange}>
                  <option value="Any"> Any </option>
                  <option value="Fresh Graduate (0-2 Years)">Fresh Graduate (0-2 Years)</option>
                  <option value="Junior (2-5 Years)">Junior (2-5 Years)</option>
                  <option value="Senior (5-8 Years)">Senior (5-8 Years)</option>
                  <option value="Manager (+8 Years)">Manager (+8 Years)</option>
                </select>
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-md-4 col-sm-12">
                <label>Job Category</label>
                <select name="category" className="form-select job_input" onChange={handlejobCatgryChange}>
                  <option key="catgryJob" value=""> Select Job Category </option>
                  {jobCategories.map(category => (
                    <option value={category.categoryName}>{category.categoryName}</option>
                  ))}
                  <option key="other2" value="Other"> Other </option>
                </select>
              </div>
              <div className="col-md-4 col-sm-12">
                {otherJobCatgryBoolean && (
                  <>
                    <label className="highlight-txt"> Please, specify Job Category </label>
                    <input
                      className="job_input"
                      type="text"
                      name="category"
                      value={_jobCatgry}
                      onChange={handleChange}
                    />
                  </>
                )}
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Job Type</label>
                <select name="jobType" className="form-select job_input" onChange={handleChange}>
                  <option defaultValue=""> Select Job Type </option>
                  <option value="full-time">full-time</option>
                  <option value="part-time">part-time</option>
                  <option value="freelance">freelance</option>
                  <option value="contract">contract</option>
                </select>
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <label>Job Description</label>
                <textarea
                  className="job_input"
                  type="text"
                  name="jobDescription"
                  value={jobForm.jobDescription}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-md-6 col-sm-12">
                <label>Requirements <span>(Press Enter Key after writing one requirement, so you can record another one)</span></label>
                <input
                  onChange={(e) => setSingleRequirement(e.target.value)}
                  className="job_input"
                  type="text"
                  name="singleRequirement"
                  value={singleRequirement}
                  onKeyDown={handleRequirementsRecord}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Job Location <span>(if it is a remote work, mention 'remotely')</span></label>
                <input
                  className="job_input"
                  type="text"
                  name="jobLocation"
                  placeholder="Example: 'Cairo, Egypt'"
                  value={jobForm.jobLocation}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-2 col-sm-12">
                <label>Deadline <span>(Optional)</span> </label>
                <input
                  className="job_input"
                  type="text"
                  name="deadLine"
                  placeholder="dd/mm/yyyy"
                  value={jobForm.deadLine}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <div className="row-tags sentences">
                  {requirementsArray.map((_requirement, indx) => {
                    return (
                      <p key={indx}>{_requirement} <i className="fas fa-minus-circle" onClick={() => removeRequirement(indx)}></i></p>
                    )
                  })}
                </div>
              </div>
            </div>

            <hr />

            <h4 className="mt-4">Company Details</h4>
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <label>Company Name</label>
                <input
                  className="job_input"
                  type="text"
                  name="companyName"
                  value={jobForm.companyName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <label>Company Headquarter <span>(Optional)</span></label>
                <input
                  className="job_input"
                  type="text"
                  name="companyHeadQuarter"
                  placeholder="Example: 'London, UK'"
                  value={jobForm.companyHeadQuarter}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <label>Description About Company <span>(optional)</span> </label>
                <textarea
                  placeholder="Short information about company business or may be a link to their website"
                  className="job_input"
                  type="text"
                  name="aboutCompany"
                  value={jobForm.aboutCompany}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row justify-content-between align-items-center actions-section">
              <div className="col-md-7 col-sm-12">
                <ButtonLink ClassName="learn-more-btn cancel">
                  <button onClick={() => history.push("/profile/companyboard")}>Cancel</button>
                </ButtonLink>
              </div>
              <div className="col-md-2 col-sm-12">
                <ButtonLink ClassName="learn-more-btn confirm float-right">
                  <button onClick={cretaeJobHandler}>Publish</button>
                </ButtonLink>
              </div>
            </div>
          </div>
          {message.messageBody && <Message ClassName={`${message.msgType} fixed-bottom`}>{message.messageBody}</Message>}
        </div>
      </div>
    </div>
  )
}
