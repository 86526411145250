/*===  Css Custom File === */
import "./HeroImg.css";
import ButtonLink from "../../../components/ButtonLink/ButtonLink";
// import beta2 from '../../../images/beta2.png'
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const HeroImg = () => {
  const language = useSelector((state) => state.language);
  const { translation } = language;
  return (
    <div className="hero-img">
      {/* <img className="beta-version" src={beta2} alt="BetaVersion" /> */}
      <div className="row justify-content-between align-items-center">
        <div className="col-md-6 col-sm-12 mb-4">
          <div className="hero-info">
            {/* <h3 className="mb-4">{translation.DemoBroadcasting}</h3> */}
            <h2>
              <span>{translation.ClearPath}</span> {translation.TowardGoals}
            </h2>
            <p>
              {translation.HelpCompanies}
            </p>
            <ButtonLink ClassName="learn-more-link">
              <Link to={"/pricing"} className="secondcolor strong">
                {translation.SignupOrUpgrade} <i className="fas fa-arrow-right ms-1"></i>
              </Link>
            </ButtonLink>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <video autoPlay controls muted loop width='100%'>
            <source src="/RekGPT_Explainer.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default HeroImg;
