/*===  Css Custom File === */
import "./WhichTrack.css";
import ButtonLink from "../../../components/ButtonLink/ButtonLink";
import { Link } from "react-scroll";
/*===  Import Which Track Images === */
import img_1 from "../../../images/which-track.png";
import { useSelector } from "react-redux";

const WhichTrack = () => {
  const language = useSelector((state) => state.language);
  const { translation } = language;
  return (
    <div className="which-track">
      <div className="container-lg">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-5">
            <img className="img-fluid pt-5 mb-5" src={img_1} alt="bac-img" />
          </div>
          <div className="col-md-6">
            <div className="small-heading lead mb-3">{translation.StillConfused}</div>
            <h3 className="mb-4">{translation.WhichTrackTake}</h3>
            <div className="more-info">
              <h6>{translation.ExperiencedInstructor}</h6>
              <p>
                {translation.IfProfessionalCreator}
              </p>
              <h6>{translation.StarterInstructor}</h6>
              <p>
                {translation.IfNoSkillsWithSkills}
              </p>
              <h6>{translation.IfDoubts}</h6>
              <ButtonLink ClassName="learn-more-link">
                <Link
                  to="contactForm"
                  smooth={true}
                  offset={-20}
                  duration={500}
                >
                  {translation.ContactUs} <i className="fas fa-arrow-right ms-1"></i>
                </Link>
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhichTrack;
