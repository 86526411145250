import React, { useEffect } from 'react'
import "./InstructorCorses.css"
import { useDispatch, useSelector } from 'react-redux'
import { listCourses, deleteCourse, createCourse } from '../../actions/courseActions'

import welcome from '../../images/hero.webp'
import CourseStripe from '../../components/CourseStripe/CourseStripe'
import Message from '../../components/Message/Message'
import StartBaner from '../../components/StartBaner/StartBaner'
import { COURSE_CREATE_RESET } from '../../constants/courseConstants'
import ButtonLink from '../../components/ButtonLink/ButtonLink'

export default function InstructorCorses({ history }) {
  // this part to add iems to the cart and to display the selected items
  const dispatch = useDispatch();
  let instructorCourses = []
  let _instructorCourses = []

  const courseList = useSelector((state) => state.courseList)
  const { courses } = courseList
  const courseCreate = useSelector((state) => state.courseCreate)
  const { createSuccess, createdCourse } = courseCreate

  const courseDelete = useSelector((state) => state.courseDelete)
  const { success } = courseDelete

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  // console.log("course:", courses)
  // console.log("userInfo:", userInfo)
  if (userInfo.isInstructor) {
    instructorCourses = courses.filter((course) => course.user._id.toString() === userInfo._id.toString())
    _instructorCourses = instructorCourses.map(item => {
      const Courses = {
        imgType: item.courseImage.img.contentType,
        imgLink: Buffer.from(item.courseImage.img.data).toString('base64'),
        name: item.name,
        price: item.price,
        rating: item.rating,
        courseCategory: item.courseCategory,
        _id: item._id
      }
      return Courses
    })
    // console.log(instructorCourses)
  }

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteCourse(id))
    }
  }

  useEffect(() => {
    dispatch({ type: COURSE_CREATE_RESET })
    if (!userInfo || !userInfo.isInstructor) {
      history.push('/login')
    }
    if (createSuccess) {
      history.push(`/instructor/${createdCourse._id}/edit`)
    } else {
      dispatch(listCourses())
    }
  }, [dispatch, success, userInfo, history, createdCourse, createSuccess])

  const createCourseHandler = () => {
    dispatch(createCourse())
  }

  return (
    <div>
      <StartBaner imgLink={welcome}>
        <>
          <h4> Full Control </h4>
          <p>Create, Update or Delete Courses</p>
        </>
      </StartBaner>
      <section className="instructor-list-courses">
        <div className='container'>
          <div className="row justify-content-center align-items-center">
            <div className="col-9">
              {(userInfo?.isApprovedInstructor) ? (
                <>
                  <div className="courselist-createcourse">
                    <h4>Your courses</h4>
                    <ButtonLink ClassName="learn-more-btn upper-case float-right">
                      <button onClick={createCourseHandler}>Create new course</button>
                    </ButtonLink>
                    {/* <p>Placing card stripes</p> */}
                  </div>
                  {(_instructorCourses.length !== 0) ? (
                    <CourseStripe cartItems={_instructorCourses} type="admin" removeHandler={deleteHandler} />
                  ) : (
                    <div className="courselist-placeholder">
                      <h4> You have not created any courses yet </h4>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="text-center">
                    <h2 className="common-heading d-inline-block"> Glad <span>that you decided to</span> join <span>our family</span></h2>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <Message ClassName='message-info'>You can't create courses yet. We will contact you soon to have an online meeting before approving you as an instuctor on ArabsTechnopedia.</Message>
                    {/* <h5> You can't create courses yet. We will contact you soon to have an online meeting before approving you as an instuctor on ArabsTechnopedia.</h5> */}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
