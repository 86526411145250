/*===  Css Custom File === */
import "./CourseCard.css";
/*===  Import Icon Card === */
import icon from "../../images/course/icon.png";
import { Link } from "react-router-dom";

const CourseCard = ({ cours }) => {

  const courseImage = cours?.courseImage?.img?.data ? Buffer.from(cours?.courseImage?.img?.data).toString('base64') : ""
  const userImage = cours?.user?.userImage?.img?.data ? Buffer.from(cours?.user?.userImage?.img?.data).toString('base64') : ""

  return (
    <Link className="col-md-6 col-lg-4"
      key={cours._id}
      to={`/technopedia/courses/${cours._id}`}
    >
      <div className="card mb-3">
        <div className="card_img position-relative">
          <img
            src={`data:image/${courseImage.contentType};base64,${courseImage}`}
            className="card-img-top"
            alt="Course"
          />
          <img
            src={icon}
            className="card-img-top icon-img"
            alt="Course"
          />
          {cours.discount === cours.price && (
            <div className="discount-notice">
              FREE
            </div>
          )}
        </div>

        <div className="card-body">
          <h5 className="card-title">{cours.name}</h5>
          <p className="card-text">{cours.body}</p>
        </div>
        <div className="other-courses">
          {cours?.courseCategory?.map((cat, indx) => (
            <span className="text-capitalize" key={indx}>
              {cat}
            </span>
          ))}
        </div>
        <hr />
        <div className="secondary-details">
          <div className="img-wrapp">
            <img
              src={`data:image/${userImage.contentType};base64,${userImage}`}
              className="rounded-circle"
              alt="instructor"
            />
            <span className="ms-3 text-capitalize">
              {cours?.user?.name}
            </span>
          </div>
          <div className="info-wrapp">
            <div title="Sponken Language of Course" className="sub-tag">
              <i className="fas fa-globe"></i>
              {cours.courseLanguage}
            </div>
            <div title="Course Rating in scale of 5" className="sub-tag">
              <i className="fas fa-star"></i>
              {cours?.rating?.toFixed(1)} / 5
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CourseCard;
