/*===  Css Custom File === */
import { useSelector } from "react-redux";
import "./WelcomeSection.css";

const WelcomeSection = () => {
  const language = useSelector((state) => state.language);
  const { translation } = language;
  return (
    <div className="welcome-section">
      <h2 className="h1 text-center text-white text-capitalize">
        {translation.ReadyToCreateNew}
      </h2>
    </div>
  );
};

export default WelcomeSection;
