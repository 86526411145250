import { verifyEmail } from "../../hooks/GeneralFacilitateFuncs"

const verifyFields = (form) => {
  let errorDetails = ""
  if (!form.email || !verifyEmail(form.email)) {
    errorDetails = "you didn't enter a valid email address"
  }
  if (!form.name) {
    errorDetails = "you didn't enter your name"
  }
  if (!form.companyName) {
    errorDetails = "you didn't enter the name of your company"
  }
  return errorDetails
}

export default verifyFields