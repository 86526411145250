// import translatedWords from '../LanguageFiles/LanguageKeys'
import ArabicTranslation from '../LanguageFiles/ArabicTranslation'
import EnglishTranslation from '../LanguageFiles/EnglishTranslation'

export const languageReducer = (state = {translation: EnglishTranslation, translationType: "EN"}, action) => {
  switch (action.type) {
    case "AR":
      return { translation: ArabicTranslation, translationType: "AR" }
    case "EN":
      return { translation: EnglishTranslation, translationType: "EN" }
    default:
      return state
  }
}