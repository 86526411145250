import React, { useEffect, useRef } from 'react'

export default function Canvas({draw, ...rest}) {
  const canvasRef = useRef()

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    draw(context)

  }, [draw])
  return (
    <canvas ref={canvasRef} {...rest} />
  )
}
