import React, { useEffect, useRef, useState } from 'react'
import history from '../../history';
import ReCAPTCHA from 'react-google-recaptcha';
import GOOGLE from '../../AppConfig'
import { useDispatch, useSelector } from 'react-redux'
import { login, register } from '../../actions/userActions'
import './SignScreen.css'
import ButtonLink from '../../components/ButtonLink/ButtonLink'
import cancelIcon from '../../images/general-purpose/cancelIcon.png'
// import googleIcon from '../../images/general-purpose/googleIcon.png'
import linkedinIcon from '../../images/general-purpose/linkedinIcon.png'
import { Link } from 'react-router-dom'
import Message from '../../components/Message/Message';
import axios from 'axios';
import { MaximumMembersForGrant } from '../../SampleData/standardVals';
import { verifyEmail } from '../../hooks/GeneralFacilitateFuncs';
// import showPassword from '../../images/general-purpose/showPassword.png'
// import hidePassword from '../../images/general-purpose/hidePassword.png'

export default function SignScreen({ ClassName, closeModal, signLabel, signType }) {
  const dispatch = useDispatch()
  const rootURL = window.location.origin
  // const [redirectLink, setRedirectLink] = useState('')
  const [registerForm, setRegisterForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    grantCode: localStorage.getItem('grantCode') ? JSON.parse(localStorage.getItem('grantCode')) : "",
  });
  const [loginCredentials, setLoginCredentials] = useState({})
  const [message, setMessage] = useState({
    messageText: '',
    messageStyle: ""
  })
  const [token, setToken] = useState("");

  const reCaptcha = useRef();

  // temporarily set userinfo to true until connecting backend
  // const userInfo = false
  const userRegister = useSelector((state) => state.userRegister)
  const { error: signUpError, userInfo: signUpInfo } = userRegister

  const userLogin = useSelector((state) => state.userLogin)
  const { error: loginError, userInfo: loginInfo, tokenValidity } = userLogin


  const redirect = (window.location.search) ? window.location.search.split('=')[1] : '/'
  useEffect(() => {
    if (signUpInfo || loginInfo?._id) {
      reCaptcha.current.reset()
      setToken("")
      history.push(redirect)
    }
  }, [redirect, signUpInfo, loginInfo])

  // handle login with linkedin credentials
  useEffect(() => {
    const fetchVars = async () => {
      const { data: credentials } = await axios.get('/api/linkedin/credentialsinfo')
      setLoginCredentials(credentials)
    }
    fetchVars()
  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target
    setRegisterForm((prevEntries) => {
      return {
        ...prevEntries,
        [name]: value
      }
    })
  }

  const verifyPasswordStrength = (passwordStr) => {
    const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$")
    const verificationResult = pattern.test(passwordStr) && (passwordStr.length >= 10)
    return verificationResult
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!registerForm.email || !verifyEmail(registerForm.email)) {
      setMessage({
        messageStyle: "message-error",
        messageText: "You must enter a valid email address"
      })
      return
    }
    // handle signUp action ****************************
    if (signType === "signup") {
      if (!registerForm.firstName) {
        setMessage({
          messageText: "You did not enter your First Name",
          messageStyle: "message-error"
        })
        return
      }
      if (!registerForm.lastName) {
        setMessage({
          messageText: "You did not enter your Last Name",
          messageStyle: "message-error"
        })
        return
      }
      const validPassword = verifyPasswordStrength(registerForm.password)
      if (!validPassword) {
        setMessage({
          messageText: "Weak password, Please follow rules mentioned in the help for strong passwords. It is important to have a secured account",
          messageStyle: "message-error"
        })
        return
      }
      if (!token) {
        setMessage({
          messageText: "You must verify yourself by checking reCAPTCHA",
          messageStyle: "message-error"
        })
        return;
      }
      setMessage({
        messageText: "",
        messageStyle: ""
      })
      // console.log('token from reCaptcha: ', token)
      const userName = registerForm.firstName + ' ' + registerForm.lastName
      const submitForm = {
        name: userName,
        email: registerForm.email,
        password: registerForm.password,
        grantCode: registerForm.grantCode,
        registerToken: token
      }
      if (registerForm.grantCode) {
        const { data: grantinfo } = await axios.get(`/api/grants/${registerForm.grantCode}`)
        console.log("grantinfo: ", grantinfo)
        if (grantinfo?.grantCode && grantinfo?._id && grantinfo.members.length < MaximumMembersForGrant) {
          setMessage({
            messageText: `Congrats!, you have successfully used grant code. You can always track the status of your scholarship from your profile board. Few seconds and you will be directed..`,
            messageStyle: "message-success"
          })
          setTimeout(() => {
            dispatch(register(submitForm))
          }, 8000);
        } else if (grantinfo?.grantCode && grantinfo?._id && grantinfo.members.length >= MaximumMembersForGrant) {
          setMessage({
            messageText: "Expired grant code!. you can register now without code and later you can apply to grant code when you complete registeration to get a scholarship",
            messageStyle: "message-warning"
          })
        } else {
          setMessage({
            messageText: "Invalid grant code. If you don't have one, leave the field empty and later you can apply to grant code when you complete registeration",
            messageStyle: "message-warning"
          })
        }
      } else {
        dispatch(register(submitForm))
      }
    } else {
      // handle signIn action ****************************
      dispatch(login(registerForm.email, registerForm.password))
    }
  }

  return (
    <div className={ClassName} onClick={e => e.stopPropagation()}>
      <div className="cancel-button">
        <div className="cancel-wrapper" onClick={closeModal}>
          <img src={cancelIcon} alt="How We Work" />
        </div>
      </div>
      {/* <h3>Get Started</h3>
      <p>Register now and apply for <span className="highlight-txt"> Grant Code </span> through your profile page to enroll into two courses of your choice for<span className="highlight-txt"> free!</span></p> */}
      <form onSubmit={handleSubmit}>
        <div className="sign-form">
          <div className="inputs-wrapper exclude-for-signin">
            <div className="half-width-cell">
              <label> First Name* </label>
              <input
                className="half-input sign-input"
                type="text"
                name="firstName"
                value={registerForm.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="half-width-cell">
              <label> Last Name* </label>
              <input
                className="half-input sign-input"
                type="text"
                name="lastName"
                value={registerForm.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="inputs-wrapper">
            <div className="half-width-cell">
              <label> Email* </label>
              <input
                className="half-input sign-input"
                type="email"
                name="email"
                value={registerForm.email}
                onChange={handleChange}
              />
            </div>
            <div className="half-width-cell grantcode-entry">
              <label> Password*
                <i className="far fa-question-circle">
                  <span>1- Password must be longer than 10 characters.
                    <br></br>
                    2- Contains at least one special character (e.g. @,#,$,%....).
                    <br></br>
                    3- Contains at least one uppercase character (A-Z).
                    <br></br>
                    4- Contains at least one lowercase character (a-z).
                    <br></br>
                    5- Must contains numbers (0-9)</span>
                </i>
              </label>
              <input
                className="half-input sign-input"
                type="password"
                name="password"
                value={registerForm.password}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="grantcode-entry">
            <label>Grant Code (optional)
              <i className="far fa-question-circle">
                <span>If you have a grant code, you can enter it here. If you don't have it you can register now and later you can apply for grant code</span>
              </i>
            </label>
            <input
              className="text-input sign-input"
              type="text"
              name="grantCode"
              value={registerForm.grantCode}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="signup-recaptcha">
          <ReCAPTCHA
            ref={reCaptcha}
            sitekey={GOOGLE.reCaptcha}
            onChange={token => setToken(token)}
            onExpired={e => setToken("")}
          />
        </div>
        <ButtonLink ClassName="learn-more-btn full-width">
          <button>{signLabel}</button>
        </ButtonLink>
      </form>
      {(message && message.messageText) && (
        <Message ClassName={message.messageStyle}> {message.messageText} </Message>
      )}
      {loginError && <Message ClassName='message-error'> {loginError} </Message>}
      {signUpError && <Message ClassName='message-error'> {signUpError} </Message>}
      {tokenValidity === false && <Message ClassName='message-error'> Login session expired, Please Login again </Message>}
      <div className="singup-socialmedia">
        <div className="line"></div>
        <div className="singup-with-text"> Or {signLabel} With </div>
        <div className="line"></div>
      </div>
      <div className="socialmedia-buttons-container">
        <ButtonLink ClassName="socialmedia-button">
          <a className="social-login" href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${loginCredentials.clientId}&redirect_uri=${rootURL}&state=${loginCredentials.state}&scope=${loginCredentials.scope}`}>
            <img src={linkedinIcon} alt="img" />
            Linkedin
          </a>
        </ButtonLink>
        {/* <ButtonLink ClassName="socialmedia-button">
          <button onClick={onGoogleSignin}>
            <img src={googleIcon} alt="img" />
            Google
          </button>
        </ButtonLink> */}
      </div>
      <div className="alternat-sign-form">
        {signType === 'signin' ? (
          // <div className="text-alternate" onClick={() => goToNewURL('/signup')}>New to our platform?? Sign Up</div>
          <Link to='signup'> Alternatively, i want to signup with RekGPT form  </Link>
        ) : (
          // <div className="text-alternate" onClick={() => goToNewURL('/signin')}>You have an account?? Sign In</div>
          <Link to='signin'> You have an account?? Sign In </Link>
        )}
      </div>
    </div>
  )
}
