import React from 'react'
import ButtonLink from '../../components/ButtonLink/ButtonLink'

export default function Hiring({ openModal }) {
  return (
    <div className="card_container">
      <div className='recommend'>Perfect for freelancers and business starters</div>
      {/* <!-- card-header start --> */}
      <div className="card_header">
        <div className="pricing_title">
          <h5 className="ultra-strong">
            Hiring Board
          </h5>
          <h6 className="light">
            Your Own Recruitment Board
          </h6>
        </div>
        <h2 className="price main-color">
          Free
          <span className="text-color period">
            /Forever
          </span>
        </h2>
      </div>
      {/* <!-- card-header end --> */}
      {/* <!-- card-body start --> */}
      <div className="card_body general-list-style">
        <ul className="list-unstyled position-relative">
          <li className='styled-help-info'>
            Unlimited active jobs
            <i className="far fa-question-circle">
              <span>
                Post and manage as many jobs as you need at the same time
              </span>
            </i>
          </li>
          <li>
            Job board with your own domain address
          </li>
          <li className='styled-help-info'>
            No Expiry date for published jobs
            <i className="far fa-question-circle">
              <span>
                The vacancy will stay forever in the platform, it will not be closed unless you close it from your own board
              </span>
            </i>
          </li>
          <li className='styled-help-info'>
            Manage job applications
            <i className="far fa-question-circle">
              <span>
                Shortlist candidates, delete applications, invite applicants for interview. all from your recruitment board
              </span>
            </i>
          </li>
          <li className='styled-help-info'>
            Email Management
            <i className="far fa-question-circle">
              <span>
                From your own board you can send an emails and schedule interviews with candidates
              </span>
            </i>
          </li>
          <li className='styled-help-info'>
            <b>Panel powered by AI to write professional job advertises</b>
            <i className="far fa-question-circle">
              <span>
                Just write a general non-formated description about the talent that you want to hire and let AI do the magic and write a professional job advertise that fits your needs.
              </span>
            </i>
          </li>
          <li className='styled-help-info'>
            <b>Candidates Matching powered by AI</b>
            <i className="far fa-question-circle">
              <span>
                Harnessing power of Artificial Intelligence, the software will recommend best applicants who are meeting your job criteria
              </span>
            </i>
          </li>
        </ul>
      </div>
      {/* <!-- card-body end --> */}
      {/* <!-- card-footer end --> */}
      <div className="card-footer bg-transparent border-0 px-0 py-0">
        <ButtonLink ClassName="learn-more-btn full-width">
          <button onClick={() => { openModal(1) }}>Subscribe</button>
        </ButtonLink>
      </div>
      {/* <!-- card-footer end --> */}
    </div>
  )
}
