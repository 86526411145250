import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { JOB_DETAILS_RESET } from '../../../constants/jobConstants';
import companyLogo from '../../../images/general-purpose/companyLogo.png'

export default function JobsSelector({ jobs, UrlRoot }) {
  const dispatch = useDispatch();
  const [selectedJobs, setSelectedJobs] = useState(jobs)

  useEffect(() => {
    setSelectedJobs(jobs)
  }, [jobs])

  const onSelection = (job, indx) => {
    dispatch({ type: JOB_DETAILS_RESET })
    setSelectedJobs((preSelections) => {
      const newJobs = preSelections.map((job, jobIndx) => {
        if (jobIndx === indx) {
          return { ...job, selected: "active" }
        } else {
          return { ...job, selected: "" }
        }
      })
      return newJobs
    })

  }
  return (
    <>
      {selectedJobs.length !== 0 && selectedJobs.map((job, indx) => {
        const companyPic = job?.companyLogo?.img?.data ? Buffer.from(job?.companyLogo?.img?.data).toString('base64') : ""

        let deadLineMessage = ""
        const _deadline = job?.deadLine ? job.deadLine.substring(0, 10).replace(/[^a-zA-Z0-9]/g, '/') : ""
        if (_deadline) {
          const dd = _deadline.substring(0, 2)
          const mm = _deadline.substring(3, 5)
          const yyyy = _deadline.substring(6, 10)
          const deadline = mm + "/" + dd + "/" + yyyy
          const date1 = Date.now()
          const date2 = new Date(deadline)
          const diffTime = date2 - date1;
          if (diffTime <= 0) {
            deadLineMessage = "Closes very soon"
          } else {
            const _diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            deadLineMessage = `Closes in ${_diffDays} days`
          }

        }
        return (
          <Link
            key={indx}
            className={`job-card nav-link mb-4 ${job.selected}`}
            onClick={() => onSelection(job, indx)}
            to={`${UrlRoot}/job/${job._id}`}
          >
            <div className="btn-jobs-info d-flex position-relative">
              {companyPic ? (
                <img
                  className="img-fluid me-4"
                  src={`data:image/${companyPic.contentType};base64,${companyPic}`}
                  alt="profile"
                />
              ) : (
                <img className="img-fluid me-4" src="/company_pic.png" alt="company logo" />
              )}
              <div className="text-start">
                <h5>{job?.title}</h5>
                <h6>{`${job?.companyName}, ${job?.jobType}`}</h6>
                {job?.status === 'open' && deadLineMessage && <span className="warning-header-color">{deadLineMessage}</span>}
                {/* <span>{job?.createdAt?.substring(0, 10).replace(/[^a-zA-Z0-9]/g, '/')}</span> */}
              </div>
              <div className={`${job?.status}-btn`}>
                <button>{job?.status === 'open' ? "Open" : "Closed"}</button>
              </div>
            </div>
            <div className='row-tags wrap mt-1'>
              {job?.experienceLevel && <p>{job.experienceLevel}</p>}
              {job?.jobLocation && <p>{job.jobLocation}</p>}
            </div>
          </Link>
        )
      })}
    </>
  )
}
