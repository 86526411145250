/*===  Css Custom File === */
// import { useState } from "react";
import { useSelector } from "react-redux";
import "./OurMission.css";
import mission_img from "../../../images/main-page/mission1.svg";
import ButtonLink from "../../../components/ButtonLink/ButtonLink";
import { Link } from "react-router-dom";

const OurMission = () => {
  const language = useSelector((state) => state.language);
  const { translation } = language;

  return (
    <div className="our-mission">
      <div className="container-lg">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6">
            <h2 className="text-capitalize mb-4">{translation.TrustedPartner}</h2>
            <p>
              {translation.OurMissionIsToQualify}
            </p>
            <ButtonLink ClassName="learn-more-link">
              <Link to={"/pricing"} className="firstcolor strong">
                {translation.JoinUs} <i className="fas fa-arrow-right ms-1"></i>
              </Link>
            </ButtonLink>
          </div>
          <div className="col-md-5 relative">
            <img className="mission_img img-fluid pt-5 mb-5" src={mission_img} alt="bac-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
