import axios from "axios"
import { FOUNDER_PARTNERSHIP_LIST_FAIL, FOUNDER_PARTNERSHIP_LIST_LOADING, FOUNDER_PARTNERSHIP_LIST_SUCCESS, PARTNERSHIP_ACTIONON_APPLICANT_FAIL, PARTNERSHIP_ACTIONON_APPLICANT_SUCCESS, PARTNERSHIP_APPLICANT_APPLY_FAIL, PARTNERSHIP_APPLICANT_APPLY_SUCCESS, PARTNERSHIP_CREATE_FAIL, PARTNERSHIP_CREATE_SUCCESS, PARTNERSHIP_DELETE_FAIL, PARTNERSHIP_DELETE_SUCCESS, PARTNERSHIP_DETAILS_FAIL, PARTNERSHIP_DETAILS_LOADING, PARTNERSHIP_DETAILS_SUCCESS, PARTNERSHIP_LIST_FAIL, PARTNERSHIP_LIST_LOADING, PARTNERSHIP_LIST_SUCCESS, PARTNERSHIP_UPDATE_FAIL, PARTNERSHIP_UPDATE_SUCCESS } from "../constants/partnershipConstants"
import { logout } from "./userActions"

// public access to all Partnership, all users can see the list
export const listPartnerships = () => async (dispatch) => {
  dispatch({ type: PARTNERSHIP_LIST_LOADING })
  try {
    const { data } = await axios.get('/api/partnerships')
    dispatch({
      type: PARTNERSHIP_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PARTNERSHIP_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const ListPartnershipDetails = (id) => async (dispatch) => {
  dispatch({ type: PARTNERSHIP_DETAILS_LOADING })
  try {
    const { data } = await axios.get(`/api/partnerships/${id}`)
    dispatch({
      type: PARTNERSHIP_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PARTNERSHIP_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Founder fetch his written learning partnerships
export const listFounderPartnerships = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FOUNDER_PARTNERSHIP_LIST_LOADING })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get('/api/partnerships/founder/getpartnerships', config)
    dispatch({
      type: FOUNDER_PARTNERSHIP_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: FOUNDER_PARTNERSHIP_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Founder and Admin create  Partnership ****
export const createPartnership = (PartnershipForm) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.post(`/api/partnerships/founder/createpartnership`, PartnershipForm, config)
    dispatch({
      type: PARTNERSHIP_CREATE_SUCCESS,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PARTNERSHIP_CREATE_FAIL,
      payload: message,
    })
  }
}

// Founder and Admin update  Partnership ****
export const updatePartnership = (PartnershipForm) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.put(`/api/partnerships/founder/${PartnershipForm._id}`, PartnershipForm, config)
    dispatch({
      type: PARTNERSHIP_UPDATE_SUCCESS,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PARTNERSHIP_UPDATE_FAIL,
      payload: message,
    })
  }
}
// Founder delete his written Partnership
export const deletePartnership = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/partnerships/founder/${id}`, config)

    dispatch({
      type: PARTNERSHIP_DELETE_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PARTNERSHIP_DELETE_FAIL,
      payload: message,
    })
  }
}
// Founder shortlist or refuses Partnership applications
export const actionOnPartnershipApplication = (actionForm, PartnershipID) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(`/api/partnerships/approveapplicant/${PartnershipID}`, actionForm, config)
    dispatch({
      type: PARTNERSHIP_ACTIONON_APPLICANT_SUCCESS,
      payload: data.message,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PARTNERSHIP_ACTIONON_APPLICANT_FAIL,
      payload: message,
    })
  }
}
// user apply to Partnership
export const applyToPartnership = (application, PartnershipID) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.post(`/api/partnerships/${PartnershipID}`, application, config)
    dispatch({
      type: PARTNERSHIP_APPLICANT_APPLY_SUCCESS,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PARTNERSHIP_APPLICANT_APPLY_FAIL,
      payload: message,
    })
  }
}