import React, { useEffect, useState } from 'react'
import Rating from '../Rating'

export default function StudentsRatingBars({ coursedetails }) {

  const [stars, setStars] = useState({
    star5: 0,
    star4: 0,
    star3: 0,
    star2: 0,
    star1: 0,
  })

  const handleStarBars = (_coursedetails) => {
    let reviewCounts = {
      star5: 0,
      star4: 0,
      star3: 0,
      star2: 0,
      star1: 0,
    }
    _coursedetails.reviews.forEach((singleReview) => {
      // setStars((preStars) => {
      switch (singleReview.rating) {
        case 5:
          reviewCounts = { ...reviewCounts, star5: reviewCounts.star5 + 1 }
          break
        case 4:
          reviewCounts = { ...reviewCounts, star4: reviewCounts.star4 + 1 }
          break
        case 3:
          reviewCounts = { ...reviewCounts, star3: reviewCounts.star3 + 1 }
          break
        case 2:
          reviewCounts = { ...reviewCounts, star2: reviewCounts.star2 + 1 }
          break
        case 1:
          reviewCounts = { ...reviewCounts, star1: reviewCounts.star1 + 1 }
          break
        default:
          break
      }
      // })
    })
    setStars(reviewCounts)
  }

  useEffect(() => {
    if (coursedetails?._id && coursedetails?.reviews) {
      handleStarBars(coursedetails)
    }
  }, [coursedetails])
  return (
    <>
      <div className="course-feedback">
        <h3>Students Feedback</h3>
        <div className="feedback-container">
          <ul className="rate-num">
            <li>
              <h2>{coursedetails.rating.toFixed(1)}</h2>
              <p>{`Avarage rating`}</p>
              <Rating classname='averagerating-barchart' value={coursedetails.rating.toFixed(1)} />
            </li>
            {/* modification needed / some li elements need to be styled */}
            <li>
              <ul className="rate-bar">
                <li>5 Star</li>
                <li><div style={{ width: `${((stars.star5 / coursedetails.numReviews) * 100).toFixed(1)}%` }}></div></li>
                <li>{((stars.star5 / coursedetails.numReviews) * 100).toFixed(1)}%</li>
                <li>({stars.star5} Reviews)</li>
              </ul>
              <ul className="rate-bar">
                <li>4 Star</li>
                <li><div style={{ width: `${((stars.star4 / coursedetails.numReviews) * 100).toFixed(1)}%` }}></div></li>
                <li>{((stars.star4 / coursedetails.numReviews) * 100).toFixed(1)}%</li>
                <li>({stars.star4} Reviews)</li>
              </ul>
              <ul className="rate-bar">
                <li>3 Star</li>
                <li><div style={{ width: `${((stars.star3 / coursedetails.numReviews) * 100).toFixed(1)}%` }}></div></li>
                <li>{((stars.star3 / coursedetails.numReviews) * 100).toFixed(1)}%</li>
                <li>({stars.star3} Reviews)</li>
              </ul>
              <ul className="rate-bar">
                <li>2 Star</li>
                <li><div style={{ width: `${((stars.star2 / coursedetails.numReviews) * 100).toFixed(1)}%` }}></div></li>
                <li>{((stars.star2 / coursedetails.numReviews) * 100).toFixed(1)}%</li>
                <li>({stars.star2} Reviews)</li>
              </ul>
              <ul className="rate-bar">
                <li>1 Star</li>
                <li><div style={{ width: `${((stars.star1 / coursedetails.numReviews) * 100).toFixed(1)}%` }}></div></li>
                <li>{((stars.star1 / coursedetails.numReviews) * 100).toFixed(1)}%</li>
                <li>({stars.star1} Reviews)</li>
              </ul>
            </li>
          </ul>
        </div>

        {coursedetails.reviews.map((review, indx) => {
          return (
            <div key={indx} className="single-review">
              <img src="/profileLogo_new.png" alt="userLogo" className="float-left" />
              <div className="text float-left" key={review._id}>
                <div className="review-info">
                  <div>
                    <h6>{review.name}</h6>
                    <span>{review.createdAt.substring(0, 10)}</span>
                  </div>
                  <div>
                    <Rating value={review.rating} />
                  </div>
                </div>
                <p>{review.comment}</p>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
