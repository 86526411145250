/* === Import Custom Hook To Change Page Title === */
import { useDocTitle } from "../../hooks/ChangePageTitle";
import './Joinus.css'

/* Import All screens For Join Us Page */
import WelcomeSection from "../../screen/JoinUsSections/WelcomeSection/WelcomeSection";
import JoinUsLinks from "../../screen/JoinUsSections/JoinUsLinks/JoinUsLinks";
import BenefitsTeaching from "../../screen/JoinUsSections/BenefitsTeaching/BenefitsTeaching";
import RealImpact from "../../screen/JoinUsSections/RealImpact/RealImpact";
import StarterInstructor from "../../screen/JoinUsSections/StarterInstructor/StarterInstructor";
import WhichTrack from "../../screen/JoinUsSections/WhichTrack/WhichTrack";
// import BenefitsInvesting from "../../screen/JoinUsSections/BenefitsInvesting/BenefitsInvesting";
// import ShareUs from "../../screen/JoinUsSections/ShareUs/ShareUs";
// import BenefitsMentorship from "../../screen/JoinUsSections/BenefitsMentorship/BenefitsMentorship";
// import MentorsResponsibilities from "../../screen/JoinUsSections/MentorsResponsibilities/MentorsResponsibilities";
// import ContactForm from "../../layouts/ContactForm/ContactForm";

const Joinus = () => {
  /* === Page Title === */
  useDocTitle("RekGPT | Join us as instructor");

  return (
    <>
      <WelcomeSection />
      {/* <JoinUsLinks /> */}
      <BenefitsTeaching />
      <RealImpact />
      <StarterInstructor />
      <WhichTrack />
      {/* <BenefitsInvesting />
      <ShareUs />
      <BenefitsMentorship />
      <MentorsResponsibilities /> */}
      {/* <ContactForm /> */}
    </>
  );
};

export default Joinus;
