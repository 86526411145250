/* === Import Custom Hook To Change Page Title === */
import React from "react";
import { useDocTitle } from "../../hooks/ChangePageTitle";
import "./Technopedia.css";

/* === Import Sample Data Fot Test === */
// import courses from "../../SampleData/courses";

/* === Import Technopedia Components === */
// import TechnopediaOffers from "../../screen/TechnopediaSections/TechnopediaOffers/TechnopediaOffers";
import CourseCard from "../../components/CourseCard/CourseCard";
import OurInstructors from "../../screen/TechnopediaSections/OurInstructors/OurInstructors";
import TechnoSteps from "../../screen/TechnopediaSections/TechnoSteps/TechnoSteps";
import CourseSelector from "./CourseSelector";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { listCourses } from "../../actions/courseActions";
import Spinner from "../../components/Spinner/Spinner";
import Message from "../../components/Message/Message";
// import TalentsPool from "../../screen/TechnopediaSections/TalentsPool/TalentsPool";

const Technopedia = ({ location }) => {
  /* === Page Title === */
  useDocTitle("RekGPT | Qualifying academy to get your dream job");

  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const { translation } = language;

  const courseList = useSelector((state) => state.courseList)
  const { loading, error, courses } = courseList
  const [coursesList, setCoursesList] = useState(courses)

  const categoriesList = useSelector((state) => state.categoriesList)
  const { categories } = categoriesList

  const _courseCategories = categories.filter(category => category.categoryType === 'general')
  const courseCategories = [..._courseCategories, { _id: "122123", categoryType: "general", categoryName: "All Courses" }]

  useEffect(() => {
    if (courses) {
      setCoursesList(courses)
    }
  }, [courses])

  useEffect(() => {
    dispatch(listCourses())
  }, [dispatch, location])


  const onCategorySelect = (categry) => {
    if (categry.categoryName === "All Courses") {
      setCoursesList(courses)
      return
    }
    const displayedCourses = courses.filter((course) => course.courseCategory.includes(categry.categoryName))
    setCoursesList(displayedCourses)
  }

  return (
    <>
      {loading && <Spinner />}
      {error && <Message ClassName="message-error">{error}</Message>}
      {!error && !loading && (
        <>
          {/* <TechnopediaOffers /> */}

          <div className="ongoing-courses">
            <div className="container-lg">
              <h2 className="text-center mb-3 mt-5 text-capitalize">{translation.OngoingCourses}</h2>
              <div className="scroll-courses">
                {/* <button onClick={() => setCoursesList(courses)}>All Courses</button> */}
                <CourseSelector
                  categories={courseCategories}
                  onSelection={onCategorySelect}
                />
              </div>
            </div>
          </div>

          {/* List if courses in Technopedia */}
          <div className="course-card">
            <div className="container">
              <div className="row">
                {coursesList?.length !== 0 && coursesList?.map(course => {
                  return (
                    <CourseCard
                      key={course._id}
                      cours={course}
                    />
                  )
                })}
              </div>
            </div>
          </div>

          {/* <TalentsPool /> */}

          <OurInstructors />
          <TechnoSteps />
        </>
      )}

    </>
  );
};

export default Technopedia;
