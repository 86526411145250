import React from 'react'
import './PaymentSelection.css'
import { useState } from 'react';

export default function PaymentSelection({ selectPayment, paymentOptions }) {

  const [paymentselect, setPaymentSelect] = useState(paymentOptions)

  const onSelection  = (value, indx) => {
    setPaymentSelect((preSelections) => {
      const newSelection = preSelections.map((selectedCell, cellNum) => {
        if (cellNum === indx) {
          return {...selectedCell, selected: 'active'}
        } else {
          return {...selectedCell, selected: 'passive'}
        }
      })
      return newSelection
    })
    selectPayment(value, indx)
  }
  return (
    <div className="payment-card">
      <h6> Select Payment Method </h6>
      {paymentselect.map((option, indx) => {
        return (
          <div key={indx} className={`payment-container ${option.selected}`} onClick={() => onSelection(option.value, indx)}>
            <div className= "option">
              <div className="icon"><i className={option.iconPath}></i></div>
              <div className="label">{option.label}</div> 
            </div>
            <div className="outer-circle">
              <div className="inner-circle"></div>
            </div>
        </div>
        )
      })}
    </div>
  )
}
