import React from 'react'
import Rating from '../Rating';
import './CourseStripe.css'
import { Link } from 'react-router-dom'


export default function CourseStripe({ cartItems, removeHandler, type }) {

  return (
    <div className="course-stripes-container">
      <div className="items-header">
        <h6 className="items1">COURSE NAME</h6>
        <h6 className="items2">DIPLOMA</h6>
        <h6 className="items3">CERTIFICATION</h6>
      </div>
      {cartItems?.length === 0 ? (
        <div> no items in the cart </div>
      ) : (
        <div>
          {cartItems?.map((item, indx) => {
            const courseImage = item?.courseImage?.img?.data ? Buffer.from(item.courseImage.img.data).toString('base64') : ""
            let newPrice = 0
            if (type === "admin") {
              // when the items come from database
              newPrice = item.price - item.discount
            } else {
              // when it comes from CartScreen
              newPrice = item.price - item.deductedAmount
            }
            return (
              <div className="course-stripe" key={indx}>
                <div className="image-wrapper">
                  {/* <img src={item.imgLink} alt={item.name} /> */}
                  <img src={`data:image/${courseImage.contentType};base64,${courseImage}`} alt={item.name} />
                </div>
                <div className="course-details-stripe">
                  <h6>{item.name}</h6>
                  <div className="price-rate"><del>${item.price} </del> ${newPrice.toFixed(2)} <span> || </span> <Rating value={item.rating} classname="rating" /> </div>
                </div>
                <div className="course-main-category">
                  {type === "admin" ? <p>{item.courseCategory[0]}</p> : <p>{item.courseCategory}</p>}
                </div>
                <div className="certification">
                  <p> {item.isCertified ? (
                    <i className='fas fa-check' style={{ color: 'green' }}></i>
                  ) : (
                    <i className='fas fa-times' style={{ color: 'red' }}></i>
                  )} </p>
                </div>
                <div className="remove-course">
                  {type === "cart" ? (
                    <i title="Remove course" onClick={() => removeHandler(item.course)} className="fas fa-trash"></i>
                  ) : (
                    <i title="Remove course" onClick={() => removeHandler(item._id)} className="fas fa-trash"></i>
                  )}
                  {type === "admin" ? (
                    <Link to={`/instructor/${item._id}/edit`} title="Edit course"><i className="fas fa-edit"></i></Link>
                  ) : null}
                </div>
              </div>
            )
          }
          )}
        </div>
      )}
    </div>
  )
}
