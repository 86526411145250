import React, { useState } from 'react'
import './ModalScreens.css'
import cancelIcon from '../../../images/general-purpose/cancelIcon.png'
import ButtonLink from '../../../components/ButtonLink/ButtonLink'
import { useEffect } from 'react'


export default function EditInfoModal({ closeModal, title, userType, details, onUpdateConfirm }) {

  const [updateForm, setRegisterForm] = useState({
    profession: "",
    classification: "",
    introduction: ""
  })
  const [skillsArray, setSkillsArray] = useState([])
  const [singleSkill, setSingleSkill] = useState("")

  const handleChange = (event) => {
    const { name, value } = event.target
    setRegisterForm((prevEntries) => {
      return {
        ...prevEntries,
        [name]: value
      }
    })
  }
  const handleUpdateInfo = (event) => {
    event.preventDefault()
    if (userType === "instructor") {
      const _instructorDetails = {
        instructorProfession: updateForm.profession,
        instructorClassification: updateForm.classification,
        instructorIntroduction: updateForm.introduction
      }
      const _updateForm = { isInstructor: true, instructorDetails: _instructorDetails }
      onUpdateConfirm(_updateForm)
    } else if (userType === "mentor") {
      const _mentorDetails = {
        mentorProfession: updateForm.profession,
        mentorIntroduction: updateForm.introduction
      }
      const _updateForm = { isMentor: true, mentorDetails: _mentorDetails }
      onUpdateConfirm(_updateForm)
    } else if (userType === "founder") {
      const _founderDetails = {
        founderProfession: updateForm.profession,
        founderIntroduction: updateForm.introduction,
        founderkeySkills: skillsArray
      }
      const _updateForm = { isFounder: true, founderDetails: _founderDetails }
      onUpdateConfirm(_updateForm)
    }
    closeModal()
  }

  const handleSkillsRecord = (event) => {
    if (event.key === 'Enter') {
      setSkillsArray(preSkills => {
        return [...preSkills, singleSkill]
      })
      setSingleSkill("")
    }
  }
  const removeSkill = (indx) => {
    setSkillsArray((preSkills) => {
      return preSkills.filter((skill, id) => { return id !== indx })
    })
  }

  useEffect(() => {
    switch (userType) {
      case "instructor":
        setRegisterForm((prevEntries => {
          return {
            ...prevEntries,
            profession: details.instructorProfession,
            classification: details.instructorClassification,
            introduction: details.instructorIntroduction
          }
        }))
        break;
      case "mentor":
        setRegisterForm(prevEntries => {
          return {
            ...prevEntries,
            profession: details.mentorProfession,
            introduction: details.mentorIntroduction
          }
        })
        break;
      case "founder":
        setRegisterForm(prevEntries => {
          return {
            ...prevEntries,
            profession: details.founderProfession,
            introduction: details.founderIntroduction
          }
        })
        setSkillsArray(details.founderkeySkills)
        break;
      default:
        break;
    }
  }, [userType, details]);


  return (
    <div className="profile-modal-container" onClick={e => e.stopPropagation()}>
      <div className="cancel-button">
        <div className="cancel-wrapper" onClick={closeModal}>
          <img src={cancelIcon} alt="How We Work" />
        </div>
      </div>
      <div className="edit-userinfo-form">
        <h3>{title}</h3>
        <div className='form'>
          <label>Profession </label>
          <input
            className="profile-input"
            type="text"
            name="profession"
            value={updateForm.profession}
            onChange={handleChange}
          />
          {userType === "instructor" && (
            <>
              <label>Recent Class </label>
              <select name="classification" className="form-select profile-input" onChange={handleChange}>
                <option defaultValue={updateForm.classification}>{updateForm.classification}</option>
                <option value="Starter">Starter</option>
                <option value="Expert">Expert</option>
              </select>
            </>
          )}
          {userType === "founder" && (
            <>
              <label>Key Skills <span>(Optional {singleSkill.length}/30)</span> </label>
              <input
                onChange={(e) => setSingleSkill(e.target.value)}
                className="profile-input"
                type="text"
                name="singleSkill"
                value={singleSkill}
                maxLength="30"
                onKeyDown={handleSkillsRecord}
              />
              <div className="row-tags wrap">
                {skillsArray.map((_skill, indx) => {
                  return (
                    <p key={indx}>{_skill} <i className="fas fa-minus-circle tag-removal-icon" onClick={() => removeSkill(indx)}></i></p>
                  )
                })}
              </div>
            </>
          )}
          <label> About me (Introduce yourself in short words) </label>
          <textarea
            className="profile-input"
            type="text"
            name="introduction"
            value={updateForm.introduction}
            onChange={handleChange}
          />
          <ButtonLink ClassName="learn-more-btn full-width">
            <button onClick={handleUpdateInfo}> Apply </button>
          </ButtonLink>
        </div>
      </div>
    </div>
  )
}
