const MinimumMembersForGrant = 4
const MaximumMembersForGrant = 8

const vidLanguageoptions = [
  {
    label: "Watch in English >>",
    value: "english",
    selected: "active"
  },
  {
    label: "Watch in Arabic >>",
    value: "arabic",
    selected: ""
  }
]

export { MinimumMembersForGrant, MaximumMembersForGrant, vidLanguageoptions }