/*===  Import Benefits Teaching Images === */
import { useSelector } from "react-redux";
import img_1 from "../../../images/icons/teach1.png";
import img_2 from "../../../images/icons/teach2.png";
import img_3 from "../../../images/icons/teach3.png";

const BenefitsTeaching = () => {
  const language = useSelector((state) => state.language);
  const { translation } = language;
  return (
    <div
      id="teach-us"
      className="how-works text-center"
      style={{ padding: "100px 0" }}
    >
      <div className="container-lg">
        <div className="row justify-content-evenly align-items-align-items-end">
          <h2 className="mb-5">{translation.BenefitsOfTeaching}</h2>
          {/* Start Cards */}
          <div className="col-md-4 col-lg-3">
            <div className="card py-4 mb-3">
              <img
                src={img_1}
                className="card-img-top m-auto"
                alt="How We Work"
              />
              <div className="card-body">
                <h5 className="card-title text-capitalize py-3">
                  {translation.BringChange}
                </h5>
                <p className="card-text">
                  {translation.MainCoreOfOrganization}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-lg-3">
            <div className="card py-4 mb-3">
              <img
                src={img_2}
                className="card-img-top m-auto"
                alt="How We Work"
              />
              <div className="card-body">
                <h5 className="card-title text-capitalize py-3">
                  {translation.BuildUniqueNetwork}
                </h5>
                <p className="card-text">
                  {translation.JoinExpertsClub}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-lg-3">
            <div className="card py-4 mb-3">
              <img
                src={img_3}
                className="card-img-top m-auto"
                alt="How We Work"
              />
              <div className="card-body">
                <h5 className="card-title text-capitalize py-3">
                  {translation.EarnSomeMoney}
                </h5>
                <p className="card-text">
                  {translation.WhileYouImpactWorld}
                </p>
              </div>
            </div>
          </div>
          {/* End Cards */}
        </div>
      </div>
    </div>
  );
};

export default BenefitsTeaching;
