/*===  Css Custom File === */
import "./DetailsJobs.css";
// import Images
// import jobs from "../../../SampleData/jobs";
import JobScreen from "./JobScreen";
import JobsSelector from "./JobsSelector";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";
import { listJobs } from "../../../actions/jobActions";
import Spinner from "../../../components/Spinner/Spinner";
import Message from "../../../components/Message/Message";

export default function DetailsJobs({ filteredValues }) {
  const dispatch = useDispatch();
  const { path: pathroot, url: urlroot } = useRouteMatch()

  const jobList = useSelector((state) => state.jobList)
  const { loading, error, jobs } = jobList

  const [jobsList, setJobsList] = useState(jobs)
  const [defaultJob, setDefaultJob] = useState({})

  useEffect(() => {
    let displayedJobs = []
    if (!filteredValues.title && !filteredValues.category) {
      setDefaultJob(jobs[0])
      setJobsList(jobs)
    } else {
      // console.log("filteredValues: ", filteredValues)
      // console.log("jobs:  ", jobs)
      if (!filteredValues.title) {
        displayedJobs = jobs.filter(_job => _job.category === filteredValues.category)
      } else if (!filteredValues.category) {
        displayedJobs = jobs.filter(_job => _job.title === filteredValues.title)
      } else {
        const firstGroup = jobs.filter(_job => _job.category === filteredValues.category)
        displayedJobs = firstGroup.filter(_job => _job.title === filteredValues.title)
      }
      // const displayedJobs = jobs.filter(_job => (_job.title === filteredPath.title || _job.category === filteredPath.category))
      setJobsList(displayedJobs)
    }
  }, [jobs, filteredValues])


  useEffect(() => {
    dispatch(listJobs())
  }, [dispatch])
  return (
    <div className="details-jobs">
      {loading && <Spinner />}
      {error && <Message ClassName="message-error">{error}</Message>}
      {!loading && !error && (
        <>
          <div className="container-lg">
            {/* Start Details Jobs */}
            <div className="d-md-flex justify-content-center">
              <div className="nav flex-column nav-pills me-4 quarter-1-4">
                <JobsSelector
                  jobs={jobsList}
                  UrlRoot={urlroot}
                />
              </div>
              <div className="tab-content quarter-3-4">
                <Switch>
                  <Route exact path={pathroot}>
                    <JobScreen selectedJob={defaultJob} />
                  </Route>
                  <Route path={`${pathroot}/job/:jobId`}>
                    <JobScreen />
                  </Route>
                </Switch>
              </div>
            </div>
            {/* End Details Jobs */}
          </div>
        </>
      )}
    </div>
  );
};
