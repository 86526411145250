import { useState } from "react";
import axios from 'axios'
const tus = require("tus-js-client")

const useVideoUpload = () => {
  const [vidUploadRes, setVidUploadRes] = useState({
    vidId: "",
    uploadPercent: "",
    fileName: "",
    faultMsg: "",
    uploadStatus: false
  });
  // let mediaId = ''
  const triggerVidUpload = async (vid) => {
    try {
      const { data: uploadCredits } = await axios.get("/api/videosupload")
      // console.log("uploadCredits: ", uploadCredits)
      const options = {
        endpoint: `https://api.cloudflare.com/client/v4/accounts/${uploadCredits.flareID}/stream`,
        headers: {
          'Authorization': `Bearer ${uploadCredits.flareToken}`,
        },
        chunkSize: 50 * 1024 * 1024, // Required a minimum chunk size of 5MB, here we use 50MB.
        metadata: {
          name: vid.name,
          filename: vid.name,
          filetype: vid.type,
          defaulttimestamppct: 0.5,
        },
        uploadSize: vid.size,
        onError: function (error) {
          throw error;
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
          // console.log(bytesUploaded, bytesTotal, percentage + "%");
          setTimeout(() => {
            setVidUploadRes((prevVals) => {
              return {
                ...prevVals,
                uploadPercent: percentage
              }
            })
          }, 1500);
        },
        onSuccess: function () {
          console.log("Upload finished")
          setVidUploadRes((prevVals) => {
            return {
              ...prevVals,
              uploadStatus: true,
            }
          })
        },
        onAfterResponse: function (req, res) {
          return new Promise(resolve => {
            const mediaIdHeader = res.getHeader("stream-media-id");
            if (mediaIdHeader) {
              // mediaId = mediaIdHeader;
              // console.log("mediaId: ", mediaId)
              setVidUploadRes((prevVals) => {
                return {
                  ...prevVals,
                  fileName: vid.name,
                  vidId: mediaIdHeader
                }
              })
            }
            resolve()
          })
        }
      };

      const upload = new tus.Upload(vid, options);
      upload.start();
      // console.log("mediaId: ", mediaId)

    } catch (error) {
      setVidUploadRes((prevVals) => {
        return {
          ...prevVals,
          faultMsg: error,
        }
      })
    }
  }

  return [vidUploadRes, triggerVidUpload]

};

export default useVideoUpload
