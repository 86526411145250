import axios from 'axios'
import { GRANT_UPDATE_SUCCESS } from '../constants/grantsConstants'
import { ORDER_LIST_ORDERS_RESET } from '../constants/orderConstants'
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  UPDATE_USER_COURSES_SUCCESS,
  UPDATE_USER_COURSES_RESET,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  VALID_TOKEN_SUCCESS,
  VALID_TOKEN_FAIL
} from '../constants/userConstants'

// action for login*******************
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(
      '/api/users/login',
      { email, password },
      config
    )

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })
    const dataWithTokenValidation = {...data, tokenValidity: true}
    localStorage.setItem('userInfo', JSON.stringify(dataWithTokenValidation))
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// action for linkedin login*******************
export const linkedinLogin = (authorizationInfo) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.post('/api/linkedin/verifyuser', authorizationInfo, config)
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })
    localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
// action for register*****************
export const register = (registerForm) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.post('/api/users', registerForm, config)
    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    })
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })
    localStorage.setItem('userInfo', JSON.stringify(data))

    // triggered only when grantCode used in registeration
    if (registerForm.grantCode) {
      const grantForm = { userId: data._id, userName: data.name, grantCode: registerForm.grantCode }
      const { data: grantInfo } = await axios.put('/api/grants/apply', grantForm, config)
      dispatch({
        type: GRANT_UPDATE_SUCCESS,
        payload: grantInfo,
      })
    }
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Action of logout*********************************
export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo')
  localStorage.removeItem('grantCode')
  // optional actions
  // localStorage.removeItem('cartItems')
  // dispatch({ type: CART_RESET })
  // shippingAddress
  // localStorage.removeItem('shippingAddress')
  dispatch({ type: USER_LOGOUT })
  dispatch({ type: UPDATE_USER_COURSES_RESET })
  dispatch({ type: USER_DETAILS_RESET })
  dispatch({ type: ORDER_LIST_ORDERS_RESET })
  // dispatch({ type: USER_LIST_RESET })
}

// action for updating information of the customer -- User Access --*******************
export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    })

    const { userLogin: { userInfo } } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      },
    }

    const { data } = await axios.put(`/api/users/profile`, user, config)

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
    })
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data
    })

    localStorage.setItem('userInfo', JSON.stringify(data))

  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// action for listing all users -- Admin access**********
export const listUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/users`, config)

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// action for getting information of the customer -- Admin access -- and user access with profile = 'profile'*******************
export const getUserDetails = (profile) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    })

    const { userLogin: { userInfo } } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      },
    }

    const { data } = await axios.get(`/api/users/${profile}`, config)

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    })
  }
}

// action for getting information of the customer -- Admin access -- and user access with profile = 'profile'*******************
export const verifyTokenValidity = () => async (dispatch, getState) => {
  try {
    const { userLogin: { userInfo } } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      },
    }
    await axios.get(`/api/users/profile`, config)
    dispatch({type: VALID_TOKEN_SUCCESS})

  } catch (error) {
    dispatch({type: VALID_TOKEN_FAIL})
    localStorage.removeItem('userInfo')
  }
}

// Action for updating information of the customer -- user access -- *******************
export const updateUserCourses = (enrolledCourses) => async (dispatch, getState) => {
  // enrolledCourses = {courseId: '', courseName: ''}
  const { userLogin: { userInfo } } = getState()
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`
    },
  }
  const { data } = await axios.post(`/api/users/enrolledcourses`, enrolledCourses, config)
  dispatch({
    type: UPDATE_USER_COURSES_SUCCESS,
    payload: data
  })
  localStorage.setItem('userInfo', JSON.stringify(data))
}

// -- Admin access --
export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/users/${id}`, config)

    dispatch({ type: USER_DELETE_SUCCESS })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: USER_DELETE_FAIL,
      payload: message,
    })
  }
}

// -- Admin access --
export const updateUser = (user) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/users/${user._id}`, user, config)

    dispatch({ type: USER_UPDATE_SUCCESS })
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: message,
    })
  }
}
