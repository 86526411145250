import axios from "axios"
import {
  MENTOR_PATH_LIST_FAIL, MENTOR_PATH_LIST_LOADING, MENTOR_PATH_LIST_SUCCESS,
  PATH_ACTIONON_APPLICANT_FAIL,
  PATH_ACTIONON_APPLICANT_SUCCESS,
  PATH_APPLICANT_APPLY_FAIL,
  PATH_APPLICANT_APPLY_SUCCESS,
  PATH_CREATE_FAIL,
  PATH_CREATE_SUCCESS,
  PATH_DELETE_FAIL, PATH_DELETE_SUCCESS,
  PATH_DETAILS_FAIL, PATH_DETAILS_SUCCESS,
  PATH_LIST_FAIL,
  PATH_LIST_LOADING,
  PATH_LIST_SUCCESS,
  PATH_UPDATE_FAIL,
  PATH_UPDATE_SUCCESS
} from "../constants/pathConstants"
import { logout } from "./userActions"

// public access to all Path, all users can see the list
export const listPaths = () => async (dispatch) => {
  try {
    dispatch({ type: PATH_LIST_LOADING })

    const { data } = await axios.get('/api/paths')
    dispatch({
      type: PATH_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PATH_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const ListPathDetails = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/api/paths/${id}`)
    dispatch({
      type: PATH_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PATH_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Mentor fetch his written learning paths
export const listMentorPaths = () => async (dispatch, getState) => {
  try {
    dispatch({ type: MENTOR_PATH_LIST_LOADING })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get('/api/paths/mentor/getpaths', config)
    dispatch({
      type: MENTOR_PATH_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MENTOR_PATH_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
// Mentor and Admin create Learn Path ****
export const createLearnPath = (pathForm) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
      categoriesList: { categories }
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    // update categories in database
    const alreadyExistedGeneral = categories.filter( category => category.categoryName===pathForm.category)
    const alreadyExistedSpecial = categories.filter( category => category.categoryName===pathForm.pathName)
    if (alreadyExistedGeneral.length === 0) {
      const newCategory = {
        categoryType: "general",
        categoryName: pathForm.category,
      }
      await axios.post(`/api/categories/useraction`, newCategory, config)
    }

    if (alreadyExistedSpecial.length === 0) {
      const newCategory = {
        categoryType: "special",
        categoryName: pathForm.pathName,
      }
      await axios.post(`/api/categories/useraction`, newCategory, config)
    }

    await axios.post(`/api/paths/mentor/createpath`, pathForm, config)
    dispatch({
      type: PATH_CREATE_SUCCESS,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PATH_CREATE_FAIL,
      payload: message,
    })
  }
}

// Mentor and Admin update Learn Path ****
export const updateLearnPath = (pathForm) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.put(`/api/paths/mentor/${pathForm._id}`, pathForm, config)
    dispatch({
      type: PATH_UPDATE_SUCCESS,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PATH_UPDATE_FAIL,
      payload: message,
    })
  }
}
// Mentor delete his written path
export const deletePath = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/paths/mentor/${id}`, config)

    dispatch({
      type: PATH_DELETE_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PATH_DELETE_FAIL,
      payload: message,
    })
  }
}
// Mentor approves or refuses mentorship application
export const actionOnMentorship = (actionForm, pathID) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(`/api/paths/approveapplicant/${pathID}`, actionForm, config)
    dispatch({
      type: PATH_ACTIONON_APPLICANT_SUCCESS,
      payload: data.message,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PATH_ACTIONON_APPLICANT_FAIL,
      payload: message,
    })
  }
}
// user apply to enroll into this path
export const applyToLearnPath = (application, pathID) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.post(`/api/paths/${pathID}`, application, config)
    dispatch({
      type: PATH_APPLICANT_APPLY_SUCCESS,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PATH_APPLICANT_APPLY_FAIL,
      payload: message,
    })
  }
}