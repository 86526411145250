/*===  Css Custom File === */
/*===  Import How We Work Images === */
import { useSelector } from "react-redux";
import img_1 from "../../../images/icons/brand.svg";
import img_2 from "../../../images/icons/savetime.svg";
import img_3 from "../../../images/icons/ai.svg";
import img_4 from "../../../images/icons/onepoint.svg";
import img_5 from "../../../images/icons/startups_software.svg";
import './HowWorks.css'

const HowWorks = () => {
  const language = useSelector((state) => state.language);
  const { translation } = language;
  return (
    <div className="how-works text-center">
      <h2 className="text-capitalize text-center py-5">{translation.AseeuBenefits}</h2>
      <div className='container-lg benefits'>
        <div className='row justify-content-around px-3'>
          <div className='col-lg-4 col-md-12 mb-5'>
            <div className='relative'>
              <img className='icon' src={img_1} alt="icon" width={70} height={70} />
            </div>
            <div className="txt_wrapper">
              <h4>{translation.Benefit1_title}</h4>
              <p className='greyfont'>{translation.Benefit1_body}</p>
            </div>
          </div>
          <div className='col-lg-4 col-md-12 mb-5'>
            <div className='relative'>
              <img className='icon' src={img_2} alt="icon" width={70} height={70} />
            </div>
            <div className="txt_wrapper">
              <h4>{translation.Benefit2_title}</h4>
              <p className='greyfont'>{translation.Benefit2_body}</p>
            </div>
          </div>
          <div className='col-lg-4 col-md-12 mb-5'>
            <div className='relative'>
              <img className='icon' src={img_3} alt="icon" width={70} height={70} />
            </div>
            <div className="txt_wrapper">
              <h4>{translation.Benefit3_title}</h4>
              <p className='greyfont'>{translation.Benefit3_body}</p>
            </div>
          </div>
          <div className='col-lg-4 col-md-12 mb-5'>
            <div className='relative'>
              <img className='icon' src={img_4} alt="icon" width={70} height={70} />
            </div>
            <div className="txt_wrapper">
              <h4>{translation.Benefit4_title}</h4>
              <p className='greyfont'>{translation.Benefit4_body}</p>
            </div>
          </div>
          <div className='col-lg-4 col-md-12 mb-5'>
            <div className='relative'>
              <img className='icon' src={img_5} alt="icon" width={70} height={70} />
            </div>
            <div className="txt_wrapper">
              <h4>{translation.Benefit5_title}</h4>
              <p className='greyfont'>{translation.Benefit5_body}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWorks;
