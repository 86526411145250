import React from 'react'
import './Message.css'

export default function Message({ClassName, children}) {
  return (
    <div className={`message-bar ${ClassName}`}>
      {children}
    </div>
  )
}

Message.defaultProps = {
  ClassName: "message-error"
}
