export const GRANT_CREATE_SUCCESS = 'GRANT_CREATE_SUCCESS'
export const GRANT_UPDATE_SUCCESS = 'GRANT_UPDATE_SUCCESS'
export const GRANT_FETCH_STATE_SUCCESS = 'GRANT_FETCH_STATE_SUCCESS'
export const GRANT_ACTION_FAIL = 'GRANT_ACTION_FAIL'

export const GRANT_ADMIN_FETCH_ALL_SUCCESS = 'GRANT_ADMIN_FETCH_ALL_SUCCESS'
export const GRANT_ADMIN_FETCH_ALL_FAIL = 'GRANT_ADMIN_FETCH_ALL_FAIL'

export const GRANT_DELETE_SUCCESS = 'GRANT_DELETE_SUCCESS'
export const GRANT_ADMIN_CREATE_SUCCESS = 'GRANT_ADMIN_CREATE_SUCCESS'
export const GRANT_ADMIN_ACTION_FAIL = 'GRANT_ADMIN_ACTION_FAIL'
export const GRANT_ADMIN_ACTION_RESET = 'GRANT_ADMIN_ACTION_RESET'