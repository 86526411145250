import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './LearnPathStripe.css'
import ApplicationStripe from '../ApplicationStripe/ApplicationStripe'

export default function LearnPathStripe({ path, indx, removeHandler, onActionResponse }) {
  const [requestsBoolean, setRequestsBoolean] = useState(false)

  return (
    <div className="path-stripe" key={indx}>
      <div className="head">
        <div className="title">{path.pathName}</div>
        <div className="edit-tools">
          <i title="Remove LearnPath" className="fas fa-trash removal" onClick={() => removeHandler(path._id)}></i>
          <Link to={`/mentor/${path._id}/editpath`} title="Edit Path"><i className="fas fa-edit"></i></Link>
        </div>
      </div>
      <div className="row-tags">
        {path.keySkills.map((skill, _index) => (
          <p key={_index}>{skill}</p>
        ))}
      </div>

      <div className="path-applications">
        <div className={`requests ${requestsBoolean ? "active" : ""}`} onClick={() => setRequestsBoolean(!requestsBoolean)}>
          <h5>All Mentorship Requests</h5>
          <i className="fas fa-caret-down"></i>
        </div>
        <div className={`wrapper ${requestsBoolean ? "active" : ""}`}>
          {path.applications.map((_application, _index) => (
            <div key={_application._id} className="application-info">
              <ApplicationStripe
                applicationType="path"
                key={_application._id}
                application={_application}
                index={_index}
                topicID={path._id}
                onActionResponse={onActionResponse}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
