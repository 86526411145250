import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { verifyTokenValidity } from '../../../actions/userActions'
import ButtonLink from '../../../components/ButtonLink/ButtonLink'
import MultiSelector from '../../../components/MultiSelector/MultiSelector'
import history from '../../../history'
import { vidLanguageoptions } from '../../../SampleData/standardVals'
import Modal from '../../../screen/Modal/Modal'
import NewApplyModal from '../ModalScreens/NewApplyModal'

export default function MentorInvite() {
  const dispatch = useDispatch()
  const { path: pathroot } = useRouteMatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [modalOpen, setModalOpen] = useState(false)
  // const [vidID, setVidID] = useState("https://www.youtube.com/embed/4cGkKWmibVU")
  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    if (!userInfo?._id) {
      setTimeout(() => {
        history.push(`/signin?redirect=${pathroot}`)
      }, 500);
    } else {
      dispatch(verifyTokenValidity())
    }
  }, [userInfo, dispatch, pathroot])

  // const onvidSelection = (selectionVal, index) => {
  //   const baseVidLink = "https://www.youtube.com/embed/"
  //   const vidSerialID = selectionVal === "arabic" ? "7NeLMqQ3bII" : "4cGkKWmibVU"
  //   const videoLink = baseVidLink + vidSerialID
  //   setVidID(videoLink)
  // }
  return (
    <div className="user-invite">
      <div className="title-bar-profile">
        <div className="sction-title">Information</div>
        <ButtonLink ClassName="learn-more-btn">
          <button onClick={openModal}> Apply Now </button>
        </ButtonLink>
      </div>
      <div className="body">
        <ul>
          <li>
            Are you hiring manager or HR administrator in your company?
          </li>
          <li>
            Are you professor and seraching for a talent to join your lab?
          </li>
          <li>
            Are you co-founder in a tech startup?
          </li>
        </ul>
        <p>If you fulfil <b>one of these requirements</b>, own your recruitment board to start searching for talents and hiring smoothly and efficiently</p>
      </div>
      {/* <div className="row vidinfo-wrap">
        <div className="col-md-5 col-sm-12">
          <MultiSelector
            label="Check how mentorship works on Technopedia"
            onSelection={onvidSelection}
            arrangementClass="vertical"
            options={vidLanguageoptions}
          />
        </div>
        <div className="col-md-7 col-sm-12">
          <iframe
            width="100%"
            height="350"
            src={vidID}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div> */}
      {modalOpen && (
        <Modal closeModal={closeModal}>
          <NewApplyModal applicationType="mentor" closeModal={closeModal} />
        </Modal>
      )}
    </div>
  )
}
