import React, { useEffect, useState } from 'react'
import './Cart.css'
import { Link } from 'react-router-dom'
import history from '../../history'

import { useDispatch, useSelector } from 'react-redux'
import { toggleGrantStatusInCart, addToCart, applyCertificationFees, removeFromCart } from '../../actions/cartActions'
import { createOrder, getOrderDetails } from '../../actions/orderActions'

import CourseStripe from '../../components/CourseStripe/CourseStripe'
import StartBaner from '../../components/StartBaner/StartBaner'
import Message from '../../components/Message/Message'
import PaymentSelection from '../../components/PaymentSelection/PaymentSelection'
import ButtonLink from '../../components/ButtonLink/ButtonLink'
import Modal from '../../screen/Modal/Modal'
import welcomeImg from '../../images/welcome.webp'
import cancelIcon from '../../images/general-purpose/cancelIcon.png'
import { fetchGrantInfo } from '../../actions/grantActions'
import MultiSelector from '../../components/MultiSelector/MultiSelector'

const setPageTitle = (title) => {
  const prevTitle = document.title;
  document.title = title;
  return () => document.title = prevTitle;
}

const certifiedTrackOptions = [
  {
    label: "Certified Track",
    value: "certify",
    selected: ""
  },
  {
    label: "Non-Certified",
    value: "nonCertify",
    selected: ""
  }
]

export default function Cart({ match }) {
  setPageTitle('My Cart')
  const dispatch = useDispatch()
  // Cart needed attributes
  // image, name, category, rating, price, courseLength

  const courseId = match.params.id
  const [fullGrantStatus, setFullGrantStatus] = useState({
    status: false,
    inUse: ""
  })
  const [cetifiedTrack, setCetifiedTrack] = useState({
    courseID: "",
    trackStatus: ""
  })
  const [paymentMethod, setPaymentMethod] = useState('card')
  const [openModal, setOpenModal] = useState(false)

  // const [selectPayMethoderr, setSelectPayMethoderr] = useState('')
  // this variable will come from userState (useInfo) to decide wether he will get full scholarship or not
  // const usergrant = false

  // this part to make sure that user signed in before placing an order
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const grantInformation = useSelector((state) => state.grantInformation)
  const { grantInfo } = grantInformation
  const _singlemember = grantInfo?.members?.find(singlemember => singlemember.member.toString() === userInfo._id.toString())
  const grantExpiryStatus = _singlemember ? _singlemember.expiredGrant : true
  // this part to add iems to the cart and to display the selected items
  // const cartItems = localStorage.getItem('cartItems')
  // ? JSON.parse(localStorage.getItem('cartItems'))
  // : []
  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const totalprice = cartItems.reduce((acc, item) => acc + item.price, 0)
  const totalDeductedAmount = cartItems.reduce((acc, item) => acc + item.deductedAmount, 0)
  const totalCertificateFees = cartItems.reduce((acc, item) => acc + item.certificationFee, 0)

  useEffect(() => {
    if (courseId) {
      dispatch(addToCart(courseId))
    }
  }, [dispatch, courseId])
  useEffect(() => {
    if (cetifiedTrack && cetifiedTrack.courseID) {
      dispatch(applyCertificationFees(cetifiedTrack.courseID, cetifiedTrack.trackStatus))
    }

  }, [dispatch, cetifiedTrack])
  useEffect(() => {
    if (fullGrantStatus && fullGrantStatus.inUse) {
      dispatch(toggleGrantStatusInCart("activate"))
    } else {
      dispatch(toggleGrantStatusInCart("deactivate"))
    }
  }, [dispatch, fullGrantStatus])

  // console.log("fullGrant: ", fullGrantStatus)
  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success } = orderCreate

  useEffect(() => {
    if (success) {
      history.push(`/order/${order._id}`)
      dispatch(getOrderDetails(order._id))
    }
    if (!grantInfo) {
      dispatch(fetchGrantInfo())
    }
  }, [dispatch, order?._id, success, grantInfo])

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id))
  }

  const handleCheck = (event) => {
    const { checked } = event.target
    if (checked) {
      setFullGrantStatus({
        status: true,
        inUse: "Yes"
      })
    } else {
      setFullGrantStatus({
        status: false,
        inUse: ""
      })
    }
  }

  const onCertifyTrackSelection = (value, indx, _courseId) => {
    setCetifiedTrack({
      courseID: _courseId,
      trackStatus: value
    })
    // console.log("returned values from multiSelection: ", value, indx, _courseId)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const checkoutHandler = (event) => {
    event.preventDefault()
    if (cartItems.length === 0) {
      setOpenModal(true)
      return
    }
    if (userInfo) {
      const _orderItems = cartItems.map(item => {
        const { course, courseCategory, name, price, deductedAmount, certificationFee, activeCertifiedTrack } = item
        return { course, courseCategory, name, price, discount: deductedAmount, certificationFee, activeCertifiedTrack }
      })
      // console.log('orderitems just befor creating order:', _orderItems)
      // console.log('cartItems just befor creating order:', cartItems)
      dispatch(createOrder({
        paymentMethod: paymentMethod,
        orderItems: _orderItems,
        originalPrice: Math.round((totalprice + Number.EPSILON) * 100) / 100,
        totalPrice: Math.round(((totalprice - totalDeductedAmount + totalCertificateFees) + Number.EPSILON) * 100) / 100,
        grantId: (grantInfo?.grantAvailability && fullGrantStatus.status) ? grantInfo?._id : ""
      }))
    } else {
      // console.log("Not possible place an order, signin first!, or no courses in the cart")
      history.push('/signin?redirect=cart')
    }
    // history.push('/signin?redirect=order')
  }

  // payment options:
  const paymentOptions = [
    { key: 0, label: 'PayPal', value: 'paypal', iconPath: 'fab fa-cc-paypal', selected: 'passive' }
    // { key: 1, label: 'Credit / Debit Card', value: 'card', iconPath: 'far fa-credit-card', selected: 'active' }
  ]
  const selectPayment = (value, index) => {
    setPaymentMethod(value)
    // console.log('value: ', value, ' index: ', index)
  }

  return (
    <div>
      {/* Start | Banner Section */}
      <StartBaner imgLink={welcomeImg}>
        <h2>Keep Your Competence</h2>
        <p>Learn in Proper way</p>
      </StartBaner>
      <section className="cart-section">
        <div className='container'>
          <div className="row">
            {/* purchased course details */}
            <div className="col-lg-8 col-md-12">
              <div className="text-center">
                <h2 className="common-heading d-inline-block"><span>Your</span> Enrolled<span> Courses</span></h2>
                {/* <p>Placing card stripes</p> */}
              </div>
              <CourseStripe cartItems={cartItems} removeHandler={removeFromCartHandler} type="cart" />
              <ButtonLink ClassName="learn-more-btn">
                <Link to="/technopedia" title="CourseList">ADD OTHER COURSES</Link>
              </ButtonLink>
            </div>
            {/* Order Details */}
            <div className="col-lg-4 col-md-12">
              {/* <div className="text-center">
                <h2 className="common-heading d-inline-block"><span>Order </span> Details</h2>
              </div> */}
              <div className="order-container">
                <h5 className="highlight-txt mb-3"> Order Details </h5>
                <div className="cart-summary mb-3">
                  <Message ClassName="message-info mini-msg styled-help-info align-left">
                    Difference Between Certified and Non-certified Track
                    <i className="far fa-question-circle">
                      <span>*In Certified Track:
                      <br></br>
                      You will need to deliver a project when you complete the course. You sould be able to pass the test easily if you followed carefully all tutorials of the course
                      <br></br>
                      If you passed the test:
                      <br></br>
                      1- You will get a certificate approved by us that you passed the course successfully
                      <br></br>
                      2-You will be recorded in our talents pool, So recruiters can find you
                      <br></br>
                      3-Course instructor can write you a recommendation on your Linkedin page to improve your chances with job hunting.
                      <br></br>
                      <br></br>
                      *In Non-Certified Track: You are not entitled to deliver any project by the end of the course, you can just audit the whole course
                      <br></br>
                      <br></br>
                      **In both Tracks, You will have access to all tutorials and contents of the Course
                      </span>
                    </i>
                  </Message>
                  {cartItems.map(item => {
                    if (item.isCertified) {
                      const newOptions = certifiedTrackOptions.map(option => {
                        if (item.activeCertifiedTrack) {
                          if (option.value === "certify") {
                            return { ...option, selected: "active" }
                          } else {
                            return { ...option, selected: "" }
                          }
                        } else {
                          if (option.value === "certify") {
                            return { ...option, selected: "" }
                          } else {
                            return { ...option, selected: "active" }
                          }
                        }
                      })
                      return (
                        <div key={item.course} className="summary-cell">
                          <MultiSelector
                            label={item.name}
                            onSelection={(value, indx) => onCertifyTrackSelection(value, indx, item.course)}
                            arrangementClass="horizontal"
                            labelStyle="small-bold-left"
                            options={newOptions}
                          />
                        </div>
                      )
                    }
                  })}
                </div>
                <div className="cart-summary">
                  <div className="summary-cell">Enrollement fees
                    <span>
                      $ {totalprice.toFixed(2)}
                    </span>
                  </div>
                  {(grantInfo?.grantAvailability && !grantExpiryStatus) ? (
                    <div className="summary-cell">
                      <Message ClassName='message-success'>Congrats!, You have vaild scholarship</Message>
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onChange={handleCheck}
                        />
                        <label style={{ fontWeight: 'bold' }} className="form-check-label label-question" htmlFor="flexCheckDefault">
                          Will you use it?
                        </label>
                      </div>
                    </div>
                  ) : null}
                  <div className="summary-cell">Discount<span>{totalDeductedAmount.toFixed(2)}</span></div>
                  <div className="summary-cell">All Certification Fees<span>{totalCertificateFees.toFixed(2)}</span></div>
                  <div className="summary-total">Final cost
                    <span>
                      $ {(totalprice - totalDeductedAmount + totalCertificateFees).toFixed(2)}
                    </span>
                  </div>
                </div>
                {(totalprice - totalDeductedAmount + totalCertificateFees) !== 0 && (
                  <PaymentSelection paymentOptions={paymentOptions} selectPayment={selectPayment} />
                )}
              </div>
              {/* Here there will be a switch between payment or direct enrollment based on scholarship state */}
              <ButtonLink ClassName="learn-more-btn flex">
                <button onClick={checkoutHandler}>Confirm order<i className="fas fa-arrow-circle-right"></i></button>
              </ButtonLink>
            </div>
          </div>
        </div>
      </section>
      {openModal && (
        <Modal closeModal={closeModal}>
          <div className="no-cart-courses" onClick={e => e.stopPropagation()}>
            <div className="cancel-button">
              <div className="cancel-wrapper" onClick={closeModal}>
                <img src={cancelIcon} alt="How We Work" />
              </div>
            </div>
            <h4> You don't have any courses in your cart </h4>
            <ButtonLink ClassName="learn-more-btn">
              <Link to="/technopedia" title="CourseList">ADD COURSES</Link>
            </ButtonLink>
          </div>
        </Modal>
      )}
    </div>
  )
}
