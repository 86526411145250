/*===  Css Custom File === */
import "./OurInstructors.css";
import MemberCard from "../../../components/MemberCard/MemberCard"
import instructors from '../../../SampleData/members'
import { useSelector } from "react-redux";

const OurInstructors = () => {
  const language = useSelector((state) => state.language);
  const { translation } = language;

  return (
    <div className="member-card-container text-center mt-5">
      <div className="container">
        <h2 className="text-capitalize mb-5">{translation.OurInstructors}</h2>
        <div className="row justify-content-center align-items-center">
          {instructors.map((instructor, indx) => {
            return (
              <div key={indx} className="col-md-6 col-lg-3">
                <MemberCard
                ClassName= "instructor"
                name= {instructor.name}
                profession= {instructor.profession}
                img= {instructor.img}
                linkedinURL= {instructor.linkedinURL}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default OurInstructors;
