import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListJobDetails, updateJob } from '../../actions/jobActions'
import ButtonLink from '../../components/ButtonLink/ButtonLink'
import Message from '../../components/Message/Message'
import { JOB_DETAILS_RESET, JOB_UPDATE_RESET } from '../../constants/jobConstants'
import history from '../../history'
import axios from 'axios'

import editLogo from '../../images/general-purpose/editLogo.png'
import { Toaster, toast } from 'react-hot-toast'

export default function UpdateJob({ match }) {
  const jobId = match.params.id
  const dispatch = useDispatch()
  const [imgClsName, setImgClsName] = useState("photo-wrapper")
  const [companylogo, setCompanylogo] = useState("")

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const jobDetails = useSelector((state) => state.jobDetails)
  const { jobdetails, detailserror } = jobDetails

  const jobUpdate = useSelector((state) => state.jobUpdate)
  const { updateSuccess, updateError } = jobUpdate

  const categoriesList = useSelector((state) => state.categoriesList)
  const { categories } = categoriesList
  const jobCategories = categories.filter(category => category.categoryType === 'general')
  // const jobTitles = categories.filter(category => category.categoryType === 'special')

  const [jobForm, setJobForm] = useState({
    title: "",
    deadLine: "",
    category: "",
    status: "",
    jobType: "",
    experienceLevel: "",
    jobDescription: "",
    companyName: "",
    jobLocation: "",
    aboutCompany: "",
    companyHeadQuarter: ""
  })
  const [requirementsArray, setRequirementsArray] = useState([])
  const [singleRequirement, setSingleRequirement] = useState("")
  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })

  useEffect(() => {
    if (detailserror) {
      setMessage({
        msgType: "message-error",
        messageBody: detailserror
      })
      return
    }
    if (!jobdetails?._id) {
      dispatch(ListJobDetails(jobId))
    } else {
      setJobForm({
        title: jobdetails.title,
        deadLine: jobdetails.deadLine,
        category: jobdetails.category,
        status: jobdetails.status,
        jobType: jobdetails.jobType,
        experienceLevel: jobdetails.experienceLevel || "",
        jobDescription: jobdetails.jobDescription,
        companyName: jobdetails.companyName,
        jobLocation: jobdetails.jobLocation,
        aboutCompany: jobdetails.aboutCompany,
        companyHeadQuarter: jobdetails.companyHeadQuarter || "",
      })
      setRequirementsArray(jobdetails.jobRequirements)
      const logo = jobDetails?.companyLogo?.img?.data ? Buffer.from(jobDetails?.companyLogo?.img?.data).toString('base64') : ""
      setCompanylogo(logo)
    }
  }, [dispatch, jobId, jobdetails, detailserror, jobDetails?.companyLogo?.img?.data])

  useEffect(() => {
    if (!userInfo?.isApprovedMentor && !userInfo?.isMentor) {
      history.push('/')
    }
    if (updateError) {
      setMessage({
        msgType: "message-error",
        messageBody: updateError
      })
      return
    }
    if (updateSuccess) {
      setMessage({
        msgType: "message-success",
        messageBody: "Your Job is updated successfully, you will be redirected to your board.."
      })
      setTimeout(() => {
        history.push("/profile/companyboard")
        dispatch({ type: JOB_DETAILS_RESET })
        dispatch({ type: JOB_UPDATE_RESET })
      }, 5000);
    }
  }, [updateSuccess, updateError, dispatch, userInfo])

  const handleChange = (event) => {
    const { name, value } = event.target
    setJobForm((prevEntries) => {
      return {
        ...prevEntries,
        [name]: value
      }
    })
  }

  const handleRequirementsRecord = (event) => {
    if (event.key === 'Enter') {
      console.log('enter key pressed')
      setRequirementsArray(preRequirements => {
        return [...preRequirements, singleRequirement]
      })
      setSingleRequirement("")
    }
  }
  const removeRequirement = (indx) => {
    setRequirementsArray((preRequirements) => {
      return preRequirements.filter((requirement, id) => { return id !== indx })
    })
  }

  const cancelUpdate = (e) => {
    e.preventDefault()
    history.push("/profile/companyboard")
    dispatch({ type: JOB_DETAILS_RESET })
  }

  const updateJobHandler = (e) => {
    e.preventDefault()
    if (!jobForm.title) {
      setMessage({
        msgType: "message-error",
        messageBody: " Job title is missing"
      })
      return
    }
    if (!jobForm.category) {
      setMessage({
        msgType: "message-error",
        messageBody: "Job category is missing"
      })
      return
    }
    if (!jobForm.experienceLevel) {
      setMessage({
        msgType: "message-error",
        messageBody: "Level of Experience is missing"
      })
      return
    }
    if (!jobForm.status) {
      setMessage({
        msgType: "message-error",
        messageBody: "Job status is missing"
      })
      return
    }
    if (!jobForm.jobType) {
      setMessage({
        msgType: "message-error",
        messageBody: "Job type is missing"
      })
      return
    }
    if (!jobForm.jobDescription) {
      setMessage({
        msgType: "message-error",
        messageBody: "Job description is missing"
      })
      return
    }
    if (requirementsArray.length === 0) {
      setMessage({
        msgType: "message-error",
        messageBody: "Requirements of the job are missing"
      })
      return
    }
    if (!jobForm.companyName) {
      setMessage({
        msgType: "message-error",
        messageBody: "Company name is missing"
      })
      return
    }
    if (!jobForm.jobLocation) {
      setMessage({
        msgType: "message-error",
        messageBody: "Job location is missing"
      })
      return
    }
    const _jobForm = { ...jobForm, jobRequirements: requirementsArray, _id: jobId }
    // console.log("jobForm: ", _jobForm)
    dispatch(updateJob(_jobForm))
  }

  const dragOverHandler = (ev) => {
    setImgClsName(`photo-wrapper dragover`)
    ev.preventDefault()
  }
  const dragLeaveHandler = (ev) => {
    setImgClsName(`photo-wrapper`)
    ev.preventDefault()
  }
  // Upload Image to database***********************
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  const uploadPhotoToDB = async (_file) => {
    const formData = new FormData()
    formData.append('image', _file)
    try {
      const { data } = await axios.put(`/api/upload/companyimage/${jobId}`, formData, config)
      toast.success(data.message || "Image uploaded Successfully, save to see in your profile")
    } catch (error) {
      toast.error("Image must be 'jpg' or 'jpeg' or 'png' and size must be less than 1 megabit")
    }
    setImgClsName(`photo-wrapper`)
  }
  const onPhotoUpload = async (e) => {
    await uploadPhotoToDB(e.target.files[0])
  }
  const dropHandler = async (ev) => {
    console.log("There is file dropped here")
    ev.preventDefault()
    await uploadPhotoToDB(ev.dataTransfer.files[0])
  }
  // Upload Image to database***************************
  return (
    <div className="jobs-editor">
      <div className="container">
        <h4 className="common-heading"> Update Job </h4>
        <div className="job-content">
          <div className="job-form">
            <h4>Vacancy Details</h4>
            <div className="row justify-content-between align-items-center">
              <div className="col-md-6 col-sm-12">
                <label>Job Title</label>
                <input
                  className="job_input"
                  type="text"
                  name="title"
                  value={jobForm.title}
                  onChange={handleChange}
                />
                {/* <select name="title" className="form-select job_input" onChange={handleChange}>
                  <option defaultValue={jobForm.title}> {jobForm.title} </option>
                  {jobTitles.map(title => (
                    <option value={title.categoryName}>{title.categoryName}</option>
                  ))}
                </select> */}
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Level Of Experience</label>
                <select name="experienceLevel" className="form-select job_input" onChange={handleChange}>
                  <option defaultValue={jobForm?.experienceLevel}>{jobForm?.experienceLevel}</option>
                  <option value="Fresh Graduate (0-2 Years)">Fresh Graduate (0-2 Years)</option>
                  <option value="Junior (2-5 Years)">Junior (2-5 Years)</option>
                  <option value="Senior (5-8 Years)">Senior (5-8 Years)</option>
                  <option value="Manager (+8 Years)">Manager (+8 Years)</option>
                  <option value="Any">Any</option>
                </select>
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-md-4 col-sm-12">
                <label>Job Category</label>
                <select name="category" className="form-select job_input" onChange={handleChange}>
                  <option defaultValue={jobForm?.category}> {jobForm?.category} </option>
                  {jobCategories.map(category => (
                    <option value={category.categoryName}>{category.categoryName}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Job Type</label>
                <select name="jobType" className="form-select job_input" onChange={handleChange}>
                  <option defaultValue={jobForm?.jobType}> {jobForm?.jobType} </option>
                  <option value="full-time">full-time</option>
                  <option value="part-time">part-time</option>
                  <option value="freelance">freelance</option>
                  <option value="contract">contract</option>
                </select>
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Job Status</label>
                <select name="status" className="form-select job_input" onChange={handleChange}>
                  <option defaultValue={jobForm?.status}> {jobForm?.status} </option>
                  <option value="open">Open</option>
                  <option value="close">Closed</option>
                </select>
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <label>Job Description</label>
                <textarea
                  className="job_input"
                  type="text"
                  name="jobDescription"
                  value={jobForm.jobDescription}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-md-6 col-sm-12">
                <label>Requirements <span>(Press Enter Key after writing one requirement, so you can record another one)</span></label>
                <input
                  onChange={(e) => setSingleRequirement(e.target.value)}
                  className="job_input"
                  type="text"
                  name="singleRequirement"
                  value={singleRequirement}
                  onKeyDown={handleRequirementsRecord}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Job Location <span>(if it is a remote work, mention 'remotely')</span></label>
                <input
                  className="job_input"
                  type="text"
                  name="jobLocation"
                  placeholder="Example: 'Cairo, Egypt'"
                  value={jobForm.jobLocation}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-2 col-sm-12">
                <label>Deadline <span>(Optional)</span> </label>
                <input
                  className="job_input"
                  type="text"
                  name="deadLine"
                  placeholder="dd/mm/yyyy"
                  value={jobForm.deadLine}
                  onChange={handleChange}
                />
              </div>

            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <div className="row-tags sentences">
                  {requirementsArray.map((_requirement, indx) => {
                    return (
                      <p key={indx}>{_requirement} <i className="fas fa-minus-circle" onClick={() => removeRequirement(indx)}></i></p>
                    )
                  })}
                </div>
              </div>
            </div>

            <hr />

            <div className="personal-photo">
              <div className={imgClsName}>
                {companylogo ? (
                  <img
                    src={`data:image/${companylogo.contentType};base64,${companylogo}`}
                    className="user-img"
                    alt="profile"
                    id='drop_zone'
                    onDrop={dropHandler}
                    onDragOver={dragOverHandler}
                    onDragLeave={dragLeaveHandler}
                  />
                ) : (
                  <img
                    className="user-img"
                    src="/company_pic.png"
                    alt="userLogo"
                    id='drop_zone'
                    onDrop={dropHandler}
                    onDragOver={dragOverHandler}
                    onDragLeave={dragLeaveHandler}
                  />
                )}
                <div className="edit-icn">
                  <label title="Image must be 'jpg' or 'jpeg' or 'png' and size must be less than 1 megabit" htmlFor="upload-photo">
                    <img src={editLogo} alt="editLogo" />
                  </label>
                  <input
                    id="upload-photo"
                    type="file"
                    className="upload-input"
                    placeholder="PERSONAL PHOTO"
                    onChange={onPhotoUpload}
                  />
                </div>
              </div>
            </div>

            <h4 className="mt-4">Company Details</h4>
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <label>Company Name</label>
                <input
                  className="job_input"
                  type="text"
                  name="companyName"
                  value={jobForm.companyName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <label>Company Headquarter <span>(Optional)</span></label>
                <input
                  className="job_input"
                  type="text"
                  name="companyHeadQuarter"
                  placeholder="Example: 'London, UK'"
                  value={jobForm.companyHeadQuarter}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <label>Description About Company <span>(optional)</span> </label>
                <textarea
                  placeholder="Short information about company business or may be a link to their website"
                  className="job_input"
                  type="text"
                  name="aboutCompany"
                  value={jobForm.aboutCompany}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row justify-content-between align-items-center actions-section">
              <div className="col-md-7 col-sm-12">
                <ButtonLink ClassName="learn-more-btn cancel">
                  <button onClick={cancelUpdate}>Cancel</button>
                </ButtonLink>
              </div>
              <div className="col-md-3 col-sm-12">
                <ButtonLink ClassName="learn-more-btn confirm float-right">
                  <button onClick={updateJobHandler}>Update</button>
                </ButtonLink>
              </div>
            </div>
          </div>
          {message.messageBody && <Message ClassName={`${message.msgType} fixed-bottom`}>{message.messageBody}</Message>}
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            toastOptions={{
              success: {
                duration: 3000
              },
              error: {
                duration: 3000
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}
