import React, { useEffect, useState } from 'react'
import call from "../../../images/jobs/call.png";
import email from "../../../images/jobs/email.png";
import Modal from '../../Modal/Modal';
import JobForm from './JobForm';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ListJobDetails } from '../../../actions/jobActions';
import history from '../../../history';

export default function JobScreen({ selectedJob }) {
  const dispatch = useDispatch();
  const { jobId } = useParams()

  const [modalOpen, setModalOpen] = useState(false)
  const [_selectedJob, setSelectedJob] = useState(selectedJob)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const jobDetails = useSelector((state) => state.jobDetails)
  const { jobdetails } = jobDetails

  useEffect(() => {
    if (jobId) {
      if (!jobdetails) {
        dispatch(ListJobDetails(jobId))
      } else {
        setSelectedJob(jobdetails)
      }
    } else {
      setSelectedJob(selectedJob)
    }
  }, [dispatch, jobId, jobdetails, selectedJob])


  const openModal = () => {
    if (!userInfo?._id) {
      history.push(`/signin?redirect=${window.location.pathname}`)
    } else {
      setModalOpen(true)
    }
  }
  const closeModal = () => {
    setModalOpen(false)
  }
  let publishDate = ""
  if (_selectedJob?.createdAt) {
    const yyyy = _selectedJob.createdAt.substring(0, 4)
    const mm = _selectedJob.createdAt.substring(5, 7)
    const dd = _selectedJob.createdAt.substring(8, 10)
    publishDate = dd + "/" + mm + "/" + yyyy
  }

  const companyPic = _selectedJob?.companyLogo?.img?.data ? Buffer.from(_selectedJob?.companyLogo?.img?.data).toString('base64') : ""

  return (
    <>
      {/* Start Details Jobs */}
      <div className="body-details d-flex position-relative">
        <div>
          <h5>{_selectedJob?.title}</h5>
          {/* <div className='row-tags grayscale-tags wrap'>
            <p>{_selectedJob?.jobType}</p>
            <p>{_selectedJob?.jobLocation}</p>
          </div> */}
          <p className="minor-info">{_selectedJob?.jobType}  |  {_selectedJob?.jobLocation}</p>
          <p className="minor-info">Published at: <span>{publishDate}</span> </p>
          {_selectedJob?.deadLine && (
            <p className="minor-info">Deadline: <span>{_selectedJob?.deadLine?.substring(0, 10).replace(/[^a-zA-Z0-9]/g, '/')}</span> </p>
          )}
        </div>
        {_selectedJob.status === "open" && (
          <div>
            <button className="apply-btn" onClick={openModal}>Apply Now</button>
          </div>
        )}
      </div>
      <div className="more-info mt-5 technopedia-offers">
        <h6 className="text-capitalize">description</h6>
        <p>{_selectedJob?.jobDescription}</p>
        <h6 className="text-capitalize">Requirements</h6>
        <div className='general-list-style'>
          <ul className="list-unstyled position-relative">
            {_selectedJob?.jobRequirements?.map((requirement, indx) => {
              return (
                <li key={indx}>{requirement}</li>
              )
            })}
          </ul>
        </div>

        <div className="btn-jobs-info d-flex position-relative mt-5">
          {companyPic ? (
            <img
              className="img-fluid me-4"
              src={`data:image/${companyPic.contentType};base64,${companyPic}`}
              alt="profile"
            />
          ) : (
            <img className="img-fluid me-4" src="/company_pic.png" alt="company logo" />
          )}
          <div className="text-start">
            <h5>{_selectedJob?.companyName}</h5>
            <span>{_selectedJob?.companyHeadQuarter}</span>
          </div>
        </div>
        <p className="mt-5 mb-4">{_selectedJob?.aboutCompany}</p>
        {/* <h6 className="mb-3">Contacts</h6> */}
        {/* <div className="contacts-info">
          <span className="text-muted right-margined">
            <img src={call} alt="call" />
            +358414970371
          </span>
          <span className="text-muted">
            <img src={email} alt="call" />
            info@rekgpt.com
          </span>
        </div> */}
      </div>
      {modalOpen && (
        <Modal closeModal={closeModal}>
          <JobForm closeModal={closeModal} title={_selectedJob?.title} userInfo={userInfo} jobID={jobId} />
        </Modal>
      )}
      {/* End Details Jobs */}
    </>
  )
}

JobScreen.defaultProps = {
  selectedJob: {}
}
