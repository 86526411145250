import React from 'react'
import underProcessing from '../../images/general-purpose/underProcessing.png'
export default function ApplicationProcess({ sectionBody }) {
  return (
    <div className="application-process">
      <img src={underProcessing} alt="under-processing" />
      <p>{sectionBody}</p>
    </div>
  )
}
