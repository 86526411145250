import React, { useState } from 'react'
import './MultiSelector.css'
export default function MultiSelector({ label, onSelection, arrangementClass, labelStyle, options }) {
  const [newOptions, setNewOptions] = useState(options)

  const onSelect = (value, indx) => {
    setNewOptions((preSelections) => {
      const newSelection = preSelections.map((selectedCell, cellNum) => {
        if (cellNum === indx) {
          return { ...selectedCell, selected: 'active' }
        } else {
          return { ...selectedCell, selected: '' }
        }
      })
      return newSelection
    })
    onSelection(value, indx)
  }
  return (
    <div className="multi-selector">
      <h6 className={labelStyle}> {label} </h6>
      <div className={`options-container ${arrangementClass}`}>
        {newOptions.map((option, indx) => {
          return (
            <div key={indx} className={`selected-val ${option.selected}`} onClick={() => onSelect(option.value, indx)}>
              <p className="option-label">{option.label}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

MultiSelector.defaultProps = {
  arrangementClass: "horizontal",
  labelStyle: ""
}
