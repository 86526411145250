import React, { useState, useEffect, useCallback } from 'react'
import './Cart.css'
import axios from 'axios'
import history from '../../history'
// import { PayPalButton } from 'react-paypal-button-v2'
import Paypal from '../../components/PayPal/PayPal'
import { useDispatch, useSelector } from 'react-redux'

import { deleteOrder, payOrder } from '../../actions/orderActions'
import { ORDER_CREATE_RESET, ORDER_DETAILS_RESET, ORDER_PAY_RESET } from '../../constants/orderConstants'
import { CART_RESET } from '../../constants/cartConstants'
import { updateUserCourses } from '../../actions/userActions'

import ButtonLink from '../../components/ButtonLink/ButtonLink'
import StartBaner from '../../components/StartBaner/StartBaner'
import Message from '../../components/Message/Message'
import Modal from '../../screen/Modal/Modal'
import ElectronicCard from '../../components/ElectronicCardForm/ElectronicCard'
import welcomeImg from '../../images/welcome.webp'

const setPageTitle = (title) => {
  const prevTitle = document.title;
  document.title = title;
  return () => document.title = prevTitle;
}
export default function Order() {
  setPageTitle('My Order')
  const [sdkReady, setSdkReady] = useState(false)
  const [itemsInPayPalScreen, setItemsInPayPalScreen] = useState("Courses")

  const dispatch = useDispatch()

  const orderDetails = useSelector((state) => state.orderDetails)
  const { orderdetail } = orderDetails

  // this part to get images of the order
  const cartItems = localStorage.getItem('cartItems')
    ? JSON.parse(localStorage.getItem('cartItems'))
    : []

  // order paymnet
  const orderPay = useSelector((state) => state.orderPay)
  const { payLoading, paySuccess } = orderPay

  const addPayPalScript = async () => {
    const payPalScript = document.createElement('script')
    // const head = document.getElementsByTagName('head')[0]
    const { data: clientId } = await axios.get('/api/config/paypal')
    payPalScript.type = 'text/javascript'
    payPalScript.id = "checkout-button";
    payPalScript.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`
    // example of setting an attribute
    // payPalScript.setAttribute('data-url', 'https://www.paypal.com/')
    payPalScript.defer = true
    // head.appendChild(payPalScript)
    payPalScript.onload = () => {
      setSdkReady(true)
    }
    document.body.appendChild(payPalScript)
  }

  // const creditCardScript = async () => {
  //   const head = document.getElementsByTagName('head')[0]
  //   const cardScript = document.createElement('script')
  //   const { data: AppId } = await axios.get('/api/config/creditCard')
  //   cardScript.src = `https://api.vapulus.com:1338/app/session/script?appId=${AppId}`
  //   cardScript.defer = true
  //   head.appendChild(cardScript)
  //   cardScript.onload = () => {
  //     setSdkReady(true)
  //     // console.log('script loaded')
  //   }
  //   // document.body.appendChild(cardScript)
  // }

  // to handle credit or debit card payment (vapulus payments)
  // const submitPayCardInfo = (cardInfo) => {
  //   window.PaymentSession.updateSessionFromForm()
  //   console.log(cardInfo)
  // }

  useEffect(() => {
    if (!sdkReady) {
      if (orderdetail.paymentMethod === 'card') {
        console.log('card payment')
        // creditCardScript()
      } else if (orderdetail.paymentMethod === 'paypal') {
        addPayPalScript()
      }
    }
    // removing on unmount if needed
    //  payPalScript.parentNode.removeChild( payPalScript );
  }, [sdkReady, orderdetail.paymentMethod])

  const includePayInfo = useCallback(
    (_items) => {
      const _orderItems = _items.map(item => {
        return item.name
      })
      // console.log("_orderItems: ", _orderItems)
      setItemsInPayPalScreen(_orderItems.join(" | "))
    },
    [],
  );
  // 

  useEffect(() => {
    if (orderdetail.orderItems) {
      includePayInfo(orderdetail.orderItems)
    }
    if (orderdetail.isPaid || paySuccess) {
      // activate the action to update user profile by adding purchased courses to his belt
      const enrolledCourses = orderdetail.orderItems.map((item) => {
        const certifiedTrack = {EnrollStatus: item.activeCertifiedTrack, completionStatus: false}
        return { courseId: item.course, courseName: item.name, certifiedTrack }
      })
      dispatch(updateUserCourses(enrolledCourses))
      setTimeout(() => {
        dispatch({ type: ORDER_PAY_RESET })
        dispatch({ type: ORDER_CREATE_RESET })
        dispatch({ type: CART_RESET })
        localStorage.removeItem('cartItems')
        // after reset it should push the student to his courses where he can start courses
        history.push('/mycourses')
      }, 8000);
    }
  }, [dispatch, paySuccess, orderdetail, includePayInfo])

  const successPaymentHandler = (paymentResult) => {
    // console.log('On success data: ', paymentResult)
    dispatch(payOrder(orderdetail.orderItems, orderdetail._id, paymentResult, orderdetail.grantId))
  }


  // to handle full grant of the course
  const grantEnrollmenttHandler = (event) => {
    event.preventDefault()
    dispatch(payOrder(orderdetail.orderItems, orderdetail._id, {}, orderdetail.grantId))
  }

  const cancelOrderHandler = () => {
    dispatch({ type: ORDER_CREATE_RESET })
    dispatch({ type: ORDER_DETAILS_RESET })
    dispatch(deleteOrder(orderdetail._id))
    history.push('/cart')
  }

  return (
    <div>
      <div>
        {/* Start | Banner Section */}
        <StartBaner imgLink={welcomeImg}>
          <h2>Keep Your Competence</h2>
          <p>Add new skills to your belt now</p>
        </StartBaner>
        <section className="cart-section">
          <div className='container'>
            <div className="row justify-content-center">
              {/* purchased course details */}
              <div className="col-lg-6 col-md-12">
                <div className="text-left">
                  <h2 className="common-heading d-inline-block">Your Details</h2>
                </div>
                <div className="userdetails-order">
                  <div className="userdetails">Name:<span>{orderdetail.user.name}</span></div>
                  <div className="userdetails">Email:<span>{orderdetail.user.email}</span></div>
                  <div className="userdetails">Phone:<span>{orderdetail.user.phone}</span></div>
                  <div className="userdetails">Payment Method:
                    <span>{orderdetail.paymentMethod === 'paypal' ? 'PayPal' : 'Debit/Credit Card'}</span>
                  </div>
                </div>

                <div className="text-left">
                  <h2 className="common-heading d-inline-block">Ordered Courses</h2>
                </div>
                <div className="course-stripes-container">
                  {cartItems.length === 0 ? (
                    <div> no items in the cart </div>
                  ) : (
                    <div>
                      {cartItems.map((item, indx) => {
                        const courseImage = item?.courseImage?.img?.data ? Buffer.from(item.courseImage.img.data).toString('base64') : ""
                        return (
                          <div className="order-stripe course-stripe" key={indx}>
                            <div className="image-wrapper">
                              <img src={`data:image/${courseImage.contentType};base64,${courseImage}`} alt={item.name} />
                            </div>
                            <div className="course-details-stripe">
                              <h6>{item.name}</h6>
                            </div>
                            <div className="order-price certification">
                              <p> {item.price}</p>
                            </div>
                          </div>
                        )
                      }
                      )}
                    </div>
                  )}
                </div>
                {paySuccess && (
                  <Modal>
                    <div className="order-congratulate-popup">
                      <h5>Congratulations! You are enrolled!</h5>
                      <p>this is one step closer in the right direction...</p>
                      <p> you will be redirected to your courses page within seconds....</p>
                    </div>
                  </Modal>
                )}
              </div>
              {/* Order Details */}
              <div className="col-lg-4 col-md-12">
                <div className="text-left">
                  <h2 className="common-heading d-inline-block"><span>Order </span> Details <span>& </span>Payment</h2>
                  {/* <p>subtotal</p> */}
                </div>
                <div className="final-order-container">
                  <div className="cart-summary">
                    {orderdetail?.grantId && (
                      <div className="text-uppercase summary-cell">
                        <Message ClassName='message-success'>Scholarship applied to the order</Message>
                      </div>
                    )}
                    <div className="text-uppercase summary-cell">Original Cost
                      <span>
                        ${orderdetail?.originalPrice?.toFixed(2)}
                      </span>
                    </div>
                    {/* <div className="text-uppercase summary-cell">Discount<span>%50</span></div> */}
                    <div className="text-uppercase summary-total">Final cost
                      <span>
                        ${orderdetail?.totalPrice?.toFixed(2)}
                      </span>
                    </div>
                  </div>

                  {/* Here there will be a switch between payment or direct enrollment based on scholarship state */}
                  <div className="payment-order">
                    {payLoading && <p>Loading......</p>}
                    {(parseInt(orderdetail.totalPrice) === 0) ? (
                      <ButtonLink ClassName="learn-more-btn flex">
                        <button onClick={grantEnrollmenttHandler}>Place Order <i className="fas fa-arrow-circle-right"></i></button>
                      </ButtonLink>
                    ) : (sdkReady && !paySuccess) ? (
                      orderdetail.paymentMethod === 'paypal' ? (
                        <Paypal
                          paymentDetails={itemsInPayPalScreen}
                          amountFees={orderdetail.totalPrice}
                          onSuccess={successPaymentHandler}
                        />
                      ) : (
                        <ElectronicCard />
                      )
                    ) : null}
                  </div>
                </div>
                <ButtonLink ClassName="cancel-order">
                  <button onClick={cancelOrderHandler}><i className="fas fa-arrow-circle-right"></i>Cancel Order</button>
                </ButtonLink>
              </div>
            </div>
          </div>
        </section>

      </div>

    </div>
  )
}
