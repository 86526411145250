import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from 'react-phone-number-input'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import axios from 'axios'
import { updateUserProfile } from '../../../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../../../constants/userConstants'
import history from '../../../history'
import ButtonLink from '../../../components/ButtonLink/ButtonLink'

// import userLogo from '../../../images/userLogo.svg'
import editLogo from '../../../images/general-purpose/editLogo.png'
import Message from '../../../components/Message/Message'


export default function MainProfile() {
  const dispatch = useDispatch()
  const [imgClsName, setImgClsName] = useState("photo-wrapper")
  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })
  const [profileInfo, setProfileInfo] = useState({
    name: "",
    linkedinURL: "",
    email: "",
    address: "",
  })
  const [phoneNumber, setphoneNumber] = useState('')
  const [countryOfResidence, setCountryOfResidence] = useState('')
  const options = useMemo(() => countryList().getData(), [])

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const {
    error: errorUpdate,
    success: successUpdate,
  } = userUpdateProfile

  const userImage = userInfo?.userImage?.img?.data ? Buffer.from(userInfo?.userImage?.img?.data).toString('base64') : ""

  useEffect(() => {
    if (!userInfo?._id) {
      history.push('/signin')
    } else {
      if (errorUpdate) {
        setMessage({
          msgType: "error",
          messageBody: errorUpdate
        })
        return
      }
      if (successUpdate) {
        setMessage({
          msgType: "success",
          messageBody: "Information Updated Successfully"
        })
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
      } else {
        setProfileInfo({
          name: userInfo.name,
          linkedinURL: userInfo.linkedinURL ? userInfo.linkedinURL : "",
          email: userInfo.email ? userInfo.email : "",
          address: userInfo.address ? userInfo.address : "",
        })
        setphoneNumber(userInfo.phone ? userInfo.phone : "")
        setCountryOfResidence((userInfo.countryOfResidence ? userInfo.countryOfResidence : ""))
      }
    }
  }, [userInfo, dispatch, errorUpdate, successUpdate])

  const handleChange = (event) => {
    const { name, value } = event.target
    setProfileInfo((prevEntries) => {
      return {
        ...prevEntries,
        [name]: value
      }
    })
  }
  const handleCountryChange = (info) => {
    setCountryOfResidence(info)
  }

  const onSaving = (e) => {
    e.preventDefault()
    const updateForm = { ...profileInfo, phone: phoneNumber, countryOfResidence }
    dispatch(updateUserProfile(updateForm))
  }

  const dragOverHandler = (ev) => {
    setImgClsName(`photo-wrapper dragover`)
    ev.preventDefault()
  }
  const dragLeaveHandler = (ev) => {
    setImgClsName(`photo-wrapper`)
    ev.preventDefault()
  }
  // Upload Image to database***********************
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  const uploadPhotoToDB = async (_file) => {
    const formData = new FormData()
    formData.append('image', _file)
    try {
      const { data } = await axios.put(`/api/upload/userimage/${userInfo._id}`, formData, config)
      setMessage({
        msgType: "success",
        messageBody: "Image uploaded Successfully, save to see in your profile"
      })
    } catch (error) {
      setMessage({
        msgType: "error",
        messageBody: "Image must be 'jpg' or 'jpeg' or 'png' and size must be less than 1 megabit"
      })
      // console.error(error)
    }
    setImgClsName(`photo-wrapper`)
  }
  const onPhotoUpload = async (e) => {
    await uploadPhotoToDB(e.target.files[0])
  }
  const dropHandler = async (ev) => {
    console.log("There is file dropped here")
    ev.preventDefault()
    await uploadPhotoToDB(ev.dataTransfer.files[0])
  }
  // Upload Image to database***************************

  return (
    <div className="main-profile">
      <div className="personal-photo">
        <div className={imgClsName}>
          {userImage ? (
            <img
              src={`data:image/${userImage.contentType};base64,${userImage}`}
              className="user-img"
              alt="profile"
              id='drop_zone'
              onDrop={dropHandler}
              onDragOver={dragOverHandler}
              onDragLeave={dragLeaveHandler}
            />
          ) : (
            <img
              className="user-img"
              src="/profileLogo_new.png"
              alt="userLogo"
              id='drop_zone'
              onDrop={dropHandler}
              onDragOver={dragOverHandler}
              onDragLeave={dragLeaveHandler}
            />
          )}
          <div className="edit-icn">
            <label title="Image must be 'jpg' or 'jpeg' or 'png' and size must be less than 1 megabit" htmlFor="upload-photo">
              <img src={editLogo} alt="editLogo" />
            </label>
            <input
              id="upload-photo"
              type="file"
              className="upload-input"
              placeholder="PERSONAL PHOTO"
              onChange={onPhotoUpload}
            />
          </div>
        </div>
      </div>
      <div className="inputs-wrapper">
        <div className="half-width-cell">
          <label> User Name </label>
          <input
            className="half-input profile-input"
            type="text"
            name="name"
            value={profileInfo.name}
            onChange={handleChange}
          />
          <label> Phone Number </label>
          <PhoneInput
            international
            defaultCountry="EG"
            className="half-input profile-input"
            id="exampleFormControlTextarea1"
            value={phoneNumber}
            onChange={setphoneNumber}
          />
          <label> Current Address </label>
          <input
            className="half-input profile-input"
            type="text"
            name="address"
            value={profileInfo.address}
            onChange={handleChange}
          />
          <ButtonLink ClassName="socialmedia-button full-width">
            <button onClick={() => history.push('/')}>
              Back To Home
            </button>
          </ButtonLink>
        </div>
        <div className="half-width-cell">
          <label> Linkedin Profile </label>
          <input
            className="half-input profile-input"
            type="text"
            name="linkedinURL"
            value={profileInfo.linkedinURL}
            onChange={handleChange}
          />
          <label> Email Address </label>
          <input
            className="half-input profile-input"
            type="email"
            name="email"
            value={profileInfo.email}
            onChange={handleChange}
          />
          <label> Country of Residence </label>
          <Select
            className="half-input profile-input no-padding"
            options={options}
            name="countryOfResidence"
            value={countryOfResidence}
            onChange={handleCountryChange}
          />
          <ButtonLink ClassName="socialmedia-button full-width">
            <button onClick={onSaving}>
              Save Changes
            </button>
          </ButtonLink>
        </div>


      </div>
      {message.messageBody && <Message ClassName={message.msgType === "success" ? "message-success" : "message-error"}>{message.messageBody}</Message>}
    </div>
  )
}
