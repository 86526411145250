const ArabicTranslation = {
  Home: "الرئيسيه",
  LearningPaths: "مسارات التعلم",
  Jobs: "وظائف",
  Pricing: "الأسعار",
  Plans: "خطط الدفع",
  AboutUs: 'من نحن',
  ArabsTechnopedia: 'منصة تيكنوبيديا',
  Entrepreneurs: "رواد أعمال",
  EntrepreneursOasis: "واحة رواد الأعمال",
  CoFounder: "مؤسس مشارك",
  Community: "مجتمع الرواد",
  Freelancers: "المستقليين",
  DigitalAccountant: "المحاسب الرقمي",
  JoinUs: "انضم الينا",
  Profile: "ملفي الشخصي",
  MyEnrolledCourses: "كورساتي",
  MyLearnPath: "مساري التعليمي",
  Cart: "صفحة التسجيل",
  Logout: "خروج",
  SignIn: "دخول",
  Register: "اشتراك",
  Instructor: "مدرس",
  Invest: "استثمر",
  Educate: "درسّ",
  English: "انجليزي",
  Arabic: "عربي",
  WatchEnglish: "شاهد الانجليزيه",
  WatchArabic: "شاهد بالعربيه",
  LearnMore: "اعرف أكتر",
  Announce: "أعلن عن الوظائف",
  Learning: "التعلم",
  ReceiveApplication: "استلام الطلبات",
  StartUp: "وظيفه او مشروع خاص",
  ContactUs: "اتصل بنا",
  Optionally: "أختياريا",

  // Main Page
  DemoBroadcasting: "بث تجريبي",
  GetA: "الحل الشامل",
  ClearPath: "سوفتوير معزز بالذكاء الاصطناعي",
  TowardGoals: "بما يناسب احتياجات الشركات الناشئه",
  HelpCompanies: "ريكجبت هو النظام الرقمي الخاص بشركتك لتساعدك على ادارة و أتمتة عمليات التوظيف من الألف الى الياء بشكل ذكي و بسيط",
  SignupOrUpgrade: "أختر النظام الذي يناسب أحتياجاتك",
  WeHelpYouthEspecially: "نساعد الشباب على الحصول على الوظيفه المناسبه من خلال برامج التوجيه و التعلم عن بعد أو بناء الشبكات الاجتماعيه لمساعدتهم على بدأ العمل الخاص",
  OnePlateformFuture: "منصة واحدة لتخطيط لمستقبلك",
  Howwework: "كيفية استعمال المنصه",
  ListOpenVacancies: "استخدم حسابك على منصتنا لإنشاء وظائف شاغرة في شركتك. بمجرد كتابة تفاصيل الوظيفة الشاغرة ونشرها ، سيتم إدراج الوظائف الشاغرة في قسم الوظائف لدينا. في ملفك الشخصي ، ستجد رابطًا مخصصًا لشركتك ويشير إلى الوظائف الشاغرة لديك. الأن يمكنك مشاركة هذا الرابط على موقع الويب الخاص بك لبدء تلقي طلبات التوظيف",
  GainNeededExperience: "اكتسب الخبرة المطلوبة من خلال تعلم التقنيات المطلوبه من قبل الشركات و التي يقوم بتدريسها خبراء في مجالات عملهم",
  ReceivingJobApplications: "سوف تبدأ في تلقي طلبات العمل. الآن في ملفك الشخصي ، يمكنك رؤية طلبات العمل لكل وظيفه اعلنت عنها . بلوحة التحكم الخاصه بك ، لديك سيطرة كاملة لاختيار المرشحين المناسبين والبدء في جدولة المقابلات. ستساعدك تقنية الذكاء الاصطناعي الخاصة بنا في العثور على طلبات العمل الأكثر ملائمه للوظيفه الشاغره لديك",
  StartUpOrJobApply: "بعد أن تكون جاهزًا ، تقدم بطلب للحصول على وظيفة من الوظائف المعروضه على منصتنا أو استخدم مساحة رواد الأعمال المتاحه على ريكجبت بشكل أفضل لبناء شراكات وبدء عملك الخاص كمؤسس أو شريك مؤسس",
  TrustedPartner: "وفّر وقتك و مصروفاتك من خلال التوظيف بشكل ذكي",
  OurMissionIsToQualify: "ريكجبت هو أكثر من مجرد تطبيق توظيف يساعدك على ادارة عملية التوظيف بكفائه. يمكنك استخدام التطبيق مجانًا. هدفنا النهائي هو ان نتأكد من نجاحك بنسبة 100%. من خلال أشتراكك في الخدمه المدفوعه تستطيع تحقيق هدفك في وقت قياسي و تقليل التكاليف و ذلك من خلال استخدام المزايا الذكيه للتطبيق كالذكاء الصناعي و الاتصال الالي بمنصات الاعلان عن الوظائف بدون تكاليف اضافيه و الكثير من المزايا الأخرى",
  FillGap: "تأهيل لسوق العمل",
  HelpAvoidWasting: "هدفنا النهائي هو ضمان نجاح الشركات الشريكة لنا في إيجاد وفرة من المرشحين المؤهلين. ريكجبت لديها رؤية للمشاركه في بناء مجتمعات صحية ومزدهرة ، ولهذا انطلاقاً من واجبنا المجتمعي فاننا نتعلم من متطلبات الشركات الشريكة لنا ونصمم برامج تعليمية يقدمها خبراء لتأهيل الأجيال القادمة لاحتياجات سوق العمل",
  StartWithTechnopedia: "ابدأ الآن مع تكنوبيديا",
  WeAreDifferentBecause: "نحن مختلفون لأننا نعمل بطريقة مختلفة. يقوم خبراؤنا بالتواصل مع الشركات أولا و تحديد المهارات التي يحتاجونها و يوجد بها نقص في سوق العمل. هدفنا هو التأهيل و التدريب على التقنيات المطلوبه بشده. ابدأ الآن للحصول على جودة عالية من التعليم والتدريب. أثبت مهاراتك من خلال الحصول على شهادة معنا وأثبت كفائتك من خلال التطبيق العملي",
  IfYourDreamIsEntrepreneur: "إذا كان حلمك هو أنك تصبح رائد أعمال ، فنحن نتفهّم تمامًا احتياجاتك والعقبات التي تعوقك عن تحقيق حلمك. معانا سوف تجد كل الأدوات والإرشادات لتحويل حلمك إلى حقيقة",
  DistinguishYourself: "ميّز نفسك",
  SpeedYourLearning: "سرّع عملية تأهيلك",
  ClearPathTowardYourGoal: "احصل على مسار واضح و فعال من خلال أن يكون لديك مرشد و موجهه يضعك على الطريق الصحيح و يكون مرجع ليك",
  PreciseConciseTraining: "تدريب دقيق وموجز لتحقيق هدفك. لا داعي لإضاعة المزيد من الوقت من خلال اتخاذ مسارات غير واضحة",
  TechnologyAdvancingRapidely: "التكنولوجيا تتقدم بسرعة. من خلال تعلم أحدث التقنيات ، فأنت على الطريق الصحيح للحصول على الوظيفة التي تحلم بها",

  // Benefits
  AseeuBenefits: "لماذا ريكجبت سوفتوير هو اختيارك الأمثل",
  Benefit1_title: "تسويق ممتاز لعلامتك التجاريه",
  Benefit1_body: "جودة عملية التوظيف الخاصه بك هي أول انطباع تتركه لدى المتقدمين للعمل. الانطباع الجيد سوف يكون له تأثير كبير على سمعة شركتك و يجعل منها كيان جذاب لبناء الشراكات. ادارة عملية التوظيف الخاصه بك بشكل احترافي سوف تساعدك على توظيف افضل الكوادر",
  Benefit2_title: "وفّر وقتك و اخّفض تكاليفك",
  Benefit2_body: "يسمح لك استخدام برنامج التوظيف بأتمتة وإدارة عملية التوظيف بكفاءة. إنه يلغي عملك الورقي ويوفر وقتك وأموالك بشكل كبير للغايه. من خلال برنامج ريكجبت, تستطيع ادارة كل عمليات التوظيف الخاصه بك من مكان واحد",
  Benefit3_title: "نظام ذكي لانتاجيه أعلى",
  Benefit3_body: "نظام ريكجبت الذكي سوف يساعدك في العثور على الأشخاص المناسبيين الذين تتناسب مؤهلاتهم  مع متطلبات الوظيفه المتاحه لديك. هذه الميزه سوف تساعدك كثيراً على توفير الوقت و الجهد الذي كنت تحتاجه لدراسة طلبات العمل كما انها سوف تزيد من كفائة اختياراتك",
  Benefit4_title: "مكان واحد لتصل الى الجميع",
  Benefit4_body: "لكي تظهر الوظائف المتاحه لديك أمام الباحثيين عن عمل, فإنك تبدأ في الإعلان عنها على منصات التوظيف المختلفه. هذه عملية تستنزف الكثير من الوقت و المال. ماذا لو كان بإمكانك الإعلان عن وظيفتك على هذه المنصات تلقائيًا بمجرد كتابتها على لوحة التحكم الخاصه بك على ريكجبت",
  Benefit5_title: "نظام تشغيل يدعم اللغه العربيه",
  Benefit5_body: "ريكجبت كسوفتوير للتوظيف تم بناءه بشكل أساسي لخدمة الشركات ووكالات التوظيف في منطقة الشرق الأوسط وشمال إفريقيا. مما يجعلها الخيار الأمثل لك إذا كانت الوظائف الشاغرة لديك لا تتطلب إتقان لغة أجنبية و يفضل ادارتها و تنظيمها باللغه العربيه",


  // Technopedia
  WhyTechnopediaExperience: "لماذا تقدم تكنوبيديا تجربة فريدة من نوعها",
  ContinuousFollowUp: "المتابعة المستمرة لضمان تحقيق أهداف التدريب",
  LearningInNativeLanguage: "تعلم بلغتك الأم للتأكد من حصولك على أقصى استفادة من هذه التجربة",
  BuildRealProject: "قم ببناء مشروع حقيقي لإثبات كفاءتك",
  ContinuousImprove: "مدرّسونا يقومون بتحديث الكورسات باستمرار لضمان تفوقك و تميزك في المجال",
  LearnExperienceSupported: "يتم دعم تجربة التعلم الخاصة بك من خلال اتباع مسارات كاملة محددة جيدًا والتي صممها متخصصون",
  MentorshipToEnsure: "الإرشاد و التوجيه للتأكد من أنك على الطريق الصحيح",
  ConnectionToLabor: "الاتصال بسوق العمل ، بحيث انك تعمل في الشركه و المجال الذي تريده بمجرد الانتهاء من دراستك",
  OngoingCourses: "الكورسات الحاليه",
  OurInstructors: "مدرّسونا",
  ConsiderAScholarship: "أحصل على منحه دراسيه",
  MakeAnInvestment: "قُم بالاستثمار معنا",
  EducateWithUs: "درّس معنا",
  ASEEUIsFoundedTo: "تم تأسيس ريكجبت بالأساس لمساعدة الشباب للحصول على فرصّ جيده لتحقيق أحلامهم, اذا كانت المصاريف هي الشيء الذي يعوقك يمكنك التعلم مجانا",
  ThereAreDevotedInstructors: "هناك مدربون يكرسون وقتهم الكامل لتقديم دورات تدريبية عالية الجودة. هذا هو سبب وجود بعض الدورات المدفوعة. إذا كنت تشاركنا نفس الرؤية ، يمكنك الاستثمار معنا لتعظيم الفائده",
  ParticipateWithUsEducation: "شارك معنا في مساعدة الآلاف من الشباب المجتهديين في تحقيق أحلامهم. انتقل إلى مستوى آخر من الخبرة في مجالك من خلال تعليمه للآخرين وإثبات مهارتك امام العديد من الشركات",
  WeDoNotSellIllusions: "نحن لا نبيع الأوهام ، بل نعطي قيمة حقيقية",
  YouAreOnlyCertifiedIf: "يتم اعتمادك معنا فقط إذا أكملت الدورة بنجاح واجتازت الاختبار بنهاية الدورة. عندما يتم اعتمادك معنا ، سوف يتم تسجيل ملفك الشخصي ضمن مجموعة الموهوبيين على منصتنا وفي هذه الحاله سوف يكون متاحًا لأصحاب الأعمال الذين يبحثون عن الموهوبيين لدينا التواصل معك",
  SomeOfCourcesCan: "يمكن التسجيل في بعض دوراتنا مجانًا. إذا قمت بالتسجيل في مسار غير معتمد(لا يمنح شهاده) ، فلن تحتاج إلى اجتياز أي اختبارات أو تقديم أي مشاريع",

  // Joinus
  ReadyToCreateNew: " هل جاهز لصناعة واقع جديد معنا؟",
  TeachWithUs: "درّس معنا",
  InvestWithUs: "استثمر معنا",
  BeAMentor: "كُن مرشدا",
  BenefitsOfTeaching: "مزايا التدريس على تكنوبيديا",
  BringChange: "اصنع فارقاً",
  BuildUniqueNetwork: "بناء شبكة مهنيه فريدة ",
  EarnSomeMoney: "زود دخلك",
  MainCoreOfOrganization: "الجوهر الأساسي لوجود ريكجبت هو إحداث تأثير إيجابي في حياة الآخرين. من خلال تعليم الأشخاص الآخرين بلغتهم الأم ومن خلال برنامج المنح الدراسية لدينا ، ستساعد الآلاف على تحقيق أحلامهم",
  JoinExpertsClub: "الانضمام إلى نادي الخبراء في ريكجبت تجربة لا تقدر بثمن. سوف يمكنك هذا من التعاون مع أشخاص مميزين في مجالاتهم الخاصة وسوف تحصل على دعم كامل من شبكة الخبراء لدينا إذا قررت أن تبدأ مشروعك التجاري يومًا ما",
  WhileYouImpactWorld: "أثناء عملك لإحداث تأثير حقيقي في هذا العالم ، سنحرص على تحقيقك بعض الأرباح من عملك لأننا نعلم أن عملك كمدرب يتطلب الكثير من الوقت والجهد",
  MakeRealImpact: "إصنع فارق و تأثير حقيقي",
  JumbToLevel: "انتقل إلى مستوى آخر من الخبرة في مجالك من خلال تعليمه لأشخاص آخرين وكسب بعض المال",
  NoteSure: "لست متأكدا؟ شاهد هذا الفيديو ",
  ToSeeHowMuch: "لمعرفة مزايا الانضمام الى مجتمعنا على تكنوبيديا و كم هو سهل صناعة المحتوى على تكنوبيديا",
  AvailableTracks: "المسارات المتاحة",
  ThereAreTwoTracks: "يوجد مساران متاحان للتدريس على تكنوبيديا. حدد المسار المناسب بناءً على اهتماماتك ومستوى خبرتك في التدريس عبر الإنترنت",
  StarterInstructor: "مدرس مبتدئ",
  ExperiencedInstructor: "مدرس خبير",
  StillConfused: "مازلت محتار",
  WhichTrackTake: "ما المسار الذي يجب أن أسجل فيه؟",
  IfProfessionalCreator: "إذا كان لديك خبره كبيره في صناعة المحتوى الأونلاين ، فعلى سبيل المثال ، لديك مهارات كبيرة في تسجيل وتحرير الفيديوهات و الجرافيكس أو الموشن جرافيكس وما إلى ذلك. إذا كنت ترغب في تدريس دورة تتعلق بأحدى هذه المهارات, فيفضل أن تختار مسار المدرب الخبير",
  IfNoSkillsWithSkills: "إذا لم تكن لديك مهارات باحدى هذه المجالات المذكورة سابقًا ، فيفضل أن تقوم بالتسجيل كمدرب مبتديء و تستطيع لاحقا ان تغير مسار تسجيلك الى مدرب محترف في أي وقت تريد لو شعرت أنك أصبحت تمتلك المهارات لعمل محتوى احترافي وحدك",
  IfDoubts: "إذا مايزال لديك أسئله ، فلا تتردد في الأتصال بنا و التحدث الينا",
  BenefitsofInvesting: "فوائد الاستثمار معنا",
  WhatModel: "ما هو نموذج عملنا",
  WhyConsiderInvest: "متى و لماذا تفكر في الإستثمار معنا؟",
  WhatManageStyle: "ما هو أسلوبنا في الإداره؟",
  WhatFinanceTransparency: "ماذا عن الشفافية المالية؟",
  ClosedLoopModel: " نتبع نموذج عمل قائم على الاستدامه لتطوير خدماتنا. هذا يعني أن الجزء الأكبر من أرباحنا يتم ضخه مرة أخرى لتحسين منصتنا الرقمية والخدمات التي يمكننا تقديمها",
  InvestorFirstMotive: "كمستثمر ، يجب أن يكون دافعك الأول هو دعم مهمة هذه المبادرة بدلاً من تعظيم مكاسبك المالية ؛ ومع ذلك ، سوف تكون قادرًا على تأمين بعض الأرباح. 30٪ فقط من الأرباح متاحة للمساهمين ، ويتم ضخ الباقي مرة أخرى في خدماتنا",
  DecisionMakingInASEEU: "يتم اتخاذ القرار في الشركة من خلال التصويت عن طريق المصادقة على أصوات المساهمين الذين يمتلكون الحصة الأكبر في حقوق ملكية الشركة. يتم تنفيذ كل شيء وإدارته بشكل آمن من خلال المنصة الرقمية",
  DigitalizingOurServices: "نحن نقوم برقمنة خدماتنا. هذا يعني أنه حتى إدارة شؤون المستثمرين تتم من خلال الوسائل الرقمية. بصفتك مستثمرًا ، سوف تقوم بمراقبة ومتابعة جميع أنشطة الشركة والتزاماتها وحصتك الماليه في رأس المال و حصة جميع الأعضاء في الشركة",
  ShareUsTheVision: "شاركنا الرؤية لمساعدة ملايين الشباب",
  IfYouShareUsAndWilling: "إذا كنت تشاركنا الرؤيه و الأهداف وترغب في المساهمة في عمل ذو تأثير كبير ، انضم إلينا",
  BenefitsOfMentorship: "مزايا التوجيه و الارشاد معنا",
  ShortTime: "قليل من الوقت و مجانا",
  NotTimeConsuming: "الإشراف من خلال ريكجبت لن يحتاج منك الكثير من الوقت",
  JustAboutOnePath: "يتطلب فقط كتابة و وصف مسار تعليمي واحد و مشاركة الحزمه التقنيه الخاصه بك",
  PostJobsIfHave: "أستخدم المنصه مجانا للإعلان عن الوظائف الشاغره في مؤسستك",
  MentorOnAseeuEnable: "كونك مرشد وموجهه معنا سوف يمكنك من بناء شبكة فريدة من نوعها لأنك سوف تكون جزء من مجتمع مكون من خبراء و متخصصين في مجالات مختلفه",
  FindYourTalent: "دخول مجاني لقاعدة البيانات التي تضم كل الموهوبيين المسجليين على منصتنا",
  EarnPoints: "نقاط مكتسبه",
  BeingActiveMentor: ":لو موجهه نشط و متفاعل ",
  FreeStartUpMembership: "يمكنك الحصول على عضويه مميزه لخدماتنا الموجهه لرواد الأعمال",
  PremiumSubscription: "يمكنك الحصول على عضويه مميزه تتيح لك الوصول المجاني لكورساتنا على المنصه",
  MentorRoles: "مهام و دور الموجهه",
  AtLeastOnePath: "كتابة مسار تعليمي واحد على الأقل ليكون بمثابة دليل إرشادي للشباب",
  AddRecommendation: "التوصيه على الشباب الذين أشرفت عليهم بنجاح و أن تقوم بدورك كمرجع يستطيع أصحاب الأعمال الرجوع اليه ان ارادوا ان يتأكدوا من أهلية المتدرب الذي أشرفت على تعلمه",
  PostJobsThatOpen: "أعلن عن الوظائف المفتوحة في مكان عملك أو أي أماكن عمل أخرى بحيث يمكن أخذ توصيتك في الأعتبار",
  ModifyWrittenPath: "قم بتعديل مسار التعلم المكتوب الخاص بك بناءً على ملاحظات المتدربين إذا لزم الأمر",
  ReviseMentees: "راجع السير الذاتية للمتدربين ممن أشرفت عليهم وقدم ملاحظاتك إذا لزم الأمر",
  YouCanEncourage: " يمكنك تشجيع المتدرب الخاص بك على إنشاء دورة تدريبية عبر الإنترنت على منصتنا تكنوبيديا و في هذه الحاله سوف تعمل كمشرف على المحتوى المقدم في الدورة و سوف تتشارك مع المتدرب الخاص بك في أرباح مبيعات الكورسات",
  ToSeeHowEasyMentor: "لتعرف كم هو سهل الارشاد والتوجيه على ريكجبت",
  JoinAsMentor: "أنضم لينا كموجهه",

  // About us
  AseeuMentors: "موجهي ريكجبت",
  SpicialistsGuiding: "المتخصصين الذين يقومون بتوجيه و ارشاد شباب ريكجبت",
  WhoWeAre: "من نحن",
  ASEEUStandsFor: "ريكجبت هي الحروف الأولى من 'العلماء والمهندسون العرب في أوروبا' بالإنجليزيه. بدأت الشركة كمبادرة من قبل مجموعة صغيرة من الشباب المهاجريين. كانت فكرتنا الرئيسية هي مساعدة الشباب في منطقة الشرق الأوسط وشمال إفريقيا للحصول على فرص عمل جيدة أو بدء أعمالهم التجارية ومساعدة المهاجرين على تسريع عملية الاندماج في المجتمعات المستضيفة لهم",
  OurMission: "مهمتنا",
  OurMissionIsHelp: "مهمتنا هي مساعدة الشباب في الحصول على وظائف أحلامهم من خلال التوجيه والتعليم المناسبين أو بدء أعمالهم من خلال بناء شبكة مهنية والحصول على التوجيهات اللازمة لتحقيق النجاح. من ناحية أخرى ، نود مساعدة الاقتصادات الأوروبية للوصول إلى القوى العاملة الماهرة التي يمكن أن تملأ الفجوة بين العرض والطلب في بعض القطاعات مثل تكنولوجيا المعلومات والذكاء الاصطناعي",
  OurDeam: "حلمنا",
  OurDreamIsToHave: "حلمنا هو أن يكون لدينا قريبًا عدد كبير من الخبراء والمتخصصين الذين يشاركوننا نفس الرؤية والاستعداد لتوجيه الشباب إلى الاتجاه الصحيح ومساعدتهم على تحقيق أحلامهم",
  OurVision: "رؤيتنا",
  WeBelieveThat: "نعتقد أن الشباب في منطقة الشرق الأوسط وأوروبا لديهم إمكانات عالية للنمو ودفع اقتصادات البلدان حول البحر الأبيض المتوسط. نعتقد أن خلق مساحة للتعاون بين الشباب الذين يعيشون في هذه المنطقة يمكن أن يؤدي إلى نتائج مذهلة من حيث الازدهار والتفاهم المتبادل",
  FAQ: "الأسئله الشائعه",
  TheseAreFaq: "هذه هي الأسئلهاالأكثر شيوعاً حول ريكجبت",

  // EntreprenuerOasis
  BoostingSuccessWy: "نمهدلك الطريق للنجاح",
  HelpingYouTackle: "نساعدك على تخطي جميع العقبات التي قد توقفك عن مواصلة مسارك و نزودك بجميع الأدوات التي تحتاجها لتكون رائد أعمال ناجح",
  StartupBusiness: "أبدأ البيزنس الخاص بيك",
  StartBusinessToSuccess: "إن بدء عمل تجاري والانتقال به إلى مستوى النجاح ليس بالمهمة السهلة. لتحقيق النجاح ، هناك ركائز يجب بناؤها لضمان النجاح و الاستمراريه",
  WeCanBuildTeam: "يمكننا مساعدتك في بناء فريق العمل الذي تحتاجه لتنفيذ الفكرة. يمكننا مساعدتك أيضًا من خلال خبرتنا في بدء التشغيل. علاوة على ذلك ، إذا رأينا إمكانات عالية في فريقك وفكرتك ، فيمكننا مساعدتك في التمويل الأولي لإنشاء المنتج الأولي ومساعدتك في مرحلة الإطلاق",
  FoundersIdea: "الفكرة: يجب أن تكون فكرة المشروع الخاص بك واضحه في ذهنك",
  FoundersAttitude: "أهليتك لهذا المسار: يجب أن يكون لديك إرادة قوية ورغبة كبيره بالإضافه الى الكثير من الصبر للمواصله في هذا المسار",
  FoundersPatience: "الخبرة: يجب أن يكون لديك الخبرة والفريق المؤهل للإنتقال بمشروع من مرحلة الفكره الى الواقع",
  FounderFunding: "التمويل: يجب أن يكون لديك المال الكافي للبدء أو أن تعرف كيف ستؤمن الأموال اللازمه للبدأ و الاستمرار",
  // explainer sections in EntreprenuerOasis
  CoFounderMatching: "المساعده في ايجاد الشريك",
  EntrepreneurshipNotEasy: "ريادة الأعمال ليست طريقًا سهلاً على الإطلاق. يحتاج إلى الكثير من الصبر والمثابرة و العمل الشاق.  يكفي إلقاء نظرة سريعة على شركات التقنيه مثل جوجل, فيسبوك, باي بال أو ميكروسوفت سوف تجد أنه من المستحيل تقريبًا أو على الأقل من الصعب جدًا تحقيق نجاح كبير بمفردك. مهمتنا هي مساعدتك في العثور على شريك النجاح.",
  EngageOthers: "تفاعل مع الأخريين",
  PropablyHaveQuestions: "على الأرجح ، سيكون لديك العديد من الأسئلة. تفاعل مع رواد الأعمال الآخرين. اسألهم عما تريد لمساعدتك في إيجاد حل لمشكلات عملك. من خلال هذه المساحه سوف نساعد بعضنا البعض لبناء مجتمع حقيقي من رواد الأعمال.",
  EquityManagement: "أدوات أونلاين للإداره الماليه",
  DoYouHavePartners: "هل لديك شركاء في شركتك الناشئه ولا تعرف كيفية حساب رأس المال لكل منهم؟ نحن نقدم لك أداة رائعة عبر الإنترنت لتسجيل نفقاتك باستمرار وحساب و متابعة ملكية كل عضو في الشركة بشكل شفاف تماماً يساعدكم على مواصلة العمل و بناء الثقه بين بعضكم البعض و بناء الثقه مع المستثمريين",
  startupTools: "أدوات للشركات الناشئه",
  ListHereBestTools: "نعرض لك هنا أفضل الأدوات التي يمكن أن تساعدك في رحلة تطوير مشروعك الخاص. عندما تبدأ رحلتك ، سيكون لديك العديد من الأسئلة حول العديد من الموضوعات مثل ما هي الأدوات التي يجب أن أستخدمها لبناء منتجي أو خدمتي ، والأدوات التي تساعدني في متابعة الحسابات ، والتسويق ، وإدارة الفريق ، وتحصيل المدفوعات ، والكثير من الأسئلة الأخرى",
  // Footer
  EducationalCourses: "كورسات تعليميه",
  FrequentlyAskedQuestions: "أكثر الأسئله شيوعا",
  BeMentorOnASEEU: "كُن مرشد و موجهه معنا",
  aseeuAsJbsBoard: "أستعمل ريكجبت كمنصه خاصه بك للوظائف",
  TeachOnASEEUTechnopedia: "درّس على منصتنا تكنوبيديا",
  JobsInCompanies: "وظائف متاحه على منصتنا",
  JobsInAseeu: "اعمل معنا",
  InterestedInvestASEEU: "مهتم بالأستثمار معنا؟"
}

export default ArabicTranslation