import React, { useEffect, useState } from 'react'
import draftToHtml from 'draftjs-to-html';
import Canvas from '../../../components/Canvas/Canvas'
import Modal from '../../Modal/Modal'
import PathForm from './PathForm'
// import userLogo from '../../../images/userLogo.svg'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { ListPathDetails } from '../../../actions/pathActions'
import history from '../../../history'

export default function PathScreen({ selectedPath }) {
  const dispatch = useDispatch();
  const { pathId } = useParams()
  const [modalOpen, setModalOpen] = useState(false)
  const [_selectedPath, setSelectedPath] = useState(selectedPath)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const pathDetails = useSelector((state) => state.pathDetails)
  const { pathdetails, detailserror } = pathDetails

  useEffect(() => {
    if (pathId) {
      if (!pathdetails) {
        dispatch(ListPathDetails(pathId))
      } else {
        setSelectedPath(pathdetails)
      }
    } else {
      setSelectedPath(selectedPath)
    }
  }, [dispatch, pathId, pathdetails, selectedPath])

  const draw = (ctx) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.lineWidth = 3;
    // full circle
    ctx.beginPath();
    ctx.strokeStyle = "#e0e0e0";
    ctx.arc(100, 75, 50, 0, 2 * Math.PI);
    ctx.stroke();
    // indicator
    ctx.lineWidth = 4;
    ctx.beginPath();
    ctx.strokeStyle = "#7ef0ac";
    ctx.arc(100, 75, 50, 0, (_selectedPath?.jobDemand / 100) * 2 * Math.PI);
    ctx.stroke();
    // indicator
    ctx.beginPath();
    ctx.strokeStyle = "#604cac";
    ctx.arc(100, 75, 35, 0, (_selectedPath?.startEasy / 100) * 2 * Math.PI);
    ctx.stroke();
    return [_selectedPath?.startEasy, _selectedPath?.jobDemand]
  }

  const openModal = () => {
    if (!userInfo?._id) {
      history.push(`/signin?redirect=${window.location.pathname}`)
    } else {
      setModalOpen(true)
    }
  }
  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      {_selectedPath?.article ? (
        <>
          {/* Start Details Jobs */}
          <div className="body-details d-flex position-relative">
            <div>
              <h5 className="text-capitalize">{_selectedPath?.pathName}</h5>
              {_selectedPath?.keySkills?.length !== 0 && (
                <div className="row-tags mt-3">
                  {_selectedPath?.keySkills?.map((cat, indx) => {
                    return (
                      <p key={indx}>{cat}</p>
                    )
                  })}
                </div>
              )}
            </div>
            {userInfo?._id ? (_selectedPath.user._id.toString() !== userInfo._id.toString() && (
              <div>
                <button className="apply-btn" onClick={openModal}>Interested</button>
              </div>
            )) : (
              <div>
                <button className="apply-btn" onClick={openModal}>Interested</button>
              </div>
            )}
          </div>
          <div className="more-info mt-3">
            <div className="article" dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(_selectedPath?.article)) }}>
            </div>
            {/* {_selectedPath?.recommendedGroups && _selectedPath?.recommendedGroups[0] && (
              <>
                <h6 className="text-capitalize mt-4 mb-3">this track is Recommended for</h6>
                <ul className="list-unstyled position-relative">
                  {_selectedPath?.recommendedGroups?.map((recommendedGroup, indx) => {
                    return (
                      <li key={indx}>{recommendedGroup}</li>
                    )
                  })}
                </ul>
              </>
            )} */}
            {(_selectedPath?.jobDemand || _selectedPath?.startEasy) && (
              <div className="row align-items-center statistics-container">
                <div className="col-md-3 col-sm-12">
                  <div className="statistics-indicator">
                    <Canvas draw={draw} />
                  </div>
                </div>
                <div className="col-md-7 col-sm-12">
                  <div className="statistics-info">
                    <p className="job styled-help-info">Probability of catching job in this track
                      <i className="far fa-question-circle">
                        <span>Note!: This is just an estimation based on mentor expertise in the field. So, this estimation don't give an absolute guarantee that you will get a job in the field </span>
                      </i>
                    </p>
                    <span>{_selectedPath?.jobDemand} %</span>
                  </div>
                  <div className="statistics-info">
                    <p className="startB styled-help-info">Easiness of business startup
                      <i className="far fa-question-circle">
                        <span>Note!: This is just an estimation based on mentor expertise in the field. This estimation depends on some factors such as amount of fund needed to startup, sponsorship availability and features of the business in this field </span>
                      </i>
                    </p>
                    <span>{_selectedPath?.startEasy} %</span>
                  </div>
                </div>
              </div>
            )}
            <div className="row path-instruct-wrap">
              <div className="btn-paths-info d-flex position-relative">
                <img className="img-fluid me-4" src="/profileLogo_new.png" alt="userLogo" />
                <div className="text-start">
                  <h5>{_selectedPath?.user?.name}</h5>
                  <span>{_selectedPath?.user?.mentorDetails?.mentorProfession}</span>
                </div>
              </div>
              <p className="mt-3">{_selectedPath?.user?.mentorDetails?.mentorIntroduction}</p>
            </div>
          </div>
          {modalOpen && (
            <Modal closeModal={closeModal}>
              <PathForm closeModal={closeModal} title={_selectedPath?.pathName} userInfo={userInfo} pathID={pathId} />
            </Modal>
          )}
          {/* End Details Jobs */}
        </>
      ) : null}

    </>
  )
}

PathScreen.defaultProps = {
  selectedPath: {}
}
