import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { PATH_DETAILS_RESET } from '../../../constants/pathConstants';
// import userLogo from '../../../images/userLogo.svg'

export default function PathSelector({ paths, UrlRoot }) {
  const dispatch = useDispatch();
  const [selectedPaths, setSelectedPaths] = useState(paths)

  useEffect(() => {
    setSelectedPaths(paths)
  }, [paths])

  const onSelection = (path, indx) => {
    dispatch({ type: PATH_DETAILS_RESET })
    setSelectedPaths((preSelections) => {
      const newPaths = preSelections.map((path, pathIndx) => {
        if (pathIndx === indx) {
          return { ...path, selected: "active" }
        } else {
          return { ...path, selected: "" }
        }
      })
      return newPaths
    })
  }

  return (
    <>
      {selectedPaths.length !== 0 && selectedPaths.map((path, indx) => {
        const userImage = path?.user?.userImage?.img?.data ? Buffer.from(path?.user?.userImage?.img?.data).toString('base64') : ""
        return (
          <Link
            key={indx}
            className={`path-card nav-link mb-4 ${path.selected}`}
            onClick={() => onSelection(path, indx)}
            to={`${UrlRoot}/path/${path._id}`}
          >
            <h5>{path.pathName}</h5>
            <div className="btn-paths-info d-flex position-relative">

              {userImage ? (
                <img
                  className="img-fluid me-4"
                  src={`data:image/${userImage.contentType};base64,${userImage}`}
                  alt="profile"
                />
              ) : (
                <img className="img-fluid me-4" src="/profileLogo_new.png" alt="userLogo" />
              )}
              <div className="text-start">
                <h6>{`${path.user.name}`}</h6>
                <span>{path.user.mentorDetails.mentorProfession}</span>
              </div>
            </div>
            <div className="row-tags mt-3">
              {path.keySkills.map((cat, indx) => {
                return (
                  <p key={indx}>{cat}</p>
                )
              })}
            </div>
            <div className="language-tag">
              <i className="fas fa-globe"></i>
              {path.language}
            </div>
          </Link>
        )
      })}
    </>
  )
}
