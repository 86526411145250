import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { verifyTokenValidity } from '../../../actions/userActions'
import ButtonLink from '../../../components/ButtonLink/ButtonLink'
import MultiSelector from '../../../components/MultiSelector/MultiSelector'
import history from '../../../history'
import { vidLanguageoptions } from '../../../SampleData/standardVals'
import Modal from '../../../screen/Modal/Modal'
import NewApplyModal from '../ModalScreens/NewApplyModal'

export default function FounderInvite() {
  const dispatch = useDispatch()
  const { path: pathroot } = useRouteMatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [modalOpen, setModalOpen] = useState(false)
  const [vidID, setVidID] = useState("https://www.youtube.com/embed/Eug_rUjfbI4")

  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    if (!userInfo?._id) {
      setTimeout(() => {
        history.push(`/signin?redirect=${pathroot}`)
      }, 500);
    } else {
      dispatch(verifyTokenValidity())
    }
  }, [userInfo, dispatch, pathroot])

  const onvidSelection = (selectionVal, index) => {
    const baseVidLink = "https://www.youtube.com/embed/"
    const vidSerialID = selectionVal === "arabic" ? "-k9YmStctJs" : "Eug_rUjfbI4"
    const videoLink = baseVidLink+vidSerialID
    setVidID(videoLink)
  }

  return (
    <div className="user-invite">
      <div className="title-bar-profile">
        <div className="sction-title">Information</div>
        <ButtonLink ClassName="learn-more-btn">
          <button onClick={openModal}> Apply Now </button>
        </ButtonLink>
      </div>
      <div className="body">
        <ul>
          <li>
            Are you Founder for Startup company or small business company?
          </li>
          <li>
            Do you have experience in specific field and you wish to find a co-founder who has a specific set of skills to share you the journey?
          </li>
          <li>
            Do you have the experience and the team to work with but you want access to all tools that help you as Entrepreneur?
          </li>
          <li>
            Do you need a tailored consultation?
          </li>
        </ul>
        <p>If you fulfil some of these requirements, Make sure that you updated your profile and apply. When you start your application, try to give us enough details about your experience and why you are interested to register as founder</p>
        {/* <h4>Check how you can teach on our Technopedia:</h4> */}
      </div>
      {/* <div className="row vidinfo-wrap">
        <div className="col-md-5 col-sm-12">
          <MultiSelector
            label="Check how teaching works on Technopedia"
            onSelection={onvidSelection}
            arrangementClass="vertical"
            options={vidLanguageoptions}
          />
        </div>
        <div className="col-md-7 col-sm-12">
          <iframe
            width="100%"
            height="350"
            src={vidID}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div> */}
      {modalOpen && (
        <Modal closeModal={closeModal}>
          <NewApplyModal applicationType="founder" closeModal={closeModal} title="Apply As Founder" />
        </Modal>
      )}
    </div>
  )
}
