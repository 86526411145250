import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createCourseReview } from '../../actions/courseActions'
import ButtonLink from '../../components/ButtonLink/ButtonLink'
import Message from '../../components/Message/Message'
import cancelIcon from '../../images/general-purpose/cancelIcon.png'

export default function FeedbackForm({ closeModal, courseId }) {
  const dispatch = useDispatch()

  const courseReviewCreate = useSelector((state) => state.courseReviewCreate)
  const { successReview, errorReview } = courseReviewCreate

  const [comment, setComment] = useState("")
  const [rating, setRating] = useState(null)

  const [message, setMessage] = useState({
    msgType: "",
    messageBody: ""
  })

  useEffect(() => {
    if (errorReview) {
      setMessage({
        msgType: "message-error",
        messageBody: errorReview
      })
      return
    }
    if (successReview) {
      setMessage({
        msgType: "message-success",
        messageBody: "Your rating has been added successfuly. Thanks for your feedback!"
      })
      setTimeout(() => {
        closeModal()
      }, 3000);
    }
  }, [successReview, errorReview, closeModal])

  const reviewHandler = (e) => {
    e.preventDefault()
    if (!comment || comment.length < 10) {
      setMessage({
        msgType: "message-error",
        messageBody: "Very short feedback or you didn't write any. feedback must be more than 10 characters."
      })
      return
    }
    if (!rating) {
      setMessage({
        msgType: "message-error",
        messageBody: "you didn't rate the course"
      })
      return
    }
    dispatch(
      createCourseReview(courseId, { rating, comment })
    )
  }

  return (
    <div className="lessondetail-form-container" onClick={e => e.stopPropagation()}>
      <div className="cancel-button">
        <div className="cancel-wrapper" onClick={closeModal}>
          <img src={cancelIcon} alt="How We Work" />
        </div>
      </div>
      <h3>Let others know your opinion about the course</h3>
      <div className="lesson-form">
        <div className="submit-review-form">
          <p>Your review is very important for us to continue deliver a good content. Please, if you completed the course, rate it so we can improve our content</p>
          <ul style={{ display: 'contents' }}>
            {[...Array(5).keys()].map(indx => (
              <li key={indx} onClick={() => setRating(indx + 1)}> <i className="fas fa-star"></i> </li>
            ))}
          </ul>
          {rating ? (
            <p style={{ padding: '15px 0px', fontWeight: 'bold' }}> Your Rating: <span style={{ marginLeft: '10px', color: 'blueviolet' }}> {rating} </span></p>
          ) : (
            <p style={{ color: '#9a9a9a', padding: '15px 0px', fontWeight: 'bold' }}> If you completed the course, Please rate it and leave your review </p>
          )}
          <form onSubmit={reviewHandler}>
            <label className="general-input--style-label">Your Message</label>
            <textarea
              className="form-control general-input--style"
              type="text"
              value={comment}
              placeholder="Write Commnent..."
              onChange={(e) => setComment(e.target.value)}
            />
            <ButtonLink ClassName="learn-more-btn upper-case mt-3">
              <input type="submit" value="Submit Review" />
            </ButtonLink>
          </form>
          {message.messageBody && <Message ClassName={message.msgType}>{message.messageBody}</Message>}
        </div>
      </div>
    </div>
  )
}
