import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ButtonLink from "../../../components/ButtonLink/ButtonLink";

const RealImpact = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin
  const language = useSelector((state) => state.language);
  const { translation } = language;

  const externalVideoArabic = "https://www.youtube.com/watch?v=-k9YmStctJs"
  const externalVideoEnglish = "https://www.youtube.com/watch?v=Eug_rUjfbI4"

  return (
    <div className="start-up text-center text-white">
      <h2 className="text-capitalize mb-4">{translation.MakeRealImpact}</h2>
      <p className="w-50 m-auto mb-2 px-5">
        {translation.JumbToLevel}
      </p>
      <p className="w-50 m-auto mb-4 px-5">
        {translation.NoteSure} (
        <span onClick={() => window.open(externalVideoArabic, "_blank")} className="watch-external-vid"> {translation.Arabic} </span>
        |
        <span onClick={() => window.open(externalVideoEnglish, "_blank")} className="watch-external-vid"> {translation.English}</span>
        ) {translation.ToSeeHowMuch}
      </p>
      <ButtonLink ClassName="learn-more-btn">
        <Link to={userInfo?._id ? "/profile/instructorboard" : "/signin?redirect=profile/instructorboard"}>{translation.TeachWithUs}</Link>
      </ButtonLink>
    </div>
  );
};

export default RealImpact;
